import React from "react";
import { connect } from "react-redux";
import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from "module/tools";

import { Tabs, Tab } from "@material-ui/core";
import { RefreshOutlined, ErrorOutlined, CheckCircleOutlined, Warning } from "@material-ui/icons";
import { TabPanel } from "components/Common/Tabs";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import ProjectGeneralTab from "./projectDetails/ProjectGeneralTab";
import ProjectJobsTab from "./projectDetails/ProjectJobsTab";
import ProjectCustomerContractsTab from "./projectDetails/ProjectCustomerContractsTab";

function ProjectDetail({ amc, isReadonly }) {
  const [tabIdx, setTabIdx] = React.useState(0);

  var itemHasChanged = false;
  var itemSaveStatus = null;
  var itemHasWarnings = false;
  if (!!amc) {
    itemHasChanged = amc.hasChanged["PROJECT"];
    itemSaveStatus = amc.saveStatus["PROJECT"];
    itemHasWarnings = !isArrayNullOrEmpty(amc.errors["PROJECT"]);
  }

  var tabStatus = buildTabs();
  var tabs = tabStatus.map(tab => {
    var tabButton = <span>{tab.title}</span>;
    var tabContent = "";

    switch (tab.component) {
      case "ProjectGeneralTab":
        var star = "";
        var saveStatusIcon = GetSaveStatusIcon(itemSaveStatus);
        var warningIcon = itemHasWarnings ? <Warning style={{ color: "red" }} /> : "";
        if (itemHasChanged) star = " *";
        tabButton = (
          <span>
            {saveStatusIcon}
            {warningIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ProjectGeneralTab isReadonly={isReadonly} />;
        break;
      case "ProjectJobsTab":
        tabButton = <span>{tab.title}</span>;
        tabContent = <ProjectJobsTab isReadonly={isReadonly} />;
        break;
      case "ProjectCustomerContractsTab":
        tabButton = <span>{tab.title}</span>;
        tabContent = <ProjectCustomerContractsTab isReadonly={isReadonly} />;
        break;
      default:
        break;
    }

    return {
      tabButton: tabButton,
      tabContent: tabContent,
      tabName: tab.component
    };
  });

  return (
    <div style={{ width: "99%" }}>
      <>
        <Tabs variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Tabs project">
          {tabs.map((t, idx) => (
            <Tab key={"projectTab" + idx} label={t.tabButton} />
          ))}
        </Tabs>
        {tabs.map((t, idx) => (
          <TabPanel value={tabIdx} index={idx} id="projectTab">
            {t.tabContent}
          </TabPanel>
        ))}
      </>
    </div>
  );
}

function buildTabs() {
  return [
    {
      index: 0,
      title: <Trans>Project General</Trans>,
      component: "ProjectGeneralTab",
      status: "neutral",
      ref: React.createRef()
    },
    {
      index: 1,
      title: <Trans>Project Jobs</Trans>,
      component: "ProjectJobsTab",
      status: "neutral",
      ref: React.createRef()
    },
    {
      index: 2,
      title: <Trans>Project Customer Contracts</Trans>,
      component: "ProjectCustomerContractsTab",
      status: "neutral",
      ref: React.createRef()
    }
  ];
}

function GetSaveStatusIcon(saveStatus) {
  if (!isNull(saveStatus)) {
    switch (saveStatus) {
      case "PENDING":
        return <RefreshOutlined />;
      case "ERROR":
        return <ErrorOutlined style={{ color: "red" }} />;
      case "SUCCESS_RELOAD":
      case "SUCCESS":
        return <CheckCircleOutlined style={{ color: "green" }} />;
    }
  }
  return "";
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(ProjectDetail));
