import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { CircularProgress, withStyles } from "@material-ui/core";
import SearchCriterias from "./part/search/SearchCriterias";

import { isNullOrEmpty } from "tools";
import CardError from "components/Card/CardError";
import { ThirdPartySearch } from "./actions/ThirdPartyActions";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SearchResult from "./part/search/SearchResult";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

const ThirdPartySelect = ({ defaultLang, setThirdParty, initialCriteria, searchModes, masterValues }) => {
  const [criteria, setCriteria] = useState({ ...initialCriteria, searchMode: searchModes[0] });
  const [state, setState] = useState({ isLoading: false });

  useEffect(() => {
    var newCriteria = criteria;

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    if (isNullOrEmpty(criteria.pageSize)) {
      var defaultPageSize = 10;
      if (getPref !== null) {
        defaultPageSize = getPref.Pref.ResultPageSize * 1;
      }

      newCriteria = { ...newCriteria, pageSize: defaultPageSize };
    }

    if (isNullOrEmpty(criteria.criteriaCountry)) {
      var defaultCountry = "FR";
      if (getPref !== null) {
        defaultCountry = getPref.InfoGeneral.Country;
      }

      newCriteria = { ...newCriteria, criteriaCountry: defaultCountry };
    }

    setCriteria(newCriteria);
  }, []);

  const launchSearch = () => {
    setCriteria(criteria);
    setState({ ...state, isLoading: true });
    ThirdPartySearch(
      criteria,
      results => setState({ isLoading: false, searchResults: results }),
      error => setState({ isLoading: false, error: error })
    );
  };

  const cleanSearch = () => setCriteria({ ...initialCriteria, searchMode: searchModes[0] });

  var searchResult = null;
  if (state.isLoading) {
    searchResult = <CircularProgress />;
  } else if (state.error) {
    searchResult = <CardError error={state.error} />;
  } else if (state.searchResults) {
    searchResult = <SearchResult results={state.searchResults} criteria={criteria} launchSearch={launchSearch} setThirdParty={setThirdParty} />;
  }

  var layoutSearchResult;
  if (!isNullOrEmpty(searchResult)) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {searchResult}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <SearchCriterias
            criteria={criteria}
            setCriteria={setCriteria}
            launchSearch={launchSearch}
            cleanSearch={cleanSearch}
            showSearchCriteriaOnSideBar={true}
            searchModes={searchModes}
            defaultLang={defaultLang}
            masterValues={masterValues}
          />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = (
      <SearchCriterias
        criteria={criteria}
        setCriteria={setCriteria}
        launchSearch={launchSearch}
        cleanSearch={cleanSearch}
        showSearchCriteriaOnSideBar={false}
        searchModes={searchModes}
        defaultLang={defaultLang}
        masterValues={masterValues}
      />
    );
  }

  return (
    <>
      <div>{layoutSearchResult}</div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartySelect));
