import * as Actions from "../actions/types";

const initialState = {
  criterias: {
    startPage: 0,
    pageSize: 10
  }
};

export default function TP_DunsMonitoringReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.TP_COPERNIC_DUNSMONITORING_BEGIN_ACTION:
      return {
        ...state,
        isPerforming: true
      };

    case Actions.TP_COPERNIC_DUNSMONITORING_END_ACTION:
      return {
        ...state,
        isPerforming: false
      };

    case Actions.TP_COPERNIC_DUNSMONITORING_GET_ALL:
      return {
        ...state,
        result: action.result
      };

    case Actions.TP_COPERNIC_DUNSMONITORING_GET_DETAIL:
      return {
        ...state,
        item: action.item
      };

    case Actions.TP_COPERNIC_DUNSMONITORING_SAVE_END:
      return {
        ...state
      };

    case Actions.TP_COPERNIC_DUNSMONITORING_SET_CRITERIAS:
      return {
        ...state,
        criterias: action.criterias
      };

    default:
      return state;
  }
}
