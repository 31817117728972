import { ApimDelete, ApimDeletePromise, ApimGetPromise, ApimPostPromise } from "components/Common/ApimSender";

export function CustomerCrupdate(customer) {
  return ApimPostPromise("api/tpColumbus/customers", customer);
}

export function CustomerGet(thirdPartyId) {
  return ApimGetPromise(`api/tpColumbus/customers/${thirdPartyId}`);
}

export function GetCustomerHistory(thirdPartyId) {
  return ApimGetPromise(`api/tpColumbus/customers/${thirdPartyId}/history`);
}

export function DeleteCustomerSubsidairyLink(thirdPartyId, subsidairyCode) {
  return ApimDeletePromise(`api/tpColumbus/customers/${thirdPartyId}/subsidiary/${subsidairyCode}`);
}
