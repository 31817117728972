import React from 'react';

import SiteStructures from './SiteStructures';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

const TabSiteStructure = function ({ site, setSite, isEditable, openStructure }) {
    return (<SiteStructures site={site} setSite={setSite} isEditable={isEditable} openStructure={openStructure} />);
}

export default withStyles(thirdPartySearchCriteriaStyle)(TabSiteStructure);