import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/Job.Actions";
import { displayWarningFromErrorMessages, getErrorMessage, getErrorStateFromErrorMessages } from "module/tools";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { StructureUe } from "store/MasterValue/MasterValueTypes";

function JobIdentifierAndSourcePart({ amc, itemChanged, availableUes, masterValues, isReadonly }) {
  var item = !!amc.currentItem["JOB"] ? amc.currentItem["JOB"] : {};
  var warnings = amc.errors["JOB"];

  const [ues, setUes] = useState([]);

  useEffect(() => {
    let lst = availableUes;
    if (isReadonly) {
      var ue = masterValues.StructureUe.find(ue => ue.identifier === item.ueIdentifier);
      lst = ue ? [ue] : [];
    }
    setUes(lst);
  }, []);

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Identifiers and sources</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Label</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText isEditable={!isReadonly} id="label" value={item.label} onChange={e => itemChanged({ ...item, label: e.target.value })} />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Id Erp</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText isEditable={!isReadonly} id="idErp" value={item.idErp} onChange={e => itemChanged({ ...item, idErp: e.target.value })} />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Local Identifier</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="localIdentifier"
              value={item.applicationSourceIdentifier}
              onChange={e => itemChanged({ ...item, applicationSourceIdentifier: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Local Application</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={false}
              id="applicationSource"
              value={item.applicationSource}
              onChange={e => itemChanged({ ...item, applicationSource: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/UEIDENTIFIER")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/UEIDENTIFIER")}>
              <Trans>Ue</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            {/* <OldStructureAutocompleteWithDefaultValue
              selectedValue={item.ueIdentifier}
              readOnly={isReadonly}
              structures={structureUes}
              showErrorTooltip={false}
              error={getErrorMessage(warnings, "CUSTOMERCONTRACT/GENERAL/UEIDENTIFIER")}
              onValueChange={e => itemChanged({ ...item, ueIdentifier: e })}
            /> */}
            <ItemAutoComplete
              isEditable={!isReadonly}
              items={ues.map(ue => {
                return { ...ue, code: ue.identifier };
              })}
              selectedValue={item?.ueIdentifier}
              onValueChange={e => itemChanged({ ...item, ueIdentifier: e })}
              formatText={ue => `${ue.identifier} - ${ue.oldIdentifier} - ${ue.label}`}
              maxResultNumber={100}
              error={getErrorMessage(warnings, "CUSTOMERCONTRACT/GENERAL/UEIDENTIFIER")}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Analytical Reference</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="analyticalReference"
              value={item.analyticalReference}
              onChange={e => itemChanged({ ...item, analyticalReference: e.target.value })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    availableUes: state.AuthenticationReducer.user?.structuresAmc?.ues,
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobIdentifierAndSourcePart));
