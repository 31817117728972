import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import GenericServerAutocomplete from "components/Autocomplete/GenericServerAutocomplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/Job.Actions";
import { displayWarningFromErrorMessages, getErrorStateFromErrorMessages } from "module/tools";
import React from "react";
import { connect } from "react-redux";
import { CommonCurrency, JobActivityLevel3, JobNatureOfWork, JobStatus } from "store/MasterValue/MasterValueTypes";

function JobLinksPart({ amc, itemChanged, isReadonly }) {
  var item = !!amc.currentItem["JOB"] ? amc.currentItem["JOB"] : {};
  var warnings = amc.errors["JOB"];

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Links</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Project</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <GenericServerAutocomplete
              selectedValue={item.projectId}
              searchPartialUri={"api/Projects/Autocomplete/"}
              readOnly={isReadonly}
              onValueChange={e => itemChanged({ ...item, projectId: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/JOBSTATUSCODE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/JOBSTATUSCODE")}>
              <Trans>Status</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobStatus}
              selectedValue={item.jobStatusCode}
              isEditable={!isReadonly}
              prefixCode={true}
              showErrorTooltip={false}
              error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/JOBSTATUSCODE")}
              onValueChange={e => itemChanged({ ...item, jobStatusCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/ACTIVITYLEVEL3CODE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/ACTIVITYLEVEL3CODE")}>
              <Trans>Activity Level 3</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobActivityLevel3}
              selectedValue={item.activityLevel3Code}
              isEditable={!isReadonly}
              prefixCode={true}
              showErrorTooltip={false}
              error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/ACTIVITYLEVEL3CODE")}
              onValueChange={e => itemChanged({ ...item, activityLevel3Code: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CURRENCYCODE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CURRENCYCODE")}>
              <Trans>Currency</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={CommonCurrency}
              selectedValue={item.currencyCode}
              isEditable={!isReadonly}
              prefixCode={true}
              showErrorTooltip={false}
              error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/CURRENCYCODE")}
              onValueChange={e => itemChanged({ ...item, currencyCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Nature of work</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobNatureOfWork}
              selectedValue={item.natureOfWorkCode}
              isEditable={!isReadonly}
              prefixCode={true}
              onValueChange={e => itemChanged({ ...item, natureOfWorkCode: e })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobLinksPart));
