import { Trans } from "@lingui/macro";
import { Box, Checkbox, CircularProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, withStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import { extractErrorMessage } from "module/tools";
import React, { useState } from "react";
import { JobActivityLevel1, JobActivityLevel2, LoadMasterValues } from "store/MasterValue/MasterValueTypes";

const TabActivityLevel = ({ isFromJade, activities, setActivities, masterValues, defaultLang, isEditable, errors }) => {
  const [isLoading, setIsLoading] = useState("init");

  if (isLoading === "init") {
    if (!masterValues[JobActivityLevel1] && !masterValues[JobActivityLevel2]) {
      setIsLoading("true");
      LoadMasterValues([JobActivityLevel1, JobActivityLevel2]).then(() => setIsLoading(null));
    } else setIsLoading(null);
  }

  if (!!isLoading) {
    return <CircularProgress />;
  }

  const mvActLevel1 = masterValues[JobActivityLevel1];
  const mvActLevel2 = masterValues[JobActivityLevel2];

  const toggleActivity = actCode => {
    let act = activities.filter(a => a.activityLevel2Code !== actCode);
    if (!activities.some(a => a.activityLevel2Code === actCode)) act = [...act, { activityLevel2Code: actCode }];
    setActivities(act);
  };

  const canEditActivities = !isFromJade && isEditable;

  let bannerError = <></>;
  let errorActivityLevel = extractErrorMessage(errors, "ACTIVITY_LEVEL", defaultLang);
  if (errorActivityLevel) {
    bannerError = <Alert severity="error">{errorActivityLevel}</Alert>;
  }

  const lists = mvActLevel1.map(actLevel1 => {
    let actLevel2s = mvActLevel2.filter(a => a.activityLevel1Code === actLevel1.code);

    let actLevel2Items = actLevel2s.map(actLevel2 => {
      const labelId = `actLvl2_${actLevel2.code}`;
      const checked = activities.some(a => a.activityLevel2Code === actLevel2.code);
      return (
        <ListItem
          key={labelId}
          dense
          button
          style={{ backgroundColor: checked ? green[200] : "#FFFFFF" }}
          onClick={() => canEditActivities && toggleActivity(actLevel2.code)}
        >
          <ListItemIcon>
            <GreenCheckbox
              edge="start"
              size="small"
              checked={checked}
              tabIndex={-1}
              inputProps={{ "aria-labelledby": labelId }}
              disabled={!canEditActivities}
            />
          </ListItemIcon>
          <ListItemText id={labelId} primary={actLevel2.code + " - " + GetMasterValueLabel(actLevel2, defaultLang)} />
        </ListItem>
      );
    });

    return (
      <Paper elevation={3} style={{ margin: "10px" }}>
        <Typography variant="body1">{GetMasterValueLabel(actLevel1, defaultLang)}</Typography>
        <List>{actLevel2Items}</List>
      </Paper>
    );
  });

  return (
    <>
      {bannerError}
      {isFromJade && (
        <Alert severity="warning">
          <Trans>Structure_IsFromJade</Trans>
        </Alert>
      )}
      <Box display="flex" flexDirection="column" flexWrap="wrap" maxHeight="1000px">
        {lists}
      </Box>
    </>
  );
};

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

export default TabActivityLevel;
