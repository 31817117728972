import React from "react";
import ThirdPartyList from "module/tpColumbus/thirdParties/ThirdPartyListing";
import Search from "@material-ui/icons/Search";
//lingui
import { Trans } from "@lingui/macro";
import GroupListing from "module/tpColumbus/groups/GroupListing";
import { AccountTree } from "@material-ui/icons";

const tpColumbusRoutes = [
  {
    path: "/tpColumbus/ThirdPartySearch",
    name: <Trans>Third parties Search</Trans>,
    short: <Trans>Third parties Search</Trans>,
    icon: Search,
    useAsComponentInMenu: true,
    component: ThirdPartyList
  },
  {
    path: "/tpColumbus/Groups",
    name: <Trans>Group Listing</Trans>,
    short: <Trans>Group Listing</Trans>,
    icon: AccountTree,
    useAsComponentInMenu: true,
    component: GroupListing
  }
];

export default tpColumbusRoutes;
