import React, { useEffect, useState } from "react";
import ReactTable from "react-table-6";
import { connect, useDispatch } from "react-redux";
import * as Tools from "module/tools";
import * as Actions from "module/amc/store/actions/Job.Actions";
import * as PhaseActions from "module/amc/store/actions/JobPhase.Actions";
import * as Types from "module/amc/store/actions/types";

// custom components

import {
  Breadcrumbs,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
  Link,
  Popover
} from "@material-ui/core";
import { Lock, LockOpen, RefreshOutlined, ErrorOutlined, CheckCircleOutlined, BusinessCenter, Ballot, Store, Warning } from "@material-ui/icons";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import Select, { components } from "react-select";
// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

//lingui
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcStyle from "assets/jss/material-dashboard-pro-react/components/amcStyle.jsx";
import JobDetail from "../components/jobs/JobDetail";
import JobPhaseDetail from "../components/jobs/JobPhaseDetail";

function JobPopup({ amc, defaultLang, closePopup, crupdate, generateNotification, validate, setPhaseToNull, classes }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");

  var showPopup = amc.showDetail["JOB"];
  var loading = amc.loading["JOB"];
  var item = amc.currentItem["JOB"];
  var phaseItem = amc.currentItem["JOBPHASE"];
  var hasChanged =
    amc.hasChanged["JOB"] || amc.hasChanged["JOBPHASE"] || amc.hasChanged["JOBPHASEBUDGET"] || amc.hasChanged["JOBPHASEFINALPROJECTION"];
  var warnings = amc.errors["JOB"];

  const isReadonly = typeof item !== "undefined" && !!item ? item.isReadonly : true;

  const open = Boolean(anchorEl);

  var errorPart = "";
  if (!!warnings && warnings.length > 0) {
    var errorDetails = warnings.map(errorMessage => {
      return <li>{Tools.getInternationalizedErrorMessage(defaultLang, errorMessage)}</li>;
    });

    errorPart = (
      <div>
        <Typography
          color="error"
          onClick={event => {
            setAnchorEl(event.currentTarget);
          }}
          style={{ cursor: "pointer" }}
        >
          <Warning color="error" />
          &nbsp;
          <Trans>There are some errors</Trans>
        </Typography>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Typography color="error">
            <ul>{errorDetails}</ul>
          </Typography>
        </Popover>
      </div>
    );
  }

  var buttonSave = "";
  if (!isReadonly && hasChanged) {
    buttonSave = (
      <Button onClick={crupdate} color="info">
        <Trans>Save</Trans>
      </Button>
    );
  }
  var usualJobButton = "";
  if (!phaseItem) {
    usualJobButton = (
      <>
        {buttonSave}
        <Button onClick={validate} color="info">
          <Trans>Check Validity</Trans>
        </Button>
        <Button onClick={generateNotification} color="info">
          <Trans>Send Notification</Trans>
        </Button>
        <Button onClick={closePopup} color="info">
          <Trans>Close</Trans>
        </Button>
      </>
    );
  } else {
    usualJobButton = (
      <Button onClick={setPhaseToNull} color="info">
        <Trans>Close Phase Detail</Trans>
      </Button>
    );
  }

  var breadCrumbJob = "";
  var breadCrumbJobPhase = "";
  if (!!item) {
    if (!!phaseItem) {
      breadCrumbJob = (
        <Link style={{ cursor: "pointer" }} onClick={setPhaseToNull}>
          <Trans> Job</Trans>&nbsp;({item.id})
        </Link>
      );
      breadCrumbJobPhase = (
        <Typography>
          <Trans>Phase</Trans>&nbsp;({phaseItem.code})
        </Typography>
      );
    } else {
      breadCrumbJob = (
        <Typography>
          <Trans>Job</Trans>&nbsp;({item.id})
        </Typography>
      );
    }
  }

  var popup = "";
  if (showPopup) {
    var popupContent = <CircularProgress />;
    if (!loading) {
      if (!!phaseItem) popupContent = <JobPhaseDetail isReadonly={isReadonly} />;
      else popupContent = <JobDetail isReadonly={isReadonly} />;
    }
    popup = (
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open
        onClose={closePopup}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogTitle>
          <GridContainer>
            <GridItem xs="9" sm="9" md="9">
              <Breadcrumbs aria-label="Breadcrumb">
                {breadCrumbJob}
                {breadCrumbJobPhase}
              </Breadcrumbs>
            </GridItem>
            <GridItem xs="3" sm="3" md="3">
              {errorPart}
            </GridItem>
          </GridContainer>
        </DialogTitle>
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          {popupContent}
        </DialogContent>
        <DialogActions>{usualJobButton}</DialogActions>
      </Dialog>
    );
  }

  return <div>{popup}</div>;
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    },
    crupdate: () => {
      dispatch(Actions.Crupdate());
    },
    generateNotification: () => {
      dispatch(Actions.GenerateNotification());
    },
    validate: () => {
      dispatch(Actions.Validate());
    },
    closePopup: () => {
      dispatch({
        type: Types.AMC_END_EDITING,
        module: "JOB"
      });
    },
    setPhaseToNull: () => {
      dispatch(PhaseActions.SelectItem(null));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcStyle)(JobPopup));
