import { ApimGetPromise } from "components/Common/ApimSender";
import { RootStore } from "store/RootStore";
import { isArray } from "tools";
import { addMasterValue } from "./MasterValueActions";

export const LoadMasterValues = mvTypes => {
  var masterValues = RootStore.getState().MasterValueReducer;

  return new Promise((callback, reject) => {
    if (!isArray(mvTypes)) {
      mvTypes = [mvTypes];
    }

    mvTypes = mvTypes.filter(mvType => !masterValues[mvType]);

    var promises = mvTypes.reduce(async (previousPromise, mvType) => {
      await previousPromise;
      return ApimGetPromise(GenerateApiUrl(mvType)).then(c => RootStore.dispatch(addMasterValue(mvType, c)));
    }, Promise.resolve());

    promises
      .then(() => {
        callback();
      })
      .catch(e => {
        reject(e);
      });
  });
};

function GenerateApiUrl(mvType) {
  switch (mvType) {
    case CommonCountry:
      return "api/countries/allMv";
    case StructureErt:
      return "api/erts";
    case StructureSj:
      return "api/sjs";
    case StructureUe:
      return "api/ues";
    case StructureUp:
      return "api/ups";
    default:
      return "api/mastervalue/" + mvType;
  }
}

// export const LoadMasterValue = (mvType, callbackFn, errorFn) => {
//   var dispatch = useDispatch();
//   var masterValues = useSelector(state => state.MasterValueReducer);
//   if (!!masterValues[mvType]) {
//     if (callbackFn) callbackFn();
//   } else {
//     ApimGet(
//       "api/mastervalue/" + mvType,
//       c => {
//         dispatch(addMasterValue(mvType, c));
//         if (callbackFn) callbackFn();
//       },
//       error => {
//         if (errorFn) {
//           errorFn(error);
//         }
//       }
//     );
//   }
// };

export const CommonCountry = "countries";
export const CommonCountryCounty = "CommonCountryCounty";
export const CommonCountryState = "CommonCountryState";
export const CommonCurrency = "CommonCurrency";
export const CommonLanguage = "CommonLanguage";
export const CommonTimeZone = "CommonTimeZone";
export const JobActivityLevel1 = "JobActivityLevel1";
export const JobActivityLevel2 = "JobActivityLevel2";
export const JobActivityLevel3 = "JobActivityLevel3";
export const JobBu = "JobBu";
export const JobContractualSetup = "JobContractualSetup";
export const JobCustomerContractStatus = "JobCustomerContractStatus";
export const JobCustomerContractType = "JobCustomerContractType";
export const JobIasManagementMode = "JobIasManagementMode";
export const JobStatus = "JobStatus";
export const JobNatureOfWork = "JobNatureOfWork";
export const JobProjectStatus = "JobProjectStatus";
export const JobProjectType = "JobProjectType";
export const JobSegmentationLevel1 = "JobSegmentationLevel1";
export const JobSegmentationLevel2 = "JobSegmentationLevel2";
export const JobSegmentationLevel3 = "JobSegmentationLevel3";
export const JobSegmentationLevel4 = "JobSegmentationLevel4";
export const JobUnitOfWork = "JobUnitOfWork";
export const LocationCharacteristicType = "LocationCharacteristicType";
export const LocationCharacteristicValue = "LocationCharacteristicValue";
export const LocationContactType = "LocationContactType";
export const LocationNature = "LocationNature";
export const LocationStatus = "LocationStatus";
export const LocationType = "LocationType";
export const NotifColfluxActionType = "NotifColfluxActionType";
export const NotifColfluxItemType = "NotifColfluxItemType";
export const NotifStatus = "NotifStatus";
export const StructIris2Activite = "StructIris2Activite";
export const StructSjMethodeConsolidation = "StructSjMethodeConsolidation";
export const StructSjSystemeCompta = "StructSjSystemeCompta";
export const StructSjSystemePaie = "StructSjSystemePaie";
export const StructStructureType = "StructStructureType";
export const StructUeActivite = "StructUeActivite";
export const StructTag = "StructTag";
export const StructTagType = "StructTagType";
export const StructUpTypeInstallation = "StructUpTypeInstallation";
export const StructureErt = "StructureErt";
export const StructureSj = "StructureSj";
export const StructureUe = "StructureUe";
export const StructureUp = "StructureUp";
export const TpBenificiaryType = "TpBenificiaryType";
export const TpBic = "TpBic";
export const TpCompanyComplianceScore = "TpCompanyComplianceScore";
export const TpContactDetailType = "TpContactDetailType";
export const TpContactType = "TpContactType";
export const TpCoupaActivity = "TpCoupaActivity";
export const TpCustomerNature = "TpCustomerNature";
export const TpCustomerStatus = "TpCustomerStatus";
export const TpDematerializationType = "TpDematerializationType";
export const TpFee = "TpFee";
export const TpGroup = "TpGroup";
export const TpIndustryCode = "TpIndustryCode";
export const TpIndustryCodeRepository = "TpIndustryCodeRepository";
export const TpLegalForm = "TpLegalForm";
export const TpLegalIdentifier = "TpLegalIdentifier";
export const TpLiquidatorType = "TpLiquidatorType";
export const TpPaymentCharacteristicOrigin = "TpPaymentCharacteristicOrigin";
export const TpPaymentCharacteristicStatus = "TpPaymentCharacteristicStatus";
export const TpPaymentCharacteristicType = "TpPaymentCharacteristicType";
export const TpPaymentMethod = "TpPaymentMethod";
export const TpPaymentPeriod = "TpPaymentPeriod";
export const TpScope = "TpScope";
export const TpSecurization = "TpSecurization";
export const TpSupplierNature = "TpSupplierNature";
export const TpSupplierStatus = "TpSupplierStatus";
export const TpThirdPartyCategory = "TpThirdPartyCategory";
export const TpThirdPartyStatus = "TpThirdPartyStatus";
export const WorkflowStatus = "WorkflowStatus";
export const WorkflowStep = "WorkflowStep";
export const WorkflowType = "WorkflowType";
export const OperationType = "OperationType";
