export const ADD_HISTORY_THIRDPARTY = 'ADD_HISTORY_THIRDPARTY';
export const ADD_HISTORY_COMPANY = 'ADD_HISTORY_COMPANY';
export const ADD_HISTORY_PRODUCT = 'ADD_HISTORY_PRODUCT';
export const ADD_HISTORY_SITE = 'ADD_HISTORY_SITE';
export const ADD_HISTORY_STRUCTURE = 'ADD_HISTORY_STRUCTURE';
export const ADD_HISTORY_JOB = 'ADD_HISTORY_JOB';

export const REMOVE_HISTORY_THIRDPARTY = 'REMOVE_HISTORY_THIRDPARTY';
export const REMOVE_HISTORY_COMPANY = 'REMOVE_HISTORY_COMPANY';
export const REMOVE_HISTORY_PRODUCT = 'REMOVE_HISTORY_PRODUCT';
export const REMOVE_HISTORY_SITE = 'REMOVE_HISTORY_SITE';
export const REMOVE_HISTORY_STRUCTURE = 'REMOVE_HISTORY_STRUCTURE';
export const REMOVE_HISTORY_JOB = 'REMOVE_HISTORY_JOB';

//ADD Module
export function addHistoryThirdParty(thirdParty) {
    return { type: ADD_HISTORY_THIRDPARTY, thirdParty }
}
export function addHistoryCompany(company) {
    return { type: ADD_HISTORY_COMPANY, company}
}
export function addHistoryProduct(product) {
    return { type: ADD_HISTORY_PRODUCT, product }
}
export function addHistorySite(site) {
    return { type: ADD_HISTORY_SITE, site }
}
export function addHistoryStructure(structure) {
    return { type: ADD_HISTORY_STRUCTURE, structure }
}
export function addHistoryJob(job) {
    return { type: ADD_HISTORY_JOB, job }
}
//Remove Module
export function removeHistoryThirdParty(thirdParty) {
    return { type: REMOVE_HISTORY_THIRDPARTY, thirdParty }
}
export function removeHistoryCompany(company) {
    return { type: REMOVE_HISTORY_COMPANY, company}
}
export function removeHistoryProduct(product) {
    return { type: REMOVE_HISTORY_PRODUCT, product }
}
export function removeHistorySite(site) {
    return { type: REMOVE_HISTORY_SITE, site}
}
export function removeHistoryStructure(structure) {
    return { type: REMOVE_HISTORY_STRUCTURE, structure}
}
export function removeHistoryJob(job) {
    return { type: REMOVE_HISTORY_JOB , job }
}