import { ApimDeletePromise, ApimGetPromise, ApimPostPromise } from "components/Common/ApimSender";

export function SupplierCrupdate(supplier) {
  return ApimPostPromise("api/tpColumbus/suppliers", supplier);
}

export function SupplierGet(thirdPartyId) {
  return ApimGetPromise(`api/tpColumbus/suppliers/${thirdPartyId}`);
}

export function GetSupplierHistory(thirdPartyId) {
  return ApimGetPromise(`api/tpColumbus/suppliers/${thirdPartyId}/history`);
}

export function GetAllSubsidairies() {
  return ApimGetPromise("api/tpColumbus/suppliers/allSubsidiaries");
}

export function DeleteSupplierSubsidairyLink(thirdPartyId, subsidairyCode) {
  return ApimDeletePromise(`api/tpColumbus/suppliers/${thirdPartyId}/subsidiary/${subsidairyCode}`);
}
