import { ApimGet, ApimPost } from "components/Common/ApimSender";

export function CompanyGet(companyIdentifier, callbackFn, errorFn) {
  ApimGet("api/TpCopernic/Companies/" + companyIdentifier, c => callbackFn(c), e => errorFn(e));
}

export function CompanySearch(criterias, callbackFn, errorFn) {
  return ApimPost("api/TpCopernic/Companies/search", criterias, countries => callbackFn(countries), e => errorFn(e));
}

export function ComplianceCheck(companyIdentifier, callbackFn, errorFn) {
  return ApimGet(`api/TpCopernic/Companies/${companyIdentifier}/compliance/check`, countries => callbackFn(countries), e => errorFn(e));
}

export function ComplianceGet(companyIdentifier, callbackFn, errorFn) {
  return ApimGet(`api/TpCopernic/Companies/${companyIdentifier}/compliance`, countries => callbackFn(countries), e => errorFn(e));
}
