import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/Project.Actions";
import { displayWarningFromErrorMessages, getErrorStateFromErrorMessages } from "module/tools";
import React from "react";
import { connect } from "react-redux";
import { JobActivityLevel2, JobActivityLevel3, JobIasManagementMode, JobProjectStatus, JobProjectType } from "store/MasterValue/MasterValueTypes";

function ProjectLinksPart({ amc, itemChanged, isReadonly }) {
  var item = !!amc.currentItem["PROJECT"] ? amc.currentItem["PROJECT"] : {};
  var warnings = amc.errors["PROJECT"];

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Links</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Status</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobProjectStatus}
              selectedValue={item.projectStatusCode}
              isEditable={!isReadonly}
              prefixCode={true}
              onValueChange={e => itemChanged({ ...item, projectStatusCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "PROJECT/IASMANAGEMENTMODE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "PROJECT/IASMANAGEMENTMODE")}>
              <Trans>Ias Management Mode</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobIasManagementMode}
              selectedValue={item.iasManagementModeCode}
              isEditable={!isReadonly}
              prefixCode={true}
              error={getErrorStateFromErrorMessages(warnings, "PROJECT/IASMANAGEMENTMODE")}
              onValueChange={e => itemChanged({ ...item, iasManagementModeCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "PROJECT/PROJECTTYPE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "PROJECT/PROJECTTYPE")}>
              <Trans>Project type</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobProjectType}
              selectedValue={item.projectTypeCode}
              isEditable={!isReadonly}
              prefixCode={true}
              error={getErrorStateFromErrorMessages(warnings, "PROJECT/PROJECTTYPE")}
              onValueChange={e => itemChanged({ ...item, projectTypeCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "PROJECT/ACTIVITYLEVEL2")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "PROJECT/ACTIVITYLEVEL2")}>
              <Trans>Activity Level 2</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobActivityLevel2}
              selectedValue={item.activityLevel2Code}
              isEditable={!isReadonly}
              prefixCode={true}
              error={getErrorStateFromErrorMessages(warnings, "PROJECT/ACTIVITYLEVEL2")}
              onValueChange={e => itemChanged({ ...item, activityLevel2Code: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "PROJECT/ACTIVITYLEVEL3")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "PROJECT/ACTIVITYLEVEL3")}>
              <Trans>Activity Level 3</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobActivityLevel3}
              selectedValue={item.activityLevel3Code}
              isEditable={!isReadonly}
              prefixCode={true}
              error={getErrorStateFromErrorMessages(warnings, "PROJECT/ACTIVITYLEVEL3")}
              onValueChange={e => itemChanged({ ...item, activityLevel3Code: e })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(ProjectLinksPart));
