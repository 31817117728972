import { Trans } from "@lingui/macro";
import { FormControlLabel, Switch } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted";
import { extractErrorMessage } from "module/tools";
import React from "react";
import { StructIris2Activite } from "store/MasterValue/MasterValueTypes";

const TabAnnexe = ({ structure, setStructure, isEditable, defaultLang, errors }) => {
  const ue = structure.ue;
  const setUe = v => setStructure({ ...structure, ue: v });

  return (
    <>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Structure_Tab_Annexe</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_SJ_CodeHra</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={isEditable}
                value={ue.codeHra}
                onChange={e => setUe({ ...ue, codeHra: e.target.value })}
                error={extractErrorMessage(errors, "UE_HRA", defaultLang)}
                showErrorTooltip
              />
            </GridItem>
            {/*<GridItem xs={4} sm={4} md={4}><Muted><Trans>Structure_UE_Secteur</Trans></Muted></GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                            <FieldText
                                isEditable={isEditable}
                                value={ue.secteur}
                                onChange={e => setUe({ ...ue, secteur: e.target.value })}
                            />
                        </GridItem>*/}
            <GridItem xs={4} sm={4} md={4}></GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FormControlLabel
                control={<Switch size="small" checked={ue.isBilan} disabled={!isEditable} onChange={() => setUe({ ...ue, isBilan: !ue.isBilan })} />}
                label={
                  <Muted>
                    <Trans>Structure_UE_IsBilan</Trans>
                  </Muted>
                }
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_UE_Iris2Activites</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <MasterValueAutocomplete
                isEditable={isEditable}
                multiple
                mvType={StructIris2Activite}
                selectedValue={ue.iris2Activites.map(a => a.iris2ActiviteCode)}
                onValueChange={v =>
                  setUe({
                    ...ue,
                    iris2Activites: v.map(a => {
                      return { iris2ActiviteCode: a };
                    })
                  })
                }
                size="small"
                prefixCode
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(structureStyle)(TabAnnexe);
