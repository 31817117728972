import React, { useState } from "react";
import { CircularProgress, IconButton, TextField, Checkbox } from "@material-ui/core";
import CardError from "components/Card/CardError";
import ReactTable from "react-table-6";
import { Save, Cancel, Edit } from "@material-ui/icons";
import { Trans } from "@lingui/macro";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import { secondsToTimespan } from "tools";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import { CommonTimeZone } from "store/MasterValue/MasterValueTypes";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";

export default function PartStates({ country, setCountry, masterValues, defaultLang, classes }) {
  var [state, setState] = useState({ currentState: null });

  if (state.isLoading) {
    return <CircularProgress />;
  }
  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (!country || !country.states) {
    return;
  }

  const states = country.states;
  const edit = code => setState({ ...state, currentState: states.find(s => s.code === code) });
  const cancel = () => setState({ ...state, currentState: null });
  const save = () => {
    setCountry({
      ...country,
      states: states.map(s => {
        var currentState = state.currentState;
        if (s.code === currentState.code) {
          return currentState;
        }
        return s;
      })
    });
    setState({ ...state, currentState: null });
  };

  const reactTableColumns = [
    {
      accessor: "actions",
      width: 90,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Code</Trans>,
      accessor: "code"
    },
    {
      Header: <Trans>Label_FR</Trans>,
      accessor: "labelFR"
    },
    {
      Header: <Trans>Label_EN</Trans>,
      accessor: "labelEN"
    },
    {
      Header: <Trans>Label_NL</Trans>,
      accessor: "labelNL"
    },
    {
      Header: <Trans>IsEnabled</Trans>,
      accessor: "isEnabled"
    },
    {
      Header: <Trans>TimeZone</Trans>,
      accessor: "timeZone"
    }
  ];

  var timezones = null;
  var items = states.map(c => {
    if (state.currentState && state.currentState.code === c.code) {
      if (!timezones) timezones = masterValues[CommonTimeZone].filter(tz => tz.countryCode === country.codeIso2);

      let currentState = state.currentState;
      return {
        actions: (
          <>
            <IconButton size="small" onClick={cancel}>
              <Cancel />
            </IconButton>
            <IconButton size="small" onClick={save}>
              <Save />
            </IconButton>
          </>
        ),
        code: c.code,
        labelFR: (
          <TextField
            value={currentState.label_FR}
            onChange={e =>
              setState({
                ...state,
                currentState: { ...state.currentState, label_FR: e.target.value }
              })
            }
            fullWidth
          />
        ),
        labelEN: (
          <TextField
            value={currentState.label_EN}
            onChange={e =>
              setState({
                ...state,
                currentState: { ...state.currentState, label_EN: e.target.value }
              })
            }
            fullWidth
          />
        ),
        labelNL: (
          <TextField
            value={currentState.label_NL}
            onChange={e =>
              setState({
                ...state,
                currentState: { ...state.currentState, label_NL: e.target.value }
              })
            }
            fullWidth
          />
        ),
        isEnabled: (
          <Checkbox
            checked={currentState.isEnabled}
            onClick={e =>
              setState({
                ...state,
                currentState: { ...state.currentState, isEnabled: !state.currentState.isEnabled }
              })
            }
          />
        ),
        timeZone: (
          <ItemAutoComplete
            isEditable
            isRequired
            items={timezones}
            selectedValue={currentState.timeZoneCode}
            onValueChange={v => setState({ ...state, currentState: { ...state.currentState, timeZoneCode: v } })}
            formatText={v => GetMasterValueLabel(v, defaultLang) + " (" + (v.utcOffset >= 0 ? "+" : "-") + "" + secondsToTimespan(v.utcOffset) + ")"}
            size="small"
          />
        )
      };
    }

    var timeZone = masterValues[CommonTimeZone].find(tz => tz.code === c.timeZoneCode);
    return {
      actions: (
        <>
          <IconButton size="small" onClick={e => edit(c.code)}>
            <Edit />
          </IconButton>
        </>
      ),
      code: c.code,
      labelFR: c.label_FR,
      labelEN: c.label_EN,
      labelNL: c.label_NL,
      isEnabled: <Checkbox checked={c.isEnabled} disabled={true} />,
      timeZone: timeZone ? GetMasterValueLabel(timeZone, defaultLang) : ""
    };
  });

  var pageSize = 10;

  var reactTable = (
    <ReactTable
      data={items}
      //pages={items.pages}
      columns={reactTableColumns}
      defaultPageSize={pageSize}
      showPaginationBottom={true}
      className="-striped -highlight"
      style={ReactTableStyle.main}
    />
  );

  return <>{reactTable}</>;
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};
