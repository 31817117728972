import React from "react";
import PropTypes from "prop-types";
//style
import withStyles from "@material-ui/core/styles/withStyles";
import style from "assets/jss/material-dashboard-pro-react/components/ProfilStyle.jsx";
// @material-ui/core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import classNames from "classnames";
//icon
import { Dashboard, Category, Domain, LocationOn } from "@material-ui/icons";
import AccountTree from "@material-ui/icons/AccountTree";
// **lingui js
import { Trans } from "@lingui/macro";
import ReactCountryFlag from "react-country-flag";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";

class InfoGeneralDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModule: false,
      openLangue: false,
      InfoGeneral: this.props.InfoGeneral
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLangueChange = this.handleLangueChange.bind(this);
    this.handleModuleChange = this.handleModuleChange.bind(this);

    this.handleLangueClose = this.handleLangueClose.bind(this);
    this.handleModuleClose = this.handleModuleClose.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);

    this.handleModuleOpen = this.handleModuleOpen.bind(this);
    this.handleLangueOpen = this.handleLangueOpen.bind(this);
  }

  //open
  handleLangueOpen = () => {
    this.setState({ openLangue: true });
  };
  handleModuleOpen = () => {
    this.setState({ openModule: true });
  };

  // close
  handleLangueClose = () => {
    this.setState({ openLangue: false });
  };
  handleModuleClose = () => {
    this.setState({ openModule: false });
  };

  //change

  handleChange = event => {
    var InfoGeneral = this.state.InfoGeneral;
    this.setState({ InfoGeneral: InfoGeneral });
    if (InfoGeneral.Checked === "Oui") {
      InfoGeneral.Checked = "Non";
      InfoGeneral.Connexion = false;
      this.setState({ InfoGeneral: InfoGeneral });
    } else {
      InfoGeneral.Checked = "Oui";
      InfoGeneral.Connexion = true;
      this.setState({ InfoGeneral: InfoGeneral });
    }
    this.props.handelInfo(this.state.InfoGeneral);
  };

  handleLangueChange = event => {
    this.props.setDefaultLang(event.target.value);
  };
  handleModuleChange = event => {
    var InfoGeneral = this.state.InfoGeneral;
    InfoGeneral.Module = event.target.value;
    this.setState({ InfoGeneral: InfoGeneral });
    this.props.handelInfo(this.state.InfoGeneral);
  };
  handleCountryChange = codeIso2 => {
    var InfoGeneral = this.state.InfoGeneral;
    InfoGeneral.Country = codeIso2;
    this.setState({ InfoGeneral: InfoGeneral });
    this.props.handelInfo(this.state.InfoGeneral);
  };

  render() {
    const { classes } = this.props;

    return (
      <GridItem xs={6} sm={6} md={6}>
        <Card>
          <CardHeader color="success">
            <h5>
              {" "}
              <Trans>General Information </Trans>
            </h5>
          </CardHeader>
          <CardBody>
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                <label className={classNames(classes.Margin, classes.LabelSize)}>
                  {" "}
                  <Trans>Automatic connection </Trans>{" "}
                </label>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                <FormControlLabel
                  control={
                    <Switch
                      classes={{
                        switchBase: classes.iOSSwitchBase,
                        bar: classes.iOSBar,
                        icon: classes.iOSIcon,
                        iconChecked: classes.iOSIconChecked,
                        checked: classes.iOSChecked
                      }}
                      disableRipple
                      checked={this.state.InfoGeneral.Connexion}
                      onChange={this.handleChange}
                      value={this.state.InfoGeneral.checked}
                    />
                  }
                  label={this.state.InfoGeneral.checked}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                <label className={classes.LabelSize}>
                  {" "}
                  <Trans>Language </Trans>{" "}
                </label>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <Select
                  open={this.state.openLangue}
                  onClose={this.handleLangueClose}
                  onOpen={this.handleLangueOpen}
                  value={this.props.defaultLang}
                  onChange={this.handleLangueChange}
                  fullWidth
                >
                  <MenuItem value="EN">
                    <ReactCountryFlag countryCode="GB" svg style={{ width: "25px" }} />
                    <Trans>English</Trans>
                  </MenuItem>
                  <MenuItem value="FR">
                    <ReactCountryFlag countryCode="FR" svg style={{ width: "25px" }} />
                    <Trans>French</Trans>
                  </MenuItem>
                  <MenuItem value="NL">
                    <ReactCountryFlag countryCode="NL" svg style={{ width: "25px" }} />
                    <Trans>Dutch</Trans>
                  </MenuItem>
                  <MenuItem value="CS">
                    <ReactCountryFlag countryCode="CZ" svg style={{ width: "25px" }} />
                    <Trans>Czech</Trans>
                  </MenuItem>
                  <MenuItem value="SK">
                    <ReactCountryFlag countryCode="SK" svg style={{ width: "25px" }} />
                    <Trans>Slovak</Trans>
                  </MenuItem>
                </Select>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                <label className={classes.LabelSize}>
                  <Trans>Default Country</Trans>
                </label>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <CountryAutocomplete
                  countryCode={this.state.InfoGeneral.Country}
                  onChange={codeIso2 => {
                    this.handleCountryChange(codeIso2);
                  }}
                  defaultLang={this.props.defaultLang}
                  isEditable={true}
                />
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                <label className={classes.LabelSize}>
                  <Trans> Default module </Trans>{" "}
                </label>
              </GridItem>
              <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                <Select
                  open={this.state.openModule}
                  onClose={this.handleModuleClose}
                  onOpen={this.handleModuleOpen}
                  value={this.state.InfoGeneral.Module}
                  onChange={this.handleModuleChange}
                  fullWidth
                >
                  <MenuItem value="Report">
                    <Dashboard color="inherit" />
                    <Trans>Dashboard</Trans>
                  </MenuItem>
                  <MenuItem value="TH">
                    <Domain color="primary" />
                    <Trans> Third Party / Customer / Supplier </Trans>
                  </MenuItem>
                  <MenuItem value="AMC">
                    <AccountTree color="primary" />
                    <Trans>AMC</Trans>
                  </MenuItem>
                  <MenuItem value="Sites">
                    <LocationOn color="primary" />
                    <Trans>Sites</Trans>
                  </MenuItem>
                  <MenuItem value="Product">
                    <Category color="secondary" />
                    <Trans> Products </Trans>
                  </MenuItem>
                </Select>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    );
  }
}

InfoGeneralDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(style)(InfoGeneralDetails);
