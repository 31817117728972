import React, { memo, useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { AzureMap, AzureMapDataSourceProvider, AzureMapFeature, AzureMapLayerProvider, AzureMapsProvider, AzureMapPopup } from "react-azure-maps";
import { AuthenticationType, data } from "azure-maps-control";
import { RootStore } from "store/RootStore";
import uuidv4 from "uuid/v4"; // Generate GUID

const renderPoint = marker => {
  const rendId = Math.random();

  return (
    <AzureMapFeature
      key={rendId}
      id={rendId.toString()}
      type="Point"
      coordinate={[marker.longitude, marker.latitude]}
      properties={{
        ...marker
      }}
    />
  );
};

const ShowMap = ({ position, zoom, markers }) => {
  const [popupOptions, setPopupOptions] = useState({});
  const [currentMarker, setCurrentMarker] = useState(null);
  const [currentId] = useState("showMap-" + uuidv4());
  var popupContent = <div id={currentId}></div>;

  const option = useMemo(() => {
    return {
      authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: process.env.REACT_APP_AZURE_MAP_KEY
      },
      center: !position ? [-100.01, 45.01] : position,
      zoom: !zoom ? 2 : zoom,
      view: "Auto",
      showFeedbackLink: false,
      showLogo: false
    };
  }, []);

  useEffect(() => {
    const div = document.getElementById(currentId);
    if (div) {
      ReactDOM.render(<ShowMapPopupContent currentMarker={currentMarker} styles={styles} />, div);
    }
  }, [currentMarker]);

  const memoizedMarkerRender = useMemo(() => {
    return markers.map(marker => renderPoint(marker));
  }, [markers]);

  return (
    <>
      <div style={styles.map}>
        <AzureMapsProvider>
          <AzureMap options={option}>
            <AzureMapDataSourceProvider id={"MultiplePoint AzureMapDataSourceProvider"}>
              <AzureMapLayerProvider
                id={"MultiplePoint AzureMapLayerProvider"}
                options={{
                  iconOptions: {
                    image: "pin-red"
                  }
                }}
                events={{
                  click: e => {
                    if (e.shapes && e.shapes.length > 0) {
                      const prop = e.shapes[0];
                      // Set popup options
                      setPopupOptions({
                        ...popupOptions,
                        position: new data.Position(prop.data.geometry.coordinates[0], prop.data.geometry.coordinates[1]),
                        pixelOffset: [0, -18]
                      });
                      if (prop.data.properties)
                        // Set popup properties from Feature Properties that are declared on create Feature
                        setCurrentMarker({
                          ...prop.data.properties
                        });
                    }
                  }
                }}
                type="SymbolLayer"
              ></AzureMapLayerProvider>
              {memoizedMarkerRender}
            </AzureMapDataSourceProvider>
            <AzureMapPopup isVisible={!!currentMarker} options={popupOptions} popupContent={popupContent} />
          </AzureMap>
        </AzureMapsProvider>
      </div>
    </>
  );
};

const ShowMapPopupContent = ({ styles, currentMarker }) => {
  return (
    <div
      style={{ ...styles.popupStyles, cursor: !!currentMarker.open ? "pointer" : "" }}
      onClick={() => {
        if (!!currentMarker.open) currentMarker.open();
      }}
    >
      <div>{currentMarker.name}</div>
      <div>
        {currentMarker.line1}
        <br />
        {currentMarker.line2}
        <br />
        {currentMarker.zipCode} {currentMarker.city}
        <br />
        {currentMarker.country}
      </div>
    </div>
  );
};

const styles = {
  map: {
    height: 500
  },
  buttonContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: "10px",
    gridAutoColumns: "max-content",
    padding: "10px 0",
    alignItems: "center"
  },
  button: {
    height: 35,
    width: 80,
    backgroundColor: "#68aba3",
    "text-align": "center"
  },
  popupStyles: {
    padding: "20px",
    color: "black"
  }
};

export default memo(ShowMap);
