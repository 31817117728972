import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  Popover,
  TextField,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import { ExpandMore, ChevronRight, Add, Delete } from "@material-ui/icons";
import { TreeView, TreeItem } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import { isNullOrEmpty } from "../../../tools";
import { GroupTreeGet } from "../actions/Group.Actions";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import CardMessage from "components/Card/CardMessage";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { formatISO } from "date-fns";
import { sortBy } from "tools";

const GTree = ({ groupId, setGroup, createGroup, deleteGroup }) => {
  const [state, setState] = useState({ isLoading: false, error: null });
  const [filterText, setFilterText] = useState("");
  const [context, setContext] = useState(null);
  const [createState, setCreateState] = useState(null);
  const classes = useTreeItemStyles();

  useEffect(() => {
    launchSearch();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => (state.groups ? treeFilter(filterText, state.groups, state.relations) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [filterText]);

  const launchSearch = () => {
    setState({ ...state, isLoading: true });

    GroupTreeGet()
      .then(g => treeLoad(g))
      .catch(e => setState({ ...state, isLoading: false, error: e }));
  };

  const treeLoad = result => {
    let groups = sortBy(result.groups, "label");
    let relations = result.relations;

    let parents = allParents(groupId, relations);
    let children = allChildren(groupId, relations);
    relations = [...new Set([...parents, ...children])];
    treeFilter("", groups, relations);
  };
  
  const treeFilter = (text, groups, relations) => {
    let expanded = [
      groupId.toString(),
      ...allParents(groupId, relations).map(t => {
        return t.parentId;
      })
    ];
    let filteredRelations = relations;

    if (text.length > 2) {
      let regex = new RegExp(text, "i");
      let maps = groups
        .filter(g => regex.test(g.label))
        .map(g => {
          return allParents(g.id, relations).map(t => {
            return t.parentId;
          });
        });
      expanded = maps.filter(a => a.length > 0)
        .join(",")
        .split(",");
    }
    
    let nodes = groups.filter(g => !g.parentId || g.parentId === 0).map(g => treeBuilder(g.id, groups, filteredRelations, text));
    if (createGroup) {
      let rootLabel = (
        <div className={classes.labelRoot}>
          <Typography variant="body2" className={classes.labelText} fontSize="small">
            root
          </Typography>
          <Typography variant="caption" color="inherit" className={classes.labelButton} fontSize="small">
            {createGroup && (
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  createGroup();
                }}
              >
                <Add fontSize="small" />
              </IconButton>
            )}
          </Typography>
        </div>
      );
      nodes = (
        <TreeItem key={0} nodeId="0" label={rootLabel}>
          {nodes}
        </TreeItem>
      );
    }
    setFilterText(text);
    setState({
      ...state,
      isLoading: false,
      groups: groups,
      relations: relations,
      treeNodes: { nodes: nodes, expanded: [...new Set(expanded)] }
    });
  };
  const treeBuilder = (groupId, groups, relations, filterText) => {
    var g = groups.find(g => g.id === groupId);
    if (!g) {
      return;
    }

    var map = groups.filter(g => g.parentId === groupId).map(c => treeBuilder(c.id, groups, relations, filterText));
    var id = g.id;
    var labelContent = g.label;
    if (!isNullOrEmpty(filterText)) {
      // Split on highlight term and include term into parts, ignore case
      const parts = labelContent.split(new RegExp(`(${filterText})`, "gi"));
      labelContent = (
        <span>
          {parts.map((part, i) => (
            <span key={i} style={{ backgroundColor: part.toLowerCase() === filterText.toLowerCase() ? "yellow" : "white" }}>
              {part}
            </span>
          ))}
        </span>
      );
    }
    var nodeLabel = (
      <div className={classes.labelRoot}>
        <Typography variant="body2" className={classes.labelText} fontSize="small">
          {labelContent}
        </Typography>
        <Typography variant="caption" color="initial" className={classes.right} fontSize="small">
          {g.thirdPartyCount}
        </Typography>
        <Typography variant="caption" color="inherit" className={classes.labelButton} fontSize="small">
          {createGroup && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                createGroup(g);
              }}
            >
              <Add fontSize="small" />
            </IconButton>
          )}
          {deleteGroup && g.id > 0 && !groups.some(s => s.parentId === g.id) && (
            <IconButton
              onClick={e => {
                e.stopPropagation();
                g.id === 0 ? setGroup(null) : deleteGroup(g);
              }}
            >
              <Delete fontSize="small" />
            </IconButton>
          )}
        </Typography>
      </div>
    );
    var label = (
      <>
        {/* <Chip size="small" variant="outlined" label={s.structureType} /> */}
        <span> {nodeLabel}</span>
      </>
    );
    return (
      <TreeItem key={id} nodeId={id.toString()} label={label} onLabelClick={() => id > 0 && setGroup(g)}>
        {map}
      </TreeItem>
    );
  };

  var treeView = "";
  if (state.isLoading) {
    treeView = <CircularProgress />;
  } else if (state.error) {
    treeView = <CardError error={state.error} />;
  } else if (state.treeNodes) {
    treeView = (
      <Card>
        <CardHeader>
          <TextField label="Filtre" defaultValue={filterText} onChange={e => setFilterText(e.target.value)} variant="outlined" />
        </CardHeader>
        <CardBody>
          <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            expanded={state.treeNodes.expanded}
            onNodeToggle={(e, nodeIds) => setState({ ...state, treeNodes: { ...state.treeNodes, expanded: nodeIds } })}
          >
            {state.treeNodes.nodes}
          </TreeView>
        </CardBody>
      </Card>
    );
  } else {
    treeView = <CardMessage message={<Trans>NoResult</Trans>} />;
  }
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {treeView}
        </GridItem>
      </GridContainer>
      <Popover
        open={Boolean(context)}
        anchorReference="anchorPosition"
        anchorPosition={context ? { top: context.mouseY, left: context.mouseX } : undefined}
        onClose={() => setContext(null)}
      >
        <List component="nav">{context ? context.menu : ""}</List>
      </Popover>
      {createState ? (
        <Dialog
          open
          onClose={() => {
            if (!createState.isLoading) setCreateState(null);
          }}
        >
          <DialogContent>{createState.isLoading ? <CircularProgress /> : <CardError error={createState.error} />}</DialogContent>
          <DialogActions>
            {createState.isLoading ? (
              <></>
            ) : (
              <Button onClick={() => setCreateState(null)}>
                <Trans>Close</Trans>
              </Button>
            )}
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

function allParents(groupId, relations) {
  if (!groupId) return [];

  let filteredRelations = relations.filter(r => r.childId === groupId);
  var directParents = [];
  for (var i = 0; i < filteredRelations.length; i++) {
    let f = filteredRelations[i];
    directParents.push(f);
    let parents = allParents(f.parentId, relations);
    for (var j = 0; j < parents.length; j++) {
      let p = parents[j];
      directParents.push(p);
    }
  }

  return directParents;
}

function allChildren(groupId, relations) {
  if (groupId === undefined || groupId === null) return [];

  var filteredRelations = relations.filter(s => s.parentId === groupId);
  var directChildren = [];

  for (var i = 0; i < filteredRelations.length; i++) {
    let r = filteredRelations[i];
    directChildren.push(r);
    let children = allChildren(r.childId, relations);

    for (var j = 0; j < children.length; j++) {
      let p = children[j];
      directChildren.push(p);
    }
  }

  return directChildren;
}

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent"
    }
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightSmall,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightSmall
    }
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "small",
    color: "inherit"
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
    "&:hover $labelButton": {
      visibility: "visible"
    }
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelButton: {
    visibility: "hidden"
  },
  labelText: {
    fontWeight: "small",
    flexGrow: 1
  }
}));
export default withStyles(thirdPartySearchCriteriaStyle)(GTree);
