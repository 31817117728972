import React, { useState } from "react";
import ReactTable from "react-table-6";
import { connect } from "react-redux";
import * as Actions from "../store/actions";
import * as HistoryActions from "store/History/HistoryActions";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Checkbox, CircularProgress, FormControl, FormControlLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { Add, Assignment, Description, ViewHeadline, Map as MapIcon } from "@material-ui/icons";

import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import SiteWizard from "./SiteWizard";
//import SiteDetail from './SiteDetail';
import SiteSearch from "../components/SiteSearch";

//lingui
import { Trans, t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { isNull, isArrayNullOrEmpty, isNullOrEmpty } from "tools";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import CardError from "../components/CardError";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { ExportResult } from "services/site/SiteHelper";
import SiteMap from "../components/SiteDetail/SiteMap";
import { SiteMapPositions } from "../components/SiteDetail/SiteMapPositions";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import { HasRight } from "services/user/UserHelper";

function SiteList({ searchCtx, openSite, addHistory, classes, defaultLang, selectForStructure }) {
  const [showCreation, setShowCreation] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [resultMode, setResultMode] = useState("Table");

  var sites = searchCtx.results;
  var searchError = searchCtx.error;
  var isSearching = searchCtx.isSearching;

  i18n.activate(defaultLang.toLowerCase());

  const reactTableColumns = [
    {
      sortable: false,
      filterable: false,
      Header: x => {
        return (
          <FormControlLabel
            control={
              <Checkbox
                type="checkbox"
                className="checkbox"
                checked={selectAll}
                onChange={e => {
                  var isSelectAll = e.currentTarget.checked;
                  setSelectAll(isSelectAll);
                  setSelectedIds(isSelectAll ? sites.map(tp => tp.id) : []);
                }}
              />
            }
            label={<Trans>Rows</Trans>}
          />
        );
      },
      accessor: "ChexBox",
      width: 50,
      sortable: false,
      filterable: false,
      Cell: rowInfo => {
        var identifier = rowInfo.original.Id;
        if (identifier > 0) {
          return (
            <Checkbox
              type="checkbox"
              className="checkbox"
              checked={selectedIds.some(id => id === identifier)}
              onChange={() =>
                setSelectedIds(selectedIds.some(id => id === identifier) ? selectedIds.filter(id => id !== identifier) : [...selectedIds, identifier])
              }
            />
          );
        } else return <FormControlLabel disabled control={<Checkbox value="" />} />;
      }
    },
    {
      accessor: "actions",
      width: 50,
      sortable: false,
      filterable: false
    },
    //{
    //    Header: <Trans>Status</Trans>,
    //    accessor: "Status",
    //    Cell: (rowInfo) => {
    //        var statusIcon = <LockOpen style={{ 'color': 'green' }} />;
    //        if (rowInfo.original.Status !== null && rowInfo.original.Status === 'Closed')
    //            statusIcon = <Lock style={{ 'color': 'red' }} />;
    //        var headQuarterIcon = "";
    //        if (rowInfo.original.IsHeadQuarter)
    //            headQuarterIcon = (<Flag style={{ 'color': 'orange' }} />);

    //        return (
    //            <div>
    //                {statusIcon}
    //                {headQuarterIcon}
    //            </div>
    //        );
    //    }
    //},
    {
      Header: <Trans>Id</Trans>,
      accessor: "Id"
    },
    {
      Header: <Trans>Name</Trans>,
      accessor: "Name",
      Cell: rowInfo => {
        var label = rowInfo.original.Name;
        return <span title={label}>{label}</span>;
      }
    },
    {
      Header: <Trans>AdressLine1</Trans>,
      accessor: "AdresseLine1",
      Cell: rowInfo => {
        var label = rowInfo.original.AdresseLine1;
        return <span title={label}>{label}</span>;
      }
    },
    {
      Header: <Trans>AdressCp</Trans>,
      accessor: "AdresseCp",
      Cell: rowInfo => {
        var label = rowInfo.original.AdresseCp;
        return <span title={label}>{label}</span>;
      }
    },
    {
      Header: <Trans>AdressCity</Trans>,
      accessor: "AdresseCity",
      Cell: rowInfo => {
        var label = rowInfo.original.AdresseCity;
        return <span title={label}>{label}</span>;
      }
    },
    {
      Header: <Trans>AdressCountry</Trans>,
      accessor: "AdresseCountry",
      Cell: rowInfo => {
        var label = rowInfo.original.AdresseCountry;
        return <span title={label}>{label}</span>;
      }
    },
    {
      Header: <Trans>LastUpdateDate</Trans>,
      accessor: "LastUpdateDate",
      Cell: rowInfo => {
        var label = rowInfo.original.LastUpdateDate;
        return <span title={label}>{label}</span>;
      }
    }
  ];

  var searchResult;
  if (searchError) {
    searchResult = <CardError error={searchError} />;
  } else if (isSearching) {
    searchResult = (
      <center>
        <CircularProgress />
      </center>
    );
  } else if (!isNullOrEmpty(sites)) {
    var items = ConvertDatas(sites, openSite, addHistory);

    var resultTitle = "";
    var tabContent = <></>;

    if (resultMode === "Table") {
      var localStorageHelper = new LocalStorageHelper();
      var getPref = localStorageHelper.get("Preferences");
      var pageSize = 10;
      if (getPref !== null) {
        pageSize = getPref.Pref.ResultPageSize * 1;
      }

      tabContent = (
        <div className="table-result show">
          <ReactTable
            //manual
            data={items.tabItems}
            //pages={items.pages}
            columns={reactTableColumns}
            defaultPageSize={pageSize}
            showPaginationBottom={true}
            className="-striped -highlight"
            style={ReactTableStyle.main}
          />
        </div>
      );
      resultTitle = <Trans>Table result</Trans>;
    } else if (resultMode === "Map") {
      var markers = sites
        .filter(site => site.gpsCoordinates !== null)
        .map(site => {
          var gps = site.gpsCoordinates;
          if (gps.longitude) {
            var countryName = GetMasterValueLabel(site.address_Country, defaultLang);
            return {
              id: site.id,
              name: site.name,
              line1: site.address_Line1,
              line2: site.address_Line2,
              zipCode: site.address_ZipCode,
              city: site.address_City,
              country: countryName,
              longitude: gps.longitude,
              latitude: gps.latitude,
              open: () => {
                openSite(site.id);
                addHistory(site);
              }
            };
          }
          return null;
        });

      //var position = [2.53333, 46.48333];
      var gps = SiteMapPositions(searchCtx.criterias.criteriaCountry);
      tabContent = (
        <div className="map-result show">
          <SiteMap position={gps.position} zoom={gps.zoom} markers={markers.filter(m => !isNull(m))} />
        </div>
      );
      resultTitle = <Trans>Map result</Trans>;
    }

    var listLabel = [];
    listLabel.push({ code: "Id", label: i18n._("Id") });
    listLabel.push({ code: "Name", label: i18n._("Name") });
    listLabel.push({ code: "Address_Line1", label: i18n._("Address_Line1") });
    listLabel.push({ code: "Address_Line2", label: i18n._("Address_Line2") });
    listLabel.push({ code: "Address_City", label: i18n._("Address_City") });
    listLabel.push({ code: "Address_ZipCode", label: i18n._("Address_ZipCode") });
    listLabel.push({ code: "Address_StatisticZipCode", label: i18n._("Address_StatisticZipCode") });
    listLabel.push({ code: "Address_State", label: i18n._("Address_State") });
    listLabel.push({ code: "Address_County", label: i18n._("Address_County") });
    listLabel.push({ code: "Address_Country", label: i18n._("Address_Country") });
    listLabel.push({ code: "GpsCoordinates", label: i18n._("GpsCoordinates") });
    listLabel.push({ code: "LocationNature", label: i18n._("LocationNature") });
    listLabel.push({ code: "LocationStatus", label: i18n._("LocationStatus") });
    listLabel.push({ code: "LocationType", label: i18n._("LocationType") });
    listLabel.push({ code: "LocationLabel", label: i18n._("LocationLabel") });
    listLabel.push({ code: "Activities", label: i18n._("Activities") });
    listLabel.push({ code: "Structures", label: i18n._("Structures") });
    listLabel.push({ code: "Contacts", label: i18n._("Contacts") });

    var menu = "";
    if (!isArrayNullOrEmpty(selectedIds) || selectAll) {
      menu = (
        <FormControl className={classes.formControl}>
          <Select onChange={e => SelectedRowAction(selectedIds, e.target.value, listLabel)}>
            <MenuItem value="action">
              <Trans>Choose actions</Trans>
            </MenuItem>
            <MenuItem value={RowActionExport}>
              <Description color="secondary" />
              <Trans> Export </Trans>
            </MenuItem>
          </Select>
        </FormControl>
      );
    }

    searchResult = (
      <Card>
        <CardHeader icon>
          <div className="blockHeaderResult">
            <div className="blockHeaderResultItem">
              <ToggleButtonGroup value={resultMode} exclusive onChange={e => setResultMode(e.currentTarget.value)}>
                <ToggleButton value="Table">
                  <ViewHeadline />
                </ToggleButton>
                <ToggleButton value="Map">
                  <MapIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="blockHeaderResultItem">
              <Typography ariant="display1">{resultTitle}</Typography>
            </div>
            <div className="blockHeaderResultItem">{menu}</div>
          </div>
        </CardHeader>
        <CardBody>
          {/*<div className={tableResultClasses.join(' ')}>
                        {reactTable}
                    </div>
                    <div className={mapResultClasses.join(' ')}>
                        {mapContent}
                    </div>*/}
          {tabContent}
        </CardBody>
      </Card>
    );
  }

  var dialog = "";
  if (showCreation) {
    let needApprobation = HasRight("site.edit") && !HasRight("site.approve");
    dialog = <SiteWizard handleClose={() => setShowCreation(false)} defaultLang={defaultLang} needApprobation={needApprobation} showStruct={true} />;
  }
  //else if (!isArrayNullOrEmpty(openedIds)) {
  //    // show last ID
  //    var lastOpenedId = openedIds[openedIds.length - 1];

  //    switch (lastOpenedId.type) {
  //        case "site":
  //            dialog = (
  //                <SiteDetail siteId={lastOpenedId.id} />
  //            );
  //            break;
  //        default:
  //            throw "Unknown type: " + lastOpenedId.type;
  //    }
  //}

  var buttonCreationContent = "";
  if (HasRight("site.edit") || HasRight("site.admin")) {
    buttonCreationContent = (
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <Button variant="contained" size="sm" onClick={() => setShowCreation(true)} color="info">
            <Add />
            <Trans>Site_Create</Trans>
          </Button>
        </GridItem>
      </GridContainer>
    );
  }

  var layoutSearchResult;
  if (searchResult) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {searchResult}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <SiteSearch showSearchCriteriaOnSideBar={true} defaultLang={defaultLang} />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = <SiteSearch showSearchCriteriaOnSideBar={false} defaultLang={defaultLang} />;
  }

  return (
    <div>
      {buttonCreationContent}
      {layoutSearchResult}
      {dialog}
    </div>
  );
}

///-----------------------------------------------------------
/// Convertit le résultat d'une recherche en item pour react-table
///-----------------------------------------------------------
function ConvertDatas(sites, openSite, addHistory) {
  if (!sites || isArrayNullOrEmpty(sites)) return [];

  var result = {
    datas: sites,
    tabItems: []
  };
  sites.map((prop, key) => {
    result.tabItems.push({
      Id: prop.id,
      Name: prop.name,
      AdresseLine1: prop.address_Line1,
      AdresseCp: prop.address_ZipCode,
      AdresseCity: prop.address_City,
      AdresseCountry: prop.address_CountryCode,
      LastUpdateDate: prop.lastUpdateDate,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button
            justIcon
            round
            simple
            onClick={() => {
              let item = sites.find(o => o.id !== 0 && o.id === prop.id);
              openSite(item.id);
              addHistory(item);
            }}
            color="info"
            className="like"
          >
            <Assignment />
          </Button>{" "}
        </div>
      )
    });
  });

  return result;
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const RowActionExport = "Export";
function SelectedRowAction(selectedIds, action, labels) {
  if (action === RowActionExport) {
    //const labels = getLabelList();
    const data = { selectedIds, labels };
    ExportResult(data);
  }
}

const mapStateToProps = state => {
  return {
    searchCtx: state.ST_Search,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openSite: siteId => {
      dispatch(Actions.OpenSite(siteId));
    },
    addHistory: site => dispatch(HistoryActions.addHistorySite(site))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(SiteList));
