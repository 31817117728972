import React, { useState } from 'react';
import { CircularProgress, IconButton, TextField, Checkbox } from '@material-ui/core';
import CardError from 'components/Card/CardError';
import ReactTable from 'react-table-6';
import { Save, Cancel, Edit } from '@material-ui/icons';
import { Trans } from '@lingui/macro';

export default function PartCounties({ counties, setCounties, classes }) {
    var [state, setState] = useState({ currentCounty: null });

    if (state.isLoading) {
        return <CircularProgress />;
    }
    if (state.error) {
        return <CardError error={state.error} />;
    }

    if (!counties) {
        return;
    }

    const edit = (code) => setState({ ...state, currentCounty: counties.find(s => s.code === code) });
    const cancel = () => setState({ ...state, currentCounty: null });
    const save = () => {
        setCounties(
            counties.map(s => {
                var currentCounty = state.currentCounty;
                if (s.code === currentCounty.code) {
                    return currentCounty;
                }
                return s;
            })
        );
        setState({ ...state, currentCounty: null });
    };

    const reactTableColumns = [
        {
            accessor: "actions",
            width: 90,
            sortable: false,
            filterable: false,
        },
        {
            Header: <Trans>Code</Trans>,
            accessor: "code"
        },
        {
            Header: <Trans>Label_FR</Trans>,
            accessor: "labelFR"
        },
        {
            Header: <Trans>Label_EN</Trans>,
            accessor: "labelEN"
        },
        {
            Header: <Trans>Label_NL</Trans>,
            accessor: "labelNL"
        },
        {
            Header: <Trans>IsEnabled</Trans>,
            accessor: "isEnabled"
        }
    ];

    var items = counties.map(c => {
        if (state.currentCounty && state.currentCounty.code === c.code) {
            let currentCounty = state.currentCounty;
            return {
                actions: <>
                    <IconButton size="small" onClick={cancel}>
                        <Cancel />
                    </IconButton>
                    <IconButton size="small" onClick={save}>
                        <Save />
                    </IconButton>
                </>,
                code: c.code,
                labelFR:
                    <TextField
                        value={currentCounty.label_FR}
                        onChange={e => setState({
                            ...state, currentCounty: { ...state.currentCounty, label_FR: e.target.value }
                        })}
                        fullWidth
                    />,
                labelEN:
                    <TextField
                        value={currentCounty.label_EN}
                        onChange={e => setState({
                            ...state, currentCounty: { ...state.currentCounty, label_EN: e.target.value }
                        })}
                        fullWidth
                    />,
                labelNL:
                    <TextField
                        value={currentCounty.label_NL}
                        onChange={e => setState({
                            ...state, currentCounty: { ...state.currentCounty, label_NL: e.target.value }
                        })}
                        fullWidth
                    />,
                isEnabled: <Checkbox checked={currentCounty.isEnabled} onClick={e => setState({
                    ...state, currentCounty: { ...state.currentCounty, isEnabled: !state.currentCounty.isEnabled }
                })} />
            }
        }

        return {
            actions:
                <>
                    <IconButton size="small" onClick={e => edit(c.code)}>
                        <Edit />
                    </IconButton >
                </>,
            code: c.code,
            labelFR: c.label_FR,
            labelEN: c.label_EN,
            labelNL: c.label_NL,
            isEnabled: <Checkbox checked={c.isEnabled} disabled={true} />
        };
    });

    var pageSize = 10;

    var reactTable = (
        <ReactTable
            data={items}
            //pages={items.pages}
            columns={reactTableColumns}
            defaultPageSize={pageSize}
            showPaginationBottom={true}
            className="-striped -highlight"
            style={ReactTableStyle.main}
        />
    );

    return <>{reactTable}</>;
}

const ReactTableStyle = {
    main: {
        height: "100%",
        overflow: "hidden"
    }
};