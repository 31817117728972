import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import KeyValueEditor from "components/Common/KeyValueEditor";
import React from "react";
import { TpIndustryCode, TpLegalIdentifier } from "store/MasterValue/MasterValueTypes";

const TabThirdPartySource = function({ thirdParty, setThirdParty, isEditable }) {
  return (
    <>
      <KeyValueEditor
        cardLabel={<Trans>Legal identifiers</Trans>}
        keyMasterValue={TpLegalIdentifier}
        keyMasterValueFilter={mv => mv.countryCode === thirdParty.address_CountryCode}
        keyPropertyName="key"
        valuePropertyName="value"
        items={thirdParty.legalIdentifiers}
        onValueChanged={items => setThirdParty({ ...thirdParty, legalIdentifiers: items })}
        isEditable={isEditable}
        maxlength={20}
      />

      <KeyValueEditor
        cardLabel={<Trans>Industry codes</Trans>}
        keyMasterValue={TpIndustryCode}
        keyPropertyName="key"
        valuePropertyName="value"
        items={thirdParty.activitySectors}
        onValueChanged={items => setThirdParty({ ...thirdParty, activitySectors: items })}
        isEditable={isEditable}
      />
    </>
  );
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabThirdPartySource);
