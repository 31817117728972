import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../store/actions";
import { isArrayNullOrEmpty, isNull } from "../../tools";

import Button from "../../../components/CustomButtons/Button";

import IconButton from "@material-ui/core/IconButton";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardError from "components/Card/CardError";
import TabCustomerGeneral from "./ThirdPartyDetail/TabCustomerGeneral";
import TabCustomerSj from "./ThirdPartyDetail/TabCustomerSj";
import TabCustomerHistory from "./ThirdPartyDetail/TabCustomerHistory";

import { Grid, CircularProgress, SnackbarContent, Tooltip } from "@material-ui/core";
import { AddAlert, CheckCircleOutlined, ErrorOutlined, Error, Warning } from "@material-ui/icons";
import LinkMaterialUi from "@material-ui/core/Link";
import ArrowForward from "@material-ui/icons/ArrowForward";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { AntTabs, AntTab, TabPanel } from "../../../components/Common/Tabs";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from "@lingui/macro";
import { HasRight } from "services/user/UserHelper";

import { ThirdPartyValidate, ThirdPartyCrupdate } from "../thirdParties/actions/ThirdPartyActions.js";
import { OpenThirdParty } from "../store/actions/index.jsx";

function ThirdPartyCustomerDatas({ thirdPartyCtx, customerCtx, transformIntoCustomer, classes, defaultLang }) {
  var dispatch = useDispatch();
  var thirdParty = thirdPartyCtx.thirdParty;
  const [tabIdx, setTabIdx] = useState(0);
  const [errors, setErrors] = useState([]);
  const infoNonDiffusable = "INFORMATION NON DIFFUSIBLE";

  function validateThirdParty() {
    ThirdPartyValidate(
      thirdParty,
      "thirdPartyTransform",
      errors => {
        if (!isArrayNullOrEmpty(errors)) {
          setErrors(errors);
        } else {
          transformIntoCustomer({ thirdParty });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  function mergeThirdPartiesAndReload(foundThirdParty) {
    if (foundThirdParty.identifier !== null
      && foundThirdParty.identifier !== undefined
      && foundThirdParty.identifier > 0) {
        
      if (foundThirdParty.duns === null && thirdParty.duns !== null) {
          foundThirdParty.duns = thirdParty.duns;

          ThirdPartyCrupdate(
            foundThirdParty,
            tp => {
              dispatch(Actions.CloseThirdParty());
              dispatch(OpenThirdParty(tp.identifier));
            },
            error => console.log(error)
          );
      }
    }
  }

  var generalIcon = "";
  if (!isNull(customerCtx)) {
    if (!isNull(customerCtx.error)) {
      if (isArrayNullOrEmpty(customerCtx.error.response.data)) {
        return <CardError error={customerCtx.error} />;
      } else {
        generalIcon = <ErrorOutlined style={{ color: "red" }} />;
      }
    }

    if (customerCtx.saveStatus === "PENDING")
      return (
        <center>
          <p>
            <Trans>Loading in progress</Trans>
          </p>
          <p>
            <CircularProgress />
          </p>
        </center>
      );
    if (customerCtx.saveStatus === "SUCCESS_RELOAD") {
      setTimeout(() => dispatch(Actions.OpenThirdParty(customerCtx.customer.thirdPartyId, thirdParty.identifier, "ThirdPartyCustomerDatas")), 2000);
      return (
        <center>
          <p>
            <Trans>Success refreshing</Trans>
          </p>
          <p>
            <CheckCircleOutlined style={{ color: "green" }} />
          </p>
        </center>
      );
    }
  }

  if (!thirdParty.isCustomer || isNull(customerCtx)) {
    var innerElement;

    if (!isArrayNullOrEmpty(errors)) {
      var errorMessage = errors.map((t, idx) => {
        if (t.code === "ExistingLegalIdentifier") {
          return thirdParty.duns === t.complexLinkedObject.duns ? (
            <div>
              <div style={{ lineHeight: "0.5em" }}>
                <Error style={{ verticalAlign: "middle", color: "red" }} />
                <span style={{ verticalAlign: "middle", color: "red", marginRight: "5px" }}>
                  <strong>
                    <Trans>A third party with the same legal identifier already exist</Trans>
                  </strong>
                </span>
                <Trans>You can choose the existing third party or abort current workflow.</Trans>
              </div>
              <ol>
                <Tooltip
                  placement="right"
                  title={
                    <>
                      {t.complexLinkedObject.address_Line2}
                      <br />
                      {t.complexLinkedObject.address_Line1}
                      <br />
                      {t.complexLinkedObject.address_ZipCode}
                      <br />
                      {t.complexLinkedObject.address_City}
                      <br />
                      {t.complexLinkedObject.address_CountryCode}
                    </>
                  }
                >
                  <LinkMaterialUi
                    style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
                    onClick={event => mergeThirdPartiesAndReload(t.complexLinkedObject)}
                  >
                    <IconButton
                      style={{
                        color: "red"
                      }}
                    >
                      <ArrowForward />
                    </IconButton>
                    <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
                      {t.complexLinkedObject.longName} - {t.complexLinkedObject.legalIdentifier}
                    </span>
                  </LinkMaterialUi>
                </Tooltip>
              </ol>
            </div>
          ) : (
            <div>
              <div style={{ lineHeight: "0.5em" }}>
                <Warning style={{ verticalAlign: "middle", color: "orange" }} />
                <span style={{ verticalAlign: "middle", color: "orange", marginRight: "5px" }}>
                  <strong>
                    <Trans>A third party with the same legal identifier already exist</Trans>
                  </strong>
                </span>
                <Trans>You can choose the existing third party or continue current workflow.</Trans>
              </div>
              <ol>
                <Tooltip
                  placement="right"
                  title={
                    <>
                      {t.complexLinkedObject.address_Line2}
                      <br />
                      {t.complexLinkedObject.address_Line1}
                      <br />
                      {t.complexLinkedObject.address_ZipCode}
                      <br />
                      {t.complexLinkedObject.address_City}
                      <br />
                      {t.complexLinkedObject.address_CountryCode}
                    </>
                  }
                >
                  <LinkMaterialUi
                    style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
                    onClick={event => mergeThirdPartiesAndReload(t.complexLinkedObject)}
                  >
                    <Trans>Existing third party</Trans>
                    <IconButton style={{ color: "orange" }}><ArrowForward /></IconButton>
                    <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
                      {t.complexLinkedObject.longName} - {t.complexLinkedObject.legalIdentifier}
                    </span>
                  </LinkMaterialUi>
                </Tooltip>
              </ol>
              <ol>
                <Tooltip
                  placement="right"
                  title={
                    <>
                      {thirdParty.address_Line2}
                      <br />
                      {thirdParty.address_Line1}
                      <br />
                      {thirdParty.address_ZipCode}
                      <br />
                      {thirdParty.address_City}
                      <br />
                      {thirdParty.address_CountryCode}
                    </>
                  }
                >
                  <LinkMaterialUi
                    style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
                    onClick={event => transformIntoCustomer({ thirdParty })}
                  >
                    <Trans>Current third party</Trans>
                    <IconButton style={{ color: "orange" }}><ArrowForward /></IconButton>
                    <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
                      {thirdParty.longName} - {thirdParty.legalIdentifier}
                    </span>
                  </LinkMaterialUi>
                </Tooltip>
              </ol>
            </div>
          );
        }
      });

      innerElement = (
        <Card>
          <CardBody>{errorMessage}</CardBody>
        </Card>
      );
    } else if ((thirdParty.thirdPartyStatusCode !== "Closed" || thirdPartyCtx.isEditableClosed) && HasRight("thirdparty_customer.transform")
    && thirdParty.customLongName.toUpperCase() !== infoNonDiffusable) {
    innerElement = (
        <Button type="submit" color="info" className={classes.registerButton} onClick={() => validateThirdParty()}>
          <Trans> Transform ThirdParty into Customer </Trans>
        </Button>
      );
    } else {
      innerElement = (
        <SnackbarContent message={<Trans>You don't have rights to transform this third party into a customer.</Trans>} icon={AddAlert} color="info" />
      );
    }

    return (
      <Grid style={GridContainerStyle.mainGrid} container>
        <Grid item xs={12}>
          <Grid container justify="center">
            {innerElement}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  var isEditable = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : customerCtx.isEditable;
  var isEditableDemat = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : customerCtx.isEditableDemat;
  var canAddSj = (thirdParty.thirdPartyStatusCode === "Closed" || thirdParty.customLongName.toUpperCase() === infoNonDiffusable) ? false : customerCtx.canAddSj;
  return (
    <div className="divContainer">
      <form autoComplete="false">
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs supplier"
            >
              <AntTab
                label={
                  <>
                    {generalIcon}
                    <Trans>General</Trans>
                  </>
                }
              />
              <AntTab label={<Trans>Sjs linked</Trans>} />
              <AntTab label={<Trans>History</Trans>} />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <TabCustomerGeneral defaultLang={defaultLang} isEditable={isEditable} isEditableDemat={isEditableDemat} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <TabCustomerSj canAddSj={canAddSj} />
            </TabPanel>
            <TabPanel value={tabIdx} index={2}>
              <TabCustomerHistory defaultLang={defaultLang} />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const GridContainerStyle = {
  mainGrid: {
    marginTop: "40px"
  }
};
const TabVerticalStyle = {
  tabs: {
    marginTop: "0.5rem"
  }
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null,
    customerCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].customerCtx : null,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    transformIntoCustomer: customer => {
      dispatch(Actions.SaveCustomer(customer, true));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyCustomerDatas));
