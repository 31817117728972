import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { RootStore } from 'store/RootStore';
import { ReportVisual } from 'powerbi-report-component';
import { models } from 'powerbi-client'; // Import from the dependency

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import BaseReportVisual from "../common/BaseReportVisual";

function JobReportVisual({ handleSelecedData }) {

    function HandleSelecedData(data) {
        if (!!handleSelecedData)
            handleSelecedData(data);
    }

    return (
        <BaseReportVisual
            reportId="a27b5862-eb08-4930-90ab-402f49d964f6"
            pageName="ReportSsection"
            visualName="c4672311ba67631f9130"
            dataSelectionHandler={HandleSelecedData}
        />
    );
}

export default withStyles(dashboardStyle)(JobReportVisual);