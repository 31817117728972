import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Autorenew from '@material-ui/icons/Autorenew';
import Selector from 'components/Common/Selector';
import { Trans } from "@lingui/macro";
import IconButton from '@material-ui/core/IconButton';


const styles = {
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        marginLeft: 8,
        flex: 1,
    },
    SearchButton: {
        float: "right"
    }
};

class StructuresSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedMasterValue: props.selectedMasterValue
        }
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    handleClickSearch = event => {
        event.preventDefault();
        if (this.state.selectedMasterValue !== null && this.state.selectedMasterValue !== '') {

            var masterValueType = this.props.masterValuesTypes.find((t) => { return t.value == this.state.selectedMasterValue; });

            if (this.props.handleSearch !== null)
                this.props.handleSearch(masterValueType);
        }
    }

    handleSearch = (masterValueSelected) => {
        if (masterValueSelected !== null && masterValueSelected !== '') {

            var masterValueType = this.props.masterValuesTypes.find((t) => { return t.value == masterValueSelected; });

            this.setState({ selectedMasterValue: masterValueSelected });
            if (this.props.handleSearch !== null)
                this.props.handleSearch(masterValueType);
        }
    }

    handleRefresh = () => {
        if (this.state.selectedMasterValue !== null && this.state.selectedMasterValue !== '') {

            var masterValueType = this.props.masterValuesTypes.find((t) => { return t.value == this.state.selectedMasterValue; });

            if (this.props.handleSearch !== null)
                this.props.handleSearch(masterValueType);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <center>
                <Paper className={classes.root} elevation={1}>
                    <Selector
                        items={this.props.masterValuesTypes}
                        displayedProperties={{
                            keyProperty: 'value',
                            valueProperty: 'label'
                        }}
                        value={this.state.selectedMasterValue}
                        onChange={(event) => { this.handleSearch(event.target.value);  }}
                        isSearchable={true}
                        isEditable
                    />
                    <IconButton className={classes.iconButton} onClick={this.handleRefresh} aria-label="Search">
                        <Autorenew color="secondary" />
                    </IconButton>
                </Paper>
            </center>

        );
    }
}

StructuresSearch.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(StructuresSearch);
