import * as Actions from "./types";

const initialState = {
  criterias: {
    startPage: 0,
    pageSize: 10
  }
};

export default function UR_ProfileDelegationReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.USERANDRIGHT_PROFILEDELEGATION_BEGIN_ACTION:
      return {
        ...state,
        isPerforming: true
      };

    case Actions.USERANDRIGHT_PROFILEDELEGATION_END_ACTION:
      return {
        ...state,
        item: action.item,
        isPerforming: false,
        showDetail: false
      };

    case Actions.USERANDRIGHT_PROFILEDELEGATION_GET_ALL:
      return {
        ...state,
        result: action.result
      };

    case Actions.USERANDRIGHT_PROFILEDELEGATION_GET_DETAIL:
      return {
        ...state,
        item: action.item,
        isNew: action.isNew === undefined || action.isNew === null ? state.isNew : action.isNew,
        showDetail: true
      };
  
    case Actions.USERANDRIGHT_PROFILEDELEGATION_GET_EXISTING:
      return {
        ...state,
        existingItem: action.item
      };

    case Actions.USERANDRIGHT_PROFILEDELEGATION_SAVE_END:
      return {
        ...state,
        isPerforming: false,
        item: action.item
      };
      
    case Actions.USERANDRIGHT_PROFILEDELEGATION_SAVE_ERROR:
      return {
        ...state,
        isPerforming: false
      };

    case Actions.USERANDRIGHT_PROFILEDELEGATION_SET_CRITERIAS:
      return {
        ...state,
        criterias: action.criterias
      };

    default:
      return state;
  }
}