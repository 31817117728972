import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { SearchOutlined } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { WorkflowStatus, WorkflowType } from "store/MasterValue/MasterValueTypes";

function WorkflowSearch({ criteria, setCriteria, launchSearch, classes }) {
  var search = e => {
    e.preventDefault();
    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    var pageSize = 10;
    if (getPref !== null) {
      pageSize = getPref.Pref.ResultPageSize * 1;
    }

    launchSearch({
      ...criteria,
      startPage: 0,
      pageSize: pageSize,
      orderProperty: null,
      isDescendingOrder: false
    });
  };

  // var workflowStatuses = [
  //   { code: "Pending", label: i18n._(t`Pending`) },
  //   { code: "Started", label: i18n._(t`Draft`) },
  //   { code: "Finished", label: i18n._(t`Finished`) },
  //   { code: "Aborted", label: i18n._(t`Aborted`) },
  //   { code: "OnError", label: i18n._(t`On Error`) }
  // ];

  // var workflowTypes = [
  //   { code: "CustomerCreation", label: i18n._(t`Customer creation`) },
  //   { code: "SupplierCreation", label: i18n._(t`Supplier creation`) },
  //   { code: "LongNameShortening", label: i18n._(t`Long Name Shortening`) },
  //   { code: "InfolegaleMerge", label: i18n._(t`Infolegale Merge`) },
  //   { code: "LocationCrupdate", label: i18n._(t`Location Crupdate`) },
  //   { code: "SupplierRequest", label: i18n._(t`Supplier Request`) },
  //   { code: "PayCharCreation", label: i18n._(t`PayChar creation`) }
  // ];

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <SearchOutlined />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <div>
              <form onSubmit={search} autoComplete="off">
                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <MasterValueAutocomplete
                      mvType={WorkflowStatus}
                      selectedValue={criteria?.statusCodes}
                      isEditable
                      multiple
                      onValueChange={e => setCriteria({ ...criteria, statusCodes: e })}
                      isMulti
                    />
                    {/*<Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={workflowStatuses}
                                            placeholder={<Trans>Workflow Status</Trans>}
                                            menuPlacement="auto"
                                            menuPosition="fixed"
                                            onChange={changeWorkflowStatus}
                                            value={workflowStatus}
                                            isSearchable={false}
                                        >
                                        </Select>*/}
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4}>
                    <MasterValueAutocomplete
                      mvType={WorkflowType}
                      selectedValue={criteria?.typeCodes}
                      isEditable
                      multiple
                      onValueChange={e => setCriteria({ ...criteria, typeCodes: e })}
                      isMulti
                    />
                    {/*<Select
                                            isMulti
                                            closeMenuOnSelect={false}
                                            options={workflowTypes}
                                            placeholder={<Trans>Workflow Type</Trans>}
                                            menuPlacement="auto"
                                            menuPosition="fixed"
                                            onChange={changeWorkflowType}
                                            value={workflowType}
                                            isSearchable={false}
                                        >
                                        </Select>*/}
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={4} sm={4} md={4}>
                    <CustomInput
                      labelText={<Trans>Label</Trans>}
                      id="criteriaLabelWorkflow"
                      onChange={e => {
                        setCriteria({ ...criteria, label: e.target.value });
                      }}
                      value={criteria?.label}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <Button type="submit" color="info" className={classes.registerButton} onClick={launchSearch}>
                  <Trans>Search</Trans>
                </Button>
              </form>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(WorkflowSearch);
