//import { isNullOrEmpty } from "../../tools";

//const GetMasterValueLabel = (masterValue, lang) => {
//    if (!lang) {
//        console.log('lang is not defined.');
//        console.trace();
//        lang = 'en';
//        }

//    lang = lang.toUpperCase();
//    if (!masterValue)
//        return "";

//    var value = masterValue[`label_${lang}`];
//    if (isNullOrEmpty(value) && lang !== "EN") {
//        return GetMasterValueLabel(masterValue, 'EN');
//    }

//    return value;
//};

const GetMasterValueLabel = masterValue => {
  return masterValue?.label ?? "Ø";
};

export default GetMasterValueLabel;
