import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ErrorBoundary from "components/ErrorBoundary";
import * as dateFnsLocales from "date-fns/locale";
import GlobalLayout from "layouts/GlobalLayout";
import Technical from "layouts/TechnicalLayout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import LoginPage from "views/TechnicalViews/LoginPage";
import HubbleContextProvider from "./store/IndexedDb/HubbleContextProvider";

const MainApp = ({ authenticationCtx }) => {
  var dateFnsLocale = null;

  dateFnsLocale = dateFnsLocales.enGB;

  var inner;
  if (!authenticationCtx.user) {
    inner = (
      <Technical>
        <LoginPage />
      </Technical>
    );
  } else {
    inner = <GlobalLayout />;
  }

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsLocale}>
        <HubbleContextProvider>
          <AutoRefreshingRouter>
            <ErrorBoundary>{inner}</ErrorBoundary>
          </AutoRefreshingRouter>
        </HubbleContextProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
};

export const AutoRefreshingRouter = ({ children }) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  useEffect(() => {
    const id = setTimeout(
      () => setShouldRefresh(true),
      1000 * 60 * 60 * 6 // 6 hours in milliseconds
    );
    return () => clearTimeout(id);
  }, []);

  return <BrowserRouter forceRefresh={shouldRefresh}>{children}</BrowserRouter>;
};

const mapStateToProps = state => {
  return {
    authenticationCtx: state.AuthenticationReducer
  };
};

export default connect(mapStateToProps)(MainApp);
