import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/JobPhase.Actions";
import React from "react";
import { connect } from "react-redux";
import { JobActivityLevel3, JobNatureOfWork, JobSegmentationLevel1, JobUnitOfWork } from "store/MasterValue/MasterValueTypes";

function JobPhaseLinksPart({ amc, itemChanged, isReadonly }) {
  var currentJob = !!amc.currentItem["JOB"] ? amc.currentItem["JOB"] : {};
  var item = !!amc.currentItem["JOBPHASE"] ? amc.currentItem["JOBPHASE"] : {};

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Links</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Segmentation Level 1</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobSegmentationLevel1}
              selectedValue={item.segmentationLevel1Code}
              isEditable={!isReadonly}
              prefixCode={true}
              showErrorTooltip={false}
              onValueChange={e => itemChanged(currentJob, { ...item, segmentationLevel1Code: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Activity Level 3</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobActivityLevel3}
              selectedValue={item.activityLevel3Code}
              isEditable={!isReadonly}
              prefixCode={true}
              showErrorTooltip={false}
              onValueChange={e => itemChanged(currentJob, { ...item, activityLevel3Code: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Nature Of Work</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobNatureOfWork}
              selectedValue={item.natureOfWorkCode}
              isEditable={!isReadonly}
              prefixCode={true}
              showErrorTooltip={false}
              onValueChange={e => itemChanged(currentJob, { ...item, natureOfWorkCode: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Quantity</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="quantity"
              value={item.quantity}
              type="number"
              onChange={e => itemChanged(currentJob, { ...item, quantity: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Unit Of Work</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobUnitOfWork}
              selectedValue={item.unitOfWorkCode}
              isEditable={!isReadonly}
              prefixCode={true}
              showErrorTooltip={false}
              onValueChange={e => itemChanged(currentJob, { ...item, unitOfWorkCode: e })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: (job, item) => {
      dispatch(Actions.ItemChanged(job, item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobPhaseLinksPart));
