import * as Actions from '../actions/types';

const initialState = {
    criterias: null,
    openedIds: [],
    results: null,
    error: null
};

export default function ST_SearchReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.SEARCH_BEGIN:
            return {
                ...state,
                criterias: action.criterias,
                searchScope: action.searchScope,
                searchMode: action.searchMode,
                results: [],
                error: null,
                isSearching: true
            };
        case Actions.SEARCH_END:
            return {
                ...state,
                criterias: action.criterias,
                searchMode: action.searchMode,
                searchScope: action.searchScope,
                results: action.results,
                isSearching: false
            };
        case Actions.SEARCH_ERROR:
            console.error(action.error);
            return {
                ...state,
                error: action.error
            };
        case Actions.SITE_CLOSE:
            // remove last
            return {
                ...state,
                openedIds: state.openedIds.filter((item, index) => index < (state.openedIds.length - 1))
            };
        case Actions.SITE_OPEN:
            return {
                ...state,
                openedIds: [
                    ...state.openedIds,
                    {
                        type: "site",
                        id: action.thirdPartyidentifier
                    }
                ]
            };
        default:
            return state;
    }
}