import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { connect } from "react-redux";
import { JobActivityLevel2, LocationNature, LocationType } from "store/MasterValue/MasterValueTypes";
import SiteActivities from "../SiteDetail/SiteActivities";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

function SiteStep2Type({ site, setSite, errors, defaultLang, masterValues }) {
  var error = code => errors.some(e => e.code === code);
  var cardType = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Site_Create_Type</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer justify="center">
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Site_Type</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={LocationType}
              selectedValue={site.locationTypeCode}
              onValueChange={code => setSite({ ...site, locationTypeCode: code })}
              isEditable
            />
          </GridItem>
          <GridItem style={{ marginTop: 20, paddingTop: 20 }} xs={4} sm={4} md={4}>
            <FormLabel error={error("SiteNatureMissing")} required>
              <Trans>Site_Nature</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              isEditable
              mvType={LocationNature}
              selectedValue={site.locationNatureCode}
              onValueChange={v => setSite({ ...site, locationNatureCode: v })}
              size="small"
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );

  var componentSiteActivities = (
    <SiteActivities
      site={site}
      setSite={setSite}
      isEditable={true}
      defaultLang={defaultLang}
      activityValues={masterValues[JobActivityLevel2].filter(c => c.isEnabled)}
    />
  );

  var cardActivities = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Site_Create_Activities</Trans>
        </h4>
      </CardHeader>
      <CardBody>{componentSiteActivities}</CardBody>
    </Card>
  );

  return (
    <>
      {cardType}
      {cardActivities}
    </>
  );
}

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(SiteStep2Type));
