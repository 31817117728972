import React from "react";

import Card from "./Card";
import CardBody from "./CardBody";
import CardHeader from "./CardHeader";
import CardIcon from "./CardIcon";
import { Error } from "@material-ui/icons";
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import { isArray, isObject } from "../../tools";
import { Typography } from "@material-ui/core";

function CardError({ error, defaultLang, footerMessage }) {
  if (!defaultLang) defaultLang = "FR";
  var stackMessage = "";
  let message = "";
  if (error?.response?.status === 401) {
    message = "401 Access Denied";
  } else if (error.response && error.response.data) {
    let data = error.response.data;
    if (isObject(data) && !isArray(data)) {
      message = (
        <ul>
          {Object.entries(data).map(({ key, value }) => (
            <li>
              {key} = {value}
            </li>
          ))}
        </ul>
      );
    } else if (isArray(data)) {
      message = (
        <ul>
          {data.map(d => (
            <li>{defaultLang === "FR" ? d.descriptionFr : d.descriptionEn}</li>
          ))}
        </ul>
      );
    } else {
      message = data;
    }
  } else {
    if (error.stack) stackMessage = error.stack.split("\n").map((item, idx) => <li key={idx}>{item}</li>);

    message = error.message;
  }

  return (
    <Card>
      <CardHeader color="danger" icon>
        <CardIcon color="danger">
          <Error />
        </CardIcon>
      </CardHeader>
      <CardBody>
        <h4>Oops, there is a problem</h4>
        <div>
          <strong>{message}</strong>
        </div>
        <ul>{stackMessage}</ul>
        {footerMessage && <Typography>{footerMessage}</Typography>}
      </CardBody>
    </Card>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(CardError);
