import React, { useState, useEffect } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
//
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//Icon
import StarIcon from '@material-ui/icons/Star';
import WorkIcon from '@material-ui/icons/Work';

//lingui JS
import { Trans } from "@lingui/macro";

function ProductPointOfSale({ product }) {

    var ProductUpPointOfSale = "";
    if (product.upPointOfSales !== null) {

        ProductUpPointOfSale = product.upPointOfSales.map((UpPointOfSale) => {
            return (
                <div>
                    <ListItem>
                        <ListItemIcon>
                            <bookmarkIcon />
                        </ListItemIcon>
                        <ListItemText inset secondary={UpPointOfSale.upIdentifier} />
                    </ListItem>
                </div>
            );
        });
    }

    return (<>
        <form>
            <GridContainer xs={12} sm={12} md={12}>
                <GridItem xs={6} sm={6} md={6}>
                    <Card>
                        <CardHeader style={{
                            ...Styles.cardHeader
                        }} icon>
                            <ListItem button>
                                <ListItemIcon>
                                    <StarIcon />
                                </ListItemIcon>
                                <ListItemText primary={<Trans>Product Up Point Of Sales</Trans>} />
                            </ListItem>
                        </CardHeader>
                        <CardBody>
                            {ProductUpPointOfSale}
                        </CardBody>
                    </Card>
                </GridItem>

            </GridContainer>
        </form>
    </>);
};

const Styles = {
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    },
};


export default ProductPointOfSale;