export const STRUCTURE_CLOSE = 'STRUCTURE CLOSE';
export const STRUCTURE_OPEN = 'STRUCTURE OPEN';
export const STRUCTURE_RELOAD = 'STRUCTURE RELOAD';

export const STRUCTURE_COLCODE_BEGIN_ACTION = "STRUCTURE COLCODE_BEGIN_ACTION";
export const STRUCTURE_COLCODE_END_ACTION = "STRUCTURE COLCODE_END_ACTION";
export const STRUCTURE_COLCODE_GET_ALL = "STRUCTURE COLCODE_GET_ALL";
export const STRUCTURE_COLCODE_GET_DETAIL = "STRUCTURE COLCODE_GET_DETAIL";
export const STRUCTURE_COLCODE_GET_EXISTING = "STRUCTURE COLCODE_GET_EXISTING";
export const STRUCTURE_COLCODE_SAVE_END = "STRUCTURE COLCODE_SAVE_END";
export const STRUCTURE_COLCODE_SAVE_ERROR = "STRUCTURE COLCODE_SAVE_ERROR";
export const STRUCTURE_COLCODE_SET_CRITERIAS = 'STRUCTURE COLCODE_SET_CRITERIAS';