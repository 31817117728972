import React, { useState } from "react";
import { connect } from "react-redux";
import { isArrayNullOrEmpty } from "tools";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

import HistoryContainer from "module/history/containers/HistoryContainer";
import CardError from "components/Card/CardError";
import { CircularProgress } from "@material-ui/core";
import { GetCustomerHistory } from "../../store/actions";

const TabCustomerHistory = function({ customerCtx }) {
  const [state, setState] = useState({ isLoading: false });

  var customer = customerCtx.customer;

  if (!state.isLoading && !state.history) {
    setState({ isLoading: true, history: [] });

    GetCustomerHistory(
      customer.thirdPartyId,
      h => setState({ isLoading: false, history: h }),
      e => setState({ isLoading: false, history: [], error: e })
    );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  return <HistoryContainer currentItem={customer} items={state.history} />;
};

const mapStateToProps = state => {
  return {
    customerCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].customerCtx : null
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(TabCustomerHistory));
