//lingui
import { Trans } from "@lingui/macro";
import Search from "@material-ui/icons/Search";
import ProductListings from "module/product/ProductListings.js";
import React from "react";

const productRoutes = [
  {
    path: "/products",
    name: <Trans>Product Search</Trans>,
    short: <Trans>Product Search</Trans>,
    icon: Search,
    component: ProductListings
  }
];

export default productRoutes;
