import * as Actions from "./types";
import { isNullOrEmpty } from "../../../tools";
import { ApimGet } from "components/Common/ApimSender";

export function CleanSearchPaymentCharacteristics() {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_SEARCH_CLEAN,
      searchScope: "paymentCharacteristics"
    });
  };
}

export function SearchPaymentCharacteristics(criterias, searchMode) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_SEARCH_BEGIN,
      criterias: criterias,
      searchMode: searchMode,
      searchScope: "paymentCharacteristics"
    });

    const queryString = searchMode === "Simple" ? ConvertCriteriaToSimpleQueryString(criterias) : ConvertCriteriaToQueryString(criterias);
    ApimGet(
      "api/TpCopernic/PaymentCharacteristics" + queryString,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_SEARCH_END,
          results: responseJson,
          criterias: criterias,
          searchMode: searchMode,
          searchScope: "paymentCharacteristics"
        });
      },
      error => {
        dispatch({
          type: Actions.TP_COPERNIC_SEARCH_ERROR,
          error: error
        });
      }
    );
  };
}

// Convert Criteria To Simple Query String
function ConvertCriteriaToSimpleQueryString(criteria) {
  var queryString = "";
  var separator = "";
  if (!isNullOrEmpty(criteria.criteriaIban)) {
    queryString += separator + "Iban=" + criteria.criteriaIban;
    separator = "&";
  }
  if (!isNullOrEmpty(criteria.criteriaStatus)) {
    queryString += separator + "StatusCode=" + criteria.criteriaStatus;
    separator = "&";
  }
  if (!isNullOrEmpty(queryString)) queryString = "?" + queryString;

  return queryString;
}

function ConvertCriteriaToQueryString(criteria) {
  var queryString = "";
  var isEmpty = true;
  var separator = "";
  if (!isNullOrEmpty(criteria.criteriaMdmId)) {
    queryString += separator + "MdmId=" + criteria.criteriaMdmId;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.criterialegalIdentifier)) {
    queryString += separator + "LegalIdentifier=" + criteria.criteriaLegalIdentifier;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.criteriaAddress)) {
    queryString += separator + "LineAddress=" + criteria.criteriaAddress;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.criteriaLongName)) {
    queryString += separator + "LongName=" + criteria.criteriaLongName;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.criteriaCity)) {
    queryString += separator + "City=" + criteria.criteriaCity;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.criteriaCountry)) {
    queryString += separator + "Country=" + criteria.criteriaCountry;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.isCustomer)) {
    queryString += separator + "CustomerSearch=" + criteria.isCustomer;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.isSupplier)) {
    queryString += separator + "SupplierSearch=" + criteria.isSupplier;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.isPointOfSale)) {
    queryString += separator + "PointOfSaleSearch=" + criteria.isPointOfSale;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.startPage)) {
    queryString += separator + "StartPage=" + criteria.startPage;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryString += separator + "PageSize=" + criteria.pageSize;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryString += separator + "OrderProperty=" + criteria.orderProperty;
    separator = "&";
    isEmpty = false;
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryString += separator + "IsDescendingOrder=" + criteria.isDescendingOrder;
    separator = "&";
    isEmpty = false;
  }
  if (!isEmpty) queryString = "?" + queryString;

  return queryString;
}
