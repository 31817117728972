import { Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { ApimGetPromise } from "components/Common/ApimSender";
import React, { useEffect, useState } from "react";
import { highlightText } from "../../module/tools";
import { isNullOrEmpty } from "../../tools";

export default function GenericServerAutocomplete({ selectedValue, searchPartialUri, error, onValueChange, readOnly }) {
  const [value, setValue] = useState(selectedValue);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!isNullOrEmpty(selectedValue)) {
      ApimGetPromise(searchPartialUri + selectedValue).then(results => {
        setOptions(results);
        setValue(results[0]);
      });
    } else {
      setValue(null);
      setInputValue("");
    }
  }, [selectedValue]);

  useEffect(() => {
    let active = true;

    if (inputValue.length <= 2) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (!searchPartialUri) return undefined;

    ApimGetPromise(searchPartialUri + inputValue)
      .then(results => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      })
      .catch(error => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          newOptions = [
            ...newOptions,
            {
              error: error,
              value: null
            }
          ];

          setOptions(newOptions);
        }
      });

    return () => {
      active = false;
    };
  }, [inputValue]);

  if (!searchPartialUri) {
    return <Typography>searchPartialUri is required</Typography>;
  }

  if (readOnly) {
    return <Typography>{value && value.text}</Typography>;
  }
  return (
    <Autocomplete
      id="identifiedUserAutocomplete"
      clearOnEscape
      getOptionLabel={option => (typeof option === "string" ? option : option.text)}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      fullWidth
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) onValueChange(newValue.value);
        else onValueChange(null, null);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => <TextField {...params} error={error} fullWidth />}
      renderOption={option => {
        const parts = highlightText(option.text, inputValue);

        return (
          <Grid container alignItems="center">
            <Grid item></Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
