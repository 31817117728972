import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted";
import React from "react";
import { StructSjMethodeConsolidation, StructSjSystemeCompta, StructSjSystemePaie } from "store/MasterValue/MasterValueTypes";
import { extractErrorMessage } from "module/tools";

const TabAnnexe = ({ structure, setStructure, isEditable, defaultLang, errors }) => {
  const sj = structure.sj;
  const setSj = v => setStructure({ ...structure, sj: v });

  return (
    <>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Structure_Tab_Annexe</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_SJ_CodeJdeSepSarl</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={isEditable}
                value={sj.codeJdeSepSarl}
                onChange={e => setSj({ ...sj, codeJdeSepSarl: e.target.value })}
                error={extractErrorMessage(errors, "SJ_JDESEPSARL", defaultLang)}
                showErrorTooltip
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_SJ_CodeHra</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={isEditable}
                value={sj.codeHra}
                onChange={e => setSj({ ...sj, codeHra: e.target.value })}
                error={extractErrorMessage(errors, "SJ_HRA", defaultLang)}
                showErrorTooltip
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_SJ_MethConso</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <MasterValueAutocomplete
                isEditable={isEditable}
                mvType={StructSjMethodeConsolidation}
                selectedValue={sj.methodeConsolidationCode}
                onValueChange={v => setSj({ ...sj, methodeConsolidationCode: v })}
                size="small"
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_SJ_SystemePaie</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <MasterValueAutocomplete
                isEditable={isEditable}
                mvType={StructSjSystemePaie}
                selectedValue={sj.systemePaieCode}
                onValueChange={v => setSj({ ...sj, systemePaieCode: v })}
                size="small"
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_SJ_SystemeCompta</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <MasterValueAutocomplete
                isEditable={isEditable}
                mvType={StructSjSystemeCompta}
                selectedValue={sj.systemeComptaCode}
                onValueChange={v => setSj({ ...sj, systemeComptaCode: v })}
                size="small"
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_SJ_CapitalSocial</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={isEditable}
                value={sj.capitalSocial}
                onChange={e => setSj({ ...sj, capitalSocial: e.target.value })}
                error={extractErrorMessage(errors, "SJ_CAPITAL", defaultLang)}
                showErrorTooltip
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_SJ_SiteInformatique</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={isEditable}
                value={sj.siteInformatique}
                onChange={e => setSj({ ...sj, siteInformatique: e.target.value })}
                error={extractErrorMessage(errors, "SJ_SITEINFO", defaultLang)}
                showErrorTooltip
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_SJ_Partenaires</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <FieldText
                isEditable={isEditable}
                value={sj.partenaires}
                onChange={e => setSj({ ...sj, partenaires: e.target.value })}
                error={extractErrorMessage(errors, "SJ_PARTNERS", defaultLang)}
                showErrorTooltip
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(structureStyle)(TabAnnexe);
