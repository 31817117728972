import React from "react";
import axios from "axios";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "services/authentication/MsalAuth";
import { apimLoginRequest } from "services/authentication/MsalAuth";

const HubbleTables = [
  {
    type: "Custom",
    url: "Countries/all",
    name: "countries"
  },
  {
    type: "Custom",
    url: "Erts",
    name: "structureErts"
  },
  {
    type: "Custom",
    url: "Sjs",
    name: "structureSjs"
  },
  {
    type: "Custom",
    url: "Ues",
    name: "structureUes"
  },
  {
    type: "Custom",
    url: "Ups",
    name: "structureUps"
  },
  {
    type: "Custom",
    url: "Ues/ByProfileCode/MDMS_TPS_WF_CREATOR¤MDMS_TPS_PURCHASER",
    name: "uesForPurchaserWorkflows"
  },
  {
    type: "Single",
    url: "Users",
    name: "currentUser"
  },
  {
    type: "MasterValue",
    url: "CommonCountryCounty",
    name: "countryCounties"
  },
  {
    type: "MasterValue",
    url: "CommonCountryState",
    name: "countryStates"
  },
  {
    type: "MasterValue",
    url: "JobActivityLevel1",
    name: "activitiesLevel1"
  },
  {
    type: "MasterValue",
    url: "JobActivityLevel2",
    name: "activitiesLevel2"
  },
  {
    type: "MasterValue",
    url: "JobActivityLevel3",
    name: "activitiesLevel3"
  },
  {
    type: "MasterValue",
    url: "TpBenificiaryType",
    name: "beneficiaryTypes"
  },
  {
    type: "MasterValue",
    url: "TpCoupaActivity",
    name: "coupaActivities"
  },
  {
    type: "MasterValue",
    url: "TpContactType",
    name: "contactTypes"
  },
  {
    type: "MasterValue",
    url: "TpCustomerNature",
    name: "customerNatures"
  },
  {
    type: "MasterValue",
    url: "TpCustomerStatus",
    name: "customerStatuses"
  },
  {
    type: "MasterValue",
    url: "TpDematerializationType",
    name: "dematerializations"
  },
  {
    type: "MasterValue",
    url: "TpFee",
    name: "fees"
  },
  {
    type: "MasterValue",
    url: "TpGroup",
    name: "groups"
  },
  {
    type: "MasterValue",
    url: "TpIndustryCode",
    name: "industryCodes"
  },
  {
    type: "MasterValue",
    url: "TpIndustryCodeRepository",
    name: "industryCodeRepositories"
  },
  {
    type: "MasterValue",
    url: "CommonLanguage",
    name: "languages"
  },
  {
    type: "MasterValue",
    url: "TpLegalForm",
    name: "legalForms"
  },
  {
    type: "MasterValue",
    url: "TpLegalIdentifier",
    name: "legalIdentifiers"
  },
  {
    type: "MasterValue",
    url: "LocationContactType",
    name: "locationContactTypes"
  },
  {
    type: "MasterValue",
    url: "LocationStatus",
    name: "locationStatuses"
  },
  {
    type: "MasterValue",
    url: "LocationType",
    name: "locationTypes"
  },
  {
    type: "MasterValue",
    url: "LocationCharacteristicType",
    name: "locationCharTypes"
  },
  {
    type: "MasterValue",
    url: "LocationCharacteristicValue",
    name: "locationCharValues"
  },
  {
    type: "MasterValue",
    url: "TpPaymentCharacteristicOrigin",
    name: "paymentCharacteristicOrigins"
  },
  {
    type: "MasterValue",
    url: "TpPaymentCharacteristicStatus",
    name: "paymentCharacteristicStatuses"
  },
  {
    type: "MasterValue",
    url: "TpPaymentCharacteristicType",
    name: "paymentCharacteristicTypes"
  },
  {
    type: "MasterValue",
    url: "TpPaymentMethod",
    name: "paymentMethods"
  },
  {
    type: "MasterValue",
    url: "TpPaymentPeriod",
    name: "paymentPeriods"
  },
  {
    type: "MasterValue",
    url: "TpScope",
    name: "scopes"
  },
  {
    type: "MasterValue",
    url: "TpSecurization",
    name: "securizations"
  },
  {
    type: "MasterValue",
    url: "StructSjMethodeConsolidation",
    name: "structSjMethConsos"
  },
  {
    type: "MasterValue",
    url: "StructSjSystemeCompta",
    name: "structSjSystCompta"
  },
  {
    type: "MasterValue",
    url: "StructSjSystemePaie",
    name: "structSjSystPaies"
  },
  {
    type: "MasterValue",
    url: "StructUeActivite",
    name: "structUeActivites"
  },
  {
    type: "MasterValue",
    url: "StructTag",
    name: "structTags"
  },
  {
    type: "MasterValue",
    url: "StructTagType",
    name: "structTagTypes"
  },
  {
    type: "MasterValue",
    url: "StructIris2Activite",
    name: "structIris2Activites"
  },
  {
    type: "MasterValue",
    url: "StructStructureType",
    name: "structureTypes"
  },
  {
    type: "MasterValue",
    url: "StructUpTypeInstallation",
    name: "structUpTypeInstallations"
  },
  {
    type: "MasterValue",
    url: "TpSupplierNature",
    name: "supplierNatures"
  },
  {
    type: "MasterValue",
    url: "TpSupplierStatus",
    name: "supplierStatuses"
  },
  {
    type: "MasterValue",
    url: "TpThirdPartyCategory",
    name: "thirdPartyCategories"
  },
  {
    type: "MasterValue",
    url: "TpThirdPartyStatus",
    name: "thirdPartyStatuses"
  },
  {
    type: "MasterValue",
    url: "WorkflowStatus",
    name: "workflowStatuses"
  },
  {
    type: "MasterValue",
    url: "WorkflowStep",
    name: "workflowSteps"
  },
  {
    type: "MasterValue",
    url: "WorkflowType",
    name: "workflowTypes"
  },
  {
    type: "MasterValue",
    url: "JobProjectType",
    name: "projecttypes"
  },
  {
    type: "MasterValue",
    url: "JobIasManagementMode",
    name: "iasmanagementmodes"
  },
  {
    type: "MasterValue",
    url: "JobProjectStatus",
    name: "projectstatuses"
  },
  {
    type: "MasterValue",
    url: "JobBu",
    name: "bus"
  },
  {
    type: "MasterValue",
    url: "CommonCurrency",
    name: "currencies"
  },
  {
    type: "MasterValue",
    url: "CommonTimeZone",
    name: "timezones"
  },
  {
    type: "MasterValue",
    url: "JobCustomerContractStatus",
    name: "customercontractstatuses"
  },
  {
    type: "MasterValue",
    url: "JobCustomerContractType",
    name: "customercontracttypes"
  },
  {
    type: "MasterValue",
    url: "JobContractualSetup",
    name: "contractualsetup"
  },
  {
    type: "MasterValue",
    url: "JobStatus",
    name: "jobstatuses"
  },
  {
    type: "MasterValue",
    url: "JobNatureOfWork",
    name: "naturesofwork"
  },
  {
    type: "MasterValue",
    url: "JobUnitOfWork",
    name: "unitsOfwork"
  },
  {
    type: "MasterValue",
    url: "JobSegmentationLevel1",
    name: "segmentationsLevel1"
  },
  {
    type: "MasterValue",
    url: "TpCompanyComplianceScore",
    name: "companyComplianceScore"
  }
];

// set up the database when this function is called
async function InitializeDatabase(callbackFn, errorFn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  var OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
  var apiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

  msalInstance
    .acquireTokenSilent({
      ...apimLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(apiTokenResponse => {
      let token = apiTokenResponse.accessToken;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };
      let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
      if (authJwt) {
        headers = { ...headers, AuthJwt: authJwt.token };
      }

      var promises = [];
      HubbleTables.filter(t => t.type !== "MasterValue").forEach(urlAction => {
        promises.push(
          axios
            .get(apiMdmColasEndPoint + "api/" + urlAction.url, { headers })
            .then(r => {
              if (r.status === 200) {
                return r.data;
              } else if (r.status === 204) {
                return [];
              }
            })
            .then(json => {
              if (Array.isArray(json)) {
                return { items: json, name: urlAction.name, type: urlAction.type };
              }

              return {
                items: [{ ...json }],
                name: urlAction.name,
                type: urlAction.type
              };
            })
        );
      });

      promises.push(
        axios
          .post(
            apiMdmColasEndPoint + "api/MasterValue",
            {
              types: HubbleTables.filter(t => t.type === "MasterValue").map(m => {
                return m.url;
              })
            },
            { headers }
          )
          .then(r => {
            if (r.status === 200) {
              return r.data;
            } else if (r.status === 204) {
              return [];
            }
          })
          .then(masterValues => {
            return masterValues.map(mv => {
              let mvItem = HubbleTables.find(m => m.url === mv.type);
              if (!mvItem) return;
              return {
                name: mvItem.name,
                items: mv.values
              };
            });
          })
      );

      var hasException = false;

      var masterValues = {};
      axios
        .all(promises)
        .then(res => {
          res.forEach(async r => {
            if (Array.isArray(r)) {
              r.forEach(s => {
                //db.table(s.name).bulkAdd(s.items).catch((error) => console.log(`Error with ${s.name}: ${error}`));
                if (s.type === "Single") masterValues[s.name] = s.items[0];
                else masterValues[s.name] = s;
              });
            } else {
              //db.table(r.name).bulkAdd(r.items).catch((error) => console.log(`Error with ${r.name}: ${error}`));;
              if (r.type === "Single") masterValues[r.name] = r.items[0];
              else masterValues[r.name] = r;
            }
          });
        })
        .catch(exception => {
          hasException = true;
          errorFn(exception);
        })
        .then(() => {
          if (!hasException) callbackFn(masterValues);
        });
    });
}

const HubbleContext = React.createContext();

function HubbleContextProvider({ children }) {
  //const getData = async (name) => {
  //    let db = new Dexie("HubbleDb");

  //    return db.table(name).toArray();
  //};

  const initData = async (callbackFn, errorFn) => {
    await InitializeDatabase(callbackFn, errorFn);
  };

  return <HubbleContext.Provider value={{ /*getData,*/ initData }}>{children}</HubbleContext.Provider>;
}

export default HubbleContextProvider;
export { HubbleContext, HubbleTables };
