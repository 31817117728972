import { Trans } from "@lingui/macro";
import { Button, IconButton, TextField, Typography } from "@material-ui/core";
import { Add, Check, Close, Delete, Edit } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import React, { useEffect, useState } from "react";
import { isNull } from "../../tools";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

const InputInLineStyle = {
  textInput: {
    marginLeft: "10px"
  },
  listItem: {
    paddingTop: "2px",
    paddingBottom: "2px"
  }
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

function KeyValueEditor({
  onValueChanged,
  cardLabel,
  keyMasterValue,
  keyMasterValueFilter,
  keyPropertyName,
  maxlength,
  valuePropertyName,
  items,
  isEditable
}) {
  var [table, setTable] = useState([]);
  var [key, setKey] = useState(null);
  var [value, setValue] = useState(null);
  var [editIndex, setEditIndex] = useState(-1);
  var [isCreation, setIsCreation] = useState(false);

  useEffect(() => {
    setTable(items ?? []);
    setEditIndex(-1);
    setIsCreation(false);
  }, [items]);

  isEditable = isNull(isEditable) ? false : isEditable;

  function cancelEdit() {
    setEditIndex(-1);
    if (isCreation) {
      var indexToRemove = table.length - 1;
      setTable(
        table.filter((item, index) => {
          if (index !== indexToRemove) {
            return item;
          }
        })
      );
      setIsCreation(false);
    }
  }

  function create() {
    var newItem = {};
    newItem[keyPropertyName] = "";
    newItem[valuePropertyName] = "";
    var newTable = [...table, newItem];
    setTable(newTable);
    setIsCreation(true);
    setKey("");
    setValue("");
    setEditIndex(newTable.length - 1);
  }

  function edit(index) {
    var row = table[index];
    setKey(row[keyPropertyName]);
    setValue(row[valuePropertyName]);
    setEditIndex(index);
  }

  function save() {
    var row = table[editIndex];
    row[keyPropertyName] = key;
    row[valuePropertyName] = value;
    setIsCreation(false);
    setEditIndex(-1);
    setTable(table);
    onValueChanged(table);
  }

  function remove(index) {
    var newTable = [...table.slice(0, index), ...table.slice(index + 1)];
    setTable(newTable);
    onValueChanged(newTable);
  }

  var btnAdd = "";
  if (editIndex === -1 && isEditable) {
    btnAdd = (
      <Button onClick={() => create()}>
        <Add />
        &nbsp;<Trans>Add</Trans>
      </Button>
    );
  }

  var displayedCollection = table
    .filter(i => !isNull(i))
    .map((item, index) => {
      if (index === editIndex) {
        return (
          <GridContainer key={index}>
            <GridItem xs={4} sm={4} md={4}>
              <MasterValueAutocomplete
                isEditable={isEditable}
                mvType={keyMasterValue}
                mvFilter={keyMasterValueFilter}
                selectedValue={key}
                onValueChange={c => setKey(c)}
              />
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <TextField
                margin="dense"
                type="text"
                fullWidth
                style={{ ...InputInLineStyle.textInput }}
                value={value}
                onChange={e => {
                  var value = e.target.value;
                  if (!!maxlength && maxlength > 0 && value.length > maxlength) value = value.substring(0, maxlength);
                  setValue(value);
                }}
              />
            </GridItem>
            <GridItem xs={3} sm={3} md={3} style={{ textAlign: "center" }}>
              <IconButton onClick={() => save()}>
                <Check />
              </IconButton>
              <IconButton onClick={() => cancelEdit()}>
                <Close />
              </IconButton>
            </GridItem>
          </GridContainer>
        );
      } else {
        var btn = "";
        if (editIndex === -1 && isEditable) {
          btn = (
            <>
              <IconButton onClick={() => edit(index)}>
                <Edit />
              </IconButton>
              <IconButton onClick={() => remove(index)}>
                <Delete style={{ color: "red" }} />
              </IconButton>
            </>
          );
        }
        return (
          <GridContainer key={index}>
            <GridItem xs={4} sm={4} md={4}>
              <MasterValueAutocomplete isEditable={false} mvType={keyMasterValue} selectedValue={item[keyPropertyName]} />
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <Typography>{item[valuePropertyName]}</Typography>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} style={{ textAlign: "center" }}>
              {btn}
            </GridItem>
          </GridContainer>
        );
      }
    });

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <GridContainer>
          <GridItem xs={9} sm={9} md={9}>
            <h4>{cardLabel}</h4>
          </GridItem>
          <GridItem xs={3} sm={3} md={3}>
            {btnAdd}
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>{displayedCollection}</CardBody>
    </Card>
  );
}
export default withStyles(thirdPartySearchCriteriaStyle)(KeyValueEditor);
