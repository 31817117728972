import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../store/actions";
import { isArrayNullOrEmpty, isNull } from "../../tools";

import Button from "../../../components/CustomButtons/Button";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { AntTabs, AntTab, TabPanel } from "../../../components/Common/Tabs";

import CardError from "components/Card/CardError";
import ComponentCoupa from "./ThirdPartyDetail/ComponentCoupa";
import TabPointOfSaleGeneral from "./ThirdPartyDetail/TabPointOfSaleGeneral";
import TabPointOfSaleSupplier from "./ThirdPartyDetail/TabPointOfSaleSupplier";

import { Grid, CircularProgress, SnackbarContent } from "@material-ui/core";
import { AddAlert, CheckCircleOutlined, ErrorOutlined } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from "@lingui/macro";
import { HasRight } from "services/user/UserHelper";

function ThirdPartyPointOfSaleDatas({ thirdPartyCtx, pointOfSaleCtx, transformIntoPointOfSale, classes, defaultLang }) {
  var dispatch = useDispatch();
  var thirdParty = thirdPartyCtx.thirdParty;
  var [isFirstTime, setIsFirstTime] = useState(true);
  const [tabIdx, setTabIdx] = React.useState(0);
  const infoNonDiffusable = "INFORMATION NON DIFFUSIBLE";

  useEffect(() => {
    if (isFirstTime) {
      if (isNull(pointOfSaleCtx)) {
        dispatch(Actions.GetPointOfSale(thirdParty.identifier));
      }
      setIsFirstTime(false);
    }
  });

  var coupaIcon = "";
  if (!isNull(pointOfSaleCtx)) {
    if (!isNull(pointOfSaleCtx.error)) {
      if (isArrayNullOrEmpty(pointOfSaleCtx.error.response.data) || pointOfSaleCtx.error.response.data.some(e => e.code === "Unknown")) {
        return <CardError error={pointOfSaleCtx.error} />;
      } else {
        var errorData = pointOfSaleCtx.error.response.data;
        if (errorData.some(e => e.code.startsWith("COUPA_"))) {
          coupaIcon = <ErrorOutlined style={{ color: "red" }} />;
        }
      }
    }

    if (pointOfSaleCtx.saveStatus === "PENDING")
      return (
        <center>
          <p>
            <Trans>Loading in progress</Trans>
          </p>
          <p>
            <CircularProgress />
          </p>
        </center>
      );
    if (pointOfSaleCtx.saveStatus === "SUCCESS_RELOAD") {
      setTimeout(
        () => dispatch(Actions.OpenThirdParty(pointOfSaleCtx.pointOfSale.thirdPartyIdentifier, thirdParty.identifier, "ThirdPartyPointOfSaleDatas")),
        2000
      );
      return (
        <center>
          <p>
            <Trans>Success refreshing</Trans>
          </p>
          <p>
            <CheckCircleOutlined style={{ color: "green" }} />
          </p>
        </center>
      );
    }
  }

  if (!thirdParty.isPointOfSale || isNull(pointOfSaleCtx)) {
    var innerElement;

    if ((thirdParty.thirdPartyStatusCode !== "Closed" || thirdPartyCtx.isEditableClosed) && HasRight("thirdparty_supplier.transform")
      && thirdParty.customLongName.toUpperCase() !== infoNonDiffusable) {
      innerElement = (
        <Button type="submit" color="info" className={classes.registerButton} onClick={() => transformIntoPointOfSale({ thirdParty })}>
          <Trans> Transform ThirdParty into PointOfSale </Trans>
        </Button>
      );
    } else {
      innerElement = (
        <SnackbarContent
          message={<Trans>You don't have rights to transform this third party into a point of sale.</Trans>}
          icon={AddAlert}
          color="info"
        />
      );
    }

    return (
      <Grid style={GridContainerStyle.mainGrid} container>
        <Grid item xs={12}>
          <Grid container justify="center">
            {innerElement}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  var isEditable = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : pointOfSaleCtx.isEditable;
  var isEditableCoupa = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : pointOfSaleCtx.isEditableCoupa;
  return (
    <div className="divContainer">
      <form autoComplete="false">
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs supplier"
            >
              <AntTab label={<Trans>General</Trans>} />
              <AntTab label={<Trans>Supplier</Trans>} />
              <AntTab
                label={
                  <>
                    {coupaIcon}
                    <Trans>Coupa</Trans>
                  </>
                }
              />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <TabPointOfSaleGeneral defaultLang={defaultLang} isEditable={isEditable} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <TabPointOfSaleSupplier />
            </TabPanel>
            <TabPanel value={tabIdx} index={2}>
              <ComponentCoupa
                defaultLang={defaultLang}
                isEditable={isEditableCoupa}
                coupa={pointOfSaleCtx.coupa}
                onGetCoupa={() => dispatch(Actions.PointOfSaleCoupaGet(thirdParty.identifier))}
                onValueChanged={coupa => dispatch(Actions.PointOfSaleCoupaEdit(coupa))}
                error={pointOfSaleCtx.error}
                supplierCountryCode={thirdParty.countryCode}
              />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const GridContainerStyle = {
  mainGrid: {
    marginTop: "40px"
  }
};
const TabVerticalStyle = {
  tabs: {
    marginTop: "0.5rem"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};
const ReactSelectStyle = {
  menu: provided => ({
    ...provided,
    zIndex: 999
  })
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null,
    pointOfSaleCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].pointOfSaleCtx : null,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    transformIntoPointOfSale: pointOfSale => {
      dispatch(Actions.SavePointOfSale(pointOfSale, true));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyPointOfSaleDatas));
