//lingui
import { Trans } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/admin/adminStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { StructureErt, StructureSj, StructureUe } from "store/MasterValue/MasterValueTypes";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";

function MigrationResult({ migrationReport }) {
  const reactTableColumns = [
    {
      Header: <Trans>Status</Trans>,
      accessor: "status"
    },
    {
      Header: <Trans>Entity Id</Trans>,
      accessor: "mdmId"
    },
    {
      Header: <Trans>Structure</Trans>,
      accessor: "structure"
    },
    {
      Header: <Trans>Structure Type</Trans>,
      accessor: "structureType"
    }
  ];

  function GetStatusCount(statusToFind) {
    if (!migrationReport.items) return 0;
    else {
      var filtredItems = migrationReport.items.filter((item, index) => {
        return statusToFind === item.status;
      });
      return filtredItems.length;
    }
  }

  return (
    <div style={{ paddingTop: "30px" }}>
      <Card>
        <CardHeader color="info">
          <Typography>
            <Trans>Migration Summary</Trans>
          </Typography>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={8} sm={8} md={8}>
                      <Trans>Global Status</Trans>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      {migrationReport.status}
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <Trans>Total Items count</Trans>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      {!migrationReport.items ? 0 : migrationReport.items.length}
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <Trans>Total Ok Items count</Trans>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      {GetStatusCount("OK")}
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <Trans>Total Ko Items count</Trans>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      {GetStatusCount("KO")}
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <Trans>Total Already migrated items count</Trans>
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                      {GetStatusCount("AlreadyMigrated")}
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <ReactTable
                columns={reactTableColumns}
                data={migrationReport.items}
                showPaginationBottom={true}
                className="-striped -highlight"
                pageSize={10}
                style={ReactTableStyle.main}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    structureUes: state.MasterValueReducer[StructureUe],
    structureSjs: state.MasterValueReducer[StructureSj],
    structureErts: state.MasterValueReducer[StructureErt]
  };
};

export default connect(mapStateToProps)(withStyles(amcSearchStyle)(MigrationResult));
