import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";

// style
import "assets/scss/material-dashboard-pro-react/react-web-tabs.css";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: 1,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  }
});

const InputInLineStyle = {
  textInput: {
    marginLeft: "10px"
  },
  listItem: {
    paddingTop: "2px",
    paddingBottom: "2px"
  }
};

class KeysValuesDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: this.props.disabled,
      displayedProperties: this.props.displayedProperties
    };
    this.handleChange = this.handleChange.bind(this);
    this.transformKey = this.transformKey.bind(this);
  }

  handleChange = (event, item) => {
    item[this.state.displayedProperties.valueProperty] = event.target.value;
    if (this.props.handleChange !== null && this.props.handleChange !== undefined) this.props.handleChange(item);
  };

  transformKey(item) {
    if (this.props.transformKey !== undefined) return this.props.transformKey(item);
    else return item[this.state.displayedProperties.keyProperty];
  }

  emptyIfNull(value) {
    return value === null || value === undefined ? "" : value;
  }

  render() {
    var displayedCollection = "";
    if (this.props.collection !== null && this.props.collection !== undefined) {
      displayedCollection = this.props.collection.map((item, idx) => {
        if (item === null || item === undefined) return;
        return (
          <tr key={idx}>
            <td>
              <FormLabel>{this.transformKey(item)}</FormLabel>
            </td>
            <td>
              <TextField
                disabled={this.state.disabled}
                margin="dense"
                id="itemCollection{item[this.state.keyProperty]}"
                type="text"
                fullWidth
                style={{ ...InputInLineStyle.textInput }}
                value={this.emptyIfNull(item[this.state.displayedProperties.valueProperty])}
                onChange={event => {
                  this.handleChange(event, item);
                }}
              />
            </td>
            <td></td>
          </tr>
        );
      });
    }

    var addToCollectionControl = "";
    if (!this.state.disabled) {
      addToCollectionControl = this.props.children;
    }

    return (
      <table style={{ width: "100%" }}>
        <tbody>
          {displayedCollection}
          {addToCollectionControl}
        </tbody>
      </table>
    );
  }
}

export default withStyles(thirdPartySearchCriteriaStyle)(KeysValuesDisplay);
