import React, { useState } from "react";
import { GetUsersByProfileTerritories } from "services/user/UserHelper";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import CardError from "components/Card/CardError";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Chip, CircularProgress } from "@material-ui/core";

import { Trans } from "@lingui/macro";
import SiteDetailContent from "../../../site/containers/SiteDetailContent";
import { Alert, AlertTitle } from "@material-ui/lab";

function LocationCrupdate({ workflow }) {
  var alert = <></>;
  if (workflow.workflowStatusCode === "Pending") {
    alert = (
      <Alert severity="info">
        <AlertTitle>
          <Trans>Workflow_Status_Pending</Trans>
        </AlertTitle>
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "OnError") {
    alert = (
      <Alert severity="error">
        <AlertTitle>
          <Trans>Workflow_Status_Error</Trans>
        </AlertTitle>
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "Aborted") {
    alert = (
      <Alert severity="warning">
        <AlertTitle>
          <Trans>Workflow_Status_Aborted</Trans>
        </AlertTitle>
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "Finished") {
    alert = (
      <Alert severity="success">
        <AlertTitle>
          <Trans>Workflow_Status_Finished</Trans>
        </AlertTitle>
      </Alert>
    );
  }

  return (
    <div className="divContainer">
      {alert}
      {workflow.workflowStatusCode === "Pending" && <CardValidators workflow={workflow} />}
      <CardLocation workflowLinkedObject={workflow.linkedObject} />
    </div>
  );
}

function CardValidators({ workflow }) {
  var [state, setState] = useState({ isLoading: false });
  if (!state.isLoading && !state.validators) {
    setState({ isLoading: true });
    GetUsersByProfileTerritories(
      workflow.validatorProfileCodes,
      workflow.validatorTerritoryCountryCode,
      workflow.validatorTerritoryErtId,
      v => setState({ isLoading: false, error: null, validators: v }),
      e => setState({ isLoading: false, error: e, validators: [] })
    );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  var body = "";
  if (state.isLoading) {
    body = <CircularProgress />;
  } else if (state.validators) {
    body = state.validators.map((v, k) => <Chip key={k} label={v.firstName + " " + v.lastName + " (" + v.id + ")"} />);
  }

  return (
    <Card>
      <CardBody>
        <div>
          <strong>
            <Trans>Workflow_WaitingForValidationFrom</Trans>
          </strong>
          {body}
        </div>
        <div>
          <strong>
            <Trans> WF_AuthorComment : </Trans>
          </strong>
          {workflow.linkedObject.authorComment}
        </div>
      </CardBody>
    </Card>
  );
}

function CardLocation({ workflowLinkedObject }) {
  if (!workflowLinkedObject) return <CardError error={{ message: "Associated location is not found." }} />;

  return <SiteDetailContent isEditable={false} site={workflowLinkedObject.location} />;
}

export default withStyles(thirdPartySearchCriteriaStyle)(LocationCrupdate);
