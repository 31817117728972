import { Trans } from "@lingui/macro";
import { Checkbox, CircularProgress, FormControlLabel, InputLabel, Switch, Tooltip, withStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import OldStructureAutocomplete from "components/Autocomplete/OldStructureAutocomplete";
import CardError from "components/Card/CardError";
import FieldText from "components/Common/FieldText";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { addMasterValue } from "store/MasterValue/MasterValueActions";
import { StructTagType } from "store/MasterValue/MasterValueTypes";
import { LoadMasterValues, StructStructureType, StructTag } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty } from "tools";

function SearchCriteriasSimple({
  showSearchCriteriaOnSideBar,
  criteria,
  setCriteria,
  launchSearch,
  cleanSearch,
  masterValues,
  classes,
  defaultLang
}) {
  var [mvIsLoading, setMvIsLoading] = useState("init");
  var [mvIsUpdating, setMvIsUpdating] = useState("init");
  var [mvError, setMvError] = useState(null);
  const dispatch = useDispatch();

  var structureTypesWidth = 3;
  var otherCriteriasWidth = 9;
  var countrySimpleSearchWidth = 4;
  var simpleSearchWidth = 8;
  var identifierSearchWidth = 6;
  if (showSearchCriteriaOnSideBar) {
    countrySimpleSearchWidth = 12;
    simpleSearchWidth = 12;
    structureTypesWidth = 12;
    otherCriteriasWidth = 12;
    identifierSearchWidth = 8;
  }

  if (mvIsLoading === "init") {
    setMvIsLoading("true");
    LoadMasterValues([StructStructureType, StructTag, StructTagType])
      .then(() => setMvIsLoading(null))
      .catch(e => {
        setMvIsLoading("error");
        setMvError(e);
      });
  }

  if (!mvIsLoading && mvIsUpdating === "init") {
    // adapt StructTag mastervalues
    var tagTypes = masterValues[StructTagType];
    var tags = masterValues[StructTag].map(tag => {
      return { ...tag, structureTagType: tagTypes.find(t => t.code === tag.structureTagTypeCode) };
    });
    dispatch(addMasterValue(StructTag, tags));
    setMvIsUpdating(null);
  }

  if (!!mvIsLoading || !!mvIsUpdating) {
    return <CircularProgress />;
  }

  if (!!mvError) {
    return <CardError error={mvError} />;
  }

  const structureTypes1 = [
    { code: "DIR", showCountry: false, showTags: false },
    { code: "ERT", showCountry: true, showTags: true },
    { code: "SJ", showCountry: true, showTags: true },
    { code: "UE", showCountry: true, showTags: true },
    { code: "UP", showCountry: true, showTags: true },
    { code: "EP", showCountry: true, showTags: true }
  ];
  const structureTypes2 = [
    { code: "AREA", showCountry: false, showTags: false },
    { code: "DIV", showCountry: false, showTags: false },
    { code: "COMP", showCountry: false, showTags: false }
  ];

  const toggleButtonsLine1 = structureTypes1.map(s1 => masterValues[StructStructureType].find(s => s1.code === s.code));
  const toggleButtonsLine2 = structureTypes2.map(s2 => masterValues[StructStructureType].find(s => s2.code === s.code));

  let otherCriterias = <></>;
  let buttons = <></>;
  if (!isArrayNullOrEmpty(criteria.structureTypes)) {
    let country = <></>;
    if (
      criteria.structureTypes.some(
        s => structureTypes1.some(s1 => s1.code === s && !!s1.showCountry) || structureTypes2.some(s2 => s2.code === s && !!s2.showCountry)
      )
    )
      country = (
        <CountryAutocomplete
          countryCode={criteria.country}
          onChange={countryCode => setCriteria({ ...criteria, country: countryCode })}
          defaultLang={defaultLang}
          label={<Trans>Country</Trans>}
          isEditable
        />
      );
    let structureTypeTag = <></>;
    if (
      criteria.structureTypes.some(
        s => structureTypes1.some(s1 => s1.code === s && !!s1.showTags) || structureTypes2.some(s2 => s2.code === s && !!s2.showTags)
      )
    ) {
      structureTypeTag = (
        <MasterValueAutocomplete
          isEditable={true}
          sortByText={true}
          mvType={StructTagType}
          // groupBy={i => {
          //   return i.structureTagType ? GetMasterValueLabel(i.structureTagType, defaultLang) : "(vide)";
          // }}
          selectedValue={criteria.structureTypeTags}
          onValueChange={value => setCriteria({ ...criteria, structureTypeTags: value })}
          size="small"
          multiple
          label={<Trans>Structure_TypeTags</Trans>}
        />
      );
    }

    let structureTag = <></>;
    if (
      criteria.structureTypes.some(
        s => structureTypes1.some(s1 => s1.code === s && !!s1.showTags) || structureTypes2.some(s2 => s2.code === s && !!s2.showTags)
      )
    ) {
      structureTag = (
        <MasterValueAutocomplete
          isEditable={true}
          sortByText={true}
          mvType={StructTag}
          groupBy={i => {
            return i.structureTagType ? GetMasterValueLabel(i.structureTagType, defaultLang) : "(vide)";
          }}
          selectedValue={criteria.structureTags}
          onValueChange={value => setCriteria({ ...criteria, structureTags: value })}
          size="small"
          multiple
          label={<Trans>Structure_Tags</Trans>}
        />
      );
    }

    otherCriterias = (
      <GridItem xs={otherCriteriasWidth} sm={otherCriteriasWidth} md={otherCriteriasWidth}>
        <GridContainer>
          <GridItem xs={countrySimpleSearchWidth} sm={countrySimpleSearchWidth} md={countrySimpleSearchWidth}>
            {country}
          </GridItem>
          <GridItem xs={identifierSearchWidth} sm={identifierSearchWidth} md={identifierSearchWidth}>
            <FieldText
              label={<Trans>Structure_Search_FreeText</Trans>}
              id="criteriaFullTextSearch"
              onChange={e => setCriteria({ ...criteria, freeSearch: e.target.value })}
              value={criteria.freeSearch ? criteria.freeSearch : ""}
              isEditable
              fullWidth
            />
          </GridItem>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
                checked={!!criteria.fullText}
                onChange={e => setCriteria({ ...criteria, fullText: e.target.checked })}
              />
            }
            classes={{
              label: classes.label
            }}
            label={<Trans>Exact Value</Trans>}
          />
          <GridItem xs={countrySimpleSearchWidth} sm={countrySimpleSearchWidth} md={countrySimpleSearchWidth}>
            {structureTypeTag}
          </GridItem>
          <GridItem xs={countrySimpleSearchWidth} sm={countrySimpleSearchWidth} md={countrySimpleSearchWidth}>
            {structureTag}
          </GridItem>
          <GridItem xs={countrySimpleSearchWidth} sm={countrySimpleSearchWidth} md={countrySimpleSearchWidth}>
            <OldStructureAutocomplete
              structureTypes={["UP", "UE", "SJ", "ERT"]}
              value={{ structureId: criteria.criteriaStructureId, structureType: criteria.criteriaStructureType }}
              onChange={(structureId, structureType) =>
                setCriteria({
                  ...criteria,
                  criteriaStructureId: structureId ? structureId : null,
                  criteriaStructureType: structureType ? structureType : null
                })
              }
              defaultLang={defaultLang}
            />
          </GridItem>
          <GridItem xs={simpleSearchWidth} sm={simpleSearchWidth} md={simpleSearchWidth}>
            <FormControlLabel
              style={{ marginTop: 30, marginLeft: 0, marginBottom: 20, color: "black" }}
              value="start"
              control={
                <Switch
                  color="secondary"
                  size="small"
                  checked={criteria.onlyActive}
                  onChange={() =>
                    setCriteria({
                      ...criteria,
                      onlyActive: !criteria.onlyActive
                    })
                  }
                />
              }
              label={<Trans>Struct_OnlyActive</Trans>}
              labelPlacement="start"
            />
          </GridItem>
        </GridContainer>
      </GridItem>
    );

    buttons = (
      <>
        <Button onClick={cleanSearch}>
          <Trans> Clean </Trans>
        </Button>
        <Button type="submit" color="info" onClick={launchSearch}>
          <Trans> Search </Trans>
        </Button>
      </>
    );
  }

  return (
    <form onSubmit={launchSearch} autoComplete="off">
      <GridContainer>
        <GridItem xs={structureTypesWidth} sm={structureTypesWidth} md={structureTypesWidth}>
          <div style={{ marginTop: "10px" }}>
            <InputLabel shrink>
              <Trans>Structure_Types</Trans>
            </InputLabel>
            <ToggleButtonGroup
              value={criteria.structureTypes ? criteria.structureTypes : []}
              onChange={(e, v) => setCriteria({ ...criteria, structureTypes: v })}
              size="small"
              style={{ flexWrap: "wrap" }}
            >
              {toggleButtonsLine1.map((i, key) => (
                <ToggleButton key={"sType_" + key} value={i.code}>
                  <Tooltip title={GetMasterValueLabel(i, defaultLang)}>
                    <span>{i.code}</span>
                  </Tooltip>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <br />
            <ToggleButtonGroup
              value={criteria.structureTypes ? criteria.structureTypes : []}
              onChange={(e, v) => setCriteria({ ...criteria, structureTypes: v })}
              size="small"
              style={{ flexWrap: "wrap" }}
            >
              {toggleButtonsLine2.map((i, key) => (
                <ToggleButton key={"sType_" + key} value={i.code}>
                  <Tooltip title={GetMasterValueLabel(i, defaultLang)}>
                    <span>{i.code}</span>
                  </Tooltip>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </div>
        </GridItem>
        {otherCriterias}
      </GridContainer>
      <div className={classes.flexRight}>{buttons}</div>
    </form>
  );
}

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(SearchCriteriasSimple));
