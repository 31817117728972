import React from 'react';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import SiteCharacteristics from '../SiteDetail/SiteCharacteristics';

//lingui
import { Trans } from "@lingui/macro";

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
    }
};

export default function SiteStep5Caracteristique({ site, setSite, defaultLang, masterValues }) {
    var cardCharacteristics = (
        <Card
            style={{
                ...CardInlineStyle.card
            }}
        >
            <CardHeader
                style={{
                    ...CardInlineStyle.cardHeader
                }}
                icon>
                <h4><Trans>Site_Create_Characteristics</Trans></h4>
            </CardHeader>
            <CardBody>
                <SiteCharacteristics
                    site={site}
                    setSite={setSite}
                    defaultLang={defaultLang}
                    isEditable
                    masterValues={masterValues}
                />
            </CardBody>
        </Card>
    );

    return cardCharacteristics;
}