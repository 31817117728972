import React from "react";
import JobAndContractDashBoard from "views/JobAndContract/JobAndContractDashBoard";
import JobList from "module/amc/containers/JobList";
import WorkerList from "module/amc/containers/WorkerList";
import CustomerContractList from "module/amc/containers/CustomerContractList";
import ProjectList from "module/amc/containers/ProjectList";
import PersonAdd from "@material-ui/icons/PersonAdd";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LocalShipping from "@material-ui/icons/LocalShipping";
import Work from "@material-ui/icons/Work";
import ThumbUp from "@material-ui/icons/ThumbUp";
import TableChart from "@material-ui/icons/TableChart";
import { Trans } from "@lingui/macro";

const amcRoutes = [
  {
    path: "/amc/projects",
    name: <Trans>Projects</Trans>,
    short: <Trans>Projects</Trans>,
    mustHaveRights: "amc.view",
    mini: "P",
    icon: Work,
    component: ProjectList
  },
  {
    path: "/amc/customercontracts",
    name: <Trans>Customer contracts</Trans>,
    short: <Trans>Customer contracts</Trans>,
    mustHaveRights: "amc.view",
    mini: "CC",
    icon: ThumbUp,
    component: CustomerContractList
  },
  {
    path: "/amc/jobs",
    name: <Trans>Jobs</Trans>,
    short: <Trans>Jobs</Trans>,
    mustHaveRights: "amc.view",
    mini: "J",
    icon: LocalShipping,
    component: JobList
  },
  {
    path: "/amc/workers",
    name: <Trans>Workers</Trans>,
    short: <Trans>Workers</Trans>,
    mustHaveRights: "amc.view",
    mini: "J",
    icon: AccountCircle,
    component: WorkerList
  }
];

export default amcRoutes;
