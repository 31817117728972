import { Trans } from "@lingui/macro";
import { withStyles } from "@material-ui/core";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import CustomInput from "components/CustomInput/CustomInput.jsx";

function SearchCriteriaSimple({ showSearchCriteriaOnSideBar, criteria, setCriteria, launchSearch, cleanSearch, classes }) {
  var simpleSearchWidth = 12;
  if (showSearchCriteriaOnSideBar) {
    simpleSearchWidth = 15;
  }

  return (
    <form onSubmit={launchSearch} autoComplete="off">
      <GridContainer>
        <GridItem
          formControlProps={{
            fullWidth: true
          }}
          xs={simpleSearchWidth}
          sm={simpleSearchWidth}
          md={simpleSearchWidth}
        >
          <CustomInput
            labelText={<Trans>Id_MDM,_Code,_Nom_Commercial</Trans>}
            id="CriteriaFreeSearch"
            onChange={e =>
              setCriteria({
                ...criteria,
                freeSearch: e.target.value
              })
            }
            value={!!criteria.freeSearch ? criteria.freeSearch : ""}
            formControlProps={{
              fullWidth: true
            }}
          />
        </GridItem>
      </GridContainer>
      <div>
        <div className={classes.flexRight}>
          <Button onClick={cleanSearch}>
            <Trans> Clean </Trans>
          </Button>
          <Button type="submit" color="info">
            <Trans>Search</Trans>
          </Button>
        </div>
      </div>
    </form>
  );
}
export default withStyles(thirdPartySearchCriteriaStyle)(SearchCriteriaSimple); //connect(mapStateToProps)
