import { Trans } from "@lingui/macro";
import { Button, Chip, CircularProgress, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Assignment, ErrorOutline, PermContactCalendar } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import { addDays, isValid } from "date-fns";
import React, { useState } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { HasRight } from "services/user/UserHelper";
import { CommonCountry, TpPaymentCharacteristicStatus } from "store/MasterValue/MasterValueTypes";
import {
  formatDate,
  nextOpenDay,
  toDate
} from "tools";
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from "../../../tools";
import * as Actions from "../../store/actions";

const HistoPaymentCharacteristic = function ({ thirdParty, supplierCtx, masterValues, userInfo, defaultLang, openThirdParty, getPayChars, setPayChars }) {

  var supplier = supplierCtx.supplier;
  supplier.thirdParty = thirdParty;

  var payChars = supplierCtx.paymentCharacteristics;
  if (!payChars) {
    getPayChars(supplier.thirdPartyIdentifier);
    return <CircularProgress />;
  }

  var isCreatable = false;
  if (userInfo.canAdd) {
    let supplierErtIds = supplier.supplierSjs.map(supplierSj => supplierSj.sj?.ertIdentifier);
    isCreatable =
      userInfo.isAdmin ||
      (!isNull(userInfo.structures) &&
        userInfo.structures.erts.some(ert => supplierErtIds.some(sErtId => ert.identifier === sErtId)) &&
        masterValues[CommonCountry].some(
          c => c.codeIso2 === thirdParty.countryCode && !!c.allowPayCharManualEdit && !isArrayNullOrEmpty(c.allowedPayCharTypes)
        ));
  }

  function renderPaymentCharacteristicsChip(payChar, paymentCharacteristicStatusCodes) {
    if (payChar && !isNullOrEmpty(payChar.sisScore)) {
      var splitted = payChar.sisScore.split("¤");
      var sisScore = splitted[0];
      var statusCode = splitted[1];
      var label = paymentCharacteristicStatusCodes.find(i => i.code === statusCode)?.label ?? "";
      if (isNaN(sisScore)) return <Chip style={{ borderStyle: "solid", borderWidth: "2px", borderColor: "#ffbf00" }} label={label} size="small" />;
      if (sisScore < 30)
        return (
          <Chip
            style={{ backgroundColor: "#ff2e00" }}
            label={
              <span>
                {sisScore} - {label}
              </span>
            }
            size="small"
          />
        );
      if (sisScore >= 30 && sisScore <= 70)
        return (
          <Chip
            style={{ backgroundColor: "#ffbf00" }}
            label={
              <span>
                {sisScore} - {label}
              </span>
            }
            size="small"
          />
        );
      return (
        <Chip
          style={{ backgroundColor: "#57d500" }}
          label={
            <span>
              {sisScore} - {label}
            </span>
          }
          size="small"
        />
      );
    }
    return <Trans>SIS NotComputed</Trans>;
  }

  function renderPaymentCharacteristicsReason(payChar) {
    if (payChar && payChar.sisComputedReasons && payChar.sisComputedReasons.length > 0) {
      return (
        <Tooltip
          title={
            <>
              {payChar.sisComputedReasons.map((v, key) => (
                <div key={key}>
                  <strong>{v.key}</strong> - {v.value}
                </div>
              ))}
            </>
          }
          placement="top"
        >
          <ErrorOutline />
        </Tooltip>
      );
    }
    return <span></span>;
  }

  const onPayCharOpen = function (payCharId) {
    var minDate = null;
    var maxDate = null;
    var sortedPayChars = SortPayCharsByStartOfValidityDate(payChars);
    sortedPayChars.map((p, idx) => {
      if (p.identifier == payCharId) {
        minDate = idx === 0 ? null : nextOpenDay(addDays(new Date(sortedPayChars[idx - 1].endOfValidityDate), 1));
        maxDate = idx === sortedPayChars.length - 1 ? null : nextOpenDay(addDays(new Date(sortedPayChars[idx + 1].startOfValidityDate), 1));
      }
    });

    setPayChars(
      payChars.map(p => {
        if (p.identifier === payCharId) {
          return { ...p, supplier: supplier, showDialog: true, minDate: minDate, maxDate: maxDate };
        }
        return { ...p, showDialog: false };
      })
    );
  };

  ///------------------------------
  /// Crée le contenu (les lignes) de la table-react affichant les PaymentCharacteristics associés
  ///------------------------------
  function convertDataToReactTable() {
    if (isNull(payChars)) return [];
    return payChars
      .map((prop, key) => {
        var thirdParty = prop.beneficiary ? prop.beneficiary.thirdParty : supplier.thirdParty;
        var lblType, lblName;
        if (isNull(prop.beneficiaryIdentifier)) {
          lblType = "SUPPLIER";
          lblName = thirdParty.longName;
        } else {
          lblType = prop.beneficiary.benificiaryTypeCode;
          lblName = (
            <div key={key}>
              <Button justIcon round simple onClick={() => openThirdParty(thirdParty.identifier)} color="info" className="like">
                <Assignment />
              </Button>
              {thirdParty.longName}
            </div>
          );
        }
        return {
          identifier: prop.identifier,
          type: `${lblType} (${prop.paymentCharacteristicTypeCode})`,
          name: lblName,
          bankAccount: prop.bankAccount,
          bankCode: prop.bankCode,
          bankLabel: prop.bankLabel,
          sisScore: prop.sisScore + "¤" + prop.paymentCharacteristicStatusCode,
          sisComputedReasons: prop.sisComputedReasons,
          sisCheckDate: prop.sisCheckDate + " (UTC)",
          startOfValidityDate: formatDate(prop.startOfValidityDate, defaultLang),
          endOfValidityDate: prop.endOfValidityDate ? formatDate(prop.endOfValidityDate, defaultLang) : "",
          isContactFound: prop.thirdPartyContact,
          actions: (
            <div className="actions-right" key={key}>
              <Button justIcon round simple onClick={() => onPayCharOpen(prop.identifier)} color="info" className="like">
                <Assignment />
              </Button>
            </div>
          )
        };
      });
  }
  return (
    <Card style={{ ...CardInlineStyle.card }}>
      <CardHeader style={{ ...CardInlineStyle.cardHeader }}>
        <h4>
          <Trans>History</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <ReactTable
          data={convertDataToReactTable()}
          columns={[
            {
              Header: <Trans>Actions</Trans>,
              accessor: "actions",
              sortable: false,
              filterable: false
            },
            {
              Header: <Trans>Type</Trans>,
              accessor: "type"
            },
            {
              Header: <Trans>Name</Trans>,
              accessor: "name"
            },
            {
              Header: <Trans>SIS Score</Trans>,
              accessor: "sisScore",
              Cell: row => (
                <span style={{ display: "inline-flex" }}>
                  {renderPaymentCharacteristicsChip(
                    row.original,
                    masterValues[TpPaymentCharacteristicStatus].map(i => {
                      return { code: i.code, label: GetMasterValueLabel(i, defaultLang) };
                    })
                  )}
                  {renderPaymentCharacteristicsReason(row.original)}
                </span>
              )
            },
            {
              Header: <Trans>StartDate</Trans>,
              accessor: "startOfValidityDate",
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                let dateA = toDate(a, defaultLang);
                let dateB = toDate(a, defaultLang);

                return dateA > dateB ? 1 : -1;
              }
            },
            {
              Header: <Trans>EndDate</Trans>,
              accessor: "endOfValidityDate"
            },
            {
              Header: <Trans>Contact</Trans>,
              accessor: "isContactFound",
              Cell: row =>
                row.value && row.value.lastName ? (
                  <Tooltip title={`${row.value.lastName} ${row.value.firstName}`}>
                    <PermContactCalendar />
                  </Tooltip>
                ) : (
                  <></>
                ),
              sortMethod: (a, b) => {
                if (!a || !a.lastName) {
                  return -1;
                }
                if (!b || !b.lastName) {
                  return -1;
                }
                return a.lastName > b.lastName ? 1 : -1;
              }
            }
          ]}
          defaultPageSize={5}
          showPaginationBottom={false}
          showPageSizeOptions={false}
          className="-striped -highlight"
          showFilters={true}
          style={ReactTableStyle.main}
        />
      </CardBody>
    </Card>

  );


};

const SortPayCharsByStartOfValidityDate = function (payChars) {
  return payChars.sort(function (a, b) {
    if (a.startOfValidityDate < b.startOfValidityDate) return -1;
    else if (a.startOfValidityDate > b.startOfValidityDate) return 1;
    return 0;
  });
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    thirdParty: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx.thirdParty : null,
    supplierCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].supplierCtx : null,
    userInfo: {
      structures: state.AuthenticationReducer.user.structureTps,
      isAdmin: HasRight("thirdparty_supplier.edit"),
      canAdd: HasRight("thirdparty_supplier.add_iban"),
      canEdit: HasRight("thirdparty_supplier.edit_iban")
    },
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openThirdParty: thirdPartyId => {
      dispatch(Actions.OpenThirdParty(thirdPartyId));
    },
    getPayChars: supplierId => {
      dispatch(Actions.SupplierPayCharGet(supplierId));
    },
    setPayChars: payChars => {
      dispatch(Actions.SupplierPayCharEdit(payChars));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(HistoPaymentCharacteristic));
