import { Trans } from "@lingui/macro";
import { CircularProgress, Paper, Dialog, DialogContent, DialogActions, Button } from "@material-ui/core";
import CardError from "components/Card/CardError";
import DialogBox from "components/DialogBox/DialogBox";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import { connect } from "react-redux";
import { isNull, isArray } from "tools";
import { GroupDelete, GroupGetAll, GroupTreeGet } from "./actions/Group.Actions";
import GroupDetail from "./part/detail/GroupDetail";
import GroupTree from "./part/detail/GroupTree";
import GTree from "./part/GTree";
import { Alert } from "@material-ui/lab";

const GroupListing = ({ userInfo }) => {
  const [groups, setGroups] = useState(null);
  const [group, setGroup] = useState(null);
  const [error, setError] = useState(null);
  const [dialogBox, setDialogBox] = useState(null);

  let isColsAudit = userInfo.profiles.some(p => p.profileIdentifier.endsWith("MDM_COLS_AUDIT"));

  var banner = <></>;
  if (error && isArray(error.response.data) && error.response.data !== undefined) 
  {
    banner = error.response.data.map((e, idx) => (
      <Alert severity="error" key={"error_" + idx}>
        {userInfo.language === 'FR' ? e.descriptionFr : e.descriptionEn}
      </Alert>
    ));
  }
  else if (error) return <CardError error={error} />;

  /* if (isNull(groups)) {
    //GroupGetAll()
    GroupTreeGet()
      .then(g => setGroups(g))
      .catch(e => setError(e));
    return <CircularProgress />;
  } */

  const reloadGroups = () => {
    setGroups(null);
    setGroup(null);
  };

  const selectGroup = (g, confirm) => {
    if (!!group?.hasChanged && !confirm) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmEraseWithoutSave </Trans>, yes: () => selectGroup(g, true) });
    } else {
      setDialogBox(null);
      setGroup(g);
    }
  };

  const createGroup = parent => {
    setGroup({
      id: 0,
      label: "newGroup",
      countryCode: parent?.countryCode ?? userInfo?.country,
      parentId: parent?.id ?? 0,
      hasChanged: true
    });
  };

  const deleteGroup = (g, confirm) => {
    if (!confirm) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmDelete </Trans>, yes: () => deleteGroup(g, true) });
    } else {
      setDialogBox(null);
      GroupDelete(g)
        .then(() => reloadGroups())
        .catch(e => setError(e));
    }
  };

  return (
    <>
      {banner}

      <Dialog open={!!group} maxWidth="md" fullWidth>
        <DialogContent>
          <GroupDetail group={group} setGroup={setGroup} reloadGroups={reloadGroups} isGroupEditable={!isColsAudit} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setGroup(null)}>
            <Trans>Close</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <GridContainer>
        <GridItem xs={8}>
          <Paper>
            {/* <GroupTree
              groups={groups}
              group={group}
              setGroup={g => selectGroup(g, false)}
              createGroup={isColsAudit ? null : createGroup}
              deleteGroup={isColsAudit ? null : g => deleteGroup(g, false)}
            /> */}
            <GTree
              groupId={0}
              setGroup={g => selectGroup(g, false)}
              createGroup={isColsAudit ? null : createGroup}
              deleteGroup={isColsAudit ? null : g => deleteGroup(g, false)}
            />
          </Paper>
        </GridItem>
      </GridContainer>
      {dialogBox && <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.AuthenticationReducer?.user
  };
};

export default connect(mapStateToProps)(GroupListing);
