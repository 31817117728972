import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

function RecapSupplierCreation() {
    return (
        <div className='divContainer'>
            Récap...
        </div >
    );
}

export default withStyles(thirdPartySearchCriteriaStyle)(RecapSupplierCreation);