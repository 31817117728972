import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';


const style = theme => ({
    colorSwitchBase: {
        color: purple[300],
        '&$colorChecked': {
            color: purple[500],
            '& + $colorBar': {
                backgroundColor: purple[500],
            },
        },
    },
    colorBar: {},
    colorChecked: {},
    iOSSwitchBase: {
        '&$iOSChecked': {
            color: theme.palette.common.white,
            '& + $iOSBar': {
                backgroundColor: '#52d869',
            },
        },
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.sharp,
        }),
    },
    iOSChecked: {
        transform: 'translateX(15px)',
        '& + $iOSBar': {
            opacity: 1,
            border: 'none',
        },
    },
    iOSBar: {
        borderRadius: 13,
        width: 42,
        height: 26,
        marginTop: -13,
        marginLeft: -21,
        border: 'solid 1px',
        borderColor: theme.palette.grey[400],
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    iOSIcon: {
        width: 20,
        height: 24,
    },
    iOSIconChecked: {
        boxShadow: theme.shadows[1],
    },
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 130,
    },
    TextField: {
        margin: theme.spacing.unit,
        width: "100 px",
    },
    Margin:
    {
        marginTop: '30px',
    },
    LabelSize: {
        fontSize: '16px',
        marginLeft: '20px',
        marginTop: '10px',
    },
    HeaderSize: {
        fontSize: '100px',
        marginLeft: '20px'
    },
    ButtonSave: {
        float: "right",
         margin: theme.spacing.unit,
         backgroundColor:'#4caf50',        
        width:"150px"
    },
    ButtonCancel: {
         width: "150px",
        float: "right",
        margin: theme.spacing.unit,

    },
    rightIcon: {
        marginLeft: theme.spacing.unit,

    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    Success: {
        backgroundColor: green[600],
    },
    icon: {
        fontSize: 20,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
    close: {
        padding: theme.spacing.unit / 2,
    },
});
export default style;
