import React from "react";
import { useDispatch } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { FormLabel, TextField } from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { isNull } from "tools";
import Warning from "components/Typography/Warning";
import Button from "components/CustomButtons/Button";
import * as ThirdPartyActions from "module/tpCopernic/store/actions";
import { HasOneOfRights } from "services/user/UserHelper";
import { Alert, AlertTitle } from "@material-ui/lab";

function ShorteningLongName({ workflow, setWorkflow, errors }) {
  const thirdParty = workflow.linkedObject;
  const dispatch = useDispatch();

  function longNameChange(event) {
    thirdParty.customLongName = event.target.value;
    setWorkflow({ ...workflow, linkedObject: thirdParty });
  }
  function companyLongNameChange(event) {
    thirdParty.customCompanyLongName = event.target.value;
    setWorkflow({ ...workflow, linkedObject: thirdParty });
  }

  var errorPart = <></>;
  if (!isNull(errors) && errors.length > 0) {
    var currentError = errors[0];
    errorPart = (
      <GridContainer>
        <GridItem xs={2}></GridItem>
        <GridItem xs={2}></GridItem>
        <GridItem xs={5} sm={5} md={5}>
          <div>
            <div style={{ lineHeight: "0.5em" }}>
              <Warning style={{ verticalAlign: "middle", color: "orange" }} />
              <span style={{ verticalAlign: "middle", color: "orange" }}>
                <strong>{currentError.descriptionFr}</strong>
              </span>
            </div>
          </div>
        </GridItem>
      </GridContainer>
    );
  }

  var alert = <></>;
  if (workflow.workflowStatusCode === "Pending") {
    alert = (
      <Alert severity="info">
        <AlertTitle>
          <Trans>Workflow_Status_Pending</Trans>
        </AlertTitle>
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "OnError") {
    alert = (
      <Alert severity="error">
        <AlertTitle>
          <Trans>Workflow_Status_Error</Trans>
        </AlertTitle>
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "Aborted") {
    alert = (
      <Alert severity="warning">
        <AlertTitle>
          <Trans>Workflow_Status_Aborted</Trans>
        </AlertTitle>
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "Finished") {
    alert = (
      <Alert severity="success">
        <AlertTitle>
          <Trans>Workflow_Status_Finished</Trans>
        </AlertTitle>
      </Alert>
    );
  }

  var thirdPartyButton = <></>;
  if (
    thirdParty.identifier &&
    HasOneOfRights(["thirdparty_customer.view", "thirdparty_workflow.edit", "thirdparty_supplier.view", "thirdparty_workflow.view"])
  ) {
    thirdPartyButton = (
      <center>
        <Button color="info" onClick={() => dispatch(ThirdPartyActions.OpenThirdParty(thirdParty.identifier))}>
          <Trans>ThirdParty_Open</Trans>
        </Button>
      </center>
    );
  }

  return (
    <div style={{ width: "90%", marginTop: "50px" }}>
      {alert}
      {errorPart}
      <GridContainer>
        <GridItem xs={2}></GridItem>
        <GridItem xs={2}>
          <FormLabel>
            <Trans>Custom long name</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={6}>
          <TextField margin="dense" id="contentGroup" fullWidth value={thirdParty.customLongName} onChange={longNameChange} />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={2}></GridItem>
        <GridItem xs={2}>
          <FormLabel>
            <Trans>Custom Company long name</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={6}>
          <TextField margin="dense" id="contentGroup" fullWidth value={thirdParty.customCompanyLongName} onChange={companyLongNameChange} />
        </GridItem>
      </GridContainer>

      {thirdPartyButton}
    </div>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(ShorteningLongName);
