import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import BaseAppInsightTable from "../common/BaseAppInsightTable";
import { CircularProgress } from "@material-ui/core";
import { SearchAppInsightDatas } from "services/report/ReportHelper";

function CustomerContractAppInsight({ parameters }) {
  const [isSearching, setIsSearching] = useState(true);
  const [appInsightDatas, setAppInsightDatas] = useState(null);

  var criterias = null;
  var day = "";
  var time = "";
  var status = "";
  useEffect(() => {
    if (!!parameters && parameters.dataPoints.length > 0) {
      if (parameters.dataPoints[0].identity.length == 2) {
        criterias = {
          type: "CustomerContractV3",
          day: parameters.dataPoints[0].identity[0].equals,
          status: parameters.dataPoints[0].identity[1].equals,
          direction: "Integrer",
          application: "Driver"
        };
      } else if (parameters.dataPoints[0].identity.length == 3) {
        criterias = {
          type: "CustomerContractV3",
          day: parameters.dataPoints[0].identity[0].equals,
          time: parameters.dataPoints[0].identity[1].equals,
          status: parameters.dataPoints[0].identity[2].equals,
          direction: "Integrer",
          application: "Driver"
        };
      }

      if (!!criterias) {
        setIsSearching(true);
        SearchAppInsightDatas(criterias, datas => {
          setAppInsightDatas(datas);
          setIsSearching(false);
        });
      }
    }
  }, [parameters]);

  var resultComponent = <></>;
  if (isSearching)
    resultComponent = (
      <center>
        <CircularProgress />
      </center>
    );
  else resultComponent = <BaseAppInsightTable datas={appInsightDatas} />;

  return (
    <div>
      <p>
        CusTomer Contracts {day} - {time} - {status}
      </p>
      {resultComponent}
    </div>
  );
}

export default withStyles(dashboardStyle)(CustomerContractAppInsight);
