import { Trans } from "@lingui/macro";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Add, Assignment, Check, CloudQueue, Close } from "@material-ui/icons";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ColCodeSearchCriteria from "module/structure/part/search/ColCodeSearchCriteria";
import ColCodeDetail from "./ColCodeDetail";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import { format, parseISO } from "date-fns";
import { isNull } from "tools";
import { HasRight } from "services/user/UserHelper";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button";
import { QueueColCodeExport } from "./store/actions/ColCode.Actions";
import * as Actions from "./store/actions/ColCode.Actions";
import { STRUCTURE_COLCODE_GET_DETAIL } from "./store/actions/types";

function ColCode({ colCodeCtx, getDetail, queueExport, classes, defaultLang }) {
  const dispatch = useDispatch();
  const canEdit = HasRight("structure.edit");

  const [criterias, setCriterias] = useState(colCodeCtx.criterias);
  const [item, setItem] = useState(colCodeCtx.item);
  const [exported, setExported] = useState(false);

  function handleSnackbarClose() {
    setExported(false);
  }

  var result = colCodeCtx.result;

  const reactTableColumns = [
    {
      filterable: false,
      sorting: false,
      accessor: "actions",
      width: 50
    },
    {
      Header: <Trans>Id</Trans>,
      accessor: "id"
    },
    {
      Header: <Trans>Col Code</Trans>,
      accessor: "identifiant"
    },
    {
      Header: <Trans>Label</Trans>,
      accessor: "label"
    },
    {
      Header: <Trans>Date start</Trans>,
      accessor: "dateStart",
      Cell: rowInfo => format(parseISO(rowInfo.original.dateStart), "yyyy-MM-dd")
    },
    {
      Header: <Trans>Date end</Trans>,
      accessor: "dateEnd",
      Cell: rowInfo => (!isNull(rowInfo.original.dateEnd) ? format(parseISO(rowInfo.original.dateEnd), "yyyy-MM-dd") : "")
    },
    {
      Header: <Trans>Structure</Trans>,
      accessor: "identifiantStructure"
    },
    {
      Header: <Trans>Is Demat</Trans>,
      accessor: "isDemat",
      Cell: rowInfo => {
        var retour = "";
        if (!isNull(rowInfo.original.colDemat) && rowInfo.original.colDemat)
          retour = (
            <div>
              <Check />
            </div>
          );
        return retour;
      }
    }
  ];

  var newColCodeButton = "";
  if (canEdit) {
    newColCodeButton = (
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <Button variant="contained" size="sm" onClick={() => getDetail({}, true)} color="info">
            <Add />
            <Trans>New Col Code</Trans>
          </Button>
          <Button variant="contained" size="sm" onClick={() => queueExport(r => setExported(true), e => {})} color="info">
            <CloudQueue />
            <Trans>Queue Export</Trans>
          </Button>
        </GridItem>
      </GridContainer>
    );
  }

  var reactTable = "";
  if (!!result) {
    var items = ConvertDatas(result.items, getDetail);

    reactTable = (
      <Card>
        <CardBody>
          <ReactTable
            manual
            data={items}
            page={result.pageIndex}
            pages={result.nbOfPages}
            columns={reactTableColumns}
            sorted={[{ id: colCodeCtx.criterias.orderProperty, desc: colCodeCtx.criterias.isDescendingOrder }]}
            onPageChange={(state, instance) => {
              var tempCriteria = colCodeCtx.criterias;
              tempCriteria.startPage = state;
              setCriterias({ ...colCodeCtx.criterias, startPage: state });
              dispatch(Actions.SearchColCode(tempCriteria));
            }}
            onPageSizeChange={(state, instance) => {
              var tempCriteria = colCodeCtx.criterias;
              tempCriteria.pageSize = state;
              setCriterias({ ...colCodeCtx.criterias, pageSize: state });
              dispatch(Actions.SearchColCode(tempCriteria));
            }}
            onSortedChange={(newSorted, column, shiftKey) => {
              var tempCriteria = colCodeCtx.criterias;
              tempCriteria.orderProperty = newSorted[0].id;
              tempCriteria.isDescendingOrder = newSorted[0].desc;
              setCriterias({ ...colCodeCtx.criterias, orderProperty: newSorted[0].id, isDescendingOrder: newSorted[0].desc });
              dispatch(Actions.SearchColCode(tempCriteria));
            }}
            defaultPageSize={result.pageSize}
            showPaginationBottom={true}
            className="-striped -highlight"
            style={ReactTableStyle.main}
          />
        </CardBody>
      </Card>
    );
  }

  var snackbar = (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={exported}
      onClose={handleSnackbarClose}
      autoHideDuration={4000}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      message={
        <span id="message-id">
          <Trans>Export queued</Trans>
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={handleSnackbarClose}>
          <Close />
        </IconButton>
      ]}
    />
  );

  return (
    <div>
      <ColCodeSearchCriteria />
      {newColCodeButton}
      {reactTable}
      <ColCodeDetail defaultLang={defaultLang} />
      {snackbar}
    </div>
  );
}

///-----------------------------------------------------------
/// Convertit le résultat d'une recherche en item pour react-table
///-----------------------------------------------------------
function ConvertDatas(items, getDetail) {
  if (!items || items.length === 0) return [];

  var result = [];
  items.map((prop, key) => {
    result.push({
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button
            justIcon
            round
            simple
            onClick={() => {
              getDetail(prop, false);
            }}
            color="info"
            className="like"
          >
            <Assignment />
          </Button>
        </div>
      ),
      ...prop
    });
  });

  return result;
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    defaultLang: state.AuthenticationReducer.user.language,
    colCodeCtx: state.ST_ColCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDetail: (item, isNew) => {
      dispatch({
        type: STRUCTURE_COLCODE_GET_DETAIL,
        item: item,
        isNew: isNew
      });
    },
    queueExport: (callbackFn, errorFn) => {
      dispatch(QueueColCodeExport(callbackFn, errorFn));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ColCode));
