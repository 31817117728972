import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
//import * as ThirdPartyActions from './store/actions';
import { OpenThirdParty, OpenUnreferencedThirdParty } from "../store/actions";
import * as HistoryActions from 'store/History/HistoryActions';

import { CircularProgress, withStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import SearchCriterias from "./part/search/SearchCriterias";

import { isNullOrEmpty } from "tools";
import CardError from "components/Card/CardError";
import { ThirdPartySearch } from "./actions/ThirdPartyActions";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SearchResult from "./part/search/SearchResult";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

//lingui
import { Trans } from "@lingui/macro";
import ThirdPartyWizardPrivate from "../containers/ThirdPartyWizardPrivate";
import ThirdPartyWizardUnknown from "../containers/ThirdPartyWizardUnknown";
import { HasRight } from "services/user/UserHelper";
import { HasOneOfRights } from "services/user/UserHelper";

const ThirdPartyListing = ({ defaultLang, openThirdParty, openThirdPartyUnreferrenced, masterValues }) => {
  const initialCriteria = { searchMode: "Simple", criteriaFullText: "", criteriaLongName: "", criteriaLegalIdentifier: "", criteriaMdmId: "" };

  const [showPrivateCreation, setShowPrivateCreation] = useState(false);
  const [showUnknownCreation, setShowUnknownCreation] = useState(false);
  const [criteria, setCriteria] = useState(initialCriteria);
  const [state, setState] = useState({ isLoading: false });

  const getDefaultCountry = () => {
    var defaultCountry = "FR";
    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    if (getPref !== null) {
      defaultCountry = getPref.InfoGeneral.Country;
    }
    return defaultCountry;
  };

  const cleanSearch = () => {
    setCriteria({ ...initialCriteria, criteriaCountry: getDefaultCountry() });
  };

  useEffect(() => {
    var newCriteria = criteria;

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    if (isNullOrEmpty(criteria.pageSize)) {
      var defaultPageSize = 10;
      if (getPref !== null) {
        defaultPageSize = getPref.Pref.ResultPageSize * 1;
      }

      newCriteria = {
        ...newCriteria,
        criteriaCountry: getDefaultCountry(),
        pageSize: defaultPageSize
      };
    }

    setCriteria(newCriteria);
  }, []);

  const launchSearch = () => {
    setCriteria(criteria);
    setState({ ...state, isLoading: true });
    ThirdPartySearch(
      criteria,
      results => setState({ isLoading: false, searchResults: results }),
      error => setState({ isLoading: false, error: error })
    );
  };

  var searchResult = null;
  if (state.isLoading) {
    searchResult = <CircularProgress />;
  } else if (state.error) {
    searchResult = <CardError error={state.error} />;
  } else if (state.searchResults) {
    searchResult = (
      <SearchResult
        results={state.searchResults}
        criteria={criteria}
        launchSearch={launchSearch}
        setThirdParty={prop => {
          if (prop.identifier === 0) {
            openThirdPartyUnreferrenced(prop);
          } else {
            openThirdParty(prop);
          }
        }}
      />
    );
  }

  var layoutSearchResult;
  if (!isNullOrEmpty(searchResult)) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {searchResult}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <SearchCriterias
            criteria={criteria}
            setCriteria={setCriteria}
            launchSearch={launchSearch}
            cleanSearch={cleanSearch}
            showSearchCriteriaOnSideBar={true}
            defaultLang={defaultLang}
            masterValues={masterValues}
          />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = (
      <SearchCriterias
        criteria={criteria}
        setCriteria={setCriteria}
        launchSearch={launchSearch}
        cleanSearch={cleanSearch}
        showSearchCriteriaOnSideBar={false}
        defaultLang={defaultLang}
        masterValues={masterValues}
      />
    );
  }

  var dialog = "";
  if (showPrivateCreation) {
    dialog = <ThirdPartyWizardPrivate handleClose={() => setShowPrivateCreation(false)} masterValues={masterValues} />;
  } else if (showUnknownCreation) {
    dialog = <ThirdPartyWizardUnknown handleClose={() => setShowUnknownCreation(false)} masterValues={masterValues} />;
  }

  var buttonCreationContent = "";
  var buttonCreationIndividual;
  var buttonCreationUnknown;

  if (HasOneOfRights(["thirdparty_customer.create_individual", "thirdparty_supplier.create_individual"])) {
    buttonCreationIndividual = (
      <Button variant="contained" size="sm" onClick={() => setShowPrivateCreation(true)} color="info">
        <Add />
        <Trans>Private ThirdParty</Trans>
      </Button>
    );
  }
  if (HasOneOfRights(["thirdparty_customer.create_unknown", "thirdparty_supplier.create_unknown"])) {
    buttonCreationUnknown = (
      <Button variant="contained" size="sm" onClick={() => setShowUnknownCreation(true)} color="info">
        <Add />
        <Trans>Unknown ThirdParty</Trans>
      </Button>
    );
  }

  if (buttonCreationIndividual || buttonCreationUnknown) {
    buttonCreationContent = (
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          {buttonCreationIndividual}
          {buttonCreationUnknown}
        </GridItem>
      </GridContainer>
    );
  }

  return (
    <>
      <div>{buttonCreationContent}</div>
      <div>
        {layoutSearchResult}
        {dialog}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openThirdParty: tp => {
      dispatch(OpenThirdParty(tp.identifier));
      dispatch(HistoryActions.addHistoryThirdParty(tp));
    },
    openThirdPartyUnreferrenced: tp => {
      dispatch(OpenUnreferencedThirdParty(tp));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyListing));
