import React, { useState } from "react";
import GeneralInformation from "./part/detail/GeneralInformation";
import ProductPointSale from "./part/detail/ProductPointOfSale";
import ProductOptions from "./part/detail/ProductOptions";
import ProductComplement from "./part/detail/ProductComplement";
import ProductKeyWord from "./part/detail/ProductKeyWord";
import Header from "./part/detail/Header";
import { TabPanel } from "components/Common/Tabs";
import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import { Tab, Tabs } from "@material-ui/core";
import { HasRight } from "services/user/UserHelper";

const ProductDetailContent = ({ product, setProduct, defaultLang }) => {
  const [tabIdx, setTabIdx] = useState(0);

  const buildTabs = [
    {
      title: <Trans>General Information</Trans>,
      component: "GeneralInformation",
      status: "neutral"
    },
    {
      title: <Trans>Up point of sale</Trans>,
      component: "ProductUpPointSale",
      status: "neutral"
    },
    {
      title: <Trans>Options</Trans>,
      component: "ProductOptions",
      status: "neutral"
    },
    {
      title: <Trans>Complement</Trans>,
      component: "ProductComplement",
      status: "neutral"
    },
    {
      title: <Trans>Key Word</Trans>,
      component: "ProductKeyWord",
      status: "neutral"
    }
  ];

  let tabStatus = buildTabs;
  let isEditable = HasRight("product.edit");
  let tabs = tabStatus.map(tab => {
    var tabButton = <span>{tab.title}</span>;
    var tabContent = "";

    switch (tab.component) {
      case "GeneralInformation":
        var star = "";
        var saveStatusIcon = ""; //GetSaveStatusIcon(siteSaveStatus);
        var warningIcon = /*siteHasWarnings ? (<Warning style={{ 'color': 'orange' }} />) : */ "";
        //if (siteHasChanged) star = ' *';
        tabButton = (
          <span>
            {tab.title}
            {star}
          </span>
        );
        tabContent = <GeneralInformation product={product} isEditable={isEditable} />;
        break;
      case "ProductUpPointSale":
        var star = "";
        var saveStatusIcon = ""; //GetSaveStatusIcon(siteSaveStatus);
        var warningIcon = /*siteHasWarnings ? (<Warning style={{ 'color': 'orange' }} />) : */ "";
        //if (siteStructureHasChanged) star = ' *';
        tabButton = (
          <span>
            {saveStatusIcon}
            {warningIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ProductPointSale product={product} />;
        break;
      case "ProductOptions":
        var star = "";
        var saveStatusIcon = ""; //GetSaveStatusIcon(siteSaveStatus);
        var warningIcon = /*siteHasWarnings ? (<Warning style={{ 'color': 'orange' }} />) : */ "";
        //if (siteStructureHasChanged) star = ' *';
        tabButton = (
          <span>
            {saveStatusIcon}
            {warningIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ProductOptions product={product} />;
        break;
      case "ProductComplement":
        tabButton = <span>{tab.title}</span>;
        tabContent = <ProductComplement product={product} />;
        break;
      case "ProductKeyWord":
        var star = "";
        var saveStatusIcon = ""; //GetSaveStatusIcon(siteSaveStatus);
        var warningIcon = /*siteHasWarnings ? (<Warning style={{ 'color': 'orange' }} />) : */ "";
        //if (siteStructureHasChanged) star = ' *';
        tabButton = (
          <span>
            {saveStatusIcon}
            {warningIcon}
            {tab.title}
            {star}
          </span>
        );
        tabContent = <ProductKeyWord product={product} />;
        break;

      default:
        break;
    }

    return {
      tabButton: tabButton,
      tabContent: tabContent,
      tabName: tab.component
    };
  });

  return (
    <>
      <Header product={product} isEditable={false} />
      <Tabs variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Tabs Product">
        {tabs.map((t, idx) => (
          <Tab key={"productTab" + idx} label={t.tabButton} />
        ))}
      </Tabs>
      {tabs.map((t, idx) => (
        <TabPanel value={tabIdx} index={idx} id="productTab">
          {t.tabContent}
        </TabPanel>
      ))}
    </>
  );
};

export default withStyles(structureStyle)(ProductDetailContent);
