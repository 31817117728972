import React from 'react';

import Card from "./Card";
import CardBody from "./CardBody";
import CardHeader from "./CardHeader";
import CardIcon from "./CardIcon";
import { ChatBubbleOutline } from "@material-ui/icons";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from '../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle';

function CardMessage({ message }) {
    return (<Card>
        <CardHeader color="info" icon>
            <CardIcon color="info">
                <ChatBubbleOutline />
            </CardIcon>
        </CardHeader>
        <CardBody>
            <h4>Information</h4>
            <div>
                <strong>{message}</strong>
            </div>
        </CardBody>
    </Card>
    );
}

export default withStyles(thirdPartySearchCriteriaStyle)(CardMessage);