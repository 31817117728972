import * as Actions from "./types";

export function OpenCompany(companyIdentifier, tabIndex) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_COMPANY_OPEN,
      companyIdentifier,
      tabIndex: tabIndex ? tabIndex : 0
    });
  };
}

export function CloseCompany(companyIdentifier) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_COMPANY_CLOSE,
      companyIdentifier
    });
  };
}
