import * as Actions from "../actions/types";

const initialState = [];

export default function TP_PaymentCharacteristicsReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.TP_COPERNIC_PAYMENT_CHAR_CLOSE:
      return state.filter((item, index) => {
        return index < state.length - 1;
      });

    case Actions.TP_COPERNIC_PAYMENT_CHAR_OPEN:
      return [
        ...state,
        {
          paymentCharacteristicCtx: {
            paymentCharacteristic: action.paymentCharacteristic,
            isEditable: action.isEditable,
            isApplicationAdmin: action.isApplicationAdmin,
            jsonBackup: JSON.stringify(action.paymentCharacteristic)
          }
        }
      ];

    case Actions.TP_COPERNIC_PAYMENT_CHAR_EDITVALUE:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            paymentCharacteristicCtx: {
              ...item.paymentCharacteristicCtx,
              paymentCharacteristic: action.paymentCharacteristic,
              hasChanged: JSON.stringify(action.paymentCharacteristic) !== item.paymentCharacteristicCtx.jsonBackup
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_PAYMENT_CHAR_ERROR:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            paymentCharacteristicCtx: {
              ...item.paymentCharacteristicCtx,
              error: action.error,
              isLoading: false
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_PAYMENT_CHAR_SAVE_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            paymentCharacteristicCtx: {
              ...item.paymentCharacteristicCtx,
              isLoading: true
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_PAYMENT_CHAR_SAVE_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            paymentCharacteristicCtx: {
              ...item.paymentCharacteristicCtx,
              paymentCharacteristic: action.paymentCharacteristic,
              isLoading: false
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_PAYMENT_CHAR_SIS_BEGIN:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            paymentCharacteristicCtx: {
              ...item.paymentCharacteristicCtx,
              isChecking: true
            }
          };
        }
        return item;
      });

    case Actions.TP_COPERNIC_PAYMENT_CHAR_SIS_END:
      return state.map((item, index) => {
        if (index === state.length - 1) {
          return {
            ...item,
            paymentCharacteristicCtx: {
              ...item.paymentCharacteristicCtx,
              paymentCharacteristic: action.paymentCharacteristic,
              isChecking: false
            }
          };
        }
        return item;
      });

    default:
      return state;
  }
}
