import React from "react";
import { isNull } from "../../../tools";

import ThirdPartyCreation from "../commonThirdPartyWorkflow/ThirdPartyCreation";
import RecapCustomerCreation from "./RecapCustomerCreation";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

function CreationCustomer({ workflow, setWorkflow, defaultLang }) {
  var componentToDisplay = "";
  if (!isNull(workflow.workflowStepCode) && workflow.workflowStepCode === "CustomerSummary")
    componentToDisplay = (
      <RecapCustomerCreation customerSj={workflow.linkedObject} setCustomerSj={c => setWorkflow({ ...workflow, linkedObject: c })} />
    );
  else
    componentToDisplay = (
      <ThirdPartyCreation
        thirdParty={workflow.linkedObject?.customer?.thirdParty}
        setThirdParty={tp =>
          setWorkflow({
            ...workflow,
            linkedObject: {
              ...workflow.linkedObject,
              customer: {
                ...workflow.linkedObject.customer,
                thirdParty: tp
              }
            }
          })
        }
        defaultLang={defaultLang}
      />
    );

  return <div className="divContainer">{componentToDisplay}</div>;
}

export default withStyles(thirdPartySearchCriteriaStyle)(CreationCustomer);
