import React, { useState } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import PaymentCharacteristicLegalDatas from "module/tpCopernic/components/PaymentCharacteristicLegalDatas";
import { CircularProgress, Chip, Grid, Avatar } from "@material-ui/core";
import CardError from "components/Card/CardError";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { GetIdentifiedUser, GetUsersByProfileTerritories } from "services/user/UserHelper";

//lingui
import { Trans } from "@lingui/macro";
import { distinctBy, formatDate, sortBy } from "tools";
import { Person, Today } from "@material-ui/icons";
import { ApimGet } from "components/Common/ApimSender";
import { WorkflowStatus } from "store/MasterValue/MasterValueTypes";
import { Alert, AlertTitle } from "@material-ui/lab";

function PaycharCreation({ workflow, defaultLang, masterValues }) {
  let cardValid = <></>;
  if (workflow.workflowStatusCode === "Pending") {
    cardValid = <CardWaitingForValidators workflow={workflow} defaultLang={defaultLang} />;
  } else {
    cardValid = <CardProcessed workflow={workflow} masterValues={masterValues} />;
  }

  let warnMultipleErt = <></>;
  if (!!workflow?.linkedObject?.usedByMultipleErt) {
    warnMultipleErt = (
      <Alert severity="info">
        <AlertTitle>
          <Trans>Workflow_PayChar_UsedByMultipleErt</Trans>
        </AlertTitle>
      </Alert>
    );
  }

  return (
    <div className="divContainer">
      {cardValid}
      {warnMultipleErt}
      <CardPayChar workflowLinkedObject={workflow.linkedObject} />
    </div>
  );
}

function CardWaitingForValidators({ workflow }) {
  var [state, setState] = useState({ isLoading: false });

  if (!state.isLoading && !state.validators) {
    setState({ isLoading: true });
    GetUsersByProfileTerritories(
      workflow.validatorProfileCodes,
      workflow.validatorTerritoryCountryCode,
      workflow.validatorTerritoryErtId,
      v => setState({ isLoading: false, error: null, validators: v }),
      e => setState({ isLoading: false, error: e, validators: [] })
    );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  var body = "";
  if (state.isLoading) {
    body = <CircularProgress />;
  } else if (workflow.validatorId && state.validators) {
    body = distinctBy(state.validators, "identifier").map((v, k) => {
      if (v.identifier == workflow.validatorId) {
        return (
          <Chip
            key={k}
            avatar={
              <Avatar>
                <Person />
              </Avatar>
            }
            label={v.firstName + " " + v.lastName + " (" + v.identifier + ")"}
          />)
      }
    });
  } else if (state.validators) {
    body = sortBy(distinctBy(state.validators, "identifier"), "lastName").map((v, k) => (
      <Chip
        key={k}
        avatar={
          <Avatar>
            <Person />
          </Avatar>
        }
        label={v.firstName + " " + v.lastName + " (" + v.identifier + ")"}
      />
    ));
  }

  return (
    <Card>
      <CardBody>
        <Grid container>
          <Grid item xs={2}>
            <strong>
              <Trans>Workflow_WaitingForValidationFrom</Trans>
            </strong>
          </Grid>
          <Grid item xs={10}>
            {body}
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
}

function CardProcessed({ workflow, masterValues, defaultLang }) {
  var [state, setState] = useState({ isLoading: false });

  if (!state.isLoading && !state.processUser) {
    setState({ isLoading: true });
    GetIdentifiedUser(
      workflow.lastModificationUser,
      v => setState({ isLoading: false, error: null, processUser: v }),
      e => setState({ isLoading: false, error: e, processUser: [] })
    );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  var body = "";
  if (state.isLoading) {
    body = <CircularProgress />;
  } else if (state.processUser) {
    let processUser = state.processUser;
    body = (
      <Chip
        avatar={
          <Avatar>
            <Person />
          </Avatar>
        }
        label={processUser.firstName + " " + processUser.lastName + " (" + processUser.login + ")"}
      />
    );
  }

  return (
    <Card>
      <CardBody>
        <Grid container>
          <Grid item xs={2}>
            <strong>
              <Trans>Workflow_Status</Trans>
            </strong>
          </Grid>
          <Grid item xs={10}>
            {masterValues[WorkflowStatus].find(i => i.code === workflow.workflowStatusCode)?.label}
          </Grid>
          <Grid item xs={2}>
            <strong>
              <Trans>Workflow_ProcessedBy</Trans>
            </strong>
          </Grid>
          <Grid item xs={10}>
            {body}
            <Chip
              avatar={
                <Avatar>
                  <Today />
                </Avatar>
              }
              label={formatDate(workflow.lastModificationDate, defaultLang)}
            />
          </Grid>
          <Grid item xs={2}>
            <strong>
              <Trans>Workflow_Comment</Trans>
            </strong>
          </Grid>
          <Grid item xs={10}>
            {workflow.workflowStatusCode === "Finished" ? workflow.linkedObject?.validationComment : workflow.linkedObject?.abortDescription}
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  );
}

function CardPayChar({ workflowLinkedObject }) {
  const [state, setState] = useState({ isLoading: true });

  if (state.isLoading && !state.payChar) {
    const relativeUrl = `api/TpCopernic/Suppliers/${workflowLinkedObject.supplierIdentifier}/PaymentCharacteristics/${workflowLinkedObject.payCharIdentifier}`;
    ApimGet(
      relativeUrl,
      data => setState({ payChar: data, error: null, isLoading: false }),
      errorMsg => setState({ payChar: null, error: errorMsg, isLoading: false })
    );
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  if (state.error) return <CardError error={state.error} />;

  if (!state.payChar) return <CardError error={{ message: "This payment characteristic is not found in database." }} />;

  return <PaymentCharacteristicLegalDatas readOnly payChar={state.payChar} isWfDetail={true} />;
}

export default withStyles(thirdPartySearchCriteriaStyle)(PaycharCreation);
