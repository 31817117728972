import * as Actions from '../actions/types';

const initialState = {
    result: null,
    working: false,
    error: null
};

export default function AMC_Reducer(state = initialState, action) {
    switch (action.type) {

        case Actions.ADMIN_BEGIN_ACTION:
            return {
                ...state,
                working: true,
                error: null
            };

        case Actions.ADMIN_END_ACTION:
            return {
                ...state,
                working: false,
                result: action.result,
                error: null
            };

        case Actions.ADMIN_ERROR:
            return {
                ...state,
                working: false,
                result: null,
                error: action.error
            };

        case Actions.ADMIN_CLEANRESULT:
            return {
                ...state,
                working: false,
                result: null,
            };

        default:
            return state;
    }
}