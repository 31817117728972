import React from "react";
import { connect } from "react-redux";
import { isArrayNullOrEmpty } from "module/tools";

// core components
import { Error } from "@material-ui/icons";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Warning from "@material-ui/icons/Warning";
import LinkMaterialUi from "@material-ui/core/Link";

// MDM components
import ThirdPartyGeneral from "./ThirdPartyDetail/ThirdPartyGeneral";
import ThirdPartyLegalIdentifier from "./ThirdPartyDetail/ThirdPartyLegalIdentifier";
import ThirdPartyContacts from "./ThirdPartyDetail/ThirdPartyContacts";

import { Trans } from "@lingui/macro";

// style for this view
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Tooltip, withStyles } from "@material-ui/core";

function ThirdPartyUnknown({ thirdParty, setThirdParty, errors, masterValues, defaultLang }) {
  function generateLinkForExistingThirdParty(thirdparties) {
    var links = thirdparties.map(item => {
      return (
        <ul>
          <Tooltip
            placement="right"
            title={
              <>
                {item.address_Line2}
                <br />
                {item.address_Line1}
                <br />
                {item.address_ZipCode}
                <br />
                {item.address_City}
                <br />
                {item.address_CountryCode}
              </>
            }
          >
            <LinkMaterialUi
              style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
              onClick={event => {
                // handleClose();
                //dispatch(OpenThirdParty(item.identifier));
              }}
            >
              <IconButton
                style={{
                  color: "orange"
                }}
              >
                <ArrowForward />
              </IconButton>
              <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
                {item.longName} - {item.legalIdentifier}
              </span>
            </LinkMaterialUi>
          </Tooltip>
        </ul>
      );
    });
    return links;
  }

  var errorCard = "";
  if (!isArrayNullOrEmpty(errors)) {
    var errorMessage = errors.map((t, idx) => {
      if (t.code === "ExistingLegalIdentifier") {
        return (
          <div>
            <div style={{ lineHeight: "0.5em" }}>
              <Error style={{ verticalAlign: "middle", color: "red" }} />
              <span style={{ verticalAlign: "middle", color: "red", marginRight: "5px" }}>
                <strong>
                  <Trans>A third party with the same legal identifier already exist</Trans>
                </strong>
              </span>
              <Trans>You can choose the existing third party or abort current workflow.</Trans>
            </div>
            <ol>
              <Tooltip
                placement="right"
                title={
                  <>
                    {t.complexLinkedObject.address_Line2}
                    <br />
                    {t.complexLinkedObject.address_Line1}
                    <br />
                    {t.complexLinkedObject.address_ZipCode}
                    <br />
                    {t.complexLinkedObject.address_City}
                    <br />
                    {t.complexLinkedObject.address_CountryCode}
                  </>
                }
              >
                <LinkMaterialUi
                  style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
                  onClick={event => {
                    //handleClose();f
                    //if (t.complexLinkedObject.identifier !== null && t.complexLinkedObject.identifier !== undefined && t.complexLinkedObject.identifier > 0)
                    //    dispatch(OpenThirdParty(t.complexLinkedObject.identifier));
                    //else
                    //    dispatch(OpenUnreferencedThirdParty(t.complexLinkedObject));
                  }}
                >
                  <IconButton
                    style={{
                      color: "red"
                    }}
                  >
                    <ArrowForward />
                  </IconButton>
                  <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
                    {t.complexLinkedObject.longName} - {t.complexLinkedObject.legalIdentifier}
                  </span>
                </LinkMaterialUi>
              </Tooltip>
            </ol>
          </div>
        );
      } else if (t.code === "ExistingLongName") {
        var links = generateLinkForExistingThirdParty(t.complexLinkedObject);
        return (
          <div>
            <div style={{ lineHeight: "0.5em" }}>
              <Warning style={{ verticalAlign: "middle", color: "orange" }} />
              <span style={{ verticalAlign: "middle", color: "orange" }}>
                <strong>
                  <Trans>At least one third party with the same long name already exists</Trans>
                </strong>
              </span>{" "}
              - {t.complexLinkedObject.longName}
            </div>
            <ol>
              <Trans>You can choose one of the existing or decide to continue :</Trans>
              {links}
            </ol>
          </div>
        );
      } else {
        return (
          <li key={idx}>
            <strong>{t.code}</strong> - {t.descriptionFr}
          </li>
        );
      }
    });
    errorCard = (
      <Card>
        <CardBody>{errorMessage}</CardBody>
      </Card>
    );
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={5} sm={5} md={5}>
          <ThirdPartyGeneral thirdParty={thirdParty} setThirdParty={setThirdParty} errors={errors} defaultLang={defaultLang} />
        </GridItem>
        <GridItem xs={7} sm={7} md={7}>
          <ThirdPartyLegalIdentifier thirdParty={thirdParty} handleChange={setThirdParty} />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={5} sm={5} md={5}>
          <ThirdPartyContacts thirdParty={thirdParty} handleChange={setThirdParty} defaultLang={defaultLang} />
        </GridItem>
        <GridItem xs={7} sm={7} md={7}>
          {errorCard}
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyUnknown));
