import React from 'react';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

const TabSiteAnnexe = function ({ site, setSite, defaultLang, classes }) {
    return (
        <div>
        </div>
    );
}

export default withStyles(thirdPartySearchCriteriaStyle)(TabSiteAnnexe);