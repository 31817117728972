import { Breadcrumbs, Dialog, DialogContent, DialogTitle, LinearProgress, Paper, withStyles } from "@material-ui/core";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import Muted from "components/Typography/Muted";
import React from "react";
import { useSelector } from "react-redux";
const MasterValueDialog = () => {
  var selector = useSelector(state => state.ModuleReducer.contextes[state.ModuleReducer.contextes.length - 1]);
  var itemIdx = selector.itemIdx;
  var itemTotal = selector.itemTotal;

  var dialogTitle = "";

  var dialogContent = (
    <Paper>
      <p>Chargement en cours</p>
      <LinearProgress value={(itemIdx * 100) / itemTotal} variant="determinate" />
      <p>Veuillez patienter.</p>
    </Paper>
  );

  return (
    <Dialog open={true} fullWidth={true} maxWidth="lg" aria-labelledby="form-dialog-title" scroll="paper">
      <DialogTitle id="form-dialog-title">
        <Breadcrumbs aria-label="Breadcrumb">
          <Muted>{dialogTitle}</Muted>
        </Breadcrumbs>
      </DialogTitle>
      <DialogContent
        style={{
          ...DialogInlineStyleDetail.dialogContent
        }}
        dividers={true}
      >
        {dialogContent}
      </DialogContent>
    </Dialog>
  );
};

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "90%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

export default withStyles(structureStyle)(MasterValueDialog);
