export default class LocalStorageHelper {

    Save(State) {

        try {
            localStorage.setItem('Preferences', JSON.stringify(State));
        }
        catch (err) {
            console.log(err);
        }
    }

    get(key) {
        try {
            const preferences = localStorage.getItem(key);
            if (preferences == null && "undefined")
                return null;
            return JSON.parse(preferences);
        }
        catch (e) {
            console.log(e);
        }
    }

}