import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as CompanyActions from "./store/actions";
import * as HistoryActions from "store/History/HistoryActions";

import { CircularProgress } from "@material-ui/core";
import SearchCriterias from "./part/search/SearchCriterias";

import { isNullOrEmpty } from "tools";
import CardError from "components/Card/CardError";
import { CompanySearch } from "./actions/CompanyActions";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SearchResult from "./part/search/SearchResult";

const CompanyListing = ({ defaultLang, openCompany }) => {
  const [criteria, setCriteria] = useState({});
  const [state, setState] = useState({ isLoading: false });

  useEffect(() => {
    var newCriteria = criteria;

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    if (isNullOrEmpty(criteria.pageSize)) {
      var defaultPageSize = 10;
      var defaultCountry = "FR";
      if (getPref !== null) {
        defaultPageSize = getPref.Pref.ResultPageSize * 1;
        defaultCountry = getPref.InfoGeneral.Country;
      }

      newCriteria = { ...newCriteria, country: defaultCountry, pageSize: defaultPageSize };
    }

    setCriteria(newCriteria);
  }, []);

  const launchSearch = criteria => {
    setCriteria(criteria);
    setState({ ...state, isLoading: true });
    CompanySearch(criteria, results => setState({ isLoading: false, searchResults: results }), error => setState({ isLoading: false, error: error }));
  };

  var searchResult = null;
  if (state.isLoading) {
    searchResult = <CircularProgress />;
  } else if (state.error) {
    searchResult = <CardError error={state.error} />;
  } else if (state.searchResults) {
    searchResult = <SearchResult results={state.searchResults} openCompany={openCompany} />;
  }

  var layoutSearchResult;
  if (!isNullOrEmpty(searchResult)) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {searchResult}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <SearchCriterias
            criteria={criteria}
            setCriteria={setCriteria}
            search={launchSearch}
            showSearchCriteriaOnSideBar={true}
            defaultLang={defaultLang}
          />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = (
      <SearchCriterias
        criteria={criteria}
        setCriteria={setCriteria}
        search={launchSearch}
        showSearchCriteriaOnSideBar={false}
        defaultLang={defaultLang}
      />
    );
  }

  return <div>{layoutSearchResult}</div>;
};

const mapStateToProps = state => {
  return {
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openCompany: company => {
      dispatch(CompanyActions.OpenCompany(company.identifier));
      dispatch(HistoryActions.addHistoryCompany(company));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyListing);
