import { Trans } from "@lingui/macro";
import { CircularProgress, TextField, FormLabel, Switch } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardContact from "components/Card/CardContact";
import CardError from "components/Card/CardError";
import CardHeader from "components/Card/CardHeader";
import { ApimGet } from "components/Common/ApimSender";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { CommonCountry } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNull, isObjectNullOrEmpty } from "tools";

const RequestSupplierCoupa = ({ show, coupa, setCoupa, thirdParty, isEditable, masterValues, errors }) => {
  const [coupaError, setCoupaError] = useState(null);

  useEffect(() => {
    if (coupa?.idMdm === thirdParty?.identifier) {
      return;
    }

    if (!coupa && (!thirdParty || thirdParty.identifier === 0)) {
      setCoupa({
        isNew: true,
        idMdm: 0,
        emailBuyer: [],
        contact: { firstName: "", lastName: "", details: [] }
      });
      return;
    }

    if (!coupa && thirdParty.identifier) {
      ApimGet(
        "api/TpCopernic/Suppliers/" + thirdParty.identifier + "/Coupa",
        c => {
          if (isObjectNullOrEmpty(c)) {
            c = {};
          }

          // clean object
          var { containGroups, ...coupaSupplier } = c;

          if (isNull(coupaSupplier?.contact)) {
            coupaSupplier = {
              ...coupaSupplier,
              idMdm: thirdParty.identifier,
              isNew: true,
              contact: { firstName: "", lastName: "", details: [] }
            };
          }

          setCoupa(coupaSupplier);
        },
        e => setCoupaError(e)
      );
    }
  }, [thirdParty]);

  if (!show || !thirdParty) {
    return <></>;
  }

  if (!!coupaError) {
    return <CardError error={coupaError} />;
  } else if (!coupa) {
    return <CircularProgress />;
  }

  var countries = masterValues[CommonCountry];
  var currentCountry = countries.find(c => c.codeIso2 === thirdParty.address_CountryCode);
  var supplierCountryPhoneCode = !isNull(currentCountry) ? currentCountry.defaultPhoneCode : "00";

  const error = code => errors && errors.some(e => e.code === code);
  if (errors) {
    for (var i = 0; i < errors.length; i++) {
      let code = errors[i].code;
      if (code === "coupaContactFirstName") {
        errors[i].code = "FIRSTNAME";
      } else if (code === "coupaContactLastName") {
        errors[i].code = "LASTNAME";
      } else if (code === "coupaContactMail") {
        errors[i].code = "MAIL";
      } else if (code === "coupaContactPhone") {
        errors[i].code = "PHONE";
      }
    }
  }
  var isBcSendingMethodAuto = isNull(coupa) ? false : coupa.bcSendingMethod === 1 || coupa.bcSendingMethod === 3;
  var isBcUpdateSendingMethodAuto = isNull(coupa) ? false : coupa.bcUpdateSendingMethod === 1 || coupa.bcUpdateSendingMethod === 3;
  var isSupplierPunchout = !isNull(coupa) && coupa.bcSendingMethod === 3 && coupa.bcUpdateSendingMethod === 3;

  return (
    <>
      <CardContact
        contact={coupa.contact}
        onContactChange={c => setCoupa({ ...coupa, contact: c })}
        isEditable={isEditable}
        isDeletable={false}
        isSwitchable={false}
        customHeader={<Trans>WFF_Coupa_SupplierContact</Trans>}
        lockDetailTypes={true}
        countryPhoneCode={supplierCountryPhoneCode}
        requiredDetailTypeCodes={["MAIL", "PHONE"]}
        requiredDetailTitle={false}
        errorCodes={errors}
      />
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>WFF_Coupa_Supplier</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Autocomplete
                value={
                  isNull(coupa) || isArrayNullOrEmpty(coupa.emailBuyer)
                    ? []
                    : coupa.emailBuyer.map(v => {
                      return { inputValue: v };
                    })
                }
                onChange={(e, newValue) => setCoupa({ ...coupa, emailBuyer: newValue.map(v => v.inputValue) })}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  // Suggest the creation of a new value
                  if (params.inputValue !== "") {
                    filtered.push({
                      inputValue: params.inputValue,
                      title: `Add "${params.inputValue}"`
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                multiple
                disabled={!isEditable}
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option;
                }}
                options={[]}
                renderOption={option => option.title}
                renderInput={params => (
                  <TextField {...params} variant="standard" label={<Trans>Supplier Mail</Trans>} fullWidth error={error("coupaEmailBuyer")} />
                )}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>Sending BC</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <span>
                <Trans>Manual</Trans>
                <Switch
                  tabIndex={-1}
                  checked={isBcSendingMethodAuto}
                  disabled={!isEditable || isSupplierPunchout}
                  onChange={e => setCoupa({ ...coupa, bcSendingMethod: e.target.checked ? 1 : 2 })}
                />
                <Trans>Auto</Trans>
              </span>
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>Update BC</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <span>
                <Trans>Manual</Trans>
                {/* 3 : Automatique ; 2 : Manuel */}
                <Switch
                  tabIndex={-1}
                  checked={isBcUpdateSendingMethodAuto}
                  disabled={!isEditable || isSupplierPunchout}
                  onChange={e => setCoupa({ ...coupa, bcUpdateSendingMethod: e.target.checked ? 1 : 2 })}
                />
                <Trans>Auto</Trans>
              </span>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};

const filter = createFilterOptions();

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default RequestSupplierCoupa;
