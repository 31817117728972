import React from "react";

//lingui
import { Trans } from "@lingui/macro";

import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CardHeader from "../../../components/Card/CardHeader";
import { ListItem, ListItemIcon, Card, ListItemText } from "@material-ui/core";
import CardBody from "../../../components/Card/CardBody";
import Select from "react-select";
import CustomerMigration from "./parts/CustomerMigration";
import SupplierMigration from "./parts/SupplierMigration";
import BuCleaner from "./parts/BuCleaner";

function DataMigrations({ classes }) {
  const [process, setProcess] = React.useState(null);
  const img = require("assets/img/disk-search.svg");

  const processes = [
    { value: "customerMigration", label: "Customer Migration" },
    { value: "supplierMigration", label: "Supplier Migration" },
    { value: "buCleaner", label: "Bu Cleaning" }
  ];

  var content = "";
  if (process != null) {
    if (process.value === "customerMigration") content = <CustomerMigration />;
    if (process.value === "supplierMigration") content = <SupplierMigration />;
    if (process.value === "buCleaner") content = <BuCleaner />;
  }

  return (
    <GridContainer direction="row" justify="center" alignItems="center" alignContent="center" classeName={classes.position}>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <div direction="row">
              <ListItem>
                <ListItemIcon>
                  <img src={img} width="70px" />
                </ListItemIcon>
                <ListItemText primary={<Trans>Processes</Trans>} />
              </ListItem>
            </div>
          </CardHeader>
          <CardBody>
            <GridContainer alignContent="center" classeName={classes.root}>
              <GridItem xs={12} sm={12} md={12}>
                <Select
                  closeMenuOnSelect={true}
                  options={processes}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  onChange={item => setProcess(item)}
                  value={process}
                  isSearchable={false}
                  items={processes}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                {content}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(amcSearchStyle)(DataMigrations);
