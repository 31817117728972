import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import CountryDetail from "./CountryDetail";

import { setupI18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";
import CardError from "components/Card/CardError";
import { CountryCrupdate, CountryGetAll, CountryGetByCode } from "./actions/CountryActions";
import { addMasterValue } from "store/MasterValue/MasterValueActions";
import { LoadMasterValues } from "store/MasterValue/MasterValueTypes";
import { CommonTimeZone } from "store/MasterValue/MasterValueTypes";

function CountryListing({ setMasterValueCountries, classes, defaultLang }) {
  const [state, setState] = useState({ isLoading: false, country: null, error: null });
  const [countryCode, setCountryCode] = useState(null);
  const i18n = setupI18n();

  function OpenCountry(countryCode) {
    if (countryCode) {
      setState({
        ...state,
        isLoading: true,
        country: null,
        error: null
      });

      LoadMasterValues([CommonTimeZone]).then(() => {
        CountryGetByCode(
          countryCode,
          c => setState({ ...state, isLoading: false, country: c }),
          e => setState({ ...state, isLoading: false, error: e })
        );
      });
    } else {
      setState({
        ...state,
        isLoading: false,
        error: null,
        country: null
      });
    }
  }

  function SaveCountry() {
    setState({ ...state, isLoading: true });
    CountryCrupdate(
      state.country,
      () =>
        CountryGetAll(
          countries => {
            setMasterValueCountries(countries);
            setState({
              ...state,
              isLoading: false,
              country: null
            });
          },
          e =>
            setState({
              ...state,
              isLoading: false,
              country: null,
              error: e
            })
        ),
      e =>
        setState({
          ...state,
          isLoading: false,
          country: null,
          error: e
        })
    );
  }

  function closeDetail() {
    setState({ ...state, country: null });
  }

  var countryDetail = "";
  if (state.isLoading) {
    countryDetail = <CircularProgress />;
  } else if (state.error) {
    countryDetail = <CardError error={state.error} />;
  } else if (state.country) {
    countryDetail = (
      <Dialog open={true} onClose={closeDetail} fullWidth={true} maxWidth="lg" aria-labelledby="form-dialog-title" scroll="paper">
        <DialogTitle>
          <Trans> Admin_Country_Detail_Title </Trans>
        </DialogTitle>
        <DialogContent>
          <CountryDetail country={state.country} setCountry={c => setState({ ...state, country: c })} />
        </DialogContent>
        <DialogActions>
          <Button onClick={SaveCountry} color="info">
            <Trans> Save </Trans>
          </Button>
          <Button onClick={closeDetail}>
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div className={classes.flexColCenter}>
      <Card className={classes.cardSizeMedium}>
        <CardHeader title={<Trans> Admin_Country_Search_Title </Trans>} />
        <CardContent>
          <CountryAutocomplete
            isEditable
            countryCode={countryCode}
            onChange={c => {
              setCountryCode(c);
              OpenCountry(c);
            }}
            placeHolder={i18n._(t`Admin_Country_Search_PlaceHolder`)}
            defaultLang={defaultLang}
          />
        </CardContent>
      </Card>
      {countryDetail}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMasterValueCountries: countries => dispatch(addMasterValue("countries", countries))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(adminStyle)(CountryListing));
