import * as Actions from "./MasterValueActions";

function MasterValue(state = {}, action) {
  switch (action.type) {
    case Actions.Add_MASTERVALUES:
      let s = {
        ...state,
        [action.masterKey]: action.masterValue
      };
      return s;
    default:
      return state;
  }
}

export default MasterValue;
