import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { Alert, AlertTitle } from "@material-ui/lab";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { ApimGet } from "components/Common/ApimSender";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { connect } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import { StructureUe } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from "tools";
import RequesterInformation from "./RequesterInformation";
import RequestProperties from "./RequestProperties";
import RequestScope from "./RequestScope";
import RequestSupplierCoupa from "./RequestSupplierCoupa";
import RequestSupplierThirdParty from "./RequestSupplierThirdParty";

function RequestSupplierCreation({ workflow, setWorkflow, errors, masterValues, currentUser, uesForPurchaserWorkflows }) {
  const request = workflow.linkedObject;
  const defaultLang = currentUser.language;

  const setRequest = item => {
    //if (workflow.workflowStatusCode === "Started" || (workflow.workflowStatusCode == "Aborted" && workflow.creationUser === currentUser.identifier)) {

    var mustCalculateContentGroup = false;
    var mustCalculateAdvancePurchaseValidator = false;
    var isCoupaEditable = IsCoupaEditable(isReadOnly, workflow, item, currentUser);
    item.coupaSupplierChanged = isCoupaEditable;

    if (isNullOrEmpty(item.applicantUe) || isNullOrEmpty(item.requestDomainCode)) item.coupaContentGroup = null;

    if (item.contextCode !== workflow.linkedObject.contextCode) item.validators = null;

    if (
      !isNullOrEmpty(item.applicantUe) &&
      !isNullOrEmpty(item.requestDomainCode) &&
      !isNullOrEmpty(item.contextCode) &&
      item.contextCode === "consultation"
    ) {
      if (isNull(item.coupaContentGroup)) mustCalculateContentGroup = true;
      else if (item.applicantUe !== workflow.linkedObject.applicantUe || item.requestDomainCode !== workflow.linkedObject.requestDomainCode) {
        mustCalculateContentGroup = true;
      }
    } else if (
      !isNullOrEmpty(item.applicantUe) &&
      item.contextCode === "advancePurchase" &&
      (isNull(item.validators) || item.validators.length == 0)
    ) {
      mustCalculateAdvancePurchaseValidator = true;
    }

    if (!mustCalculateContentGroup && !mustCalculateAdvancePurchaseValidator) {
      setWorkflow({
        ...workflow,
        isCoupaEditable: !!isCoupaEditable,
        linkedObject: item
      });
    } else if (mustCalculateAdvancePurchaseValidator) {
      item.coupaContentGroup = null;
      ApimGet("api/TpCopernic/Suppliers/Validators/" + item.applicantUe, users => {
        item.validators = users;
        setWorkflow({
          ...workflow,
          isCoupaEditable: !!isCoupaEditable,
          linkedObject: item
        });
      });
    } else {
      ApimGet("api/TpCopernic/Suppliers/CoupaCG/" + item.applicantUe + "/" + item.requestDomainCode, contentGroup => {
        item.coupaContentGroup = contentGroup;
        if (!!contentGroup) {
          item.validators = contentGroup.users;
        }
        setWorkflow({
          ...workflow,
          isCoupaEditable: !!isCoupaEditable,
          linkedObject: item
        });
      });
    }
    //}
  };

  const error = code => errors && errors.some(e => e.code === code);

  var availableUes = !isArrayNullOrEmpty(uesForPurchaserWorkflows) ? uesForPurchaserWorkflows : [];
  if (workflow.workflowStatusCode != "Started" && workflow.workflowStatusCode != "Aborted")
    // Cas de l'admin
    availableUes = masterValues[StructureUe];

  var isReadOnly = true;
  if (workflow.workflowStatusCode == "Started"
    || (workflow.workflowStatusCode == "Aborted" && workflow.creationUser === currentUser.identifier)
    || HasRight("thirdparty_workflow.edit")) {
    isReadOnly = false;
  }

  var alert = <></>;
  if (workflow.workflowStatusCode === "Pending") {
    alert = (
      <Alert severity="info">
        <AlertTitle>
          <Trans>Workflow_Status_Pending</Trans>
        </AlertTitle>
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "OnError") {
    alert = (
      <Alert severity="error">
        <AlertTitle>
          <Trans>Workflow_Status_Error</Trans>
        </AlertTitle>
        {error("CoupaCall") && (
          <>
            <Trans>Workflow_Status_Error_CoupaCall</Trans>
          </>
        )}
        {!!request.errorComment && <>{request.errorComment}</>}
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "Aborted") {
    alert = (
      <Alert severity="warning">
        <AlertTitle>
          <Trans>Workflow_Status_Aborted</Trans>
        </AlertTitle>
        {!!request.abortDescription && <>{request.abortDescription}</>}
      </Alert>
    );
  } else if (workflow.workflowStatusCode === "Finished") {
    alert = (
      <Alert severity="success">
        <AlertTitle>
          <Trans>Workflow_Status_Finished</Trans>
        </AlertTitle>
        {!!request.validationComment && <>{request.validationComment}</>}
      </Alert>
    );
  }

  return (
    <div className="divContainer">
      <form autoComplete="off">
        {alert}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <RequesterInformation availableUes={availableUes} request={request} setRequest={setRequest} isReadOnly={isReadOnly} errors={errors} />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={5} sm={5} md={5}>
            <Card
              style={{
                ...CardInlineStyle.card
              }}
            >
              <CardHeader
                style={{
                  ...CardInlineStyle.cardHeader
                }}
                icon
              >
                <h4>
                  <Trans>Supplier legal identifier</Trans>
                </h4>
              </CardHeader>
              <CardBody>
                <RequestSupplierThirdParty request={request} setRequest={setRequest} isReadOnly={isReadOnly} error={error} />
              </CardBody>
            </Card>
            <RequestSupplierCoupa
              show={request.contextCode !== "advancePurchase"}
              thirdParty={request.linkedThirdParty}
              isEditable={workflow.isCoupaEditable}
              coupa={request.coupaSupplier}
              setCoupa={c => {
                setRequest({ ...request, coupaSupplier: c });
              }}
              defaultLang={defaultLang}
              masterValues={masterValues}
              errors={errors}
            />
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            <div>
              <RequestScope
                workflow={workflow}
                setRequest={value => {
                  setRequest(value);
                }}
                errors={errors}
                isReadOnly={isReadOnly}
                defaultLang={defaultLang}
              />
            </div>
            <div>
              <RequestProperties workflow={workflow} setRequest={setRequest} errors={errors} isReadOnly={isReadOnly} currentUser={currentUser} />
            </div>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const IsCoupaEditable = (isReadOnly, workflow, item, currentUser) => {
  if (HasRight("thirdparty_workflow.edit")) return true;
  if (isReadOnly) return false;

  if (workflow.workflowStatusCode == "Pending") {
    if (HasRight("thirdparty_supplier.edit")) return true;
    if (!!item.coupaSupplierChanged && HasRight("thirdparty_workflow.edit")) return true;
  }

  if (
    workflow.workflowStatusCode == "Started" &&
    item.coupaSupplier &&
    (!!item.coupaSupplier.isNew ||
      (isNullOrEmpty(item.coupaSupplier.contact?.firstName) ||
        isNullOrEmpty(item.coupaSupplier.contact?.lastName) ||
        !item.coupaSupplier.contact?.details?.some(d => d.contactDetailTypeCode === "MAIL" && !isNullOrEmpty(d.value)) ||
        isArrayNullOrEmpty(item.coupaSupplier.emailBuyer)))
  )
    return true;

  return false;

  // (!isReadOnly || (workflow.workflowStatusCode == "Pending" && item.contextCode === "consultation")) &&
  //     ((workflow.workflowStatusCode == "Pending" && (HasRight("thirdparty_workflow", "edit") || workflow.creationUser !== currentUser.identifier)) ||
  //       ();
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    uesForPurchaserWorkflows: state.AuthenticationReducer.user.structuresPurchaser.ues,
    currentUser: state.AuthenticationReducer.user.language,
    masterValues: state.MasterValueReducer
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(RequestSupplierCreation));
