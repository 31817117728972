import React from "react";
import StructureListing from "../module/structure/StructureListing";
import StructureTree from "../module/structure/StructureTree";
import StructureTreeIris2 from "../module/structure/StructureTreeIris2";
import ColCode from "../module/structure/ColCode";

//lingui
import { Trans } from "@lingui/macro";
import { AccountTree, Search, Build } from "@material-ui/icons";

const structureRoutes = [
  {
    path: "/structures/structures",
    name: <Trans>Structure Search</Trans>,
    short: <Trans>Structure Search</Trans>,
    icon: Search,
    useAsComponentStructureInMenu: true,
    component: StructureListing
  },
  {
    path: "/structures/treeIris3",
    name: <Trans>Structure Tree</Trans>,
    short: <Trans>Structure Tree</Trans>,
    icon: AccountTree,
    component: StructureTree
  },
  {
    path: "/structures/treeIris2",
    name: <Trans>Structure Tree Iris2</Trans>,
    short: <Trans>Structure Tree Iris2</Trans>,
    icon: AccountTree,
    component: StructureTreeIris2
  },
  {
    path: "/structures/ColCodeManagement",
    name: <Trans>Col Code Management</Trans>,
    short: <Trans>Col Code Management</Trans>,
    icon: Build,
    component: ColCode
  }
];

export default structureRoutes;
