import React from 'react';

import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";
import CardIcon from "../../../components/Card/CardIcon";
import { Error } from "@material-ui/icons";
import { isNull } from 'tools';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

function CardError({ error }) {
    var stackMessage = "error";
    if (!isNull(error) && !isNull(error.stack))
        stackMessage = error.stack.split('\n').map((item, idx) => (<li key={idx}>{item}</li>));

    return (<Card>
        <CardHeader color="danger" icon>
            <CardIcon color="danger">
                <Error />
            </CardIcon>
        </CardHeader>
        <CardBody>
            <h4>Oops, there is a problem</h4>
            <div>
                <strong>{error.message}</strong>
            </div>
            <ul>
                {stackMessage}
            </ul>
        </CardBody>
    </Card>
    );
}

export default withStyles(thirdPartySearchCriteriaStyle)(CardError);