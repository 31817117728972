import { Trans } from "@lingui/macro";
import { withStyles } from "@material-ui/core";
import { TextField, Button, CircularProgress, IconButton } from "@material-ui/core";
import { Add, Save, Delete, History, Cancel } from "@material-ui/icons";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";
import { isNullOrEmpty } from "tools";
import React, { useState } from "react";
import { connect } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import HubbleParamsSearch from "./HubbleParamsSearch";
import * as HubbleParamsActions from "store/HubbleParams/HubbleParamsActions";
import underconstruction from "assets/img/underconstruction.png";
import DialogBox from "components/DialogBox/DialogBox";
import HistoryContainer from "module/history/containers/HistoryContainer";
import CardError from "components/Card/CardError";

function HubbleParams({ hubbleParamsCtx, defaultLang, classes }) {
  const [state, setState] = useState({ isLoading: false, isNew: false });
  var [item, setItem] = useState(null);
  const [backup, setBackup] = useState(null);
  const [errorCodes, setErrorCodes] = useState([]);
  const [apiError, setApiError] = useState(null);
  let isAdmin = HasRight("application.admin");
  var [dialogBox, setDialogBox] = useState(null);

  function changeValue(value, propertyField) {
    if (isAdmin) {
      item[propertyField] = value;
      setItem({
        ...item
      });
    }
  }
  const setHubbleParam = (hubbleParam) => {
    setBackup(JSON.stringify(hubbleParam));
    setItem(hubbleParam);
    setState({ ...state, isNew: false });

  }

  function newHubbleParam() {
    setBackup(JSON.stringify({ code: "", value: "" }));
    setItem({ code: "", value: "" })
    setState({ ...state, isNew: true });
  }

  function addHP(hp) {
    HubbleParamsActions.AddHubbleParam(hp
      , (h) => {
        HubbleParamsActions.LoadHubbleParams();
        setHubbleParam(null);
      }, (e) => {
        if (e?.response?.status === 400) {
          setErrorCodes(e.response.data);
        } else {
          setApiError(e);
        }
      });
  };

  function updateHP(hp) {
    HubbleParamsActions.UpdateHubbleParam(hp
      , (h) => {
        HubbleParamsActions.LoadHubbleParams();
        setHubbleParam(null);
      }, (e) => {
        if (e?.response?.status === 400) {
          setErrorCodes(e.response.data);
        } else {
          setApiError(e);
        }
      });
  };

  function deleteHP(hp) {
    HubbleParamsActions.DeleteHubbleParam(hp);
    HubbleParamsActions.LoadHubbleParams();
    setHubbleParam(null);
  };

  var viewHistory = () => {
    setDialogBox({
      type: "cancel",
      message: <PartHistory code={item.code} defaultLang={defaultLang} />,
      cancel: text => {
        setDialogBox(null);
      }
    });
  };

  var searchItem = "";
  var content = "";
  var detail = "";
  var validateButton = "";
  var deleteButton = "";

  if (isAdmin) {

    searchItem = (
      <>
        <GridContainer alignItems="center" justify="center">
          <GridItem xs={8} sm={8} md={8}>
            <HubbleParamsSearch hubbleParams={hubbleParamsCtx.HubbleParams} setValue={setHubbleParam} />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <Button variant="contained" size="sm" onClick={newHubbleParam} color="info">
              <Add />
              <Trans>New</Trans>
            </Button>
          </GridItem>
        </GridContainer>
      </>
    );

    if (state.isLoading == false && item && (state.isNew || backup != JSON.stringify(item))) {
      validateButton = (
        <Button variant="contained" size="small" color="info"
          onClick={() => state.isNew ? addHP(item) : updateHP(item)}
          disabled={backup === JSON.stringify(item) || item.code === ""}>
          <Save />
          <Trans>Save</Trans>
        </Button>
      )
    }

    if (item && state.isNew == false) {
      deleteButton = (<>
        <IconButton onClick={viewHistory}>
          <History color="info" />
        </IconButton>
        <Button variant="contained" size="small" color="info"
          onClick={() => deleteHP(item)}>
          <Delete />
          <Trans>Delete</Trans>
        </Button>
      </>);
    }
    else if (item && state.isNew) {
      deleteButton = <Button variant="contained" size="small" color="info"
        onClick={() => setHubbleParam(null)}>
        <Cancel />
        <Trans>Cancel</Trans>
      </Button>
    }

    if (item) {
      detail = (<>
        <Card className={classes.cardSizeLarge}>
          <CardBody >
            <GridContainer alignItems="center" justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <TextField id="code" type="text" fullWidth
                  label={<Trans>Code</Trans>}
                  value={item.code}
                  onChange={event => {
                    changeValue(event.target.value, "code");
                  }} />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <TextField id="value" type="text" fullWidth
                  label={<Trans>Value</Trans>}
                  value={item.value}
                  onChange={event => {
                    changeValue(event.target.value, "value");
                  }} />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter>
            {validateButton}
            <div style={{ flex: "1 0 0" }} />
            {deleteButton}
          </CardFooter>
        </Card>
      </>)
    }
    content = (<div className={classes.flexColCenter}>
      <Card className={classes.cardSizeLarge}>
        <CardHeader icon>
          <GridContainer alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              {searchItem}
            </GridItem>
          </GridContainer>
        </CardHeader>
      </Card>
      {detail}
    </div >);
  }
  else {
    content = (<div style={{ textAlign: "center" }}>
      <div>
        <img src={underconstruction} alt="maintenance" style={{ width: "40%" }} />
      </div>
    </div>
    );
  }
  return (
    <>
      {content}
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </>
  );
}

const PartHistory = ({ code, defaultLang }) => {
  if (isNullOrEmpty(defaultLang)) defaultLang = "FR";

  const [state, setState] = useState({ isLoading: false });

  if (!state.isLoading && !state.history) {
    setState({ isLoading: true, history: [] });

    HubbleParamsActions.GetHubbleParamHistory(code, h => setState({ isLoading: false, history: h }), e => setState({ isLoading: false, history: [], error: e }));
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>HubbleParams_Part_History</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <HistoryContainer items={state.history} />
      </CardBody>
    </Card>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    hubbleParamsCtx: state.HubbleParamReducer,
    defaultLang: state.AuthenticationReducer.user.language,
  };
};

export default connect(
  mapStateToProps)
  (withStyles(adminStyle)(HubbleParams));
