import { Trans } from "@lingui/macro";
import { CircularProgress, Dialog, Fade, Grid, withStyles } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { CheckCircleOutlined, ErrorOutline } from "@material-ui/icons";
import Logo from "assets/images/hubble.png";
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import AuthenticationInWebo from "components/Authentication/AuthenticationInWebo";
import { GraphGet, GraphGetBase64 } from "components/Common/ApimSender";
import DialogBox from "components/DialogBox/DialogBox";
import React, { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { I18nLoad } from "services/I18nHelper";
import { GetCurrentUser } from "services/user/UserHelper";
import { CommonCountryCounty, OperationType } from "store/MasterValue/MasterValueTypes";
import { CommonCountryState } from "store/MasterValue/MasterValueTypes";
import { CommonCountry, LoadMasterValues, StructureErt, StructureSj, StructureUe, StructureUp } from "store/MasterValue/MasterValueTypes";
import { RootStore } from "store/RootStore";
import { isArray, isString } from "tools";
import { aadLoginSuccess, setUserProfiles } from "../../store/Authentication/Actions";
import { LoadHubbleParams } from "store/HubbleParams/HubbleParamsActions";
import AuthenticationMaintenance from "components/Authentication/AuthenticationMaintenance";
import { HubbleParameter_CurrentMaintenance, HubbleParameter_InWeboEnabled } from "constants/HubbleParameterConstants";

const Login = ({ setPayload, setUserProfiles }) => {
  const [loginPayLoad, setLoginPayLoad] = useState(null);
  const [loginUser, setLoginUser] = useState(null);
  var [dialogBox, setDialogBox] = useState(null);
  var user = null;
  var hubbleParams = null;

  var initialState = [];
  var reducer = (status, action) => {
    switch (action.type) {
      case "addStep":
        return [...status, { type: action.stepType, status: "pending" }];
      case "updateStep":
        return status.map(s => {
          if (s.type === action.stepType) {
            return { ...s, status: action.status, error: action.error, detail: action.detail };
          }
          return s;
        });
      default:
        return status;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const steps = [
    {
      type: "login",
      statusPending: (
        <div>
          <CircularProgress size={20} /> Connexion en cours
        </div>
      ),
      statusSuccess: (
        <div>
          <CheckCircleOutlined style={{ color: green[500] }} />{" "}
          {loginPayLoad ? (
            <>
              <Trans>Login_ConnectedAs</Trans> {loginPayLoad.displayName}
            </>
          ) : (
            <Trans>Login_Connected</Trans>
          )}
        </div>
      ),
      statusFail: message => (
        <div>
          <ErrorOutline style={{ color: red[500] }} /> {message}
        </div>
      )
    },
    {
      type: "user",
      statusPending: (
        <div>
          <CircularProgress size={20} /> <Trans>Login_UserDataPending</Trans>
        </div>
      ),
      statusSuccess: (
        <div>
          <CheckCircleOutlined style={{ color: green[500] }} /> <Trans>Login_UserDataDone</Trans>
        </div>
      ),
      statusFail: message => (
        <div>
          <ErrorOutline style={{ color: red[500] }} /> {message}
        </div>
      )
    },
    {
      type: "hubbleParameters",
      statusPending: (
        <div>
          <CircularProgress size={20} /> <Trans>Login_HubbleParametersPending</Trans>
        </div>
      ),
      statusSuccess: (
        <div>
          <CheckCircleOutlined style={{ color: green[500] }} /> <Trans>Login_HubbleParametersDone</Trans>
        </div>
      ),
      statusFail: message => (
        <div>
          <ErrorOutline style={{ color: red[500] }} /> {message}
        </div>
      )
    },
    {
      type: "mastervalue",
      statusPending: (
        <div>
          <CircularProgress size={20} /> <Trans>Login_MasterValuePending</Trans>
        </div>
      ),
      statusSuccess: (
        <div>
          <CheckCircleOutlined style={{ color: green[500] }} /> <Trans>Login_MasterValueDone</Trans>
        </div>
      ),
      statusFail: message => (
        <div>
          <ErrorOutline style={{ color: red[500] }} /> {message}
        </div>
      )
    },
    {
      type: "compute",
      statusPending: (
        <div>
          <CircularProgress size={20} /> <Trans>Login_ComputePending</Trans>
        </div>
      ),
      statusSuccess: (
        <div>
          <CheckCircleOutlined style={{ color: green[500] }} /> <Trans>Login_ComputeDone</Trans>
        </div>
      )
    }
  ];

  const launchNextStep = newStep => {
    if (newStep === 1) {
      // step 1: Login
      dispatch({ type: "addStep", stepType: "login" });

      // Silently acquires an access token which is then attached to a request for MS Graph data

      GraphGet("me", graphResponse => {
        GraphGetBase64("/photos/48x48/$value", pictureResponse => {
          dispatch({ type: "updateStep", stepType: "login", status: "success" });
          var toStore = {
            ...graphResponse,
            picture: pictureResponse
          };
          setLoginPayLoad(toStore);
          setPayload(toStore);
          launchNextStep(newStep + 1);
        });
      });
    } else if (newStep === 2) {
      // step 2: Get hubble params
      dispatch({ type: "addStep", stepType: "hubbleParameters" });
      LoadHubbleParams()
        .then(params => {
          hubbleParams = params;
          dispatch({ type: "updateStep", stepType: "hubbleParameters", status: "success" });

          launchNextStep(newStep + 1);
        })
        .catch(e => {
          dispatch({ type: "updateStep", stepType: "hubbleParameters", status: "fail", error: e.message });
        });
    } else if (newStep === 3) {
      // step 3: Get user data
      dispatch({ type: "addStep", stepType: "user" });
      GetCurrentUser(
        u => {
          user = u;
          I18nLoad(u.language);
          dispatch({ type: "updateStep", stepType: "user", status: "success" });

          if (
            hubbleParams.some(h => h.code === HubbleParameter_CurrentMaintenance && h.value === "True") &&
            !u.rights.some(r => r === "application.admin")
          ) {
            setDialogBox({
              type: "empty",
              canDismiss: false,
              width: "lg",
              message: (
                <>
                  <AuthenticationMaintenance />
                </>
              )
            });
          } else if (
            u.rights.some(r => r === "authentication.require_inwebo") &&
            hubbleParams.some(h => h.code === HubbleParameter_InWeboEnabled && h.value === "True")
          ) {
            setDialogBox({
              type: "empty",
              canDismiss: false,
              message: (
                <>
                  <AuthenticationInWebo
                    userIdentifier={user}
                    successCallback={() => {
                      user = { ...user, isAuthenticatedInWebo: true };
                      setDialogBox(null);
                      launchNextStep(newStep + 1);
                    }}
                  />
                </>
              )
            });
          } else launchNextStep(newStep + 1);
        },
        e => {
          dispatch({ type: "updateStep", stepType: "user", status: "fail", error: e.message });
        }
      );
    } else if (newStep === 4) {
      // step 4: Get masterValue data
      dispatch({ type: "addStep", stepType: "mastervalue" });
      LoadMasterValues([CommonCountry, CommonCountryCounty, CommonCountryState, StructureErt, StructureSj, StructureUe, StructureUp, OperationType])
        .then(() => {
          dispatch({ type: "updateStep", stepType: "mastervalue", status: "success" });
          launchNextStep(newStep + 1);
        })
        .catch(e => {
          dispatch({ type: "updateStep", stepType: "mastervalue", status: "fail", error: e.message });
        });
    } else if (newStep === 5) {
      // step 5: Compute masterValue
      dispatch({ type: "addStep", stepType: "compute" });

      const reducers = RootStore.getState();
      const mvReducer = reducers.MasterValueReducer;
      const erts = mvReducer[StructureErt].map(ert => {
        return { ertId: ert.identifier, sjId: null, ueId: null, upId: null, structure: { ...ert, structureType: "ERT" } };
      });

      const sjs = mvReducer[StructureSj].map(sj => {
        return { ertId: sj.ertIdentifier, sjId: sj.identifier, ueId: null, upId: null, structure: { ...sj, structureType: "SJ" } };
      });

      const ues = mvReducer[StructureUe].map(ue => {
        var sj = sjs.find(sj => sj.sjId === ue.sjIdentifier);
        if (!sj) return null;
        return {
          ertId: sj.ertId,
          sjId: sj.sjId,
          ueId: ue.identifier,
          upId: null,
          structure: { ...ue, structureType: "UE" }
        };
      }).filter(ue => ue);

      const ups = mvReducer[StructureUp].map(up => {
        var ue = ues.find(ue => ue.ueId === up.ueIdentifier);
        if (!ue) return null;
        var sj = sjs.find(sj => sj.sjId === ue.sjId);
        if (!sj) return null;
        return {
          ertId: sj.ertId,
          sjId: sj.sjId,
          ueId: ue.ueId,
          upId: up.identifier,
          structure: { ...up, structureType: "UP" }
        };
      }).filter(up => up);

      const allStructures = [...erts, ...sjs, ...ues, ...ups];

      let structureTps = {
        erts: GetAvailableStructures(allStructures, user, "ERT", "MDMS_TPS", false),
        sjs: GetAvailableStructures(allStructures, user, "SJ", "MDMS_TPS", false),
        ues: GetAvailableStructures(allStructures, user, "UE", "MDMS_TPS", false),
        ups: GetAvailableStructures(allStructures, user, "UP", "MDMS_TPS", false)
      };
      let structureTpc = {
        erts: GetAvailableStructures(allStructures, user, "ERT", ["MDMS_TPC", "JADE"], false),
        sjs: GetAvailableStructures(allStructures, user, "SJ", ["MDMS_TPC", "JADE"], false),
        ues: GetAvailableStructures(allStructures, user, "UE", ["MDMS_TPC", "JADE"], false),
        ups: GetAvailableStructures(allStructures, user, "UP", ["MDMS_TPC", "JADE"], false)
      };
      let structureSites = {
        erts: GetAvailableStructures(allStructures, user, "ERT", "MDMS_SITE", false),
        sjs: GetAvailableStructures(allStructures, user, "SJ", "MDMS_SITE", false),
        ues: GetAvailableStructures(allStructures, user, "UE", "MDMS_SITE", false),
        ups: GetAvailableStructures(allStructures, user, "UP", "MDMS_SITE", false)
      };
      let structuresPurchaser = {
        ues: GetAvailableStructures(allStructures, user, "UE", ["MDMS_TPS_WF_CREATOR", "MDMS_TPS_PURCHASER"], true)
      };
      let structuresAmc = {
        ues: GetAvailableStructures(allStructures, user, "UE", ["MDMS_AMC_CCH"], false)
      };
      let structuresStruct = {
        erts: GetAvailableStructures(allStructures, user, "ERT", ["MDMS_STRUCT"], false),
        sjs: GetAvailableStructures(allStructures, user, "SJ", ["MDMS_STRUCT"], false)
      };

      user = {
        ...user,
        structureTps,
        structureTpc,
        structureSites,
        structuresPurchaser,
        structuresAmc,
        structuresStruct,
        country: getDefaultCountry()
      };
      setLoginUser(user);

      dispatch({ type: "updateStep", stepType: "compute", status: "success" });
      setTimeout(() => launchNextStep(newStep + 1), 500);
    }
  };

  useEffect(() => {
    launchNextStep(1);
  }, []);

  const displaySteps = state.map(s => {
    let toReturn = <></>;
    let step = steps.find(st => st.type === s.type);
    if (!step) toReturn = <em>Undefined step</em>;
    else if (s.status === "pending") toReturn = step.statusPending;
    else if (s.status === "success") toReturn = step.statusSuccess;
    else toReturn = step.statusFail(s.error);
    return <div key={`${s.type}_${s.key}`}>{toReturn}</div>;
  });

  if (state.length === 5 && state.every(s => s.status === "success")) {
    setTimeout(() => {
      setUserProfiles(loginUser);
    }, 1000);
  }

  return (
    <>
      <Dialog open={true} maxWidth="md" fullWidth TransitionComponent={Transition} keepMounted>
        <Grid container style={{ padding: 10 }}>
          <Grid item xs={3}>
            <img src={Logo} alt="Logo" style={{ width: "100%" }} />
          </Grid>
          <Grid item xs={9}>
            <div className="font-bold">
              <Trans>LoadingPleaseWait</Trans>
            </div>
            {displaySteps}
          </Grid>
        </Grid>
      </Dialog>
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </>
  );
};

function GetAvailableStructures(allStructures, userLogin, structureType, profileTypes, exactMatch) {
  if (isString(profileTypes)) profileTypes = [profileTypes];

  const userProfiles = userLogin.profiles;
  const isAdmin = userProfiles.some(p => p.profileIdentifier.endsWith("_ADMIN"));
  const userStructures = isAdmin
    ? []
    : userProfiles
        .filter(p =>
          profileTypes.some(pt =>
            isArray(p.profileIdentifier) ? p.profileIdentifier.some(p1 => p1.startsWith(pt)) : p.profileIdentifier.startsWith(pt)
          )
        )
        .map(p => {
          return p.structures;
        })
        .flat();

  switch (structureType) {
    case "ERT":
      if (isAdmin) return allStructures.filter(s => s.ertId && !s.sjId && !s.ueId && !s.upId).map(s => s.structure);
      var ertIdentifiers = [
        ...new Set(
          allStructures
            .filter(structure =>
              userStructures.some(
                us => structure.ertId === us.ertId || (!exactMatch && structure.sjId === us.sjId) || (!exactMatch && structure.ueId === us.ueId)
              )
            )
            .map(ert => ert.ertId)
        )
      ];
      return allStructures.filter(s => ertIdentifiers.some(ertId => s.ertId === ertId && !s.sjId && !s.ueId && !s.upId)).map(s => s.structure);

    case "SJ":
      if (isAdmin) return allStructures.filter(s => s.sjId && !s.ueId && !s.upId).map(s => s.structure);
      var sjIdentifiers = [
        ...new Set(
          allStructures
            .filter(structure =>
              userStructures.some(
                us =>
                  structure.sjId &&
                  ((!exactMatch && structure.ertId === us.ertId) || structure.sjId === us.sjId || (!exactMatch && structure.ueId === us.ueId))
              )
            )
            .map(sj => sj.sjId)
        )
      ];
      return allStructures.filter(s => sjIdentifiers.some(sjId => s.sjId === sjId && !s.ueId && !s.upId)).map(s => s.structure);

    case "UE":
      if (isAdmin) return allStructures.filter(s => s.ueId && !s.upId).map(s => s.structure);
      var ueIdentifiers = [
        ...new Set(
          allStructures
            .filter(structure =>
              userStructures.some(
                us =>
                  structure.ueId &&
                  ((!exactMatch && structure.ertId === us.ertId) || (!exactMatch && structure.sjId === us.sjId) || structure.ueId === us.ueId)
              )
            )
            .map(ue => ue.ueId)
        )
      ];
      return allStructures.filter(s => ueIdentifiers.some(id => s.ueId === id && !s.upId)).map(s => s.structure);

    case "UP":
      if (isAdmin) return allStructures.filter(s => s.upId).map(s => s.structure);
      var upIdentifiers = [
        ...new Set(
          allStructures
            .filter(structure =>
              userStructures.some(us => structure.upId && (structure.ertId === us.ertId || structure.sjId === us.sjId || structure.ueId === us.ueId))
            )
            .map(ue => ue.upId)
        )
      ];
      return allStructures.filter(s => upIdentifiers.some(id => s.upId === id)).map(s => s.structure);
  }

  return [];
}

function getDefaultCountry() {
  var defaultCountry = "FR";
  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");
  if (getPref !== null) {
    defaultCountry = getPref.InfoGeneral.Country;
  }
  return defaultCountry;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    setPayload: payload => {
      dispatch(aadLoginSuccess(payload));
    },
    setUserProfiles: user => {
      dispatch(setUserProfiles(user));
    }
  };
};

export default withStyles(loginPageStyle)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login)
);
