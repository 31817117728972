//lingui
import { Trans } from "@lingui/macro";
import { Checkbox, FormControlLabel, FormLabel } from "@material-ui/core";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Check } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { connect } from "react-redux";
import { isArrayNullOrEmpty } from "../../../tools";
import * as Actions from "../../store/actions";

const TabSupplierAddress = function({ supplierCtx, isEditable, onSupplierValueChanged, classes, defaultLang }) {
  var supplier = supplierCtx.supplier;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot
                }}
                checked={supplier.isPaymentAddressOverriden}
                disabled={!isEditable}
                onChange={e => onSupplierValueChanged({ ...supplier, isPaymentAddressOverriden: e.target.checked })}
              />
            }
            classes={{
              label: classes.label
            }}
            label={<Trans>Is Address Overriden</Trans>}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={7} sm={7} md={7}>
          <Card
            style={{
              ...CardInlineStyle.card
            }}
          >
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <h4>
                <Trans>Address</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Line 1</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <FieldText
                    isEditable={isEditable}
                    id="line1"
                    value={supplier.address_Line1}
                    onChange={e => {
                      var value = e.target.value;
                      if (value.length > 40) value = value.substring(0, 40);
                      onSupplierValueChanged({ ...supplier, address_Line1: value });
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Line 2</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <FieldText
                    isEditable={isEditable}
                    id="line2"
                    value={supplier.address_Line2}
                    onChange={e => {
                      var value = e.target.value;
                      if (value.length > 40) value = value.substring(0, 40);
                      onSupplierValueChanged({ ...supplier, address_Line2: value });
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>City</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <CityZipAutocomplete
                    countryCode={supplier ? supplier.address_CountryCode : ""}
                    zipCode={supplier ? supplier.address_ZipCode : ""}
                    city={supplier ? supplier.address_City : ""}
                    countyCode={supplier ? supplier.address_CountyCode : ""}
                    stateCode={supplier ? supplier.address_StateCode : ""}
                    onZipCityChange={(zipCode, city) => {
                      onSupplierValueChanged({ ...supplier, address_ZipCode: zipCode, address_City: city });
                    }}
                    onCountyStateChange={countyState => {
                      onSupplierValueChanged({ ...supplier, address_StateCode: countyState.stateCode, address_CountyCode: countyState.countyCode });
                    }}
                    isEditable={isEditable}
                    defaultLang={defaultLang}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Country</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <CountryAutocomplete
                    countryCode={supplier.address_CountryCode}
                    onChange={countryCode => onSupplierValueChanged({ ...supplier, address_CountryCode: countryCode })}
                    defaultLang={defaultLang}
                    isEditable={isEditable}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    supplierCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].supplierCtx : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSupplierValueChanged: supplier => {
      dispatch(Actions.EditSupplier(supplier));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabSupplierAddress));
