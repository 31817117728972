import React, { useEffect, useState } from "react";
import SupervisionGlobalContainer from "../common/SupervisionGlobalContainer";
import SupervisionGlobalProcess from "../common/SupervisionGlobalProcess";
import SupervisionGlobalItem from "../common/SupervisionGlobalItem";
import { SearchAppInsightDatasGroupForGlobal } from "services/report/ReportHelper";

function CoupaPart() {
  const [statusContentGroup, setStatusContentGroup] = useState("SEARCHING");
  const [statusContentGroupGet, setStatusContentGroupGet] = useState("SEARCHING");
  const [statusContentGroupProcess, setStatusContentGroupProcess] = useState("SEARCHING");
  const [statusGroupMembership, setStatusGroupMembership] = useState("SEARCHING");
  const [statusGroupMembershipGet, setStatusGroupMembershipGet] = useState("SEARCHING");
  const [statusGroupMembershipProcess, setStatusGroupMembershipProcess] = useState("SEARCHING");
  const [statusUser, setStatusUser] = useState("SEARCHING");
  const [statusUserGet, setStatusUserGet] = useState("SEARCHING");
  const [statusUserProcess, setStatusUserProcess] = useState("SEARCHING");

  useEffect(() => {
    SearchAppInsightDatasGroupForGlobal(["TimerTrigger_GetContentGroups", "EventHubTrigger_ImportContentGroup"], datas => {
      setStatusContentGroup(datas.statusGolbal);
      setStatusContentGroupGet(datas["TimerTrigger_GetContentGroups"]);
      setStatusContentGroupProcess(datas["EventHubTrigger_ImportContentGroup"]);
    });

    SearchAppInsightDatasGroupForGlobal(["TimerTrigger_GetGroupMemberships", "EventHubTrigger_ImportContentGroupMemebership"], datas => {
      setStatusGroupMembership(datas.statusGolbal);
      setStatusGroupMembershipGet(datas["TimerTrigger_GetGroupMemberships"]);
      setStatusGroupMembershipProcess(datas["EventHubTrigger_ImportContentGroupMemebership"]);
    });

    SearchAppInsightDatasGroupForGlobal(["TimerTrigger_GetGroupMemberships", "EventHubTrigger_ImportContentGroupMemebership"], datas => {
      setStatusUser(datas.statusGolbal);
      setStatusUserGet(datas["TimerTrigger_GetUsers"]);
      setStatusUserProcess(datas["EventHubTrigger_ImportUser"]);
    });
  }, []);

  return (
    <>
      <SupervisionGlobalContainer name="Coupa">
        <>
          <SupervisionGlobalProcess name="Récupération des content groups" status={statusContentGroup}>
            <SupervisionGlobalItem name="Appel au web service Coupa" status={statusContentGroupGet} />
            <SupervisionGlobalItem name="Integration des items" status={statusContentGroupProcess} />
          </SupervisionGlobalProcess>
          <SupervisionGlobalProcess name="Récupération des groups" status={statusGroupMembership}>
            <SupervisionGlobalItem name="Appel au web service Coupa" status={statusGroupMembershipGet} />
            <SupervisionGlobalItem name="Integration des items" status={statusGroupMembershipProcess} />
          </SupervisionGlobalProcess>
          <SupervisionGlobalProcess name="Récupération des users" status={statusUser}>
            <SupervisionGlobalItem name="Appel au web service Coupa" status={statusUserGet} />
            <SupervisionGlobalItem name="Integration des items" status={statusUserProcess} />
          </SupervisionGlobalProcess>
        </>
      </SupervisionGlobalContainer>
    </>
  );
}

export default CoupaPart;
