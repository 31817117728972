import React, { useReducer, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, CardHeader, Grid, Typography, TextField, Button, CircularProgress, IconButton, CardActions } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

//lingui
import { Trans } from "@lingui/macro";
import { GetIdentifiedUser } from "services/user/UserHelper";
import CardError from "../CardError";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { isNullOrEmpty } from "tools";
import { IdentifiedUserAutocomplete } from "components/Autocomplete/IdentifiedUserAutocomplete";
import { isNull, isArrayNullOrEmpty } from "tools";
import GetMasterValueLabel from "../../../../components/Common/MasterValueLabel";

export default function SiteContact({ isEditable, contacts, setContacts, siteId, contactTypes, defaultLang }) {
  var orderedContactTypes = ["LOCAL_1", "LOCAL_2", "IT_1", "IT_2"];
  var boxes = orderedContactTypes.map(order => {
    let contactType = contactTypes.find(c => c.code === order);
    var contact = contacts.find(c => c.contactTypeCode === order);
    if (typeof contact === "undefined") {
      contact = contacts.find(c => c.locationContactTypeCode === order);
    }

    return (
      <Grid item xs={6} key={order}>
        <SiteContactCard
          contactType={GetMasterValueLabel(contactType, defaultLang)}
          contact={contact}
          setContact={c =>
            setContacts([...contacts.filter(c1 => c1.contactTypeCode !== order), { ...c, locationIdentifier: siteId, contactTypeCode: order }])
          }
          removeContact={c => setContacts(contacts.filter(c1 => c1.contactTypeCode !== order))}
          isEditable={isEditable}
        />
      </Grid>
    );
  });

  return (
    <Grid container spacing={2}>
      {boxes}
    </Grid>
  );
}

function SiteContactCard({ contact, contactType, setContact, removeContact, isEditable }) {
  const initialState = {
    userDirectory: null,
    error: null,
    status: isNull(contact) ? "empty" : "init"
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "loading":
        return {
          ...state,
          status: "loading"
        };
      case "userNotFound":
        return {
          ...state,
          status: "unknown",
          userDirectory: { login: action.userLogin },
          error: null
        };
      case "userFound":
        return {
          ...state,
          status: "show",
          userDirectory: action.userDirectory,
          error: null
        };
      case "create":
        return {
          ...state,
          status: "create",
          loading: false,
          error: null
        };
      case "removed":
      case "createCancel":
        return {
          ...state,
          status: "empty"
        };
      case "createLoading":
        return {
          ...state,
          status: "create",
          loading: true,
          error: null
        };
      case "createError":
        return {
          ...state,
          status: "create",
          loading: false,
          error: action.error
        };
      case "edit":
        return {
          ...state,
          status: "edit"
        };
      case "save":
        return {
          ...state,
          status: "init"
        };
      case "editCancel":
        return {
          ...state,
          status: "show"
        };
      case "error":
        return {
          ...state,
          status: "error",
          error: action.error
        };
      default:
        throw new Error("Unexpected action");
    }
  };

  const [context, dispatch] = useReducer(reducer, initialState);
  const classes = useStyles();
  const [createLogin, setCreateLogin] = useState("");
  const [editPhoneNumber, setEditPhoneNumber] = useState("");

  switch (context.status) {
    case "init":
      GetIdentifiedUser(
        contact.login,
        c => {
          if (isArrayNullOrEmpty(c)) {
            dispatch({ type: "userNotFound", userLogin: contact.login });
          } else {
            dispatch({ type: "userFound", userDirectory: c });
          }
        },
        e => {
          dispatch({ type: "error", error: e });
        }
      );
      dispatch({ type: "loading" });
      break;

    case "unknown":
      return (
        <Card className={classes.root} variant="outlined">
          <CardHeader
            title={contactType}
            //subheader="September 14, 2016"
          />
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              <Trans>Location_ContactNotFound</Trans> ({context.userDirectory.login})
            </Typography>
          </CardContent>
          {isEditable ? (
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={e => {
                  removeContact(contact);
                  dispatch({ type: "removed" });
                }}
              >
                <Trans>Delete</Trans>
              </Button>
            </CardActions>
          ) : (
            <></>
          )}
        </Card>
      );

    case "show":
    case "loading":
      let userDirectory = context.userDirectory;
      return (
        <Card className={classes.root} variant="outlined">
          <CardHeader
            title={contactType}
            //subheader="September 14, 2016"
          />
          <CardContent>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              {context.status === "loading" ? (
                <Skeleton />
              ) : (
                <>
                  {userDirectory.firstName} {userDirectory.lastName} ({userDirectory.login})
                </>
              )}
            </Typography>
            <Typography>{context.status === "loading" ? <Skeleton /> : <>{userDirectory.ueLabel}</>}</Typography>
            <Typography className={classes.pos} color="textSecondary">
              {context.status === "loading" ? (
                <Skeleton />
              ) : (
                <>
                  {userDirectory.phoneNumber}
                  {userDirectory.mobileNumber ? (
                    <>
                      <br />
                      {userDirectory.mobileNumber}
                    </>
                  ) : (
                    ""
                  )}
                  {contact.phoneNumber ? (
                    <>
                      <br />
                      {contact.phoneNumber}
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {context.status === "loading" ? <Skeleton /> : <>{userDirectory.functionLabel}</>}
            </Typography>
          </CardContent>
          {isEditable ? (
            <CardActions>
              <Button size="small" color="primary" onClick={e => dispatch({ type: "edit" })}>
                <Trans>Edit</Trans>
              </Button>
              <Button
                size="small"
                color="primary"
                onClick={e => {
                  removeContact(contact);
                  dispatch({ type: "removed" });
                }}
              >
                <Trans>Delete</Trans>
              </Button>
            </CardActions>
          ) : (
            <></>
          )}
          {/*<CardText>
                    <ul>
                        <li><textarea>{JSON.stringify(state.userDirectory)}</textarea></li>
                        <li>{state.status}</li>
                        <li>{contact.localId}</li>
                        <li><textarea>{JSON.stringify(state.contact)}</textarea></li>
                    </ul>
                </CardText>*/}
        </Card>
      );

    case "empty":
      return (
        <Card className={classes.root} variant="outlined">
          <CardHeader
            title={contactType}
            //subheader="September 14, 2016"
          />
          <CardContent>
            {isEditable ? (
              <IconButton onClick={e => dispatch({ type: "create" })}>
                <Add />
              </IconButton>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      );

    case "create":
      return (
        <Card className={classes.root} variant="outlined">
          <CardHeader
            title={contactType}
            //subheader="September 14, 2016"
          />
          <CardContent>
            <GridContainer>
              <GridItem xs={4}>
                <Trans>Login</Trans>
              </GridItem>
              <GridItem xs={8}>
                {/*<TextField
                                    id="login"
                                    value={createLogin}
                                    onChange={e => setCreateLogin(e.target.value)}
                                />*/}
                <IdentifiedUserAutocomplete login={createLogin} onChange={e => setCreateLogin(e)} />
                <Typography style={{ color: "red" }}>{context.error}</Typography>
              </GridItem>
            </GridContainer>
          </CardContent>
          <CardActions>
            {context.loading ? (
              <CircularProgress />
            ) : (
              <Button
                size="small"
                color="primary"
                onClick={e => {
                  if (isNullOrEmpty(createLogin)) return;

                  GetIdentifiedUser(
                    createLogin,
                    c => {
                      if (isArrayNullOrEmpty(c)) {
                        dispatch({ type: "createError", error: <Trans>Site_UserNotFound</Trans> });
                      } else {
                        setContact({ login: createLogin });
                        setCreateLogin("");
                        dispatch({ type: "save" });
                      }
                    },
                    e => {
                      dispatch({ type: "error", error: e });
                    }
                  );
                  dispatch({ type: "createLoading" });
                }}
              >
                <Trans>Save</Trans>
              </Button>
            )}
            <Button
              size="small"
              color="secondary"
              onClick={e => {
                setCreateLogin("");
                dispatch({ type: "createCancel" });
              }}
            >
              <Trans>Abort</Trans>
            </Button>
          </CardActions>
        </Card>
      );

    case "edit":
      return (
        <Card className={classes.root} variant="outlined">
          <CardHeader
            title={contactType}
            //subheader="September 14, 2016"
          />
          <CardContent>
            <GridContainer>
              <GridItem xs={4}>
                <Trans>Login</Trans>
              </GridItem>
              <GridItem xs={8}>
                <Typography>{contact.login}</Typography>
              </GridItem>
              <GridItem xs={4}>
                <Trans>Site_Contact_PhoneNumber</Trans>
              </GridItem>
              <GridItem xs={8}>
                <TextField id="phoneNumber" value={editPhoneNumber} onChange={e => setEditPhoneNumber(e.target.value)} />
              </GridItem>
            </GridContainer>
          </CardContent>
          {isEditable ? (
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={e => {
                  if (editPhoneNumber !== contact.phoneNumber) {
                    let c = { ...contact, phoneNumber: editPhoneNumber };
                    setContact(c);
                    dispatch({ type: "save" });
                  } else {
                    dispatch({ type: "editCancel" });
                  }
                }}
              >
                <Trans>Save</Trans>
              </Button>
              <Button size="small" color="secondary" onClick={e => dispatch({ type: "editCancel" })}>
                <Trans>Abort</Trans>
              </Button>
            </CardActions>
          ) : (
            <></>
          )}
        </Card>
      );

    case "error":
      return <CardError error={context.error} />;
  }
}

const useStyles = makeStyles({
  root: {
    minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});
