import {
  ADD_HISTORY_THIRDPARTY,
  ADD_HISTORY_PRODUCT,
  ADD_HISTORY_SITE,
  ADD_HISTORY_STRUCTURE,
  ADD_HISTORY_JOB,
  REMOVE_HISTORY_THIRDPARTY,
  REMOVE_HISTORY_PRODUCT,
  REMOVE_HISTORY_SITE,
  REMOVE_HISTORY_STRUCTURE,
  REMOVE_HISTORY_JOB
} from "./HistoryActions";
import { isNullOrEmpty } from "../../module/tools";

const initialState = {
  ThirdParties: [],
  Sites: [],
  Structures: []
};

function History(state = initialState, action) {
  switch (action.type) {
    case ADD_HISTORY_THIRDPARTY:
      return {
        ...state,
        ThirdParties: state.ThirdParties.some(
          tp =>
            (action.thirdParty.identifier > 0 && tp.identifier === action.thirdParty.identifier) ||
            (!isNullOrEmpty(action.thirdParty.legalIdentifier) && action.thirdParty.legalIdentifier === tp.legalIdentifier) ||
            (!isNullOrEmpty(action.thirdParty.duns) && action.thirdParty.duns === tp.duns)
        )
          ? state.ThirdParties
          : [...state.ThirdParties, action.thirdParty]
      };
    //return Object.assign({}, state, {
    //    ThirdParties: action.thirdParty
    //});
    case ADD_HISTORY_PRODUCT:
      return Object.assign({}, state, {
        Products: action.product
      });
    case ADD_HISTORY_SITE:
      return {
        ...state,
        Sites: [...state.Sites.filter(s => s.id !== action.site.id), action.site]
      };
    case ADD_HISTORY_STRUCTURE:
      return {
        ...state,
        Structures: [...state.Structures.filter(s => s.id !== action.structure.id), action.structure]
      };
    //return Object.assign({}, state, {
    //    Sites: action.site
    //});
    case ADD_HISTORY_JOB:
      return Object.assign({}, state, {
        Jobs: action.job
      });
    case REMOVE_HISTORY_THIRDPARTY:
      return Object.assign({}, state, {
        ThirdParties: state.ThirdParties.filter(item => item !== action.thirdParty)
      });

    case REMOVE_HISTORY_PRODUCT:
      return Object.assign({}, state, {
        Products: state.Products.filter(item => item !== action.product)
      });
    case REMOVE_HISTORY_SITE:
      return Object.assign({}, state, {
        Sites: state.Sites.filter(item => item !== action.site)
      });
    case REMOVE_HISTORY_STRUCTURE:
      return Object.assign({}, state, {
        Structures: state.Structures.filter(item => item.identifiant !== action.structure.identifiant)
      });
    case REMOVE_HISTORY_JOB:
      return Object.assign({}, state, {
        Jobs: state.Jobs.filter(item => item !== action.job)
      });
    default:
      return state;
  }
}
export default History;
