export const AAD_LOGIN_SUCCESS = "AAD_LOGIN_SUCCESS";
export const AAD_LOGOUT_SUCCESS = "AAD_LOGOUT_SUCCESS";
export const SET_FIRSTLOAD = "SET_FIRSTLOAD";
export const SET_USER_PROFILES = "SET_USER_PROFIL";

export function aadLoginSuccess(payload) {
  return { type: AAD_LOGIN_SUCCESS, payload };
}
export function addLogoutSuccess() {
  return { type: AAD_LOGOUT_SUCCESS };
}
export function setUserProfiles(user) {
  return { type: SET_USER_PROFILES, user };
}
export function setFirstLoad(firstLoad) {
  return { type: SET_FIRSTLOAD, firstLoad };
}
