import { CircularProgress } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import * as Actions from "module/amc/store/actions/Project.Actions";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import JobTableForExternalUse from "../../jobs/JobTableForExternalUse";

function ProjectJobsTab({ amc, searchJobChildrens }) {
  var item = amc.currentItem["PROJECT"];
  var isSearching = amc.searching["JOBCHILD"];
  var jobs = amc.results["JOBCHILD"];

  useEffect(() => {
    searchJobChildrens(item.id);
  }, []);

  var resultComponent = "";
  if (isSearching)
    resultComponent = (
      <center>
        <CircularProgress />
      </center>
    );
  else resultComponent = <JobTableForExternalUse jobs={jobs} />;

  return <div>{resultComponent}</div>;
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchJobChildrens: projectId => {
      dispatch(Actions.SearchJobChildrens(projectId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(ProjectJobsTab));
