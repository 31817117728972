import { Trans } from "@lingui/macro";
import { CircularProgress } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Assignment } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { connect } from "react-redux";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";
import CardError from "./CardError";
import SiteSearch from "./SiteSearch";
import ReactTable from "react-table-6";

const SiteSelect = ({ setLocation, searchCtx, defaultLang }) => {
  var sites = searchCtx.results;
  var searchError = searchCtx.error;
  var isSearching = searchCtx.isSearching;

  const reactTableColumns = [
    {
      accessor: "actions",
      width: 50,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Id</Trans>,
      accessor: "Identifier"
    },
    {
      Header: <Trans>Name</Trans>,
      accessor: "Name",
      Cell: rowInfo => {
        var label = rowInfo.original.Name;
        return <span title={label}>{label}</span>;
      }
    },
    {
      Header: <Trans>AdressLine1</Trans>,
      accessor: "AdresseLine1",
      Cell: rowInfo => {
        var label = rowInfo.original.AdresseLine1;
        return <span title={label}>{label}</span>;
      }
    },
    {
      Header: <Trans>AdressCp</Trans>,
      accessor: "AdresseCp",
      Cell: rowInfo => {
        var label = rowInfo.original.AdresseCp;
        return <span title={label}>{label}</span>;
      }
    },
    {
      Header: <Trans>AdressCity</Trans>,
      accessor: "AdresseCity",
      Cell: rowInfo => {
        var label = rowInfo.original.AdresseCity;
        return <span title={label}>{label}</span>;
      }
    },
    {
      Header: <Trans>AdressCountry</Trans>,
      accessor: "AdresseCountry",
      Cell: rowInfo => {
        var label = rowInfo.original.AdresseCountry;
        return <span title={label}>{label}</span>;
      }
    },
    {
      Header: <Trans>LastUpdateDate</Trans>,
      accessor: "LastUpdateDate",
      Cell: rowInfo => {
        var label = rowInfo.original.LastUpdateDate;
        return <span title={label}>{label}</span>;
      }
    }
  ];

  var searchResult;
  if (searchError) {
    searchResult = <CardError error={searchError} />;
  } else if (isSearching) {
    searchResult = (
      <center>
        <CircularProgress />
      </center>
    );
  } else if (!isNullOrEmpty(sites)) {
    var items = ConvertDatas(sites, setLocation);

    var resultTitle = "";
    var tabContent = <></>;

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    var pageSize = 10;
    if (getPref !== null) {
      pageSize = getPref.Pref.ResultPageSize * 1;
    }

    tabContent = (
      <div className="table-result show">
        <ReactTable
          //manual
          data={items.tabItems}
          //pages={items.pages}
          columns={reactTableColumns}
          defaultPageSize={pageSize}
          showPaginationBottom={true}
          className="-striped -highlight"
          style={ReactTableStyle.main}
        />
      </div>
    );
    resultTitle = <Trans>Table result</Trans>;
    searchResult = (
      <Card>
        <CardBody>{tabContent}</CardBody>
      </Card>
    );
  }

  if (searchResult) {
    return (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {searchResult}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <SiteSearch showSearchCriteriaOnSideBar={true} defaultLang={defaultLang} />
        </GridItem>
      </GridContainer>
    );
  } else {
    return <SiteSearch showSearchCriteriaOnSideBar={false} defaultLang={defaultLang} />;
  }
};

function ConvertDatas(sites, setLocation) {
  if (!sites || isArrayNullOrEmpty(sites)) return [];

  var result = {
    datas: sites,
    tabItems: []
  };
  sites.map((prop, key) => {
    result.tabItems.push({
      Identifier: prop.id,
      Name: prop.name,
      AdresseLine1: prop.address_Line1,
      AdresseCp: prop.address_ZipCode,
      AdresseCity: prop.address_City,
      AdresseCountry: prop.address_CountryCode,
      LastUpdateDate: prop.lastUpdateDate,
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button
            justIcon
            round
            simple
            onClick={() => {
              let item = sites.find(o => o.id !== 0 && o.id === prop.id);
              setLocation(item);
            }}
            color="info"
            className="like"
          >
            <Assignment />
          </Button>{" "}
        </div>
      )
    });
  });

  return result;
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    searchCtx: state.ST_Search,
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(SiteSelect));
