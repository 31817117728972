import { ApimGet, ApimMultiplePost, ApimPost } from "components/Common/ApimSender";
import { HasOneOfRights } from "services/user/UserHelper";
import * as Actions from "./types";

export function GetBeneficiary(thirdPartyId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/Beneficiaries/" + thirdPartyId,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_GET,
          beneficiary: responseJson,
          isEditable: HasOneOfRights(["thirdparty_supplier.edit", "thirdparty_customer.edit"]),
          isEditableClosed: HasOneOfRights(["thirdparty_supplier.edit_closed", "thirdparty_customer.edit_closed"])
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_ERROR,
          error: error
        });
      }
    );
  };
}

export function EditBeneficiary(beneficiary) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_BENEFICIARY_EDITVALUE,
      beneficiary: beneficiary
    });
  };
}

export function SaveBeneficiary(beneficiary, reloadThirdParty) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_BENEFICIARY_SAVE_BEGIN
    });

    ApimPost(
      "api/TpCopernic/Beneficiaries",
      beneficiary,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_SAVE_END,
          successType: reloadThirdParty ? "SUCCESS_RELOAD" : "SUCCESS",
          beneficiary: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_ERROR,
          error: error
        });
      }
    );
  };
}

export function GetSuppliersByBeneficiaryId(beneficiaryId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/Beneficiaries/" + beneficiaryId + "/suppliers",
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_SUPPLIER_GET,
          suppliers: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_ERROR,
          error: error
        });
      }
    );
  };
}

/// ------------------------------------------------------------------------------------------------------
/// SJ ACTIONS
/// ------------------------------------------------------------------------------------------------------
export function BeneficiarySjAdd(sj) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_BENEFICIARY_SJ_ADD,
      sj: sj
    });
  };
}

export function BeneficiarySjUpdate(sj) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_BENEFICIARY_SJ_UPD,
      sj: sj
    });
  };
}

export function BeneficiarySjGet(beneficiaryId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/Beneficiaries/" + beneficiaryId + "/sjs",
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_SJ_GET,
          sjs: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_ERROR,
          error: error
        });
      }
    );
  };
}

export function BeneficiarySjSave(beneficiaryId, sjs) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_BENEFICIARY_SJ_SAVE_BEGIN
    });

    const url = "api/TpCopernic/Beneficiaries/" + beneficiaryId + "/sjs";
    var urlData = sjs
      .filter(sj => sj.action !== "NONE")
      .map(sj => {
        return { url: url, data: sj.sj };
      });

    ApimMultiplePost(
      urlData,
      () => {
        ApimGet(
          url,
          sjs => {
            dispatch({
              type: Actions.TP_COPERNIC_BENEFICIARY_SJ_SAVE_END,
              sjs: sjs
            });
          },
          e =>
            dispatch({
              type: Actions.TP_COPERNIC_BENEFICIARY_ERROR,
              error: e
            })
        );
      },
      e =>
        dispatch({
          type: Actions.TP_COPERNIC_BENEFICIARY_ERROR,
          error: e
        })
    );
  };
}
