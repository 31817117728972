import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import { Table, TableContainer, TableBody, TableHead, TableRow, TableCell, Button, IconButton, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import * as StructureActions from "module/structure/store/actions";
import { addDays, formatISO } from "date-fns";
import { formatDate } from "tools";
import StructureAutocomplete from "components/Autocomplete/StructureAutocomplete";
import { Add, Close, Save, Edit, DeleteOutline } from "@material-ui/icons";
import { isNullOrEmpty } from "../../../../../tools";
import uuidv4 from "uuid/v4"; // Generate GUID
import { extractErrorMessage } from "../../../../tools";
import DateSelector from "../../../../../components/Common/DateSelector";

const TabRelation = ({ structure, setStructure, isEditable, defaultLang, errors }) => {
  if (isNullOrEmpty(defaultLang)) defaultLang = "FR";
  const dispatch = useDispatch();
  const [relationCreate, setRelationCreate] = useState(null);
  const [relationEdit, setRelationEdit] = useState();
  const [parents, setParents] = useState();

  useEffect(() => {
    setParents(
      structure.parents.map(p => {
        return { ...p, localId: uuidv4() };
      })
    );
  }, [structure]);

  if (!parents) return <></>;

  let btnAdd = <></>;
  if (isEditable && structure.identifiant != "COLAS") {
    btnAdd = (
      <Button
        variant="contained"
        startIcon={<Add />}
        size="small"
        onClick={() => setRelationCreate({ error: null, dateStart: formatISO(new Date()) })}
      >
        <Trans>Structure_Relation_Add</Trans>
      </Button>
    );
  }

  let creationRow = <></>;
  if (relationCreate && !relationEdit) {
    creationRow = (
      <TableRow>
        <TableCell>
          <DateSelector
            value={relationCreate.dateStart}
            label={<Trans>Structure_DateStart</Trans>}
            onChange={d => setRelationCreate({ ...relationCreate, dateStart: d })}
            isEditable
            maxDate={relationCreate.dateEnd}
            defaultLang={defaultLang}
          />
        </TableCell>
        <TableCell>
          <StructureAutocomplete
            isEditable
            structureTypes={["DIR", "ERT", "SJ", "UE", "UP", "EP", "AREA", "DIV", "COMP"]}
            value={relationCreate.parentIdentifiant}
            onChange={v =>
              v &&
              setRelationCreate({
                ...relationCreate,
                parentId: v.id,
                parentIdentifiant: v.identifiant,
                parentStructureType: v.structureType,
                parentLibelle: v.libelle
              })
            }
            defaultLang={defaultLang}
            size="small"
            label={<Trans>Structure_SetParent</Trans>}
            error={relationCreate.error}
          />
        </TableCell>
        <TableCell>
          <IconButton
            onClick={() => {
              var n = relationCreate;
              if (isNullOrEmpty(n.parentIdentifiant)) setRelationCreate({ error: true });
              else {
                const endlessDate = new Date(9999, 12, 31);
                setStructure({
                  ...structure,
                  parents: [
                    ...structure.parents.map(p => {
                      if (p.parentStructureType !== "COMP" && p.parentStructureType === n.parentStructureType) {
                        const pDateEnd = p.dateEnd ?? endlessDate;
                        const nDateEnd = n.dateEnd ?? endlessDate;

                        if (p.dateStart > n.dateStart && pDateEnd < nDateEnd) {
                          p.dateEnd = formatISO(addDays(new Date(n.dateStart), -1));
                        } else if (p.dateStart < n.dateStart && pDateEnd >= nDateEnd) {
                          p.dateEnd = formatISO(addDays(new Date(n.dateStart), -1));
                        }
                      }
                      return p;
                    }),
                    {
                      dateStart: n.dateStart,
                      dateEnd: null,
                      parentId: n.parentId,
                      parentIdentifiant: n.parentIdentifiant,
                      parentStructureType: n.parentStructureType,
                      parentLibelle: n.parentLibelle
                    }
                  ]
                });
                setRelationCreate(null);
              }
            }}
          >
            <Save />
          </IconButton>
          <IconButton onClick={() => setRelationCreate(null)}>
            <Close />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }
  const handleStartDate = (parent, date) => {
    let modifiedParents = parents;
    modifiedParents.map(p => {
      if (p.localId === parent.localId) {
        p.dateStart = date;
        setRelationEdit({ ...relationEdit, dateStart: date });
        return p;
      }
    });
  };

  const handleEndDate = (parent, date) => {
    let modifiedParents = parents;
    modifiedParents.map(p => {
      if (p.localId === parent.localId) {
        p.dateEnd = date;
        setRelationEdit({ ...relationEdit, dateEnd: date });
        return p;
      }
    });
  };
  let bannerError = <></>;
  let errorRelation = extractErrorMessage(errors, "RELATION", defaultLang);
  if (errorRelation) {
    bannerError = <Alert severity="error">{errorRelation}</Alert>;
  }

  return (
    <>
      {btnAdd}
      {bannerError}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Trans>Structure_Relation_Dates</Trans>
              </TableCell>
              <TableCell>
                <Trans>Structure_Relation_Parent</Trans>
              </TableCell>
              {isEditable ? <TableCell>&nbsp;</TableCell> : <></>}
            </TableRow>
          </TableHead>
          <TableBody>
            {creationRow}
            {parents
              .sort((a, b) => {
                if (a.dateStart < b.dateStart) return -1;
                else if (a.dateStart > b.dateStart) return 1;
                return 0;
              })
              .map((parent, key) => {
                if (relationEdit && relationEdit?.localId === parent.localId) {
                  return (
                    <TableRow>
                      <TableCell>
                        <DateSelector
                          value={relationEdit.dateStart}
                          label={<Trans>Structure_DateStart</Trans>}
                          onChange={d => handleStartDate(parent, d)}
                          isEditable
                          maxDate={relationEdit.dateEnd}
                          defaultLang={defaultLang}
                        />
                        <DateSelector
                          value={relationEdit.dateEnd}
                          label={<Trans>Structure_DateEnd</Trans>}
                          onChange={d => handleEndDate(parent, d)}
                          isEditable
                          minDate={relationEdit.dateStart}
                          defaultLang={defaultLang}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {parent.parentIdentifiant} - {parent.parentLibelle}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => {
                            setStructure({
                              ...structure,
                              parents: [...parents.filter(p => p.localId !== parent.localId), parent]
                            });
                            setRelationEdit(null);
                          }}
                        >
                          <Save />
                        </IconButton>
                        <IconButton onClick={() => setRelationEdit()}>
                          <Close />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }

                return (
                  <TableRow>
                    <TableCell>
                      {formatDate(parent.dateStart, defaultLang)} -&gt; {formatDate(parent.dateEnd, defaultLang)}
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => dispatch(StructureActions.OpenStructure(parent.parentStructureType, parent.parentIdentifiant))}>
                        {parent.parentIdentifiant} - {parent.parentLibelle}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {!isEditable ? (
                        <></>
                      ) : (
                        <>
                          <IconButton onClick={() => setRelationEdit(parent)}>
                            <Edit />
                          </IconButton>
                          {parents.length > 1 && (
                            <IconButton
                              onClick={() =>
                                setStructure({
                                  ...structure,
                                  parents: parents.filter(p => p.localId !== parent.localId)
                                })
                              }
                            >
                              <DeleteOutline />
                            </IconButton>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStyles(structureStyle)(TabRelation);
