import { Trans } from "@lingui/macro";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Add, Assignment, Check } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import MigCodeDetail from "module/tpCopernic/components/MigCodeMonitoring/MigCodeDetail";
import MigCodeSearchCriteria from "module/tpCopernic/components/MigCodeMonitoring/MigCodeSearchCriteria";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import ReactTable from "react-table-6";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import * as Actions from "../store/actions/MigCode.Actions";
import { TP_COPERNIC_MIGCODE_GET_DETAIL } from "../store/actions/types";

function CodeMig({ migCodeCtx, getDetail, classes }) {
  const dispatch = useDispatch();

  const [criterias, setCriterias] = useState(migCodeCtx.criterias);
  const [item, setItem] = useState(migCodeCtx.item);
  var result = migCodeCtx.result;

  const reactTableColumns = [
    {
      filterable: false,
      sorting: false,
      accessor: "actions",
      width: 50
    },
    {
      Header: <Trans>Identifier</Trans>,
      accessor: "identifier"
    },
    {
      Header: <Trans>Label EN</Trans>,
      accessor: "label_EN"
    },
    {
      Header: <Trans>Label FR</Trans>,
      accessor: "label_FR"
    },
    {
      Header: <Trans>Sj</Trans>,
      accessor: "sjIdentifier"
    },
    {
      Header: <Trans>Payment Period</Trans>,
      accessor: "paymentPeriodCode"
    },
    {
      Header: <Trans>Payment Method</Trans>,
      accessor: "paymentMethodCode"
    },
    {
      Header: <Trans>Third party category</Trans>,
      accessor: "thirdPartyCategoryCode"
    },
    {
      Header: <Trans>Is Colas</Trans>,
      accessor: "isColas",
      Cell: rowInfo => {
        var retour = "";
        if (rowInfo.original.isColas !== null && rowInfo.original.isColas)
          retour = (
            <div>
              <Check />
            </div>
          );
        return retour;
      }
    },
    {
      Header: <Trans>Is Bouygues</Trans>,
      accessor: "isBouygues",
      Cell: rowInfo => {
        var retour = "";
        if (rowInfo.original.isBouygues !== null && rowInfo.original.isBouygues)
          retour = (
            <div>
              <Check />
            </div>
          );
        return retour;
      }
    },
    {
      Header: <Trans>Is principal shareholder</Trans>,
      accessor: "isPrincipalShareHolder",
      Cell: rowInfo => {
        var retour = "";
        if (rowInfo.original.isPrincipalShareHolder !== null && rowInfo.original.isPrincipalShareHolder)
          retour = (
            <div>
              <Check />
            </div>
          );
        return retour;
      }
    }
  ];

  var reactTable = "";
  if (!!result) {
    var items = ConvertDatas(result.items, getDetail);

    reactTable = (
      <Card>
        <CardBody>
          <ReactTable
            manual
            data={items}
            page={result.pageIndex}
            pages={result.nbOfPages}
            columns={reactTableColumns}
            sorted={[{ id: migCodeCtx.criterias.orderProperty, desc: migCodeCtx.criterias.isDescendingOrder }]}
            onPageChange={(state, instance) => {
              var tempCriteria = migCodeCtx.criterias;
              tempCriteria.startPage = state;
              setCriterias({ ...migCodeCtx.criterias, startPage: state });
              dispatch(Actions.SearchMigCode(tempCriteria));
            }}
            onPageSizeChange={(state, instance) => {
              var tempCriteria = migCodeCtx.criterias;
              tempCriteria.pageSize = state;
              setCriterias({ ...migCodeCtx.criterias, pageSize: state });
              dispatch(Actions.SearchMigCode(tempCriteria));
            }}
            onSortedChange={(newSorted, column, shiftKey) => {
              var tempCriteria = migCodeCtx.criterias;
              tempCriteria.orderProperty = newSorted[0].id;
              tempCriteria.isDescendingOrder = newSorted[0].desc;
              setCriterias({ ...migCodeCtx.criterias, orderProperty: newSorted[0].id, isDescendingOrder: newSorted[0].desc });
              dispatch(Actions.SearchMigCode(tempCriteria));
            }}
            defaultPageSize={result.pageSize}
            showPaginationBottom={true}
            className="-striped -highlight"
            style={ReactTableStyle.main}
          />
        </CardBody>
      </Card>
    );
  }

  return (
    <div>
      <MigCodeSearchCriteria />
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <Button variant="contained" size="sm" onClick={() => getDetail({}, true)} color="info">
            <Add />
            <Trans>New Mig</Trans>
          </Button>
        </GridItem>
      </GridContainer>
      {reactTable}
      <MigCodeDetail />
    </div>
  );
}

///-----------------------------------------------------------
/// Convertit le résultat d'une recherche en item pour react-table
///-----------------------------------------------------------
function ConvertDatas(items, getDetail) {
  if (!items || items.length === 0) return [];

  var result = [];
  items.map((prop, key) => {
    result.push({
      actions: (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button
            justIcon
            round
            simple
            onClick={() => {
              getDetail(prop, false);
            }}
            color="info"
            className="like"
          >
            <Assignment />
          </Button>
        </div>
      ),
      ...prop
    });
  });

  return result;
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    migCodeCtx: state.TP_MigCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDetail: (item, isNew) => {
      dispatch({
        type: TP_COPERNIC_MIGCODE_GET_DETAIL,
        item: item,
        isNew: isNew
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(CodeMig));
