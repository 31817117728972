import { i18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import { Checkbox, Divider, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { CommonCurrency, CommonLanguage } from "store/MasterValue/MasterValueTypes";

function PartGeneral({ country, setCountry, defaultLang, classes }) {
  const fieldLanguages = Object.entries(country.labels).map(l => {
    let key = l[0];
    let value = l[1];
    return (
      <Grid container>
        <Grid item xs={6} sm={6} md={6}>
          <label className={classes.LabelSize}>{key}</label>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextField
            value={value}
            onChange={e => setCountry({ ...country, labels: { ...country.labels, [key]: e.target.value } })}
            margin="none"
            type="text"
            fullWidth
          />
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid container>
      <Grid item xs={8} sm={8} md={8}>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              {" "}
              <Trans>Label</Trans>
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Typography>
              <ReactCountryFlag countryCode={country.codeIso2} svg /> {country.currentLabel}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              {" "}
              <Trans>Code Iso2</Trans>
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Typography>{country.codeIso2}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              {" "}
              <Trans>Code Iso3</Trans>
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Typography>{country.codeIso3}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              {" "}
              <Trans>Phone Code</Trans>
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <Typography>{country.defaultPhoneCode}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              {" "}
              <Trans>Currency</Trans>
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={CommonCurrency}
              selectedValue={country.currencyCode}
              onValueChange={v => setCountry({ ...country, currencyCode: v })}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              {" "}
              <Trans>Language</Trans>
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={CommonLanguage}
              selectedValue={country.languageCode}
              onValueChange={v => setCountry({ ...country, languageCode: v })}
              size="small"
            />
          </Grid>
        </Grid>
        <Divider variant="middle" style={{ margin: "20px 0" }} />
        {fieldLanguages}
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
        <FormControlLabel control={<Checkbox readOnly checked={country.isEnabled} margin="none" />} label={i18n._(t` Is Enabled`)} />
        <FormControlLabel
          control={
            <Checkbox checked={country.isMemberOfUe} onClick={e => setCountry({ ...country, isMemberOfUe: !country.isMemberOfUe })} margin="none" />
          }
          label={i18n._(t` Is member of UE`)}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(adminStyle)(PartGeneral);
