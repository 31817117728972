import { Trans } from "@lingui/macro";
import { Checkbox, FormControlLabel, FormLabel, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Check, Lock, LockOpen } from "@material-ui/icons";
import logoBouygues from "assets/img/thirdPartyCategories/logo_bouygues.svg";
import logoColas from "assets/img/thirdPartyCategories/logo_colas.png";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import FieldText from "components/Common/FieldText";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { displayWarning } from "module/tools";
import GroupSelect from "module/tpColumbus/groups/GroupSelect";
import React, { useEffect, useState } from "react";
import { HasRight } from "services/user/UserHelper";
import { CommonCountry, CommonLanguage, TpIndustryCode, TpThirdPartyCategory } from "store/MasterValue/MasterValueTypes";
import { emptyIfNull, isNull } from "tools";

const TabThirdPartyGeneral = function({ thirdParty, setThirdParty, isEditable, masterValues, defaultLang, classes, isGroupEditable }) {
  const [industryCodeToDisplay, setIndustryCodeToDisplay] = useState([]);
  useEffect(() => {
    let country = masterValues[CommonCountry].find(c => c.codeIso2 === thirdParty.address_CountryCode);

    let industryCodes = thirdParty.thirdParty_ActivitySectors ?? [];
    if (country && !industryCodes.some(code => code.key === country.codeIndustryCode)) {
      industryCodes = [
        ...industryCodes,
        {
          key: country.codeIndustryCode
        }
      ];
    }
    setIndustryCodeToDisplay(industryCodes);
  }, [thirdParty.address_CountryCode]);

  var isCurrentUserAdmin = false;
  if (HasRight("application.edit")) isCurrentUserAdmin = true;

  var tpCategory = thirdParty.thirdPartyCategoryCode;
  var tpCategoryField = "";

  if (isEditable) {
    tpCategoryField = (
      <MasterValueAutocomplete
        isEditable
        isRequired
        mvType={TpThirdPartyCategory}
        selectedValue={thirdParty.thirdPartyCategoryCode}
        onValueChange={value => setThirdParty({ ...thirdParty, thirdPartyCategoryCode: value })}
      />
    );
  } else {
    if (tpCategory === "BOU")
      tpCategoryField = (
        <span>
          <img src={logoBouygues} width="60px" alt="Bouygues" /> {GetMasterValueLabel(thirdParty.thirdPartyCategory, defaultLang)}
        </span>
      );
    else if (tpCategory === "COL")
      tpCategoryField = (
        <span>
          <img src={logoColas} width="60px" alt="Colas" /> {GetMasterValueLabel(thirdParty.thirdPartyCategory, defaultLang)}
        </span>
      );
    else if (thirdParty.thirdPartyCategoryCode) {
      let tpCat = masterValues[TpThirdPartyCategory].find(t => t.code === thirdParty.thirdPartyCategoryCode);
      tpCategoryField = <span>{GetMasterValueLabel(tpCat, defaultLang)}</span>;
    }
  }

  const industryCodes = industryCodeToDisplay.map((item, key) => {
    var masterValue = masterValues[TpIndustryCode].find(m => m.code === item.key);
    if (isNull(masterValue)) return "";

    var label = GetMasterValueLabel(masterValue, defaultLang);
    return (
      <div key={key}>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <Tooltip title={label}>
              <FormLabel>{item.key}</FormLabel>
            </Tooltip>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            {item.value}
          </GridItem>
        </GridContainer>
      </div>
    );
  });

  var statusIcon = <LockOpen style={{ color: "green" }} />;
  if (thirdParty.thirdPartyStatusCode !== null && thirdParty.thirdPartyStatusCode === "Closed") statusIcon = <Lock style={{ color: "red" }} />;

  return (
    <div>
      <GridContainer>
        <GridItem xs={7} sm={7} md={7}>
          <Card
            style={{
              ...CardInlineStyle.card
            }}
          >
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <h4>
                <Trans>General informations</Trans>
                {statusIcon}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>RS Colas</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {displayWarning(thirdParty.warnings, "General/LongName")}
                  {/* Ce champ ne peut être édité car dépend de la compagnie */}
                  <FieldText
                    isEditable={isEditable}
                    id="longName"
                    value={thirdParty.customCompanyLongName}
                    onChange={e => {
                      var value = e.target.value;
                      if (value.length > 40) value = value.substring(0, 40);
                      setThirdParty({ ...thirdParty, customCompanyLongName: value });
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>NC Colas</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <FieldText
                    isEditable={isEditable}
                    id="longNameColas"
                    value={emptyIfNull(thirdParty.customLongName)}
                    onChange={e => {
                      var value = e.target.value;
                      if (value.length > 40) value = value.substring(0, 40);
                      setThirdParty({ ...thirdParty, customLongName: value });
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Group</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <GroupSelect
                    groupId={thirdParty.groupId}
                    setGroupId={gId => setThirdParty({ ...thirdParty, groupId: gId })}
                    isEditable={isEditable | isGroupEditable}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  {displayWarning(thirdParty.warnings, "General/IsHeadQuarter")}
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot
                        }}
                        checked={thirdParty.isHeadQuarter}
                        disabled={!isEditable}
                        onChange={e => setThirdParty({ ...thirdParty, isHeadQuarter: e.target.checked })}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={<Trans>HeadQuarter</Trans>}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Mark</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <FieldText
                    isEditable={isEditable}
                    id="mark"
                    value={emptyIfNull(thirdParty.mark)}
                    onChange={e => setThirdParty({ ...thirdParty, mark: e.target.value })}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Country</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <CountryAutocomplete
                    countryCode={thirdParty.countryCode}
                    onChange={codeIso2 => {
                      if (thirdParty.countryCode !== codeIso2 && (thirdParty.countryCode === "FR" || codeIso2 === "FR"))
                        setThirdParty({ ...thirdParty, address_ZipCode: null, address_City: null, countryCode: codeIso2 });
                      else setThirdParty({ ...thirdParty, countryCode: codeIso2 });
                    }}
                    defaultLang={defaultLang}
                    isEditable={isEditable}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Language</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <MasterValueAutocomplete
                    isEditable={isEditable}
                    isRequired
                    mvType={CommonLanguage}
                    selectedValue={thirdParty.languageCode}
                    onValueChange={value => setThirdParty({ ...thirdParty, languageCode: value })}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card>
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <h4>
                <Trans>Company informations</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>MIG</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  {/* A transformer en composant dédié */}
                  <FieldText
                    isEditable={false}
                    id="mig"
                    value={emptyIfNull(thirdParty.migCode)}
                    onChange={e => setThirdParty({ ...thirdParty, migCode: e.target.value })}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={5} sm={5} md={5}>
          <Card
            style={{
              ...CardInlineStyle.card
            }}
          >
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <h4>
                <Trans>Legal identifiers</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>ThirdPartyCategory</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      {tpCategoryField}
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Source</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}></GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Legal Identifier</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <FieldText
                        isEditable={false}
                        id="legalIdentifier"
                        value={emptyIfNull(thirdParty.legalIdentifier)}
                        onChange={e => setThirdParty({ ...thirdParty, legalIdentifier: e.target.value })}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Tax Code</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      {displayWarning(thirdParty.warnings, "General/TaxCode")}
                      <FieldText
                        isEditable={false}
                        id="taxCode"
                        value={emptyIfNull(thirdParty.taxCode)}
                        onChange={e => setThirdParty({ ...thirdParty, taxCode: e.target.value })}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>Additional Identifier</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      {displayWarning(thirdParty.warnings, "General/AdditionalIdentifier")}
                      <FieldText
                        isEditable={false}
                        id="addtionalIdentifier"
                        value={emptyIfNull(thirdParty.addtionalIdentifier)}
                        onChange={e => setThirdParty({ ...thirdParty, addtionalIdentifier: e.target.value })}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                      <FormLabel>
                        <Trans>DUNS</Trans>
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={8} sm={8} md={8}>
                      <FieldText
                        isEditable={
                          isEditable && (!thirdParty.thirdPartyCategoryCode || thirdParty.thirdPartyCategoryCode !== "PAR") && isCurrentUserAdmin
                        }
                        id="duns"
                        value={emptyIfNull(thirdParty.duns)}
                        onChange={e => setThirdParty({ ...thirdParty, duns: e.target.value })}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <Card
            style={{
              ...CardInlineStyle.card
            }}
          >
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <h4>
                <Trans>Industry code</Trans>
              </h4>
            </CardHeader>
            <CardBody>{industryCodes}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabThirdPartyGeneral);
