import React from "react";
import imgMaintenance from "../../assets/images/maintenance.jpg";
import { Trans } from "@lingui/macro";

export default function AuthenticationMaintenance() {
  return (
    <div style={{ textAlign: "center" }}>
      <div>
        <img src={imgMaintenance} alt="maintenance" />
      </div>
      <h1>
        <Trans> Hubble_Maintenance </Trans>
      </h1>
    </div>
  );
}
