import React, { useEffect, useState, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import throttle from 'lodash/throttle';

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { highlightText } from '../../module/tools';
import { StructureSearch } from '../../module/structure/actions/StructureActions';
import { ErrorOutlined, Lock, LockOpen } from '@material-ui/icons';
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from '../../tools';
import { InputAdornment, makeStyles, Tooltip, Typography } from '@material-ui/core';

function StructureAutocomplete({
    isEditable,
    structureTypes,
    value,
    onChange,
    label,
    error,
    showErrorTooltip,
    width,
    size }) {
    const initialSelectedValue = { text: '' };
    const [selectedValue, setSelectedValue] = useState(initialSelectedValue);
    const [options, setOptions] = useState([]);

    const fetch = useMemo(
        () =>
            throttle((keyword, callback) => {
                search(keyword, structureTypes, callback);
            }, 200),
        [selectedValue]
    );

    useEffect(() => {
        if ((!selectedValue || selectedValue.text === '') && value) {
            StructureSearch(
                { structureId: value, structureTypes: structureTypes },
                r => setSelectedValue(isArrayNullOrEmpty(r) ? initialSelectedValue : { ...r[0], text: r[0].identifiant + ' - ' + r[0].libelle })
            );
        }
    }, [value]);

    useEffect(() => {
        let active = true;

        if ((value && value.id === selectedValue.id) || selectedValue.text.length < 3) {
            setOptions(selectedValue.type ? [selectedValue] : []);
            return undefined;
        }

        fetch(selectedValue.text, (results) => {
            if (active) {
                let newOptions = [];

                if (selectedValue) {
                    newOptions = [selectedValue];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [selectedValue]);

    if (isNull(isEditable)) {
        isEditable = true;
    }

    const useStyles = makeStyles(theme => (size === 'small' ? {
        inputRoot: {
            fontSize: '14px'
        }
    } : {}));

    const classes = useStyles(size);

    if (isEditable) {
        let inputProps = {};
        if (error && showErrorTooltip) {
            inputProps = {
                startAdornment: (
                    <InputAdornment position="start">
                        <Tooltip title={error}>
                            <ErrorOutlined style={{ color: 'red' }} />
                        </Tooltip>
                    </InputAdornment>
                )
            };
        }

        return (
            <Autocomplete
                classes={classes}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.text)}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                style={{width: width}}
                value={selectedValue}
                onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setSelectedValue(newValue ? newValue : initialSelectedValue);
                    onChange(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setSelectedValue({ ...selectedValue, text: newInputValue });
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{...params.InputProps, ...inputProps}}
                        margin={size === 'small' ? "none" : "normal"}
                        label={!!label ? label : null}
                        fullWidth
                        error={error}
                    />
                )}
                renderOption={(option) => {
                    const parts = highlightText(option.text, selectedValue.text);

                    return (
                        <Grid container alignItems="center">
                            <Grid item>
                                {/*<LocationOnIcon className={classes.icon} />*/}
                                {option.isActive ? <LockOpen style={{ color: 'green' }} /> : <Lock style={{ color: 'red' }} />}
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}

                                {/*<Typography variant="body2" color="textSecondary">
                                Second text
                            </Typography>*/}
                            </Grid>
                        </Grid>
                    );
                }}
            />
        );
    }

    let toReturn;
    if (!selectedValue || isNullOrEmpty(selectedValue.text))
        toReturn = 'Ø';
    else
        toReturn = selectedValue.text;

    if (error && showErrorTooltip) {
        return <Tooltip title={error}>
            <Typography>
                <ErrorOutlined style={{ color: 'red' }} />
                {toReturn}
            </Typography>
        </Tooltip>
    }
    return <Typography>{toReturn}</Typography>
}

function search(keyword, structureTypes, callback) {
    if (!keyword || keyword.length < 2)
        return [];

    StructureSearch(
        { freesearch: keyword, structureTypes: structureTypes },
        r => callback(r.map(s => { return { ...s, text: s.identifiant + ' - ' + s.libelle }; }))
    );
}

export default withStyles(thirdPartySearchCriteriaStyle)(StructureAutocomplete);