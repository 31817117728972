import { Trans } from "@lingui/macro";
import { CircularProgress, Grid, SnackbarContent } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { AddAlert } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import CardError from "components/Card/CardError";
import { AntTab, AntTabs, TabPanel } from "components/Common/Tabs";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { HasRight } from "services/user/UserHelper";
import { isNull } from "tools";
import { SupplierGet } from "../../actions/Supplier.Actions";
import TabSupplierAddress from "./TabSupplierAddress";
import TabSupplierGeneral from "./TabSupplierGeneral";
import TabSupplierHistory from "./TabSupplierHistory";
import TabSupplierSj from "./TabSupplierSj";

const TabSupplier = ({ thirdParty, setThirdParty, defaultLang, classes }) => {
  const [tabIdx, setTabIdx] = useState(0);
  const [backup, setBackup] = useState(null);
  const [error, setError] = useState(null);

  let supplier = thirdParty.supplier;
  let setSupplier = s => {
    const { hasChanged, ...su } = s;
    setThirdParty({ ...thirdParty, supplier: { ...s, hasChanged: !backup ? false : JSON.stringify(su) !== backup } });
  };

  useEffect(() => {
    if (supplier && !backup) {
      let { hasChanged, ...s } = supplier;
      setBackup(JSON.stringify(s));
    }
  }, [supplier, backup]);

  if (error) {
    return <CardError error={error} />;
  }

  if (isNull(thirdParty.supplier?.thirdPartyId)) {
    var innerElement;
    if (!backup && thirdParty.identifier > 0) {
      SupplierGet(thirdParty.identifier)
        .then(s => {
          setSupplier(s);
        })
        .catch(e => setError(e));
      innerElement = <CircularProgress />;
    } else if (thirdParty.thirdPartyStatusCode !== "Closed" && HasRight("tp_columbus.edit")) {
      innerElement = (
        <Button
          type="submit"
          color="info"
          className={classes.registerButton}
          onClick={() =>
            setThirdParty({
              ...thirdParty,
              supplier: { hasChanged: true, thirdPartyId: thirdParty.identifier, scopeCode: "REG", supplierSubsidiaries: [] }
            })
          }
        >
          <Trans> Transform ThirdParty into Supplier </Trans>
        </Button>
      );
    } else {
      innerElement = (
        <SnackbarContent message={<Trans>You don't have rights to transform this third party into a supplier.</Trans>} icon={AddAlert} color="info" />
      );
    }

    return (
      <Grid style={GridContainerStyle.mainGrid} container>
        <Grid item xs={12}>
          <Grid container justify="center">
            {innerElement}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  var isEditable = HasRight("tp_columbus.edit");
  var canAddSj = HasRight("thirdparty_supplier.add_sj");
  return (
    <div className="divContainer">
      <form autoComplete="false">
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs supplier"
            >
              <AntTab label={<Trans>General</Trans>} />
              <AntTab label={<Trans>Address</Trans>} />
              <AntTab label={<Trans>Sjs linked</Trans>} />
              <AntTab label={<Trans>History</Trans>} />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <TabSupplierGeneral supplier={supplier} setSupplier={setSupplier} isEditable={isEditable} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <TabSupplierAddress supplier={supplier} setSupplier={setSupplier} isEditable={isEditable} defaultLang={defaultLang} />
            </TabPanel>
            <TabPanel value={tabIdx} index={2}>
              <TabSupplierSj supplier={supplier} canAddSj={isEditable} setSupplier={setSupplier} defaultLang={defaultLang} />
            </TabPanel>
            <TabPanel value={tabIdx} index={3}>
              <TabSupplierHistory supplier={supplier} setSupplier={setSupplier} defaultLang={defaultLang} />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
};

const GridContainerStyle = {
  mainGrid: {
    marginBottom: "40px",
    marginTop: "40px"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabSupplier);
