import { Trans } from "@lingui/macro";
import { CircularProgress, withStyles } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import CardError from "components/Card/CardError";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { HasRight } from "services/user/UserHelper";
import { isNullOrEmpty } from "tools";
import { OpenThirdParty } from "../store/actions";
import { ThirdPartySearch } from "./actions/ThirdPartyActions";
import SearchCriterias from "./part/search/SearchCriterias";
import SearchResult from "./part/search/SearchResult";

const ThirdPartyListing = ({ userInfo, openThirdParty, masterValues }) => {
  const initialCriteria = { searchMode: "Simple" };

  const [criteria, setCriteria] = useState(initialCriteria);
  const [state, setState] = useState({ isLoading: false });

  const cleanSearch = () => {
    setCriteria({ ...initialCriteria, criteriaCountry: userInfo.country });
  };

  useEffect(() => {
    var newCriteria = criteria;

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    if (isNullOrEmpty(criteria.pageSize)) {
      var defaultPageSize = 10;
      if (getPref !== null) {
        defaultPageSize = getPref.Pref.ResultPageSize * 1;
      }

      newCriteria = {
        ...newCriteria,
        criteriaCountry: userInfo.country,
        pageSize: defaultPageSize
      };
    }

    setCriteria(newCriteria);
  }, []);

  const launchSearch = () => {
    setCriteria(criteria);
    setState({ ...state, isLoading: true });
    ThirdPartySearch(
      criteria,
      results => setState({ isLoading: false, searchResults: results }),
      error => setState({ isLoading: false, error: error })
    );
  };

  var searchResult = null;
  if (state.isLoading) {
    searchResult = <CircularProgress />;
  } else if (state.error) {
    searchResult = <CardError error={state.error} />;
  } else if (state.searchResults) {
    searchResult = (
      <SearchResult
        results={state.searchResults}
        criteria={criteria}
        launchSearch={launchSearch}
        setThirdParty={tp => {
          openThirdParty(tp.identifier);
        }}
      />
    );
  }

  var layoutSearchResult;
  if (!isNullOrEmpty(searchResult)) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {searchResult}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <SearchCriterias
            criteria={criteria}
            setCriteria={setCriteria}
            launchSearch={launchSearch}
            cleanSearch={cleanSearch}
            showSearchCriteriaOnSideBar={true}
            defaultLang={userInfo.language}
            masterValues={masterValues}
          />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = (
      <SearchCriterias
        criteria={criteria}
        setCriteria={setCriteria}
        launchSearch={launchSearch}
        cleanSearch={cleanSearch}
        showSearchCriteriaOnSideBar={false}
        defaultLang={userInfo.language}
        masterValues={masterValues}
      />
    );
  }

  var buttonCreationContent = "";
  if (HasRight("tp_columbus.edit")) {
    buttonCreationContent = (
      <Button variant="contained" size="sm" onClick={() => openThirdParty(0)} color="info">
        <Add />
        <Trans>Columbus_Create_ThirdParty</Trans>
      </Button>
    );
  }

  return (
    <>
      <div>{buttonCreationContent}</div>
      <div>{layoutSearchResult}</div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    userInfo: state.AuthenticationReducer.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openThirdParty: tpId => {
      dispatch(OpenThirdParty(tpId, null));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyListing));
