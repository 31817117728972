import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import ComplexSearchCriteria from "components/Common/ComplexSearchCriteria";
import Button from "components/CustomButtons/Button.jsx";
import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { StructureUe } from "store/MasterValue/MasterValueTypes";

function CustomerContractSearchCriteriaComplex({ amc, structureUes, setCriterias, search, classes }) {
  const dispatch = useDispatch();

  var ues = structureUes.map((ue, index) => {
    return { value: ue.identifier, title: ue.identifier + " - " + ue.label };
  });

  const fields = {
    "cc.Identifier": {
      label: "Customer Contract Id",
      type: "number",
      fieldSettings: {
        min: 0
      },
      valueSources: ["value"],
      preferWidgets: ["number"]
    },
    "cc.ErpId": {
      label: "Customer Contract Erp Id",
      type: "number",
      fieldSettings: {
        min: 0
      },
      valueSources: ["value"],
      preferWidgets: ["number"]
    },
    "cc.label": {
      label: "Customer Contract Label",
      type: "text",
      valueSources: ["value"],
      preferWidgets: ["text"]
    },
    "cc.ueIdentifier": {
      label: "Customer Contract Ue",
      type: "select",
      valueSources: ["value"],
      fieldSettings: {
        listValues: ues
      }
    }
  };

  var queryValue = amc.complexQuery["CUSTOMERCONTRACT"];

  function SearchChange(tree, jsonCriterias) {
    setCriterias(tree, jsonCriterias);
  }

  return (
    <>
      <ComplexSearchCriteria fields={fields} queryValue={queryValue} onChange={SearchChange} />
      <div className={classes.flexRight}>
        <Button type="submit" color="info" onClick={search}>
          <Trans> Search </Trans>
        </Button>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    structureUes: state.MasterValueReducer[StructureUe]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: (complexQuery, criterias) => {
      dispatch(Actions.SetComplexCriterias(complexQuery, criterias));
    },
    search: () => {
      dispatch(Actions.ComplexSearch());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(CustomerContractSearchCriteriaComplex));
