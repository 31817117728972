import { Trans } from "@lingui/macro";
import { CircularProgress, Grid, SnackbarContent, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { AddAlert, CheckCircleOutlined, ErrorOutlined } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import { AntTab, AntTabs, TabPanel } from "components/Common/Tabs";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import { isArrayNullOrEmpty, isNull } from "../../tools";
import * as Actions from "../store/actions";
import CardError from "components/Card/CardError";
import { Error, Warning } from "@material-ui/icons";
import ArrowForward from "@material-ui/icons/ArrowForward";
import LinkMaterialUi from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import ComponentCoupa from "./ThirdPartyDetail/ComponentCoupa";
import TabSupplierAddress from "./ThirdPartyDetail/TabSupplierAddress";
import TabSupplierGeneral from "./ThirdPartyDetail/TabSupplierGeneral";
import TabSupplierHistory from "./ThirdPartyDetail/TabSupplierHistory";
import TabSupplierPayment from "./ThirdPartyDetail/TabSupplierPayment";
import TabSupplierPointOfSales from "./ThirdPartyDetail/TabSupplierPointOfSales";
import TabSupplierSj from "./ThirdPartyDetail/TabSupplierSj";
import { ThirdPartyValidate, ThirdPartyCrupdate } from "../thirdParties/actions/ThirdPartyActions.js";
import { OpenThirdParty } from "../store/actions/index.jsx";

function ThirdPartySupplierDatas({ thirdPartyCtx, supplierCtx, transformIntoSupplier, defaultLang, classes }) {
  var dispatch = useDispatch();
  var thirdParty = thirdPartyCtx.thirdParty;
  const [tabIdx, setTabIdx] = useState(0);
  const [errors, setErrors] = useState([]);
  const infoNonDiffusable = "INFORMATION NON DIFFUSIBLE";

  function validateThirdParty() {
    ThirdPartyValidate(
      thirdParty,
      "thirdPartyTransform",
      errors => {
        if (!isArrayNullOrEmpty(errors)) {
          setErrors(errors);
        } else {
          transformIntoSupplier({ thirdParty });
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  function mergeThirdPartiesAndReload(foundThirdParty) {
    if (foundThirdParty.identifier !== null
      && foundThirdParty.identifier !== undefined
      && foundThirdParty.identifier > 0) {
        
      if (foundThirdParty.duns === null && thirdParty.duns !== null) {
          foundThirdParty.duns = thirdParty.duns;

          ThirdPartyCrupdate(
            foundThirdParty,
            tp => {
              dispatch(Actions.CloseThirdParty());
              dispatch(OpenThirdParty(tp.identifier));
            },
            error => console.log(error)
          );
      }
    }
  }

  var coupaIcon = "";
  if (!isNull(supplierCtx)) {
    if (!isNull(supplierCtx.error)) {
      if (!supplierCtx.error?.response?.data || isArrayNullOrEmpty(supplierCtx.error.response.data) || (!isArrayNullOrEmpty(supplierCtx.error.response.data) && supplierCtx.error.response.data.some(e => e.code === "Unknown"))) {
        return <CardError error={supplierCtx.error} />;
      } else if (!supplierCtx.error?.response?.data || isArrayNullOrEmpty(supplierCtx.error.response.data) || (!isArrayNullOrEmpty(supplierCtx.error.response.data) && supplierCtx.error.response.data.some(e => e.code === "Duns"))) {
        return <CardError error={supplierCtx.error} />;
      } else if (isArrayNullOrEmpty(supplierCtx.error.response.data)) {
        var errorData = supplierCtx.error.response.data;
        if (errorData.some(e => e.code.startsWith("COUPA_"))) {
          coupaIcon = <ErrorOutlined style={{ color: "red" }} />;
        }
      }
    }

    if (supplierCtx.saveStatus === "PENDING")
      return (
        <center>
          <p>
            <Trans>Loading in progress</Trans>
          </p>
          <p>
            <CircularProgress />
          </p>
        </center>
      );
    if (supplierCtx.saveStatus === "SUCCESS_RELOAD") {
      setTimeout(
        () => dispatch(OpenThirdParty(supplierCtx.supplier.thirdPartyIdentifier, thirdParty.identifier, "ThirdPartySupplierDatas")),
        2000
      );
      return (
        <center>
          <p>
            <Trans>Success refreshing</Trans>
          </p>
          <p>
            <CheckCircleOutlined style={{ color: "green" }} />
          </p>
        </center>
      );
    }
  }

  if (!thirdParty.isSupplier || isNull(supplierCtx)) {
    var innerElement;
    
    if (!isArrayNullOrEmpty(errors)) {
      var errorMessage = errors.map((t, idx) => {
        if (t.code === "ExistingLegalIdentifier") {
          return thirdParty.duns === t.complexLinkedObject.duns ? (
            <div>
              <div style={{ lineHeight: "0.5em" }}>
                <Error style={{ verticalAlign: "middle", color: "red" }} />
                <span style={{ verticalAlign: "middle", color: "red", marginRight: "5px" }}>
                  <strong>
                    <Trans>A third party with the same legal identifier already exist</Trans>
                  </strong>
                </span>
                <Trans>You can choose the existing third party or abort current workflow.</Trans>
              </div>
              <ol>
                <Tooltip
                  placement="right"
                  title={
                    <>
                      {t.complexLinkedObject.address_Line2}
                      <br />
                      {t.complexLinkedObject.address_Line1}
                      <br />
                      {t.complexLinkedObject.address_ZipCode}
                      <br />
                      {t.complexLinkedObject.address_City}
                      <br />
                      {t.complexLinkedObject.address_CountryCode}
                    </>
                  }
                >
                  <LinkMaterialUi
                    style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
                    onClick={event => mergeThirdPartiesAndReload(t.complexLinkedObject)}
                  >
                    <IconButton
                      style={{
                        color: "red"
                      }}
                    >
                      <ArrowForward />
                    </IconButton>
                    <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
                      {t.complexLinkedObject.longName} - {t.complexLinkedObject.legalIdentifier}
                    </span>
                  </LinkMaterialUi>
                </Tooltip>
              </ol>
            </div>
          ) : (
            <div>
              <div style={{ lineHeight: "0.5em" }}>
                <Warning style={{ verticalAlign: "middle", color: "orange" }} />
                <span style={{ verticalAlign: "middle", color: "orange", marginRight: "5px" }}>
                  <strong>
                    <Trans>A third party with the same legal identifier already exist</Trans>
                  </strong>
                </span>
                <Trans>You can choose the existing third party or continue current workflow.</Trans>
              </div>
              <ol>
                <Tooltip
                  placement="right"
                  title={
                    <>
                      {t.complexLinkedObject.address_Line2}
                      <br />
                      {t.complexLinkedObject.address_Line1}
                      <br />
                      {t.complexLinkedObject.address_ZipCode}
                      <br />
                      {t.complexLinkedObject.address_City}
                      <br />
                      {t.complexLinkedObject.address_CountryCode}
                    </>
                  }
                >
                  <LinkMaterialUi
                    style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
                    onClick={event => mergeThirdPartiesAndReload(t.complexLinkedObject)}
                  >
                    <Trans>Existing third party</Trans>
                    <IconButton style={{ color: "orange" }}><ArrowForward /></IconButton>
                    <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
                      {t.complexLinkedObject.longName} - {t.complexLinkedObject.legalIdentifier}
                    </span>
                  </LinkMaterialUi>
                </Tooltip>
              </ol>
              <ol>
                <Tooltip
                  placement="right"
                  title={
                    <>
                      {thirdParty.address_Line2}
                      <br />
                      {thirdParty.address_Line1}
                      <br />
                      {thirdParty.address_ZipCode}
                      <br />
                      {thirdParty.address_City}
                      <br />
                      {thirdParty.address_CountryCode}
                    </>
                  }
                >
                  <LinkMaterialUi
                    style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
                    onClick={event => transformIntoSupplier({ thirdParty })}
                  >
                    <Trans>Current third party</Trans>
                    <IconButton style={{ color: "orange" }}><ArrowForward /></IconButton>
                    <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
                      {thirdParty.longName} - {thirdParty.legalIdentifier}
                    </span>
                  </LinkMaterialUi>
                </Tooltip>
              </ol>
            </div>
          );
        }
      });

      innerElement = (
        <Card>
          <CardBody>{errorMessage}</CardBody>
        </Card>
      );
    } else if ((thirdParty.thirdPartyStatusCode !== "Closed" || thirdPartyCtx.isEditableClosed) && HasRight("thirdparty_supplier.transform")
    && (thirdParty.customLongName.toUpperCase() !== infoNonDiffusable)) {
    innerElement = (
        <Button type="submit" color="info" className={classes.registerButton} onClick={() => validateThirdParty()}>
          <Trans> Transform ThirdParty into Supplier </Trans>
        </Button>
      );
    } else {
      innerElement = (
        <SnackbarContent message={<Trans>You don't have rights to transform this third party into a supplier.</Trans>} icon={AddAlert} color="info" />
      );
    }

    return (
      <Grid style={GridContainerStyle.mainGrid} container>
        <Grid item xs={12}>
          <Grid container justify="center">
            {innerElement}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  var isEditable = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : supplierCtx.isEditable;
  var isEditableCoupa = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : supplierCtx.isEditableCoupa;
  var isEditableDemat = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : supplierCtx.isEditableDemat;
  var canAddSj = (thirdParty.isPointOfSale || thirdParty.thirdPartyStatusCode === "Closed" || thirdParty.customLongName.toUpperCase() === infoNonDiffusable) ? false : supplierCtx.canAddSj;
  return (
    <div className="divContainer">
      <form autoComplete="false">
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs supplier"
            >
              <AntTab label={<Trans>General</Trans>} />
              <AntTab label={<Trans>Address</Trans>} />
              <AntTab label={<Trans>PaymentCharacteristic</Trans>} />
              <AntTab label={<Trans>Points of sale</Trans>} />
              <AntTab label={<Trans>Sjs linked</Trans>} />
              <AntTab
                label={
                  <>
                    {coupaIcon}
                    <Trans>Coupa</Trans>
                  </>
                }
              />
              <AntTab label={<Trans>History</Trans>} />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <TabSupplierGeneral isEditable={isEditable} isEditableDemat={isEditableDemat} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <TabSupplierAddress isEditable={isEditable} defaultLang={defaultLang} />
            </TabPanel>
            <TabPanel value={tabIdx} index={2}>
              <TabSupplierPayment defaultLang={defaultLang} />
            </TabPanel>
            <TabPanel value={tabIdx} index={3}>
              <TabSupplierPointOfSales defaultLang={defaultLang} />
            </TabPanel>
            <TabPanel value={tabIdx} index={4}>
              <TabSupplierSj canAddSj={canAddSj} defaultLang={defaultLang} />
            </TabPanel>
            <TabPanel value={tabIdx} index={5}>
              <ComponentCoupa
                isEditable={isEditableCoupa}
                coupa={supplierCtx.coupa}
                onGetCoupa={() => dispatch(Actions.CoupaSupplierGet(thirdParty.identifier))}
                onValueChanged={coupa => dispatch(Actions.SupplierCoupaEdit(coupa))}
                error={supplierCtx.error}
                supplierCountryCode={thirdParty.countryCode}
                defaultLang={defaultLang}
              />
            </TabPanel>
            <TabPanel value={tabIdx} index={6}>
              <TabSupplierHistory defaultLang={defaultLang} />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const GridContainerStyle = {
  mainGrid: {
    marginTop: "40px"
  }
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null,
    supplierCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].supplierCtx : null,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    transformIntoSupplier: supplier => {
      dispatch(Actions.SaveSupplier(supplier, true));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartySupplierDatas));
