import { Trans } from "@lingui/macro";
import { IconButton } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Add } from "@material-ui/icons";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import GenericServerAutocomplete from "components/Autocomplete/GenericServerAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import { ApimGetPromise } from "components/Common/ApimSender";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/Job.Actions";
import React, { useState } from "react";
import { connect } from "react-redux";
import ContactTab from "../../../common/ContactTab";

function JobAddtionnalContractManagersPart({ amc, itemChanged, isReadonly }) {
  const [newContact, setNewContact] = useState(null);
  const [employee, setEmployee] = useState(null);

  var item = !!amc.currentItem["JOB"] ? amc.currentItem["JOB"] : {};
  var dateInputProps = {};
  if (isReadonly)
    dateInputProps = {
      readOnly: { isReadonly },
      disabled: { isReadonly }
    };

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <GridContainer>
          <GridItem xs={5} sm={5} md={5}>
            <h4>
              <Trans>Additional contract managers</Trans>
            </h4>
          </GridItem>
          <GridItem xs={5} sm={5} md={5}>
            <div style={{ backgroundColor: "white", display: "flex" }}>
              <GenericServerAutocomplete
                selectedValue={newContact}
                searchPartialUri={"api/JobEmployees/Autocomplete/"}
                onValueChange={e => setNewContact(e)}
              />
              <IconButton
                onClick={() => {
                  if (newContact != null) {
                    ApimGetPromise("api/JobEmployees/" + newContact).then(employee => {
                      setNewContact(null);
                      itemChanged({
                        ...item,
                        contractManagers: [
                          ...item.contractManagers,
                          {
                            jobEmployeeLogin: newContact,
                            jobEmployee: employee
                          }
                        ]
                      });
                    });
                  }
                }}
              >
                <Add />
              </IconButton>
            </div>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <ContactTab contacts={item.contractManagers} updateList={newList => itemChanged({ ...item, contractManagers: newList })} />
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobAddtionnalContractManagersPart));
