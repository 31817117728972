import React, { useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

import HistoryContainer from "module/history/containers/HistoryContainer";
import CardError from "components/Card/CardError";
import { CircularProgress } from "@material-ui/core";
import { GetSiteHistory } from "../../actions/SiteActions";

const TabSiteHistory = function({ site, defaultLang }) {
  const [state, setState] = useState({ isLoading: false });

  if (!state.isLoading && !state.history) {
    setState({ isLoading: true, history: [] });

    GetSiteHistory(site.id, h => setState({ isLoading: false, history: h }), e => setState({ isLoading: false, history: [], error: e }));
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  return <HistoryContainer items={state.history} defaultLang={defaultLang} />;
};

export default withStyles(thirdPartySearchCriteriaStyle)(TabSiteHistory);
