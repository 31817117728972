import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import { Card, CardContent } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted";
import { Trans } from "@lingui/macro";
import HeaderField from "../../../structure/part/detail/common/HeaderField";

function Header({ product, isEditable }) {
  const cardLeft = (
    <>
      <Card style={{ ...CardInlineStyle.tpCard }} variant="outlined">
        <CardContent style={{ ...CardInlineStyle.tpCardHeader }}>
          <GridContainer>
            <HeaderField isEditable={isEditable} value={product.identifier} label={<Trans>Identifier</Trans>} />
            <HeaderField
              isEditable={isEditable}
              value={product.code}
              //onChange={e => setStructure({ ...structure, libelle: e.target.value })}
              label={<Trans>Code_Produit</Trans>}
              //error={extractErrorMessage(errors, "STR_LIBELLE", defaultLang)}
              //showErrorTooltip
            />
            <HeaderField
              isEditable={isEditable}
              value={product.ueIdentifier}
              //onChange={e => setUe({ ...ue, libelleCourt: e.target.value })}
              label={<Trans>UE_Identifier</Trans>}
              //error={extractErrorMessage(errors, "UE_LBLCOURT", defaultLang)}
              //showErrorTooltip
            />
          </GridContainer>
        </CardContent>
      </Card>
    </>
  );

  const cardRight = (
    <>
      <Card style={{ ...CardInlineStyle.tpCard }} variant="outlined">
        <CardContent style={{ ...CardInlineStyle.tpCardHeader }}>
          <GridContainer>
            <HeaderField
              isEditable={isEditable}
              value={product.commercialLabel}
              //onChange={e => setUe({ ...ue, codeCol: e.target.value })}
              label={<Trans>Commercial_Label</Trans>}
              //error={extractErrorMessage(errors, "UE_CODECOL", defaultLang)}
              //showErrorTooltip
            />

            <HeaderField
              isEditable={isEditable}
              value={!!product.technicalLabel ? product.technicalLabel : " "}
              //onChange={e => setUe({ ...ue, codeCol: e.target.value })}
              label={<Trans>Technical_Label</Trans>}
              //error={extractErrorMessage(errors, "UE_CODECOL", defaultLang)}
              //showErrorTooltip
            />

            <HeaderField
              isEditable={isEditable}
              value={!!product.genCode ? product.genCode : " "}
              //onChange={e => setUe({ ...ue, codeCol: e.target.value })}
              label={<Trans>genCode</Trans>}
              //error={extractErrorMessage(errors, "UE_CODECOL", defaultLang)}
              //showErrorTooltip
            />
          </GridContainer>
        </CardContent>
      </Card>
    </>
  );

  return (
    <GridContainer>
      <GridItem xs={6} sm={6} md={6}>
        {cardLeft}
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        {cardRight}
      </GridItem>
    </GridContainer>
  );
}
const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px",
    color: "white"
  },
  tpCard: {
    margin: "10px 0"
  },
  tpCardHeader: {
    padding: "8px 16px"
  },
  tpCardContent: {
    padding: "8px 16px",
    fontSize: "0.8rem"
  }
};

export default withStyles(structureStyle)(Header);
