import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isNull, isNullOrEmpty, isArrayNullOrEmpty } from 'tools';
import ReactTable from 'react-table-6';
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { Block, CheckCircle } from '@material-ui/icons';
import { green, red, orange } from "@material-ui/core/colors";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx";
import { Trans } from '@lingui/macro';
import { CircularProgress } from "@material-ui/core";


// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components
import { Check, SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";


function LogsResult({ ...props }) {

    const {
        datas,
        title,
        content,
        selectHandler,
        isSearching,
        classes
    } = props;

    const [showPopup, setShowPopup] = useState(false);

    const reactTableColumns = [
        {
            accessor: 'success',
            Cell: (rowInfo) => {
                var icon = '';
                if (rowInfo.original.success && rowInfo.original.success.toUpperCase() === 'TRUE') {
                    icon = (
                        <a onClick={() => {
                            if (!isNull(selectHandler)) {
                                setShowPopup(true);
                                selectHandler(rowInfo);
                            }
                        }}>
                            <CheckCircle style={{ color: green[500], cursor: 'pointer' }} />
                        </a>
                    );
                }
                else {
                    icon = (
                        <a onClick={() => {
                            if (!isNull(selectHandler)) {
                                setShowPopup(true);
                                selectHandler(rowInfo);
                            }
                        }}>
                            <Block style={{ color: red[500], cursor: 'pointer' }} />
                        </a>
                    );
                }
                return icon;
            },
            filterable: true,
            filterMethod: (filter, row) => {
                return (filter.value.toUpperCase() === 'TRUE' && row[filter.id].toUpperCase() === 'TRUE')
                    || (filter.value.toUpperCase() === 'VRAI' && row[filter.id].toUpperCase() === 'TRUE')
                    || (filter.value.toUpperCase() === '1' && row[filter.id].toUpperCase() === 'TRUE')
                    || (filter.value.toUpperCase() === 'OK' && row[filter.id].toUpperCase() === 'TRUE')
                    || (filter.value.toUpperCase() === 'FALSE' && row[filter.id].toUpperCase() === 'FALSE')
                    || (filter.value.toUpperCase() === 'FAUX' && row[filter.id].toUpperCase() === 'FALSE')
                    || (filter.value.toUpperCase() === '0' && row[filter.id].toUpperCase() === 'FALSE')
                    || (filter.value.toUpperCase() === 'KO' && row[filter.id].toUpperCase() === 'FALSE');
            },
            sortable: false,
            width: 50,
        },
        {
            Header: 'Time Stamp',
            accessor: 'timeStamp',
            Cell: (rowInfo) => {
                var date = new Date(rowInfo.original.timeStamp);
                var options = {
                    year: "numeric", month: "2-digit", day: "2-digit",
                    hour: "2-digit", minute: "2-digit", second: "2-digit",
                    hour12: false
                };
                var dateText = new Intl.DateTimeFormat('default', options).format(date);
                return dateText;
            },
            filterable: true,
            width: 145,
        },
        {
            Header: 'Name',
            accessor: 'name',
            Cell: (rowInfo) => {
                return <div style={{ textAlign: 'left' }}>{rowInfo.original.name}</div>;
            },
            filterable: true,
            filterMethod: (filter, row) => {
                if (filter !== null && filter.value !== null)
                    return row[filter.id].toUpperCase().includes(filter.value.toUpperCase());
                else
                    return true;
            }
        },
        {
            Header: 'Duration (ms)',
            accessor: 'duration',
            Cell: (rowInfo) => {
                return Math.round(rowInfo.original.duration);
            },
            filterable: true,
            width: 100,
        },
        {
            accessor: "actions",
            width: 50,
            sortable: false,
            filterable: false,
            width: 50,
        }
    ];

    var componentContent = <><CircularProgress /></>;
    if (!isSearching) {
        componentContent = (
            <>
                <Dialog
                    fullWidth={true}
                    maxWidth='lg'
                    open={showPopup}
                    onClose={() => setShowPopup(false)}
                    aria-labelledby="form-dialog-title"
                    classes={{ paper: DialogInlineStyleDetail.dialogPaper }}
                    scroll="paper">
                    <DialogTitle>
                        {title}
                    </DialogTitle>
                    <DialogContent
                        style={{
                            ...DialogInlineStyleDetail.dialogContent
                        }}
                        dividers={true}>
                        {content}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowPopup(false)} color="info">
                            <Trans>Close</Trans>
                        </Button>
                    </DialogActions>
                </Dialog>
                <ReactTable
                    data={datas}
                    columns={reactTableColumns}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    style={ReactTableStyle.main}
                    defaultPageSize={10}
                />
            </>
        );
    }

    return (
        <div style={{ width: '95%', alignContent: "center" }}>
            {componentContent}
        </div>
    );
};

const DialogInlineStyleDetail = {
    dialogContent: {
        padding: '0px 10px 0px',
        height: '95%'
    },
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    }
};

const ReactTableStyle = {
    main: {
        height: "100%",
        overflow: "hidden"
    }
};

const mapStateToProps = state => {
    return {
    };
};


const mapDispatchToProps = dispatch => {
    return {
    }
};

export default withStyles(dashboardStyle)(LogsResult);