import React, { useEffect, useMemo, useReducer, useState } from "react";
import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import { formatToLongDate } from "tools";
import { GraphGet, GraphGetBase64 } from "components/Common/ApimSender";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Trans } from "@lingui/macro";

var groupBy = function(xs, f) {
  return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
};

function HistoryContainer({ items, defaultLang }) {
  const initialState = {
    currentItems: undefined,
    users: {},
    userPictures: {}
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "setUser":
        return {
          ...state,
          users: { ...state.users, [action.key]: action.value }
        };
      case "setUserPicture":
        return {
          ...state,
          userPictures: { ...state.userPictures, [action.key]: action.value }
        };
      case "setCurrentItem":
        return {
          ...state,
          currentItem: action.value
        };
    }
  };

  const [context, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!items) return;

    var userGroups = groupBy(items, i => i.actionUser);
    for (const [key, value] of Object.entries(userGroups)) {
      if (key.startsWith("_")) {
        dispatch({ type: "setUser", key: key, value: key.substring(1, key.length - 1) });
      } else {
        GraphGet(`users/${key}@colas.com`, result => {
          dispatch({ type: "setUser", key: key, value: result?.displayName });
        });
        GraphGetBase64(`users/${key}@colas.com/photos/48x48/$value`, result => {
          dispatch({ type: "setUserPicture", key: key, value: result });
        });
      }
    }
  }, [items]);

  const itemsByDate = useMemo(() => {
    return groupBy(
      items.map(i => {
        if (i.actionDate) return { ...i, actionDate: formatToLongDate(i.actionDate, defaultLang) };
        return i;
      }),
      i => i.actionDate
    );
  }, [items]);

  if (items.length === 0) {
    return (
      <Card>
        <CardBody>
          <h4>
            <Trans>History_Empty</Trans>
          </h4>
        </CardBody>
      </Card>
    );
  }

  return (
    <div style={{ display: "flex", width: "100%", fontSize: "14px" }}>
      <div style={{ flex: 1, borderRight: "1px solid black", paddingRight: "10px", marginRight: "10px" }}>
        {Object.entries(itemsByDate).map(([key, value], idxByDate) => (
          <div key={`histoDate_${idxByDate}`} style={{ paddingBottom: "20px" }}>
            <div style={{ fontWeight: "bold", fontSize: "16px" }}>{key}</div>
            {value.map((v, idxV) => (
              <HistoryListItem
                key={`histoItem_${idxV}`}
                viewStates={context}
                item={v}
                currentItem={context.currentItem}
                setCurrentItem={i => dispatch({ type: "setCurrentItem", value: i })}
              />
            ))}
          </div>
        ))}
      </div>
      <div style={{ flex: 1 }}>{context.currentItem?.actionValues && <HistoryActionList actionList={context.currentItem.actionValues} />}</div>
    </div>
  );
}

function HistoryListItem({ viewStates, item, currentItem, setCurrentItem }) {
  const userName = viewStates.users[item.actionUser] ?? item.actionUser;
  const userPicture = viewStates.userPictures[item.actionUser];
  let entityLibelle = "";
  let actionLibelle = "";

  if (item.actionType === "CREATE") {
    actionLibelle = "a créé ";
  } else if (item.actionType === "REMOVE") {
    actionLibelle = "a supprimé ";
  } else {
    actionLibelle = "a modifié ";
  }

  if (item.entityType === "HubbleParam") entityLibelle = "le paramètre.";
  else if (item.entityType === "Location") {
    entityLibelle = `le site physique ${item.additionalId}.`;
  } else if (item.entityType === "LocationActivity") {
    entityLibelle = `l'activité ${item.additionalId}.`;
  } else if (item.entityType === "LocationCharacteristic") {
    entityLibelle = `la caractéristique ${item.additionalId}.`;
  } else if (item.entityType === "LocationContact") {
    entityLibelle = `le contact ${item.additionalId}.`;
  } else if (item.entityType === "LocationMedia") {
    entityLibelle = `le média ${item.additionalId}.`;
  } else if (item.entityType === "LocationStructure") {
    entityLibelle = `la structure ${item.additionalId}.`;
  } else if (item.entityType === "Structure") {
    entityLibelle = `la structure ${item.structureId}.`;
  } else if (item.entityType === "Area") {
    entityLibelle = `l'area ${item.structureId}.`;
  } else if (item.entityType === "Cdg") {
    entityLibelle = `le CDG ${item.structureId}.`;
  } else if (item.entityType === "Company") {
    entityLibelle = `la compagnie ${item.structureId}.`;
  } else if (item.entityType === "Dir") {
    entityLibelle = `la direction ${item.structureId}.`;
  } else if (item.entityType === "Div") {
    entityLibelle = `la division ${item.structureId}.`;
  } else if (item.entityType === "Ert") {
    entityLibelle = `l'ERT ${item.structureId}.`;
  } else if (item.entityType === "Ep") {
    entityLibelle = `l'EP ${item.structureId}.`;
  } else if (item.entityType === "Sj") {
    entityLibelle = `le SJ ${item.structureId}.`;
  } else if (item.entityType === "Ue") {
    entityLibelle = `l'UE ${item.structureId}.`;
  } else if (item.entityType === "Up") {
    entityLibelle = `l'UP ${item.structureId}.`;
  } else if (item.entityType === "UeIris2Activity") {
    entityLibelle = `l'activité IRIS2 ${item.structureId}.`;
  } else if (item.entityType === "UeActivityLevel2") {
    entityLibelle = `l'activité J@DE ${item.structureId}.`;
  } else if (item.entityType === "UpActivityLevel2") {
    entityLibelle = `l'activité J@DE ${item.structureId}.`;
  } else if (item.entityType === "StructureLocation") {
    entityLibelle = `le site physique ${item.additionalId}.`;
  } else if (item.entityType === "TpCompany") {
    entityLibelle = `la société ${item.additionalId}.`;
  } else if (item.entityType === "TpThirdParty") {
    entityLibelle = `le tiers ${item.additionalId}.`;
  } else if (item.entityType === "StructureTag") {
    entityLibelle = `le regroupement ${item.additionalId}.`;
  } else if (item.entityType === "StructureRelation") {
    entityLibelle = `la relation ${item.additionalId}.`;
  }

  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "5px",
        cursor: "pointer",
        backgroundColor: currentItem === item ? "#ddd" : "#fff",
        display: "flex"
      }}
      onClick={() => setCurrentItem(item)}
    >
      <span style={{ flex: 1 }}>
        {userPicture && <img src={userPicture} alt="" style={{ width: "30px", borderRadius: "15px" }} />}
        {` ${userName} ${actionLibelle} ${entityLibelle}`}
      </span>
    </div>
  );
}

function HistoryActionList({ actionList }) {
  return (
    <div>
      {actionList.map((kv, idx) => (
        <div key={`actionList_${idx}`}>
          <div style={{ fontWeight: "bold" }}>
            <span>{kv.name}</span>
          </div>
          <div>
            {kv.valueAfter && <span style={{ color: "#00CC00", backgroundColor: "#DDD", marginLeft: "5px", padding: "2px" }}>{kv.valueAfter}</span>}
            {kv.valueBefore && (
              <span style={{ color: "#CC0000", backgroundColor: "#DDD", textDecoration: "line-through", marginLeft: "5px", padding: "2px" }}>
                {kv.valueBefore}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default HistoryContainer;
