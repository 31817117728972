import { Trans } from "@lingui/macro";
import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Add, ChevronRight, Delete } from "@material-ui/icons";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { TreeItem, TreeView } from "@material-ui/lab";
import React from "react";
import { sortBy } from "tools";

const GroupTree = ({ groups, group, setGroup, selectGroup, createGroup, deleteGroup }) => {
  if (!groups) return <></>;

  let nodes = createGroup ? [{ id: 0, label: "root" }] : groups.filter(g => !g.parentId);

  return (
    <>
      <TreeView defaultCollapseIcon={<ExpandMore />} defaultExpandIcon={<ChevronRight />} defaultExpanded={[group?.id ?? (createGroup ? 0 : "")]}>
        {sortBy(nodes, "label").map((n, key) => {
          return (
            <GroupTreeItem
              key={key}
              nodeId={n.id}
              group={n}
              setGroup={setGroup}
              selectGroup={selectGroup}
              deleteGroup={deleteGroup}
              createGroup={createGroup}
              groups={groups}
            />
          );
        })}
      </TreeView>
    </>
  );
};

const GroupTreeItem = ({ nodeId, group, setGroup, groups, selectGroup, createGroup, deleteGroup }) => {
  const classes = useTreeItemStyles();
  let treeLabel = (
    <div className={classes.labelRoot}>
      <Typography variant="body2" className={classes.labelText}>
        {group.label}
      </Typography>
      <Typography variant="caption" color="default" className={classes.right}>
        {group.thirdPartyCount}
      </Typography>
      <Typography variant="caption" color="inherit" className={classes.labelButton}>
        {selectGroup && (
          <Button
            onClick={e => {
              e.stopPropagation();
              selectGroup(group);
            }}
          >
            <Trans>Group_Select</Trans>
          </Button>
        )}
        {createGroup && (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              createGroup(group);
            }}
          >
            <Add />
          </IconButton>
        )}
        {deleteGroup && group.id > 0 && !groups.some(g => g.parentId === group.id) && (
          <IconButton
            onClick={e => {
              e.stopPropagation();
              group.id === 0 ? setGroup(null) : deleteGroup(group);
            }}
          >
            <Delete />
          </IconButton>
        )}
      </Typography>
    </div>
  );
  let children = sortBy(groups.filter(g => g.parentId === group.id), "label");

  return (
    <TreeItem label={treeLabel} nodeId={nodeId} onLabelClick={() => !selectGroup && nodeId > 0 && setGroup(group)}>
      {children.map((g, key) => {
        return (
          <GroupTreeItem
            key={key}
            nodeId={g.id}
            group={g}
            setGroup={setGroup}
            selectGroup={selectGroup}
            createGroup={createGroup}
            deleteGroup={deleteGroup}
            groups={groups}
          />
        );
      })}
    </TreeItem>
  );
};

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)"
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
      backgroundColor: "transparent"
    }
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular
    }
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2)
    }
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit"
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
    "&:hover $labelButton": {
      visibility: "visible"
    }
  },
  labelIcon: {
    marginRight: theme.spacing(1)
  },
  labelButton: {
    visibility: "hidden"
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1
  }
}));

export default GroupTree;
