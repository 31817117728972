import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-6';
import { connect, useDispatch } from 'react-redux';
import * as Actions from 'module/amc/store/actions/CustomerContract.Actions';

// custom components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CountryAutocomplete from 'components/Autocomplete/CountryAutocomplete';
import Select, { components } from 'react-select';
import { AntTabs, AntTab, TabPanel } from 'components/Common/Tabs';
import { Warning, CheckCircleOutlined } from '@material-ui/icons';

// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

// core components
import { Check, SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";

//lingui
import { Trans } from '@lingui/macro';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import CustomerContractIdentifierAndSourcePart from './Parts/CustomerContractIdentifierAndSourcePart';
import CustomerContractLinksPart from './Parts/CustomerContractLinksPart';
import CustomerContractDatesPart from './Parts/CustomerContractDatesPart';
import CustomerContractFlagsPart from './Parts/CustomerContractFlagsPart';
import CustomerContractBudgetPart from './Parts/CustomerContractBudgetPart';
import CustomerContractAddtionnalContractManagersPart from './Parts/CustomerContractAddtionnalContractManagersPart';
import CustomerContractMainContactsPart from './Parts/CustomerContractMainContactsPart';
import CustomerContractCustomerDatasPart from './Parts/CustomerContractCustomerDatasPart';

function CustomerContractGeneralTab({ amc, isReadonly }) {
    const dispatch = useDispatch();
    const [tabIdx, setTabIdx] = React.useState(0);

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    var item = !!amc.currentItem['CUSTOMERCONTRACT'] ? amc.currentItem['CUSTOMERCONTRACT'] : {};
    var warnings = amc.errors['CUSTOMERCONTRACT'];
    var tabGeneral = (<Trans>General</Trans>);
    if (!!warnings && typeof (warnings) != "undefined" && warnings.some(w => w.code.split('/')[1] === "GENERAL"))
        tabGeneral = (<span><Warning style={{ 'color': 'orange' }} />{tabGeneral}</span>)
    var tabContacts = (<Trans>Contacts</Trans>);
    if (!!warnings && typeof (warnings) != "undefined" && warnings.some(w => w.code.split('/')[1] === "CONTACTS"))
        tabContacts = (<span><Warning style={{ 'color': 'orange' }} />{tabContacts}</span>)

    return (
        <GridContainer>
            <GridItem xs={2} sm={2} md={2}>
                <AntTabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabIdx}
                    onChange={(e, value) => setTabIdx(value)}
                    aria-label="Vertical tabs customercontracts"
                >
                    <AntTab label={tabGeneral} />
                    <AntTab label={tabContacts} />
                </AntTabs>
            </GridItem>
            <GridItem xs={10} sm={10} md={10}>
                <TabPanel value={tabIdx} index={0}>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomerContractIdentifierAndSourcePart isReadonly={isReadonly} />
                            <CustomerContractCustomerDatasPart isReadonly={isReadonly} />
                            <CustomerContractMainContactsPart isReadonly={isReadonly} />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6}>
                            <CustomerContractLinksPart isReadonly={isReadonly} />
                            <CustomerContractDatesPart isReadonly={isReadonly} />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomerContractFlagsPart isReadonly={isReadonly} />
                            <CustomerContractBudgetPart isReadonly={isReadonly} />
                        </GridItem>
                    </GridContainer>
                </TabPanel>
                <TabPanel value={tabIdx} index={1}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomerContractAddtionnalContractManagersPart isReadonly={isReadonly} />
                        </GridItem>
                    </GridContainer>
                </TabPanel>
            </GridItem>
        </GridContainer>
    );
}

const mapStateToProps = state => {
    return {
        amc: state.AMC_Reducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCriterias: criterias => {
            dispatch(Actions.SetCriterias(criterias))
        },
        search: () => {
            dispatch(Actions.Search())
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(CustomerContractGeneralTab));