import React, { useState } from "react";
import { TextField, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { isNullOrEmpty } from "../../tools";
import { GetIdentifiedUser, SearchIdentifiedUser } from "../../services/user/UserHelper";
import { highlightText } from "../../module/tools";

export function IdentifiedUserAutocomplete({ login, onChange, error }) {
  const [firstTime, setFirstTime] = useState(true);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    let active = true;

    if (firstTime) {
      setFirstTime(false);
      if (!value && !isNullOrEmpty(login)) {
        GetIdentifiedUser(login, user => {
          let opts = [
            {
              text: user.lastName + " " + user.firstName + " (" + user.login + ")",
              value: user.login
            }
          ];
          setOptions(opts);
          setValue(opts[0]);
        });
        return undefined;
      }
    }

    if (inputValue.length <= 2) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    SearchIdentifiedUser(
      inputValue,
      results => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [
              ...newOptions,
              ...results.map(i => {
                return {
                  text: i.lastName + " " + i.firstName + " (" + i.login + ")",
                  value: i.login
                };
              })
            ];
          }

          setOptions(newOptions);
        }
      },
      error => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          newOptions = [
            ...newOptions,
            {
              error: error,
              value: null
            }
          ];

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  return (
    <Autocomplete
      id="identifiedUserAutocomplete"
      clearOnEscape
      getOptionLabel={option => (typeof option === "string" ? option : option.text)}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) onChange(newValue.value);
        else onChange(null, null);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => <TextField {...params} error={error} fullWidth />}
      renderOption={option => {
        const parts = highlightText(option.text, inputValue);

        return (
          <Grid container alignItems="center">
            <Grid item></Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              {/*<Typography variant="body2" color="textSecondary">
                                Second text
                            </Typography>*/}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
