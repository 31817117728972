import { Trans } from "@lingui/macro";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Autocomplete from "@material-ui/lab/Autocomplete";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { SearchCityInseeCodes } from "services/masterdata/GeographicalMasterValuesHelper";
import { CommonCountry, CommonCountryCounty, CommonCountryState } from "store/MasterValue/MasterValueTypes";
import { highlightText } from "../../module/tools";
import { isArrayNullOrEmpty, isNullOrEmpty } from "../../tools";
import GetMasterValueLabel from "../Common/MasterValueLabel";
import Muted from "../Typography/Muted";
import ItemAutoComplete from "./ItemAutoComplete";

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2)
  }
}));

function CityZipAutocomplete({
  isEditable,
  size,

  // codes
  countryCode,
  zipCode,
  city,
  stateCode,
  countyCode,

  // errors
  zipCodeError,
  cityError,
  countyCodeError,
  stateCodeError,

  // onChange events
  onZipCityChange,
  onCountyStateChange,

  // redux
  countryReducer,
  countyReducer,
  stateReducer
}) {
  var [state, setState] = useState({ counties: [], states: [] });

  useEffect(() => {
    let country = countryReducer.find(c => c.codeIso2 === countryCode);
    if (!country) {
      setState({ states: [], counties: [] });
    } else {
      setState({ states: stateReducer.filter(s => s.countryCode == countryCode), counties: countyReducer.filter(c => c.countryCode == countryCode) });
    }
  }, [countryCode, countryReducer, setState]);

  var zipCity;
  if (!isNullOrEmpty(countryCode) && countryCode === "FR") {
    let opts=undefined;
    if(!isNullOrEmpty(zipCode) && !isNullOrEmpty(city))
    {
      opts = [
        {
          text: zipCode + " " + city,
          value: null,
          zipCode: zipCode,
          commune: city
        }
      ];
    }
    zipCity = (
      <AutocompleteComponent
        isEditable={isEditable}
        size={size}
        zipCode={zipCode}
        city={city}
        onChange={onZipCityChange}
        newValue={opts}
        error={zipCodeError || cityError}
      />
    );
  } else {
    zipCity = (
      <StaticComponent
        isEditable={isEditable}
        size={size}
        zipCode={zipCode}
        city={city}
        onChange={onZipCityChange}
        zipCodeError={zipCodeError}
        cityError={cityError}
      />
    );
  }

  var stateCounty;
  if (state.counties.some(c => !!c.stateCode)) {
    stateCounty = (
      <StateCountyComponent
        isEditable={isEditable}
        size={size}
        stateCode={stateCode}
        countyCode={countyCode}
        onCountyStateChange={onCountyStateChange}
        error={countyCodeError || stateCodeError}
        counties={state.counties}
        states={state.states}
      />
    );
  } else {
    var componentState;
    if (!isArrayNullOrEmpty(state.states)) {
      componentState = (
        <div style={{ flex: 1 }}>
          <ItemAutoComplete
            isEditable={isEditable}
            size={size}
            label={<Trans>State</Trans>}
            items={state.states}
            selectedValue={stateCode}
            onValueChange={code => onCountyStateChange({ stateCode: code, countyCode: null })}
          />
        </div>
      );
    }

    var componentCounty;
    if (!isArrayNullOrEmpty(state.counties)) {
      componentCounty = (
        <div style={{ flex: 1 }}>
          <ItemAutoComplete
            isEditable={isEditable}
            size={size}
            label={<Trans>County</Trans>}
            items={state.counties}
            selectedValue={countyCode}
            onValueChange={code => onCountyStateChange({ countyCode: code, stateCode: null })}
          />
        </div>
      );
    }

    stateCounty = (
      <div style={{ display: "flex" }}>
        {componentState}
        {componentCounty}
      </div>
    );
  }
  return (
    <>
      {zipCity}
      {stateCounty}
    </>
  );
}

function AutocompleteComponent({ isEditable, size, zipCode, city, onChange, newValue, error }) {
  const classes = useStyles();
  const [firstTime, setFirstTime] = useState(true);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    let active = true;

    if (firstTime) {
      setFirstTime(false);
      if (!value && !isNullOrEmpty(zipCode) && !isNullOrEmpty(city)) {
        let opts = [
          {
            text: zipCode + " " + city,
            value: null,
            zipCode: zipCode,
            commune: city
          }
        ];
        setOptions(opts);
        setValue(opts[0]);
        return undefined;
      }
    }

    if (inputValue.length <= 2) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    SearchCityInseeCodes({ freeSearch: inputValue }, results => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results && !isArrayNullOrEmpty(results.items)) {
          newOptions = [
            ...newOptions,
            ...results.items.map(i => {
              return {
                text: i.zipCode + " " + i.commune,
                value: i.code,
                zipCode: i.zipCode,
                commune: i.commune
              };
            })
          ];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  useEffect(() => {
    let active = true;

    
    if(newValue)
    {
        setOptions(newValue);
        setValue(newValue[0]);
        return undefined;
    }

    return () => {
      active = false;
    };
  }, [ newValue]);
  
  if (!isEditable) {
    return (
      <Muted>
        {zipCode} {city}
      </Muted>
    );
  }

  return (
    <Autocomplete
      id="cityZipAutocomplete"
      clearOnEscape
      getOptionLabel={option => (typeof option === "string" ? option : option.text)}
      filterOptions={x => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue) onChange(newValue.zipCode, newValue.commune);
        else onChange(null, null);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={params => (
        <TextField {...params} margin={size === "small" ? "none" : "dense"} error={error} label={<Trans>ZipCodeCity</Trans>} fullWidth size={size} />
      )}
      renderOption={option => {
        const parts = highlightText(option.text, inputValue);

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              {/*<Typography variant="body2" color="textSecondary">
                                Second text
                            </Typography>*/}
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

function StaticComponent({ isEditable, size, zipCode, city, onChange, zipCodeError, cityError }) {
  var [localZipCode, setLocalZipCode] = useState(zipCode);
  var [localCity, setLocalCity] = useState(city);

  if (!isEditable) {
    return (
      <Muted>
        {zipCode} {city}
      </Muted>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <TextField
        label={<Trans>ZipCode</Trans>}
        style={{ width: "40%" }}
        value={localZipCode}
        error={zipCodeError}
        onChange={e => {
          let val = e.target.value;
          setLocalZipCode(val);
          onChange(val, localCity);
        }}
        margin={size === "small" ? "none" : "dense"}
        size={size}
      />
      <TextField
        label={<Trans>City</Trans>}
        style={{ width: "60%" }}
        value={localCity}
        error={cityError}
        onChange={e => {
          let val = e.target.value;
          setLocalCity(val);
          onChange(localZipCode, val);
        }}
        margin={size === "small" ? "none" : "dense"}
        size={size}
      />
    </div>
  );
}

function StateCountyComponent({ countyCode, stateCode, onCountyStateChange, error, states, counties, isEditable, size }) {
  var [value, setValue] = useState(null);
  var customStates = states.map(i => {
    return {
      type: "state",
      value: i.code,
      text: GetMasterValueLabel(i) + (i.abbreviation ? " (" + i.abbreviation + ")" : "")
    };
  });
  var customCounties = counties.map(i => {
    return {
      type: "county",
      value: i.code,
      text: GetMasterValueLabel(i),
      state: customStates.find(s => s.value === i.stateCode)
    };
  });
  var [options] = useState([...customStates, ...customCounties]);

  useEffect(() => {
    if (countyCode) {
      setValue(options.find(c => c.type === "county" && c.value === countyCode));
    } else if (stateCode) {
      setValue(options.find(c => c.type === "state" && c.value === stateCode));
    }
  }, [countyCode, stateCode]);

  if (isEditable) {
    return (
      <Autocomplete
        clearOnEscape
        options={options}
        getOptionLabel={option => renderTextLabel(option)}
        onChange={(event, newValue) => {
          setValue(newValue);
          if (newValue) {
            if (newValue.type === "county") {
              onCountyStateChange({ countyCode: newValue.value, stateCode: newValue.state.value });
            } else {
              onCountyStateChange({ countyCode: null, stateCode: newValue.value });
            }
          } else {
            onCountyStateChange({ countyCode: null, stateCode: null });
          }
        }}
        value={value}
        renderInput={params => {
          return (
            <TextField
              {...params}
              fullWidth
              error={error}
              label={<Trans>StateCounty</Trans>}
              margin={size === "small" ? "none" : "normal"}
              size={size}
            />
          );
        }}
        renderOption={(option, { inputValue }) => {
          let text = renderTextLabel(option);

          const matches = match(text, inputValue);
          const parts = parse(text, matches);

          return (
            <div>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </div>
          );
        }}
      />
    );
  }

  if (!stateCode && !countyCode) {
    return <Muted>Ø</Muted>;
  } else if (!countyCode) {
    return <Muted>{customStates.find(c => c.value === stateCode).text}</Muted>;
  } else {
    return <Muted>{customCounties.find(c => c.value === countyCode).text}</Muted>;
  }
}

function renderTextLabel(option) {
  if (option.type === "county") {
    return option.text + " - " + option.state.text;
  }
  return option.text;
}

const mapStateToProps = state => {
  return {
    countryReducer: state.MasterValueReducer[CommonCountry],
    countyReducer: state.MasterValueReducer[CommonCountryCounty],
    stateReducer: state.MasterValueReducer[CommonCountryState]
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(CityZipAutocomplete));
