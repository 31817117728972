import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../store/actions";
import { isArrayNullOrEmpty, isNull } from "../../tools";

import Button from "../../../components/CustomButtons/Button";

import CardError from "components/Card/CardError";
import TabBeneficiaryGeneral from "./ThirdPartyDetail/TabBeneficiaryGeneral";
import TabBeneficiarySj from "./ThirdPartyDetail/TabBeneficiarySj";
import TabBeneficiarySupplier from "./ThirdPartyDetail/TabBeneficiarySupplier";

import { CircularProgress, Grid, SnackbarContent } from "@material-ui/core";
import { AddAlert, CheckCircleOutlined } from "@material-ui/icons";
//import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import { AntTabs, AntTab, TabPanel } from "../../../components/Common/Tabs";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

//lingui
import { Trans } from "@lingui/macro";
import { HasRight } from "services/user/UserHelper";

function ThirdPartyBeneficiaryDatas({ thirdPartyCtx, beneficiaryCtx, transformIntoBeneficiary, classes, defaultLang }) {
  var dispatch = useDispatch();
  var thirdParty = thirdPartyCtx.thirdParty;
  var [isFirstTime, setIsFirstTime] = useState(true);
  const [tabIdx, setTabIdx] = useState(0);
  const infoNonDiffusable = "INFORMATION NON DIFFUSIBLE";

  useEffect(() => {
    if (isFirstTime) {
      if (isNull(beneficiaryCtx)) {
        dispatch(Actions.GetBeneficiary(thirdParty.identifier));
      }
      setIsFirstTime(false);
    }
  });

  if (!isNull(beneficiaryCtx)) {
    if (!isNull(beneficiaryCtx.error)) return <CardError error={beneficiaryCtx.error} />;
    if (beneficiaryCtx.saveStatus === "PENDING")
      return (
        <center>
          <p>
            <Trans>Loading in progress</Trans>
          </p>
          <p>
            <CircularProgress />
          </p>
        </center>
      );
    if (beneficiaryCtx.saveStatus === "SUCCESS_RELOAD") {
      setTimeout(
        () => dispatch(Actions.OpenThirdParty(beneficiaryCtx.beneficiary.thirdPartyIdentifier, thirdParty.identifier, "ThirdPartyBeneficiaryDatas")),
        2000
      );
      return (
        <center>
          <p>
            <Trans>Success refreshing</Trans>
          </p>
          <p>
            <CheckCircleOutlined style={{ color: "green" }} />
          </p>
        </center>
      );
    }
  }

  if (!thirdParty.isBeneficiary || isNull(beneficiaryCtx)) {
    var innerElement;
    if ((thirdParty.thirdPartyStatusCode !== "Closed" || thirdPartyCtx.isEditableClosed) && HasRight("thirdparty_supplier.transform")
      && thirdParty.customLongName.toUpperCase() !== infoNonDiffusable) {
      innerElement = (
        <center>
          <Button
            type="submit"
            color="info"
            className={classes.registerButton}
            onClick={() => transformIntoBeneficiary({ benificiaryTypeCode: "FACTOR", thirdParty })}
          >
            <Trans> Transform ThirdParty into Beneficiary </Trans>
          </Button>
        </center>
      );
    } else {
      innerElement = (
        <SnackbarContent
          message={<Trans>You don't have rights to transform this third party into a beneficiary.</Trans>}
          icon={AddAlert}
          color="info"
        />
      );
    }

    return (
      <Grid style={GridContainerStyle.mainGrid} container>
        <Grid item xs={12}>
          <Grid container justify="center">
            {innerElement}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  var isEditable = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : beneficiaryCtx.isEditable;
  var canAddSj = (thirdParty.thirdPartyStatusCode === "Closed" || thirdParty.customLongName.toUpperCase() === infoNonDiffusable) ? false : beneficiaryCtx.canAddSj;
  return (
    <div className="divContainer">
      <form autoComplete="false">
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs supplier"
            >
              <AntTab label={<Trans>General</Trans>} />
              <AntTab label={<Trans>Suppliers linked</Trans>} />
              <AntTab label={<Trans>Sjs linked</Trans>} />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <TabBeneficiaryGeneral defaultLang={defaultLang} isEditable={isEditable} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <TabBeneficiarySupplier />
            </TabPanel>
            <TabPanel value={tabIdx} index={2}>
              <TabBeneficiarySj canAddSj={canAddSj} />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const GridContainerStyle = {
  mainGrid: {
    marginTop: "40px"
  }
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null,
    beneficiaryCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].beneficiaryCtx : null,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    transformIntoBeneficiary: beneficiary => {
      dispatch(Actions.SaveBeneficiary(beneficiary, true));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyBeneficiaryDatas));
