import * as Actions from "./types";
import axios from "axios";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";
import { ApimGet, ApimPost } from "components/Common/ApimSender";

export function SearchSites(criterias) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.SEARCH_BEGIN,
      criterias: criterias,
      searchScope: "sites"
    });

    const apiCriterias = criterias.searchMode === "Simple" ? ConvertSimpleCriteria(criterias) : ConvertCriteria(criterias);
    ApimPost(
      "api/Location/Search",
      apiCriterias,
      responseJson => {
        dispatch({
          type: Actions.SEARCH_END,
          results: responseJson,
          criterias: criterias,
          searchScope: "sites"
        });
      },
      error => {
        dispatch({
          type: Actions.SEARCH_ERROR,
          error: error
        });
      }
    );
  };
}

// Convert Criteria To Simple Query String
function ConvertSimpleCriteria(criteria) {
  var queryParams = {};

  if (!isNullOrEmpty(criteria.criteriaFullText)) {
    queryParams.freeSearch = criteria.criteriaFullText;
  }
  if (criteria.criteriaCountry !== null && criteria.criteriaCountry !== "") {
    queryParams.country = criteria.criteriaCountry;
  }
  // force simple search on active sites
  queryParams.statuses = ["ACTIVE"];

  return queryParams;
}

function ConvertCriteria(criteria) {
  var queryParams = {};

  if (!isNullOrEmpty(criteria.criteriaMdmId)) {
    queryParams.mdmId = criteria.criteriaMdmId;
  }
  if (!isNullOrEmpty(criteria.criteriaName)) {
    queryParams.name = criteria.criteriaName;
  }
  if (!isNullOrEmpty(criteria.criteriaAddress)) {
    queryParams.address = criteria.criteriaAddress;
  }
  if (!isNullOrEmpty(criteria.criteriaCountry)) {
    queryParams.country = criteria.criteriaCountry;
  }
  if (!isNullOrEmpty(criteria.criteriaCountryState)) {
    queryParams.countryState = criteria.criteriaCountryState;
  }
  if (!isNullOrEmpty(criteria.criteriaStructureId)) {
    queryParams.structureIdentifiant = criteria.criteriaStructureId;
  }
  if (!isNullOrEmpty(criteria.criteriaStructureType)) {
    queryParams.structureType = criteria.criteriaStructureType;
  }
  //if (!isNullOrEmpty(criteria.startPage)) {
  //    queryParams.push("StartPage=" + criteria.startPage);
  //}
  //if (!isNullOrEmpty(criteria.pageSize)) {
  //    queryParams.push("PageSize=" + criteria.pageSize);
  //}
  //if (!isNullOrEmpty(criteria.orderProperty)) {
  //    queryParams.push("OrderProperty=" + criteria.orderProperty);
  //}

  if (!isArrayNullOrEmpty(criteria.siteStatuses)) {
    queryParams.statuses = criteria.siteStatuses;
  }

  if (!isArrayNullOrEmpty(criteria.siteNatures)) {
    queryParams.natures = criteria.siteNatures;
  }

  if (!isArrayNullOrEmpty(criteria.siteTypes)) {
    queryParams.types = criteria.siteTypes;
  }

  return queryParams;
}
