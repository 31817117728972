import { Trans } from "@lingui/macro";
import { FormLabel, MenuItem, Select, TextField } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import CityZipAutocomplete from "components/Autocomplete/CityZipAutocomplete";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { connect } from "react-redux";
import { CommonLanguage } from "store/MasterValue/MasterValueTypes";
import { emptyIfNull, isArrayNullOrEmpty } from "tools";
import * as Actions from "../../store/actions";

function TabPrivate({ thirdPartyCtx, onValueChanged, defaultLang }) {
  var thirdParty = thirdPartyCtx.thirdParty;
  var isEditable = thirdParty.thirdPartyStatusCode === "Closed" ? thirdPartyCtx.isEditableClosed : thirdPartyCtx.isEditable;

  return (
    <div>
      <GridContainer>
        <GridItem xs={7} sm={7} md={7}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Civility</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Title</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <Select value={thirdParty.privateTitle} onChange={e => onValueChanged({ ...thirdParty, privateTitle: e.target.value })}>
                            <MenuItem value="M">
                              <Trans>Mister</Trans>
                            </MenuItem>
                            <MenuItem value="Mme">
                              <Trans>Madame</Trans>
                            </MenuItem>
                            <MenuItem value="MM">
                              <Trans>MisterMadame</Trans>
                            </MenuItem>
                          </Select>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>First name</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <TextField
                            disabled={!isEditable}
                            margin="dense"
                            id="firsName"
                            type="text"
                            fullWidth
                            value={thirdParty.privateFirstName}
                            onChange={e => onValueChanged({ ...thirdParty, privateFirstName: e.target.value })}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Last name</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <TextField
                            disabled={!isEditable}
                            margin="dense"
                            id="lastName"
                            type="text"
                            fullWidth
                            value={thirdParty.privateLastName}
                            onChange={e => onValueChanged({ ...thirdParty, privateLastName: e.target.value })}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Language</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <MasterValueAutocomplete
                            isEditable={isEditable}
                            mvType={CommonLanguage}
                            onValueChange={code => onValueChanged({ ...thirdParty, languageCode: code })}
                            selectedValue={thirdParty.languageCode}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4>
                    <Trans>Legal identifiers</Trans>
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Legal Identifier</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <TextField
                            disabled={!isEditable}
                            margin="dense"
                            fullWidth
                            type="text"
                            id="legalIdentifier"
                            value={emptyIfNull(thirdParty.legalIdentifier)}
                            onChange={e => onValueChanged({ ...thirdParty, legalIdentifier: e.target.value })}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer>
                        <GridItem xs={4} sm={4} md={4}>
                          <FormLabel>
                            <Trans>Tax Code</Trans>
                          </FormLabel>
                        </GridItem>
                        <GridItem xs={8} sm={8} md={8}>
                          <TextField
                            disabled={!isEditable}
                            margin="dense"
                            fullWidth
                            type="text"
                            id="taxCode"
                            value={emptyIfNull(thirdParty.taxCode)}
                            onChange={e => onValueChanged({ ...thirdParty, taxCode: e.target.value })}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={5} sm={5} md={5}>
          <Card
            style={{
              ...CardInlineStyle.card
            }}
          >
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <h4>
                <Trans>Address</Trans>
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Line 1</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <TextField
                    disabled={!isEditable}
                    margin="dense"
                    id="line1"
                    type="text"
                    fullWidth
                    value={thirdParty.address_Line1}
                    onChange={e => onValueChanged({ ...thirdParty, address_Line1: e.target.value })}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Line 2</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <TextField
                    disabled={!isEditable}
                    margin="dense"
                    id="line2"
                    type="text"
                    fullWidth
                    value={thirdParty.address_Line2}
                    onChange={e => onValueChanged({ ...thirdParty, address_Line2: e.target.value })}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>City</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <CityZipAutocomplete
                    countryCode={thirdParty ? thirdParty.address_CountryCode : ""}
                    zipCode={thirdParty ? thirdParty.address_ZipCode : ""}
                    city={thirdParty ? thirdParty.address_City : ""}
                    countyCode={thirdParty ? thirdParty.address_CountyCode : ""}
                    stateCode={thirdParty ? thirdParty.address_StateCode : ""}
                    onZipCityChange={(zipCode, city) => {
                      onValueChanged({ ...thirdParty, address_ZipCode: zipCode, address_City: city });
                    }}
                    onCountyStateChange={countyState => {
                      onValueChanged({ ...thirdParty, address_StateCode: countyState.stateCode, address_CountyCode: countyState.countyCode });
                    }}
                    isEditable={isEditable}
                    defaultLang={defaultLang}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <FormLabel>
                    <Trans>Country</Trans>
                  </FormLabel>
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <CountryAutocomplete
                    countryCode={thirdParty.address_CountryCode}
                    onChange={countryCode => onValueChanged({ ...thirdParty, address_CountryCode: countryCode })}
                    defaultLang={defaultLang}
                    isEditable={isEditable}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onValueChanged: thirdParty => {
      dispatch(Actions.EditThirdParty(thirdParty));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(TabPrivate));
