import React from "react";
import PropTypes from "prop-types";
//style
import withStyles from "@material-ui/core/styles/withStyles";
import style from "assets/jss/material-dashboard-pro-react/components/ProfilStyle.jsx";
//components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
// @material-ui/core 
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

//icon
import { ViewDay, ViewHeadline, ViewQuilt, ViewModule } from "@material-ui/icons";
//lingui
import { Trans } from "@lingui/macro";

class DetailPreference extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openSearch: false,
            openResultat: false,
            openResultatPageSize: false,
            Preference: props.Preference,
            max: 50

        }
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleResultChange = this.handleResultChange.bind(this);

        this.handleSearchClose = this.handleSearchClose.bind(this);
        this.handleResultatClose = this.handleResultatClose.bind(this);

        this.handleSearchOpen = this.handleSearchOpen.bind(this);
        this.handleResultatOpen = this.handleResultatOpen.bind(this);
    }

    //open 
    handleSearchOpen = () => {
        this.setState({ openSearch: true });
    };
    handleResultatOpen = () => {
        this.setState({ openResultat: true });
    };
    handleResultatPageSizeOpen = () => {
        this.setState({ openResultatPageSize: true });
    };
    // close   
    handleSearchClose = () => {
        this.setState({ openSearch: false });
    };
    handleResultatClose = () => {
        this.setState({ openResultat: false });
    };
    handleResultatPageSizeClose = () => {
        this.setState({ openResultatPageSize: false });
    };
    //change
    handleSearchChange = event => {
        var Preference = this.state.Preference;
        Preference.Search = event.target.value;
        this.setState({ Preference: Preference });
        this.props.handelPref(this.state.Preference)
    };
    handleResultChange = event => {
        var Preference = this.state.Preference;
        Preference.Result = event.target.value;
        this.setState({ Preference: Preference });
        this.props.handelPref(this.state.Preference)
    };
    handleResultPageSizeChange = event => {
        var Preference = this.state.Preference;
        Preference.ResultPageSize = event.target.value;
        this.setState({ Preference: Preference });
        this.props.handelPref(this.state.Preference)
    };
    handleChangeNumber = event => {
        var Preference = this.state.Preference;
        var number = event.target.value
        if (number > this.state.max) {
            return alert("the max number is " + this.state.max)
            Preference.number = null;
            this.setState({ Preference: Preference });
        }
        else
            Preference.number = number;
        this.setState({ Preference: Preference });
    }

    render() {
        const { classes } = this.props;

        return (
            <GridItem xs={6} sm={6} md={6}>
                <Card>
                    <CardHeader color="success">
                        <h5><Trans>Preferences</Trans></h5>
                    </CardHeader>
                    <CardBody>
                        <GridContainer
                            alignItems="center"
                            justify="center">
                            <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                                <label className={classes.LabelSize}> <Trans> Result Display Default </Trans> </label>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}  >
                                <Select
                                    open={this.state.openResultat}
                                    onClose={this.handleResultatClose}
                                    onOpen={this.handleResultatOpen}
                                    value={this.state.Preference.Result}
                                    onChange={this.handleResultChange}
                                    fullWidth
                                >
                                    <MenuItem value="Table">
                                        <ViewModule color="primary" />
                                        <Trans> Table </Trans>
                                    </MenuItem>
                                    <MenuItem value="Summary"><ViewDay color="secondary" />
                                        <Trans>  Summary </Trans>
                                    </MenuItem>
                                </Select>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                                <label className={classes.LabelSize}> <Trans> Result PageSize </Trans></label>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                                <Select
                                    open={this.state.openResultatPageSize}
                                    onClose={this.handleResultatPageSizeClose}
                                    onOpen={this.handleResultatPageSizeOpen}
                                    value={this.state.Preference.ResultPageSize}
                                    onChange={this.handleResultPageSizeChange}
                                    fullWidth
                                >
                                    <MenuItem value="5">5</MenuItem>
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                    <MenuItem value="25">25</MenuItem>
                                    <MenuItem value="50">50</MenuItem>
                                    <MenuItem value="100">100</MenuItem>
                                </Select>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                                <label className={classes.LabelSize}> <Trans> Default Search Mode </Trans></label>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                                <Select
                                    open={this.state.openSearch}
                                    onClose={this.handleSearchClose}
                                    onOpen={this.handleSearchOpen}
                                    value={this.state.Preference.Search}
                                    onChange={this.handleSearchChange}
                                    inputProps={{
                                        name: 'Search',
                                    }}
                                    fullWidth
                                >
                                    <MenuItem value="Simple">
                                        <ViewDay color="error" />
                                        <Trans>Simplified </Trans>
                                    </MenuItem>
                                    <MenuItem value="Extend"><ViewHeadline color="primary" />
                                        <Trans>Extended</Trans>
                                    </MenuItem>

                                    <MenuItem value="Complexe">< ViewQuilt color="secondary" />
                                        <Trans> Complex</Trans>
                                    </MenuItem>
                                </Select>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                                <label className={classes.LabelSize}> <Trans>Size of historic</Trans></label>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.Margin}>
                                <TextField
                                    id="filled-number"
                                    label=""
                                    value={this.state.Preference.number}
                                    onChange={this.handleChangeNumber}
                                    type="number"
                                    className={classes.textField}
                                    inputProps={{
                                        min: 1,
                                        max: this.state.max
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </GridItem>
        );
    }
}
DetailPreference.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(style)(DetailPreference);