//lingui
import { Trans } from "@lingui/macro";
import { Description, Language } from "@material-ui/icons";
import AdminDashboard from "module/admin/AdminDashboard";
import HubbleParams from "module/admin/hubbleParams/hubbleParams";
import CountryListing from "module/admin/countries/CountryListing";
import DataMigrations from "module/admin/dataMigrations/DataMigrations";
import LocationMigrate from "module/admin/locations/LocationMigrate";
import MasterValues from "module/admin/masterValues/MasterValues";
import React from "react";
import StructureMigrate from "module/admin/structures/StructureMigrate";
import Structures from "views/Administrator/Structures";

var adminRoutes = [
  {
    path: "/admin/dashboard",
    name: <Trans>Dashboard</Trans>,
    mustHaveRights: "site.admin",
    icon: Description,
    component: AdminDashboard
  },
  {
    path: "/admin/hubbleParams",
    name: <Trans>HubbleParams</Trans>,
    short: <Trans>HubbleParams</Trans>,
    icon: Language,
    component: HubbleParams
  },
  {
    path: "/admin/countries",
    name: <Trans>Countries</Trans>,
    short: <Trans>Countries</Trans>,
    icon: Language,
    component: CountryListing
  },
  {
    path: "/admin/mastervalues",
    name: <Trans>MasterValues</Trans>,
    short: <Trans>MasterValues</Trans>,
    icon: Language,
    component: MasterValues
  },
  {
    path: "/admin/structures",
    name: <Trans>Structures_Upload</Trans>,
    short: <Trans>Structures_Upload</Trans>,
    mustHaveRights: "structure.edit",
    icon: Language,
    component: StructureMigrate
  },
  {
    path: "/admin/structuresMDS",
    name: <Trans>Structures_MDS</Trans>,
    short: <Trans>Structures_MDS</Trans>,
    mustHaveRights: "structure.edit",
    icon: Language,
    component: Structures
  },
  {
    path: "/admin/locations",
    name: <Trans>Admin Locations</Trans>,
    short: <Trans>Admin Locations</Trans>,
    mustHaveRights: "site.admin",
    icon: Language,
    component: LocationMigrate
  },
  {
    path: "/admin/dataMigrations",
    name: <Trans>Data Migrations</Trans>,
    short: <Trans>Data Migrations</Trans>,
    icon: Language,
    component: DataMigrations
  }
];
export default adminRoutes;
