import { ApimGet, ApimPost } from "components/Common/ApimSender";
import { HasOneOfRights } from "services/user/UserHelper";
import { HasRight } from "services/user/UserHelper";
import * as Actions from "./types";

export function OpenPaymentCharacteristic(paymentCharacteristicId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/PaymentCharacteristics/" + paymentCharacteristicId,
      responseJson => {
        const authentifSelector = getState().AuthenticationReducer;
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_OPEN,
          paymentCharacteristic: responseJson,
          isApplicationAdmin: HasRight("application.admin"),
          isEditable: HasRight("thirdparty_supplier.edit_iban")
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_ERROR,
          error: error
        });
      }
    );
  };
}

export function CreatePaymentCharacteristic(supplierId) {
  return (dispatch, getState) => {
    if (!supplierId) {
      throw "Unable to create PaymentCharacteristic from empty supplier";
    }

    ApimGet(
      "api/TpCopernic/Suppliers/" + supplierId,
      responseJson => {
        const authentifSelector = getState().AuthenticationReducer;
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_OPEN,
          paymentCharacteristic: {
            identifier: 0,
            supplierId: supplierId,
            supplier: responseJson,
            paymentCharacteristicStatusCode: "Doubtful",
            sisScore: 50,
            sisComputedReasons: []
          },
          isApplicationAdmin: HasRight("application.admin"),
          isEditable: HasRight("thirdparty_supplier.edit_iban")
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_ERROR,
          error: error
        });
      }
    );
  };
}

export function CheckSisPaymentCharacteristics(supplierId, paymentCharacteristicId, language) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_PAYMENT_CHAR_SIS_BEGIN
    });

    ApimGet(
      "api/TpCopernic/Suppliers/" + supplierId + "/PaymentCharacteristics/" + paymentCharacteristicId + "/checkSis/" + language,
      responseJson => {
        const authentifSelector = getState().AuthenticationReducer;
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_SIS_END,
          paymentCharacteristic: responseJson,
          paymentCharacteristicId,
          isEditable: HasOneOfRights(["thirdparty_supplier.edit", "thirdparty_customer.edit"])
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_ERROR,
          error: error
        });
      }
    );
  };
}

export function EditPaymentCharacteristic(paymentCharacteristic) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_PAYMENT_CHAR_EDITVALUE,
      paymentCharacteristic: paymentCharacteristic
    });
  };
}

export function ClosePaymentCharacteristic() {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_PAYMENT_CHAR_CLOSE
    });
  };
}

export function SavePaymentCharacteristic(payChar) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_PAYMENT_CHAR_SAVE_BEGIN
    });

    ApimPost(
      "api/TpCopernic/Suppliers/" + payChar.supplierIdentifier + "/paymentCharacteristics",
      payChar,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_SAVE_END,
          payChars: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_PAYMENT_CHAR_ERROR,
          error
        });
      }
    );
  };
}
