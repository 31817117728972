import React from "react";
import ThirdPartyUnknown from "./ThirdPartyUnknown";
import { makeStyles } from "@material-ui/core/styles";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

function ThirdPartyCreation({ thirdParty, setThirdParty, errors, defaultLang }) {
  return (
    <div className="divContainer">
      <form autoComplete="off">
        <ThirdPartyUnknown thirdParty={thirdParty} setThirdParty={setThirdParty} errors={errors} defaultLang={defaultLang} />
      </form>
    </div>
  );
}

export default withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyCreation);
