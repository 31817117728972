import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "./store/actions";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import { formatISO } from "date-fns";

import { CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Breadcrumbs } from "@material-ui/core";
import CardError from "components/Card/CardError";
import Button from "components/CustomButtons/Button";
import { Trans } from "@lingui/macro";
import { StructureGet, StructureCrupdate } from "./actions/StructureActions";
import Muted from "components/Typography/Muted";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { isNullOrEmpty } from "../tools";
import { StructureGetTree } from "./actions/StructureActions";
import { HasRight } from "services/user/UserHelper";
import DialogBox from "components/DialogBox/DialogBox";
import { Alert } from "@material-ui/lab";
import StructureDetailContent from "./StructureDetailContent";
import { OpenWorkflow } from "module/workflow/store/actions";

const StructureDetail = ({ structureIdentifiant, structureType, structureToOpen, masterValues, defaultLang, isTagEditable, openWorkflow }) => {
  var dispatch = useDispatch();
  const [state, setState] = useState({ isLoading: false, isEditable: false, structure: null });
  // const [showMsgDateEndCascad, setShowMsgDateEndCascad] = useState(false);
  const [criteria, setCriteria] = useState({ dateRef: formatISO(new Date()) });
  var [dialogBox, setDialogBox] = useState(null);

  const getDefaultCountry = () => {
    var defaultCountry = "FR";
    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    if (getPref !== null) {
      defaultCountry = getPref.InfoGeneral.Country;
    }
    return defaultCountry;
  };

  useEffect(() => {
    setState({ isLoading: false, isEditable: false, structure: null });
  }, [structureIdentifiant, structureType]);

  var structure = state.structure;
  const treeLoad = (resultTree, struct) => {
    if (struct) {
      let structures = resultTree.structures;
      let relations = resultTree.relations;
      let children = [];
      let childrenStructures = [];
      if (structureType === "UE") {
        children = allChildren(struct.id, relations);
        childrenStructures = structures.filter(s => {
          if (children.filter(c => c.fromId === s.id || c.toId === s.id).length > 0 && s.id !== struct.id) {
            return s;
          }
        });
      } else if (structureType === "UP" || structureType === "EP") {
        let parents = relations.filter(f => f.toId === struct.id);
        if (parents && parents.length > 0) {
          children = allChildren(parents[0].fromId, relations);
          childrenStructures = structures.filter(s => {
            if (children.filter(c => c.fromId === s.id || c.toId === s.id).length > 0 && s.id !== struct.id) {
              return s;
            }
          });
        }
      }

      return childrenStructures;
    }
  };

  const closeDetail = confirmClose => {
    if (hasChanged() && !confirmClose) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmCloseWithoutSave </Trans>, yes: () => closeDetail(true) });
    } else {
      setDialogBox(null);
      dispatch(Actions.CloseStructure(structureType, structureIdentifiant));
    }
  };

  const hasChanged = () => {
    return JSON.stringify(structure) !== state.backupStructure;
  };

  const getStructure = (structureType, identifiant) => {
    StructureGet(
      structureType,
      identifiant,
      result => {
        if (result && (structureType === "UE" || structureType === "UP" || structureType === "EP")) {
          var dateEnd = "";
          var childs = null;
          var parents = [];
          if (structureType === "UE") {
            parents = result.parents.filter(p => p.parentTypeStructure === "SJ");
          } else if (structureType === "UP" || structureType === "EP") {
            parents = result.parents.filter(p => p.parentTypeStructure === "UE");
          }
          if (parents && parents.length == 1) {
            dateEnd = JSON.stringify(parents[0].dateEnd);
          } else if (parents && parents.length > 1) {
            // cas de plusieurs parent, prendre le plus récent
            parents = parents.sort(function(a, b) {
              if (a.dateStart < b.dateStart) return -1;
              else if (a.dateStart > b.dateStart) return 1;
              return 0;
            });
            dateEnd = JSON.stringify(parents[0].dateEnd);
          }
          StructureGetTree(
            "iris3",
            criteria.dateRef,
            resultTree => {
              childs = treeLoad(resultTree, result);
              setState({
                ...state,
                isLoading: false,
                backupStructure: JSON.stringify(result),
                childrens: childs,
                backupStructureDateEnd: dateEnd,
                structure: result,
                isEditable:
                  (result?.workflowLockId ?? 0) === 0 &&
                  (HasRight("structure.edit") || (result.structureTypeCode == "UE" && HasRight("structure.edit_ue")))
              });
            },
            e => setState({ ...state, isLoading: false, error: e, structure: result })
          );
        } else {
          setState({
            ...state,
            isLoading: false,
            structure: result,
            backupStructure: JSON.stringify(result),
            isEditable:
              (result?.workflowLockId ?? 0) === 0 &&
              (HasRight("structure.edit") || (result.structureTypeCode == "UE" && HasRight("structure.edit_ue")))
          });
        }
      },
      e => setState({ ...state, isLoading: false, error: e })
    );
  };

  const saveStructure = (confirmSave, comment) => {
    if (
      (HasRight("structure.edit") || (structure.structureTypeCode == "UE" && HasRight("structure.edit_ue"))) &&
      !HasRight("structure.admin") &&
      !confirmSave
    ) {
      setDialogBox({
        type: "okCancel",
        textbox: { title: <Trans> WF_AuthorComment </Trans>, rows: 2 },
        message: (
          <div>
            <Trans> WF_ApprobationWillSent </Trans>
            <Trans> WF_ItemWillBeLocked </Trans>
          </div>
        ),
        ok: text => {
          saveStructure(true, text);
          setDialogBox(null);
        }
      });
      return;
    }

    setState({ ...state, isLoading: true, error: null, validationErrors: null });
    StructureCrupdate(
      { structure: structure, wkfComment: comment },
      s => {
        if (!s.isSuccess) {
          var mainError = s.errors.find(e => e.severity === "Critical");
          if (mainError)
            setState({
              ...state,
              isLoading: false,
              error: { message: `FR: ${mainError.descriptionFr} / EN: ${mainError.descriptionEn}` },
              validationErrors: s.errors
            });
          else setState({ ...state, isLoading: false, error: null, validationErrors: s.errors });
          return;
        }

        if (!isNullOrEmpty(s.structureIdentifiant)) {
          getStructure(s.structureType, s.structureIdentifiant);
        } else {
          closeDetail(true);
          openWorkflow("StructureUe", s.workflowOwnerId);
        }
      },
      e => setState({ ...state, isLoading: false, error: e, validationErrors: null }),
      e => setState({ ...state, isLoading: false, error: null, validationErrors: e })
    );
  };

  var dialogTitle = "";
  var dialogContent = "";
  var dialogActions = "";
  let lockedBanner = <></>;
  if (state.isLoading) {
    dialogContent = <CircularProgress />;
  } else if (state.error) {
    dialogContent = <CardError error={state.error} />;
    dialogActions = (
      <DialogActions>
        <Button onClick={() => closeDetail(false)} color="primary">
          <Trans> Close </Trans>
        </Button>
      </DialogActions>
    );
  } else if (!structure) {
    setState({ ...state, isLoading: true });

    if (structureToOpen) {
      let isEditable =
        (structureToOpen?.workflowLockId ?? 0) === 0 &&
        (HasRight("structure.edit") || (structureToOpen.structureTypeCode == "UE" && HasRight("structure.edit_ue")));
      setState({
        ...state,
        isLoading: false,
        isEditable: isEditable,
        backupStructure: JSON.stringify(structureToOpen.id === 0 ? {} : structureToOpen),
        structure: structureToOpen
      });
    } else {
      getStructure(structureType, structureIdentifiant);
    }

    return "";
  } else {
    var btnSave = "";
    if (JSON.stringify(structure) !== state.backupStructure) {
      btnSave = (
        <Button onClick={() => saveStructure()} color="info">
          <Trans> Save </Trans>
        </Button>
      );
    }

    var btnReload = "";
    btnReload = (
      <Button
        onClick={() => {
          setState({ ...state, validationErrors: null, error: null, structure: null });
        }}
      >
        <Trans> Reload </Trans>
      </Button>
    );

    if (structure.structureTypeCode === "AREA") {
      if (structureToOpen) {
        dialogTitle = <Trans> Structure_AREA_CreateTitle </Trans>;
      } else {
        dialogTitle = (
          <>
            <Trans> Structure_Detail_Title </Trans> {structure.identifiant} - {structure.libelle}
          </>
        );
      }
    } else if (structure.structureTypeCode === "CDG") {
      if (structureToOpen) {
        dialogTitle = <Trans> Structure_CDG_CreateTitle </Trans>;
      } else {
        dialogTitle = (
          <>
            <Trans> Structure_Detail_Title </Trans> {structure.identifiant} - {structure.libelle}
          </>
        );
      }
    } else if (structure.structureTypeCode === "COMP") {
      if (structureToOpen) {
        dialogTitle = <Trans> Structure_COMP_CreateTitle </Trans>;
      } else {
        dialogTitle = (
          <>
            <Trans> Structure_Detail_Title </Trans> {structure.identifiant} - {structure.libelle}
          </>
        );
      }
    } else if (structure.structureTypeCode === "DIR") {
      if (structureToOpen) {
        dialogTitle = <Trans> Structure_DIR_CreateTitle </Trans>;
      } else {
        dialogTitle = (
          <>
            <Trans> Structure_Detail_Title </Trans> {structure.identifiant} - {structure.libelle}
          </>
        );
      }
    } else if (structure.structureTypeCode === "DIV") {
      if (structureToOpen) {
        dialogTitle = <Trans> Structure_DIV_CreateTitle </Trans>;
      } else {
        dialogTitle = (
          <>
            <Trans> Structure_Detail_Title </Trans> {structure.identifiant} - {structure.libelle}
          </>
        );
      }
    } else if (structure.structureTypeCode === "EP") {
      if (structureToOpen) {
        dialogTitle = <Trans> Structure_EP_CreateTitle </Trans>;
      } else {
        dialogTitle = (
          <>
            <Trans> Structure_Detail_Title </Trans> {structure.identifiant} - {structure.libelle}
          </>
        );
      }
    } else if (structure.structureTypeCode === "ERT") {
      var ert = structure.ert;
      if (isNullOrEmpty(structure.identifiant)) {
        ert.address_CountryCode = getDefaultCountry();
      }
      if (structureToOpen) {
        dialogTitle = <Trans> Structure_ERT_CreateTitle </Trans>;
      } else {
        dialogTitle = (
          <>
            <Trans> Structure_Detail_Title </Trans> {structure.identifiant} - {structure.libelle}
          </>
        );
      }
    } else if (structure.structureTypeCode === "SJ") {
      if (structureToOpen) {
        dialogTitle = <Trans> Structure_SJ_CreateTitle </Trans>;
      } else {
        dialogTitle = (
          <>
            <Trans> Structure_Detail_Title </Trans> {structure.identifiant} - {structure.libelle}
          </>
        );
      }
    } else if (structure.structureTypeCode === "UE") {
      if (structureToOpen) {
        dialogTitle = <Trans> Structure_UE_CreateTitle </Trans>;
      } else {
        dialogTitle = (
          <>
            <Trans> Structure_Detail_Title </Trans> {structure.identifiant} - {structure.libelle}
          </>
        );
      }
    } else if (structure.structureTypeCode === "UP") {
      if (structureToOpen) {
        dialogTitle = <Trans> Structure_UP_CreateTitle </Trans>;
      } else {
        dialogTitle = (
          <>
            <Trans> Structure_Detail_Title </Trans> {structure.identifiant} - {structure.libelle}
          </>
        );
      }
    }

    if (structure.workflowLockId > 0) {
      lockedBanner = (
        <Alert severity="warning">
          <Trans> WF_LockedUntilApprobation </Trans>
        </Alert>
      );
    }

    dialogContent = (
      <StructureDetailContent
        structure={state.structure}
        setStructure={s => setState({ ...state, structure: s })}
        masterValues={masterValues}
        defaultLang={defaultLang}
        errors={state.validationErrors}
        isEditable={state.isEditable}
        isTagEditable={isTagEditable}
      />
    );
    dialogActions = (
      <DialogActions>
        {btnSave}
        {btnReload}
        <Button onClick={() => closeDetail(false)} color={btnSave === "" ? "info" : ""}>
          <Trans> Close </Trans>
        </Button>
      </DialogActions>
    );
  }

  var dialog = (
    <Dialog open={true} onClose={() => closeDetail(false)} fullWidth={true} maxWidth="lg" aria-labelledby="form-dialog-title" scroll="paper">
      <DialogTitle id="form-dialog-title">
        <Breadcrumbs aria-label="Breadcrumb">
          <Muted>{dialogTitle}</Muted>
        </Breadcrumbs>
      </DialogTitle>
      <DialogContent
        style={{
          ...DialogInlineStyleDetail.dialogContent
        }}
        dividers={true}
      >
        {lockedBanner}
        {dialogContent}
      </DialogContent>
      {dialogActions}
    </Dialog>
  );

  return (
    <>
      {dialog}
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </>
  );

  {
    /* <Dialog open={showMsgDateEndCascad} onClose={() => setShowMsgDateEndCascad(false)}>
        <DialogContent>
          <DialogContentText>
            <div>
              <label>{<Trans>CanDateEndCascad</Trans>}</label>
              <br />
              <label>
                {" "}
                {structure && structure.identifiant}
                {state.childrens &&
                  ", " +
                    state.childrens
                      .map(c => {
                        return c.identifiant;
                      })
                      .join(", ")}{" "}
              </label>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              var str = ",";
              if (state.childrens && state.childrens.length > 0) {
                str =
                  structure.identifiant +
                  "," +
                  (state.childrens &&
                    state.childrens
                      .map(c => {
                        return c.identifiant;
                      })
                      .join(","));
              }
              saveStructure(false, str);
            }}
            color="info"
          >
            <Trans> Yes </Trans>
          </Button>
          <Button onClick={() => saveStructure(false)}>
            <Trans> No </Trans>
          </Button>
        </DialogActions>
      </Dialog> */
  }
};

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "90%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

function allChildren(structureId, relations) {
  var filteredRelations = relations.filter(s => s.fromId === structureId.toString());
  var directChildren = [];
  for (var i = 0; i < filteredRelations.length; i++) {
    let r = filteredRelations[i];
    directChildren.push(r);
    let children = allChildren(r.toId, relations);
    for (var j = 0; j < children.length; j++) {
      let p = children[j];
      directChildren.push(p);
    }
  }
  return directChildren;
}

const mapDispatchToProps = dispatch => {
  return {
    openWorkflow: (workflowTypeCode, workflowId) => {
      dispatch(OpenWorkflow(workflowTypeCode, workflowId));
    }
  };
};

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(structureStyle)(StructureDetail));
