import React from "react";
import axios from "axios";
import * as Actions from "./types";
import { isArrayNullOrEmpty, isNullOrEmpty } from "../../../tools";
import { Trans } from "@lingui/macro";
import LocalStorageHelper from "../../../../services/common/LocalStorageHelper";

import { RootStore } from "store/RootStore";
import { ApimGet, ApimPost } from "components/Common/ApimSender";

export function SetComplexCriterias(complexQuery, criterias) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SET_COMPLEX_CRITERIA,
      module: "CUSTOMERCONTRACT",
      complexCriterias: criterias,
      complexQuery: complexQuery
    });
  };
}

export function SetCriterias(criterias) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SET_CRITERIA,
      module: "CUSTOMERCONTRACT",
      criterias: criterias
    });
  };
}

export function AddItem() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_GET_END,
      module: "CUSTOMERCONTRACT",
      item: { isReadOnly: false }
    });
  };
}

export function SelectItem(item) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_GET_END,
      module: "CUSTOMERCONTRACT",
      item: item
    });
  };
}

export function SetSearchMode(searchMode) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SET_SEARCHMODE,
      module: "CUSTOMERCONTRACT",
      searchMode: searchMode
    });
  };
}

export function ItemChanged(item) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_ITEM_CHANGED,
      module: "CUSTOMERCONTRACT",
      item: item
    });
  };
}

export function Crupdate() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_UPDATE_BEGIN,
      module: "CUSTOMERCONTRACT"
    });

    const item = getState().AMC_Reducer.currentItem["CUSTOMERCONTRACT"];

    ApimPost(
      "api/CustomerContracts",
      item,
      () => {
        dispatch({
          type: Actions.AMC_UPDATE_END,
          module: "CUSTOMERCONTRACT"
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.AMC_ERROR,
          module: "CUSTOMERCONTRACT",
          error: error.response.data
        });
      }
    );
  };
}

export function GenerateNotification() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_UPDATE_BEGIN,
      module: "CUSTOMERCONTRACT"
    });

    const item = getState().AMC_Reducer.currentItem["CUSTOMERCONTRACT"];

    ApimPost(
      "api/CustomerContracts/GenerateNotification",
      item,
      () => {
        dispatch({
          type: Actions.AMC_UPDATE_END,
          module: "CUSTOMERCONTRACT"
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.AMC_ERROR,
          module: "CUSTOMERCONTRACT",
          error: error.response.data
        });
      }
    );
  };
}

export function Validate() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_UPDATE_BEGIN,
      module: "CUSTOMERCONTRACT"
    });

    const item = getState().AMC_Reducer.currentItem["CUSTOMERCONTRACT"];

    ApimPost(
      "api/CustomerContracts/Validate",
      item,
      responseJson => {
        dispatch({
          type: Actions.AMC_ERROR,
          module: "CUSTOMERCONTRACT",
          error: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.AMC_ERROR,
          module: "CUSTOMERCONTRACT",
          error: error
        });
      }
    );
  };
}

export function Get(id) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_GET_BEGIN,
      module: "CUSTOMERCONTRACT"
    });

    ApimGet(
      "api/CustomerContracts/" + id,
      responseJson => {
        dispatch({
          type: Actions.AMC_GET_END,
          module: "CUSTOMERCONTRACT",
          item: responseJson
        });
      },
      error => {
        dispatch({
          type: Actions.AMC_SEARCH_ERROR,
          error: error
        });
      }
    );
  };
}

export function ComplexSearch() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SEARCH_BEGIN,
      module: "CUSTOMERCONTRACT"
    });

    const criterias = getState().AMC_Reducer.complexCriterias["CUSTOMERCONTRACT"];
    const simpleCriterias = getState().AMC_Reducer.criterias["CUSTOMERCONTRACT"];
    var pagingAndOrderProperties = { pageSize: 10 };

    if (!!simpleCriterias) {
      pagingAndOrderProperties = {
        orderProperty: !!simpleCriterias.orderProperty && typeof simpleCriterias.orderProperty !== "undefined" ? simpleCriterias.orderProperty : null,
        isDescendingOrder: typeof simpleCriterias.isDescendingOrder !== "undefined" ? simpleCriterias.isDescendingOrder : true,
        startPage: !!simpleCriterias.startPage && typeof simpleCriterias.startPage !== "undefined" ? simpleCriterias.startPage : 0,
        pageSize: simpleCriterias.pageSize
      };
    }

    var objectCriteria = {
      sqlRequest: criterias,
      pagingAndOrderProperties: pagingAndOrderProperties
    };

    ApimPost(
      "api/CustomerContracts/ComplexSearch",
      objectCriteria,
      responseJson => {
        dispatch({
          type: Actions.AMC_SEARCH_END,
          module: "CUSTOMERCONTRACT",
          results: responseJson
        });
      },
      error => {
        dispatch({
          type: Actions.AMC_SEARCH_ERROR,
          error: error
        });
      }
    );

    const complexCriterias = getState().AMC_Reducer.complexCriterias["JOB"];
  };
}

export function Search() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.AMC_SEARCH_BEGIN,
      module: "CUSTOMERCONTRACT"
    });

    const searchMode = getState().AMC_Reducer.searchMode["CUSTOMERCONTRACT"];
    const criterias = getState().AMC_Reducer.criterias["CUSTOMERCONTRACT"];

    const queryString = ConvertCriteriaToQueryString(criterias, searchMode);
    ApimGet(
      "api/CustomerContracts" + queryString,
      responseJson => {
        dispatch({
          type: Actions.AMC_SEARCH_END,
          module: "CUSTOMERCONTRACT",
          results: responseJson
        });
      },
      error => {
        dispatch({
          type: Actions.AMC_SEARCH_ERROR,
          error: error
        });
      }
    );
  };
}

function ConvertCriteriaToQueryString(criteria, searchMode) {
  var queryString = "";
  var queryParams = [];

  if (!isNullOrEmpty(criteria.criteriaFullText) && searchMode === "Simple") {
    queryParams.push("criterias.fullText=" + criteria.criteriaFullText);
  } else if (searchMode !== "Simple") {
    if (!isNullOrEmpty(criteria.mdmId)) {
      queryParams.push("criterias.mdmId=" + criteria.mdmId);
    }
    if (!isNullOrEmpty(criteria.ertCode)) {
      queryParams.push("criterias.ertCode=" + criteria.ertCode);
    }
    if (!isNullOrEmpty(criteria.sjCode)) {
      queryParams.push("criterias.sjCode=" + criteria.sjCode);
    }
    if (!isNullOrEmpty(criteria.ueCode)) {
      queryParams.push("criterias.ueCode=" + criteria.ueCode);
    }
    if (!isNullOrEmpty(criteria.label)) {
      queryParams.push("criterias.label=" + criteria.label);
    }
    if (!isNullOrEmpty(criteria.erpIdentifier)) {
      queryParams.push("criterias.erpIdentifier=" + criteria.erpIdentifier);
    }
    if (!isNullOrEmpty(criteria.localId)) {
      queryParams.push("criterias.localId=" + criteria.localId);
    }
  }
  if (!isNullOrEmpty(criteria.orderProperty)) {
    queryParams.push("pagingAndOrder.orderProperty=" + criteria.orderProperty);
  }
  if (!isNullOrEmpty(criteria.isDescendingOrder)) {
    queryParams.push("pagingAndOrder.isDescendingOrder=" + criteria.isDescendingOrder);
  }
  if (!isNullOrEmpty(criteria.startPage)) {
    queryParams.push("pagingAndOrder.startPage=" + criteria.startPage);
  }
  if (!isNullOrEmpty(criteria.pageSize)) {
    queryParams.push("pagingAndOrder.pageSize=" + criteria.pageSize);
  }
  if (!isArrayNullOrEmpty(queryParams)) queryString = "?" + queryParams.join("&");

  return queryString;
}
