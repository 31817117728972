import * as Actions from "../actions/types";

const initialState = {
  criterias: {
    startPage: 0,
    pageSize: 10
  }
};

export default function TP_CodeMigReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.TP_COPERNIC_MIGCODE_BEGIN_ACTION:
      return {
        ...state,
        isPerforming: true
      };

    case Actions.TP_COPERNIC_MIGCODE_END_ACTION:
      return {
        ...state,
        item: action.item,
        isPerforming: false,
        showDetail: false
      };

    case Actions.TP_COPERNIC_MIGCODE_GET_ALL:
      return {
        ...state,
        result: action.result
      };

    case Actions.TP_COPERNIC_MIGCODE_GET_DETAIL:
      return {
        ...state,
        item: action.item,
        isNew: action.isNew === undefined || action.isNew === null ? state.isNew : action.isNew,
        showDetail: true
      };

    case Actions.TP_COPERNIC_MIGCODE_GET_EXISTING:
      return {
        ...state,
        existingItem: action.item
      };

    case Actions.TP_COPERNIC_MIGCODE_SAVE_END:
      return {
        ...state,
        isPerforming: false,
        item: action.item
      };

    case Actions.TP_COPERNIC_MIGCODE_SET_CRITERIAS:
      return {
        ...state,
        criterias: action.criterias
      };

    default:
      return state;
  }
}
