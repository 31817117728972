import React, { useMemo } from "react";
import { connect } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import { isArrayNullOrEmpty } from "../tools";
import MasterValueDialog from "./MasterValueDialog";
import ProductDetail from "./product/ProductDetail";
import SiteDetail from "./site/containers/SiteDetail";
import StructureDetail from "./structure/StructureDetail";
import TpColumbusTpDetail from "./tpColumbus/thirdParties/ThirdPartyDetail";
import CompanyDetail from "./tpCopernic/companies/CompanyDetail";
import TpCopernicTpDetail from "./tpCopernic/containers/ThirdPartyDetail";
import WorkflowDetail from "./workflow/components/WorkflowDetail";

const ModuleDialog = ({ moduleReducer, userInfo, userRights, masterValues }) => {
  var defaultLang = useMemo(() => userInfo?.language, [userInfo]);

  if (!moduleReducer || isArrayNullOrEmpty(moduleReducer.contextes)) {
    return <></>;
  }

  var currentContext = moduleReducer.contextes[moduleReducer.contextes.length - 1];

  if (currentContext.type === "SITE") {
    return <SiteDetail siteId={currentContext.id} defaultLang={defaultLang} masterValues={masterValues} />;
  } else if (currentContext.type === "STRUCTURE") {
    return (
      <StructureDetail
        structureIdentifiant={currentContext.identifiant}
        structureType={currentContext.structureType}
        structureToOpen={currentContext.structureToOpen}
        defaultLang={defaultLang}
        masterValues={masterValues}
        isTagEditable={userRights.isStructureTagEdit}
      />
    );
  } else if (currentContext.type === "PRODUCT") {
    return (
      <ProductDetail productId={currentContext.id} defaultLang={defaultLang} masterValues={masterValues} isEditable={userRights.isStructureEdit} />
    );
  } else if (currentContext.type === "TP_COPERNIC_COMPANY") {
    return (
      <CompanyDetail
        companyIdentifier={currentContext.id}
        isEditable={!!userRights.isAdmin || !!userRights.isSupplierAdmin}
        tabIndex={currentContext.tabIndex}
        masterValues={masterValues}
        defaultLang={defaultLang}
      />
    );
  } else if (currentContext.type === "TP_COPERNIC_THIRDPARTY") {
    return <TpCopernicTpDetail masterValues={masterValues} />;
  } else if (currentContext.type === "TP_COLUMBUS_THIRDPARTY") {
    return <TpColumbusTpDetail thirdPartyId={currentContext.id} masterValues={masterValues} defaultLang={defaultLang} userInfo={userInfo} />;
  } else if (currentContext.type === "MASTERVALUE") {
    return <MasterValueDialog />;
  } else if (currentContext.type === "WORKFLOW") {
    return <WorkflowDetail workflowTypeCode={currentContext.workflowTypeCode} workflowId={currentContext.id} />;
  }
  return <></>;
};

const mapStateToProps = state => {
  return {
    moduleReducer: state.ModuleReducer,
    authentifReducer: state.AuthenticationReducer,
    masterValues: state.MasterValueReducer,
    userRights: {
      isAdmin: HasRight("application.admin"),
      isSupplierAdmin: HasRight("thirdparty_supplier.edit"),
      isStructureTagEdit: HasRight("structure.edit_tag")
    },
    userInfo: state.AuthenticationReducer.user
  };
};

export default connect(mapStateToProps)(ModuleDialog);
