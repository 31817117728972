import React, { useState } from "react";
import { Typography, IconButton } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

//lingui
import { Trans } from "@lingui/macro";
import { Clear, Event } from "@material-ui/icons";
import { dateToUtc, getFormatDate, nextOpenDay, isValidDate } from "tools";

export default function DateSelector({ value, label, onChange, openDaysOnly, minDate, maxDate, isEditable, clearable, error, defaultLang }) {
  const [open, setOpen] = useState(false);

  if (!!isEditable) {
    const setDate = date => {
      setOpen(false);
      if (date && isValidDate(date)) {
        var d = dateToUtc(date);
        if (openDaysOnly) d = dateToUtc(nextOpenDay(new Date(d)));
        onChange(d);
      } else onChange(null);
    };

    var btnClear = clearable ? (
      <IconButton onClick={() => setDate(null)}>
        <Clear />
      </IconButton>
    ) : (
      <></>
    );

    return (
      <KeyboardDatePicker
        open={open}
        disableToolbar
        format={getFormatDate(defaultLang)}
        variant="inline"
        margin="normal"
        label={label}
        value={value}
        onChange={setDate}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        fullWidth
        invalidDateMessage={<Trans>DatePicker_Invalid</Trans>}
        error={error}
        InputProps={{
          endAdornment: (
            <>
              {btnClear}
              <IconButton onClick={() => setOpen(true)}>
                <Event />
              </IconButton>
            </>
          )
        }}
        InputAdornmentProps={{
          position: "start",
          style: {
            display: "none"
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
      />
    );
  }
  else {
    return <Typography>{value}</Typography>;
  }
}
