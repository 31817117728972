import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { Assignment, Delete } from "@material-ui/icons";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import * as Actions from "module/amc/store/actions/JobPhaseBudget.Actions";
import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";
import { JobSegmentationLevel1 } from "store/MasterValue/MasterValueTypes";
import MasterValueAutocomplete from "../../../../../../components/Autocomplete/MasterValueAutocomplete";
import PopoverConfirm from "../../../../../../components/Common/PopoverConfirm";

function JobPhaseBudgetListTab({ amc, job, selectItem, phases, isReadonly, itemChanged, deletePhase }) {
  const [selectedIndex, setSelectedIndexnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  var currentJob = !!amc.currentItem["JOB"] ? amc.currentItem["JOB"] : {};

  function ConvertDatas(items) {
    if (items == null || items.length === 0) return [];

    var datas = {
      tabItems: []
    };
    items.map((prop, key) => {
      datas.tabItems.push({
        ...prop,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={e => {
                selectItem(prop);
              }}
              color="info"
              className="like"
            >
              <Assignment />
            </Button>{" "}
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={e => {
                if (!!prop.identifier && typeof prop.identifier != "undefined") setSelectedIndexnchorEl("identifier:" + prop.identifier);
                else setSelectedIndexnchorEl("localId:" + prop.localId);
                setAnchorEl(e.currentTarget);
                //if (!!getItem && typeof getItem !== "undefined")
                //    getItem(prop.id);
              }}
              color="danger"
              className="like"
            >
              <Delete />
            </Button>{" "}
            <PopoverConfirm
              anchor={anchorEl}
              confirmHandler={() => {
                var index = -1;
                var value = selectedIndex.split(":")[1];
                if (selectedIndex.startsWith("identifier"))
                  index = items
                    .map(function(e) {
                      return e.identifier;
                    })
                    .indexOf(parseInt(selectedIndex.split(":")[1]));
                else if (selectedIndex.startsWith("localId"))
                  index = items
                    .map(function(e) {
                      return e.localId;
                    })
                    .indexOf(selectedIndex.split(":")[1]);

                if (index > -1) {
                  items.splice(index, 1);
                }
                deletePhase({
                  ...job,
                  jobPhaseBudgets: items
                });
                setAnchorEl(null);
              }}
              abortHandler={() => {
                setAnchorEl(null);
              }}
            />
          </div>
        )
      });
    });

    return datas;
  }

  function renderCodeCell(rowInfo) {
    if (!isReadonly) {
      return (
        <CustomInput
          id="code1"
          key="code1"
          formControlProps={{
            fullWidth: true
          }}
          value={rowInfo.original.code}
          onChange={e => itemChanged(currentJob, { ...rowInfo.original, actions: null, code: e.target.value })}
        />
      );
    } else {
      return <label>{rowInfo.original.code}</label>;
    }
  }

  function renderSegmentationLevel1Cell(rowInfo) {
    if (!isReadonly) {
      return (
        <MasterValueAutocomplete
          mvType={JobSegmentationLevel1}
          selectedValue={rowInfo.original.segmentationLevel1Code}
          isEditable={!isReadonly}
          prefixCode={true}
          showErrorTooltip={false}
          onValueChange={e => itemChanged(currentJob, { ...rowInfo.original, actions: null, segmentationLevel1Code: e })}
        />
      );
    } else {
      return <label>{rowInfo.original.segmentationLevel1Code}</label>;
    }
  }

  function renderExpenditureCell(rowInfo) {
    if (!isReadonly) {
      return (
        <CustomInput
          formControlProps={{
            fullWidth: true
          }}
          value={rowInfo.original.expenditure}
          onChange={e => itemChanged(currentJob, { ...rowInfo.original, actions: null, expenditure: e.target.value })}
        />
      );
    } else {
      return <label>{rowInfo.original.expenditure}</label>;
    }
  }

  const reactTableColumns = React.useMemo(
    () => [
      {
        accessor: "actions",
        width: 100,
        sortable: false,
        filterable: false
      },
      {
        Header: <Trans>Code</Trans>,
        accessor: "code",
        Cell: renderCodeCell,
        filterable: true
      },
      {
        Header: <Trans>Segmentation level 1</Trans>,
        accessor: "segmentationLevel1Code",
        Cell: renderSegmentationLevel1Cell,
        filterable: true
      },
      {
        Header: <Trans>Expenditure</Trans>,
        accessor: "expenditure",
        Cell: renderExpenditureCell,
        filterable: true
      }
    ],
    []
  );

  var items = ConvertDatas(phases);

  return (
    <div style={{ width: "99%" }}>
      <ReactTable
        data={items.tabItems}
        columns={reactTableColumns}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
        defaultPageSize={5}
      />
    </div>
  );
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: (job, item) => {
      dispatch(Actions.ItemChanged(job, item));
    },
    deletePhase: (job, phases) => {
      dispatch(Actions.DeletePhase(job, phases));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobPhaseBudgetListTab));
