import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import { CommonCountry } from "store/MasterValue/MasterValueTypes";
import { isArrayNullOrEmpty, isNull } from "../../../tools";
import PaymentCharacteristicLegalDatas from "./PaymentCharacteristicLegalDatas";

const DialogPaymentCharacteristic = function ({ paymentCharacteristic, setPaymentCharacteristic, userInfo, masterValues, errors, classes }) {
    const [openSupervision, setOpenSupervision] = useState(false);
    const [payCharState, setPayCharState] = useState(null);
    const [state, setState] = useState({});

    useEffect(() => {
        if (payCharState && payCharState.payChar) {
            let payChar = payCharState.payChar;
            let isAdmin = userInfo.isAdmin;
            let isIbanEditable = false;
            let isDateEditable = false;

            if (userInfo.canAdd || userInfo.canEdit) {
                let supplierErtIds = [...new Set(payChar.supplier.supplierSjs.map(supplierSj => supplierSj.sj?.ertIdentifier))];
                let allowedErts =
                    !isNull(userInfo.structures) &&
                    userInfo.structures.erts.some(ert => supplierErtIds.some(sErtId => ert.identifier === sErtId)) &&
                    masterValues[CommonCountry].some(
                        c =>
                            c.code === payChar.supplier.thirdParty.address_CountryCode &&
                            !isArrayNullOrEmpty(c.allowedPayCharTypes) &&
                            c.allowedPayCharTypes.some(p => p === payChar.paymentCharacteristicTypeCode) &&
                            !!c.allowPayCharManualEdit
                    );
                let needApprobationScore50 = allowedErts && payChar.sisScore === 50 && userInfo.needApprobation;
                let needApprobationAnyScore = allowedErts && userInfo.needApprobation;

                if(allowedErts && !(userInfo.isAdmin || needApprobationScore50 || payChar.sisScore <= 70))
                {
                    isIbanEditable = false;
                }
                else if(userInfo.isAdmin || needApprobationScore50 || allowedErts || payChar.sisScore <= 70)
                {
                    isIbanEditable = true;
                }
                isDateEditable = userInfo.isAdmin || needApprobationAnyScore || allowedErts;
            }

            setState({ isAdmin, isIbanEditable, isDateEditable });
        }
    }, [userInfo, payCharState]);

    if (payCharState && !paymentCharacteristic) {
        setPayCharState(null);
    } else if (!payCharState && paymentCharacteristic)
        setPayCharState({ payChar: paymentCharacteristic, jsonBackup: JSON.stringify(paymentCharacteristic), hasChanged: false });

    if (!payCharState || !paymentCharacteristic) return <></>;

    const erts = userInfo.structures.erts.filter(ert =>
        paymentCharacteristic.supplier.supplierSjs.some(ssj => ssj.sj.ertIdentifier === ert.identifier)
    );

    return (
        <>
            <PaymentCharacteristicLegalDatas
                payChar={payCharState.payChar}
                errors={errors}
                isIbanEditable={state.isIbanEditable}
                isDateEditable={state.isDateEditable}
                isAdmin={state.isAdmin}
                needApprobation={userInfo.needApprobation}
                savePaymentCharacteristic={setPaymentCharacteristic}
                classes={classes}
                erts={erts}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        masterValues: state.MasterValueReducer,
        userInfo: {
            isAdmin: HasRight("application.admin"),
            canAdd: HasRight("thirdparty_supplier.add_iban"),
            canEdit: HasRight("thirdparty_supplier.edit_iban"),
            needApprobation:
                // IMPLICITEMENT MDMS_TPS_PCENTRY
                HasRight("thirdparty_supplier.edit_iban") && !HasRight("thirdparty_supplier.edit") && !HasRight("thirdparty_workflow.edit_iban"),
            structures: state.AuthenticationReducer.user.structureTps
        }
    };
};



export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(DialogPaymentCharacteristic));
