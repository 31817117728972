import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";

function SupervisionGlobalContainer({ ...props }) {
    const {
        name,
        children
    } = props;
    return (
        <>
            <Card
                style={{
                    ...CardInlineStyle.card
                }}>
                <CardHeader
                    style={{
                        ...CardInlineStyle.cardHeader
                    }}>
                    <h4>{name}</h4>
                </CardHeader>
                <CardBody>
                    {children}
                </CardBody>
            </Card>
        </>
    );
}

const CardInlineStyle = {
    card: {
        marginTop: "10px",
    },
    cardHeader: {
        backgroundColor: "rgba(0, 172, 193, 0.6)",
        margin: "0",
        paddingLeft: "10px",
        color: "white"
    }
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcSearchStyle)(SupervisionGlobalContainer));