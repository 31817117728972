import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import OldStructureAutocompleteWithDefaultValue from "components/Autocomplete/OldStructureAutocompleteWithDefaultValue";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import { displayWarningFromErrorMessages, getErrorMessage, getErrorStateFromErrorMessages } from "module/tools";
import React from "react";
import { connect } from "react-redux";
import { StructureUe } from "store/MasterValue/MasterValueTypes";

function CustomerContractIdentifierAndSourcePart({ amc, structureUes, itemChanged, isReadonly }) {
  var item = !!amc.currentItem["CUSTOMERCONTRACT"] ? amc.currentItem["CUSTOMERCONTRACT"] : {};
  var warnings = amc.errors["CUSTOMERCONTRACT"];

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Identifiers and sources</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/LABEL")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "CUSTOMERCONTRACT/GENERAL/LABEL")}>
              <Trans>Label</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="label"
              value={item.label}
              showErrorTooltip={false}
              error={getErrorMessage(warnings, "CUSTOMERCONTRACT/GENERAL/LABEL")}
              onChange={e => itemChanged({ ...item, label: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Id Erp</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText isEditable={!isReadonly} id="erpId" value={item.erpId} onChange={e => itemChanged({ ...item, erpId: e.target.value })} />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Local Identifier</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="localIdentifier"
              value={item.applicationSourceIdentifier}
              onChange={e => itemChanged({ ...item, applicationSourceIdentifier: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Local Application</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={false}
              id="applicationSource"
              value={item.applicationSource}
              onChange={e => itemChanged({ ...item, applicationSource: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Ue</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <OldStructureAutocompleteWithDefaultValue
              selectedValue={item.ueIdentifier}
              readOnly={isReadonly}
              structures={structureUes}
              onValueChange={e => itemChanged({ ...item, ueIdentifier: e })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    structureUes: state.MasterValueReducer[StructureUe]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(CustomerContractIdentifierAndSourcePart));
