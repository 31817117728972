import React from "react";
import ThirdPartyList from "module/tpCopernic/thirdParties/ThirdPartyListing";
import HelpPage from "module/tpCopernic/containers/Help";
import CompanyList from "module/tpCopernic/companies/CompanyListing";
import PaymentCharacteristicList from "module/tpCopernic/containers/PaymentCharacteristicList";
import DunsMonitoring from "module/tpCopernic/containers/DunsMonitoring";
import CodeMig from "module/tpCopernic/containers/CodeMig";
import Search from "@material-ui/icons/Search";
import Build from "@material-ui/icons/Build";
import EuroSymbol from "@material-ui/icons/EuroSymbol";
//lingui
import { Trans } from "@lingui/macro";
import { Help } from "@material-ui/icons";

const thirdpartyRoutes = [
  {
    path: "/tpCopernic/ThirdPartySearch",
    name: <Trans>Third parties Search</Trans>,
    short: <Trans>Third parties Search</Trans>,
    icon: Search,
    useAsComponentInMenu: true,
    component: ThirdPartyList
  },
  {
    path: "/tpCopernic/CompanySearch",
    name: <Trans>Companies Search</Trans>,
    short: <Trans>Companies Search</Trans>,
    icon: Search,
    component: CompanyList
  },
  {
    path: "/tpCopernic/PaymentCharacteristicSearch",
    name: <Trans>PaymentCharacteristicSearch</Trans>,
    short: <Trans>PaymentCharacteristicSearch</Trans>,
    icon: EuroSymbol,
    component: PaymentCharacteristicList
  },
  {
    path: "/tpCopernic/MigCodeManagement",
    name: <Trans>Mig code Management</Trans>,
    short: <Trans>Mig code Management</Trans>,
    icon: Build,
    component: CodeMig,
    role: "application.admin"
  },
  {
    path: "/tpCopernic/DunsMonitoring",
    name: <Trans>Duns Monitoring</Trans>,
    short: <Trans>Duns Monitoring</Trans>,
    icon: Build,
    component: DunsMonitoring,
    role: "application.admin"
  },
  {
    path: "/tpCopernic/Help",
    name: <Trans>Help</Trans>,
    short: <Trans>Help</Trans>,
    icon: Help,
    component: HelpPage
  }
];

export default thirdpartyRoutes;
