import * as Actions from "../actions/types";

const initialState = {
  criterias: {
    startPage: 0,
    pageSize: 10
  }
};

export default function ST_ColCodeReducer(state = initialState, action) {
  switch (action.type) {
    case Actions.STRUCTURE_COLCODE_BEGIN_ACTION:
      return {
        ...state,
        isPerforming: true
      };

    case Actions.STRUCTURE_COLCODE_END_ACTION:
      return {
        ...state,
        item: action.item,
        isPerforming: false,
        showDetail: false
      };

    case Actions.STRUCTURE_COLCODE_GET_ALL:
      return {
        ...state,
        result: action.result
      };

    case Actions.STRUCTURE_COLCODE_GET_DETAIL:
      return {
        ...state,
        item: action.item,
        isNew: action.isNew === undefined || action.isNew === null ? state.isNew : action.isNew,
        showDetail: true
      };

    case Actions.STRUCTURE_COLCODE_GET_EXISTING:
      return {
        ...state,
        existingItem: action.item
      };

    case Actions.STRUCTURE_COLCODE_SAVE_END:
      return {
        ...state,
        isPerforming: false,
        item: { ...state.item, id: action.id }
      };

    case Actions.STRUCTURE_COLCODE_SAVE_ERROR:
      return {
        ...state,
        isPerforming: false
      };

    case Actions.STRUCTURE_COLCODE_SET_CRITERIAS:
      return {
        ...state,
        criterias: action.criterias
      };

    default:
      return state;
  }
}
