import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table-6';
import { connect, useDispatch } from 'react-redux';
import * as Actions from 'module/amc/store/actions/CustomerContract.Actions';
import * as Types from 'module/amc/store/actions/types';


// custom components

import { Breadcrumbs, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Typography, Link } from '@material-ui/core';
import { Lock, LockOpen, RefreshOutlined, ErrorOutlined, CheckCircleOutlined, BusinessCenter, Ballot, Store, Warning } from '@material-ui/icons';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CountryAutocomplete from 'components/Autocomplete/CountryAutocomplete';
import Select, { components } from 'react-select';
// helpers
import LocalStorageHelper from "services/common/LocalStorageHelper";

//lingui
import { Trans } from '@lingui/macro';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import amcStyle from "assets/jss/material-dashboard-pro-react/components/amcStyle.jsx";
import JobPhaseDetail from '../components/jobs/JobPhaseDetail';

function JobPhasePopup({ amc, closePopup, classes }) {
    const dispatch = useDispatch();

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");

    var showPopup = amc.showDetail['JOBPHASE'];
    var loading = amc.loading['JOBPHASE'];
    var item = amc.currentItem['JOBPHASE'];

    const isReadonly = false;


    var buttonSave = "";
    if (!isReadonly) {
        buttonSave = (
            <Button color="info">
                <Trans>Save</Trans>
            </Button>
        );
    }

    var breadcrumbs = "";
    if (!!item) {
        breadcrumbs = (
            <div>
                <Trans>Job Phase</Trans>&nbsp;({item.code})
            </div>
        );
    }

    var popup = "";
    if (showPopup) {
        var popupContent = <CircularProgress />;
        if (!loading) popupContent = <JobPhaseDetail isReadonly={isReadonly} />;
        popup = (
            <Dialog
                fullWidth={true}
                maxWidth='lg'
                open
                onClose={closePopup}
                aria-labelledby="form-dialog-title"
                classes={{ paper: classes.dialogPaper }}
                scroll="paper">
                <DialogTitle>
                    <Breadcrumbs aria-label="Breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </DialogTitle>
                <DialogContent
                    style={{
                        ...DialogInlineStyleDetail.dialogContent
                    }}
                    dividers={true}>
                    {popupContent}
                </DialogContent>
                <DialogActions>
                    {buttonSave}
                    <Button onClick={closePopup} color="info">
                        <Trans>Close</Trans>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    return (
        <div>
            {popup}
        </div>
    );
}


const DialogInlineStyleDetail = {
    dialogContent: {
        padding: '0px 10px 0px',
        height: '95%'
    },
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    }
};

const mapStateToProps = state => {
    return {
        amc: state.AMC_Reducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCriterias: criterias => {
            dispatch(Actions.SetCriterias(criterias))
        },
        search: () => {
            dispatch(Actions.Search())
        },
        closePopup: () => {
            dispatch({
                type: Types.AMC_END_EDITING,
                module: 'JOBPHASE'
            });
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(amcStyle)(JobPhasePopup));