import { Trans } from "@lingui/macro";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Add, Close, Delete, FileCopy, Flag, Lock, MoreVert } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import CardError from "components/Card/CardError";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted";
import { OpenCompany } from "module/tpCopernic/companies/store/actions";
import * as ThirdPartyActions from "module/tpCopernic/store/actions";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch } from "react-redux";
import { CommonCountry } from "store/MasterValue/MasterValueTypes";
import { isNullOrEmpty } from "tools";
import ThirdPartySelect from "../../../../tpCopernic/thirdParties/ThirdPartySelect";
import { extractErrorMessage } from "../../../../tools";
import { GetTpThirdParty, GetTpCompany } from "module/structure/actions/StructureActions";

export default function PartThirdParties({ structure, setStructure, copyDataFromTp, isEditable, masterValues, defaultLang, errors }) {
  const dispatch = useDispatch();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [currentThirdParty, setCurrentThirdParty] = useState(null);
  const [addDialogState, setAddDialogState] = useState({ open: false, wait: false, errors: [] });

  var thirdParties, setThirdParties, maxCount;

  if (!structure) return <></>;

  thirdParties = structure.thirdParties;
  setThirdParties = thirdParties => setStructure({ ...structure, thirdParties: thirdParties });

  if (structure.structureTypeCode === "EP" || structure.structureTypeCode === "UP") {
    maxCount = 1;
  }

  let bannerError = <></>;
  let errorTp = extractErrorMessage(errors, "LINK_TP", defaultLang);
  if (errorTp) {
    bannerError = <Alert severity="error">{errorTp}</Alert>;
  }

  return (
    <>
      {bannerError}
      <GridContainer>
        {thirdParties
          .filter(t => t)
          .sort((a, b) => (a.isMain === b.isMain ? 0 : a.isMain ? -1 : 1))
          .map(tp => {
            var card = <></>;
            if (tp.thirdPartyId) {
              card = (
                <CardThirdParty
                  key={`TP_${tp.thirdPartyId}`}
                  link={tp}
                  isEditable={isEditable}
                  onMenuOpen={e => {
                    setCurrentThirdParty(tp);
                    setMenuAnchor(e.currentTarget);
                  }}
                  dispatch={dispatch}
                  masterValues={masterValues}
                  defaultLang={defaultLang}
                  error={extractErrorMessage(errors, "LINK_TP_" + tp.thirdPartyId)}
                />
              );
            } else if (tp.companyId) {
              card = (
                <CardCompany
                  key={`COMP_${tp.companyId}`}
                  link={tp}
                  isEditable={isEditable}
                  onMenuOpen={e => {
                    setCurrentThirdParty(tp);
                    setMenuAnchor(e.currentTarget);
                  }}
                  dispatch={dispatch}
                  masterValues={masterValues}
                  defaultLang={defaultLang}
                  error={extractErrorMessage(errors, "LINK_COMP_" + tp.companyId)}
                />
              );
            } else {
              card = <CardError key={`TP_ERROR`} error={{ message: "Ce type de tiers est inconnu" }} />;
            }

            return (
              <GridItem xs={4} sm={4} md={4}>
                {card}
              </GridItem>
            );
          })}
        {isEditable && (!maxCount || thirdParties.length < maxCount) ? (
          <GridItem xs={5} sm={5} md={5}>
            <Button variant="contained" startIcon={<Add />} size="small" onClick={() => setAddDialogState({ ...addDialogState, open: true })}>
              <Trans>Structure_Tp_Add</Trans>
            </Button>
          </GridItem>
        ) : (
          <></>
        )}
      </GridContainer>
      <Menu
        id="tpMenuActions"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "40ch"
          }
        }}
      >
        {currentThirdParty && !currentThirdParty.isMain
          ? [
              <MenuItem
                onClick={() => {
                  setThirdParties(
                    thirdParties.map(tp => {
                      tp.isMain = tp.thirdPartyId === currentThirdParty.thirdPartyId;
                      return tp;
                    })
                  );
                  setMenuAnchor(null);
                }}
              >
                <ListItemIcon>
                  <Flag fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={<Trans>Structure_Tp_SetMain</Trans>} />
              </MenuItem>
            ]
          : []}
        <MenuItem
          onClick={() => {
            var data = {};
            if (currentThirdParty.thirdPartyId) {
              GetTpThirdParty(
                currentThirdParty.thirdPartyId,
                tp => {
                  data = {
                    address_Line1: tp.address_Line1,
                    address_Line2: tp.address_Line2,
                    address_ZipCode: tp.address_ZipCode,
                    address_City: tp.address_City,
                    address_CountyCode: tp.address_CountyCode,
                    address_StateCode: tp.address_StateCode,
                    address_CountryCode: tp.address_CountryCode,
                    raisonSociale: tp.customLongName,
                    raisonSocialeComplet: tp.longName,
                    legalIdentifier: tp.legalIdentifier,
                    taxCode: tp.taxCode
                  };
                  copyDataFromTp(data);
                  setCurrentThirdParty(null);
                  setMenuAnchor(null);
                },
                e => {
                  console.error(e);
                }
              );
            } else if (currentThirdParty.companyId) {
              GetTpCompany(
                currentThirdParty.companyId,
                comp => {
                  data = {
                    address_Line1: comp.address_Line1,
                    address_Line2: comp.address_Line2,
                    address_ZipCode: comp.address_ZipCode,
                    address_City: comp.address_City,
                    address_CountyCode: comp.address_CountyCode,
                    address_StateCode: comp.address_StateCode,
                    address_CountryCode: comp.address_CountryCode,
                    raisonSociale: comp.longName,
                    raisonSocialeComplet: comp.originalLongName,
                    legalIdentifier: comp.legalIdentifier,
                    taxCode: comp.taxCode
                  };
                  copyDataFromTp(data);
                  setCurrentThirdParty(null);
                  setMenuAnchor(null);
                },
                e => {
                  console.error(e);
                }
              );
            }
          }}
        >
          <ListItemIcon>
            <FileCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Trans>Structure_Tp_CopyData</Trans>} />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            var tps = thirdParties.filter(tp => tp.thirdPartyId !== currentThirdParty.thirdPartyId);
            if (!!currentThirdParty.isMain && tps.length >= 1) {
              tps[0].isMain = true;
            }
            setThirdParties(tps);
            setCurrentThirdParty(null);
            setMenuAnchor(null);
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Trans>Structure_Tp_Delete</Trans>} />
        </MenuItem>
      </Menu>
      <DialogThirdParty
        thirdParties={thirdParties}
        setThirdParties={setThirdParties}
        addDialogState={addDialogState}
        setAddDialogState={setAddDialogState}
        defaultLang={defaultLang}
      />
    </>
  );
}

const CardThirdParty = ({ link, isEditable, onMenuOpen, dispatch, masterValues, defaultLang, error }) => {
  const [tp, setTp] = useState(undefined);
  const [alert, setAlert] = useState(undefined);

  useEffect(() => {
    GetTpThirdParty(
      link.thirdPartyId,
      tp => setTp(tp),
      e => {
        console.error(e);
        setAlert(e);
      }
    );
  }, [link]);

  var banner;
  if (alert) {
    banner = <Alert severity="error">{alert}</Alert>;
  } else if (error) {
    banner = <Alert severity="error">{error}</Alert>;
  }

  if (banner) return banner;
  if (!tp)
    return (
      <div>
        <CircularProgress />
      </div>
    );

  const country = masterValues[CommonCountry].find(c => c.codeIso2 === tp.address_CountryCode);

  var zipCity = <></>;
  if (!isNullOrEmpty(tp.address_ZipCode) && !isNullOrEmpty(tp.address_City))
    zipCity = (
      <>
        {tp.address_ZipCode} {tp.address_City}
        <br />
      </>
    );

  var card = (
    <Card style={CardInlineStyle.tpCard} variant="outlined">
      <CardHeader
        avatar={link.isMain ? <Flag style={{ color: "orange" }} /> : <></>}
        action={
          isEditable ? (
            <IconButton onClick={e => onMenuOpen(e)}>
              <MoreVert />
            </IconButton>
          ) : (
            <></>
          )
        }
        title={
          <Button size="small" onClick={() => dispatch(ThirdPartyActions.OpenThirdParty(tp.identifier))} style={{ textDecoration: "underline" }}>
            {tp.customLongName}
          </Button>
        }
        style={{
          ...CardInlineStyle.tpCardHeader
        }}
      />
      <CardContent
        style={{
          ...CardInlineStyle.tpCardHeader
        }}
      >
        {banner}
        <GridContainer>
          <GridItem xs={5} sm={5} md={5}>
            <Muted>
              <Trans>LegalIdentifier</Trans>
            </Muted>
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            <Muted>{tp.legalIdentifier}</Muted>
          </GridItem>
          <GridItem xs={5} sm={5} md={5}>
            <Muted>
              <Trans>TaxCode</Trans>
            </Muted>
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            <Muted>{tp.taxCode}</Muted>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Muted>
              {tp.address_Line1}
              <br />
              {tp.address_Line2}
              <br />
              {zipCity}
              <ReactCountryFlag countryCode={tp.address_CountryCode} svg style={{ width: "1.5em", height: "1.5em" }} />
              &nbsp;{GetMasterValueLabel(country, defaultLang)}
            </Muted>
          </GridItem>
        </GridContainer>
      </CardContent>
      <CardActions>
        <Chip size="small" label={<Trans>Structure_Tp_ShortTp</Trans>} />
        {tp.thirdPartyStatusCode === "Closed" ? (
          <Tooltip title={<Trans>ThirdParty_Status_Closed</Trans>}>
            <Lock style={{ color: "red" }} />
          </Tooltip>
        ) : (
          <></>
        )}
      </CardActions>
    </Card>
  );

  return card;
};

const CardCompany = ({ link, isEditable, onMenuOpen, dispatch, masterValues, defaultLang, error }) => {
  const [company, setCompany] = useState(undefined);
  const [alert, setAlert] = useState(undefined);

  useEffect(() => {
    GetTpCompany(
      link.companyId,
      tp => setCompany(tp),
      e => {
        console.error(e);
        setAlert(e);
      }
    );
  }, [link]);

  var banner;
  if (alert) {
    banner = <Alert severity="error">{alert}</Alert>;
  } else if (error) {
    banner = <Alert severity="error">{error}</Alert>;
  }

  if (banner) return banner;
  if (!company)
    return (
      <div>
        <CircularProgress />
      </div>
    );
  const country = masterValues[CommonCountry].find(c => c.codeIso2 === company.address_CountryCode);

  var zipCity = <></>;
  if (!isNullOrEmpty(company.address_ZipCode) && !isNullOrEmpty(company.address_City))
    zipCity = (
      <>
        {company.address_ZipCode} {company.address_City}
        <br />
      </>
    );

  var style = { ...CardInlineStyle.tpCard };
  if (error) {
    style = {
      ...style,
      tpCard: { ...style.tpCard, backgroundColor: "#CC0000" }
    };
  }

  var card = (
    <Card style={style} variant="outlined">
      <CardHeader
        avatar={link.isMain ? <Flag style={{ color: "orange" }} /> : <></>}
        action={
          isEditable ? (
            <IconButton onClick={e => onMenuOpen(e)}>
              <MoreVert />
            </IconButton>
          ) : (
            <></>
          )
        }
        title={
          <Button size="small" onClick={() => dispatch(OpenCompany(company.identifier))} style={{ textDecoration: "underline" }}>
            {company.longName}
          </Button>
        }
        style={{
          ...CardInlineStyle.tpCardHeader
        }}
      />
      <CardContent
        style={{
          ...CardInlineStyle.tpCardHeader
        }}
      >
        <GridContainer>
          <GridItem xs={5} sm={5} md={5}>
            <Muted>
              <Trans>LegalIdentifier</Trans>
            </Muted>
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            <Typography variant="body2">{company.legalIdentifier}</Typography>
          </GridItem>
          <GridItem xs={5} sm={5} md={5}>
            <Muted>
              <Trans>TaxCode</Trans>
            </Muted>
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            <Typography variant="body2">{company.taxCode}</Typography>
          </GridItem>
          <GridItem xs={5} sm={5} md={5}>
            <Muted>
              <Trans>Adresse</Trans>
            </Muted>
          </GridItem>
          <GridItem xs={7} sm={7} md={7}>
            <Typography variant="body2">
              {company.address_Line1}
              <br />
              {company.address_Line2}
              <br />
              {zipCity}
              <ReactCountryFlag countryCode={company.address_CountryCode} svg style={{ width: "1.5em", height: "1.5em" }} />
              &nbsp;{GetMasterValueLabel(country, defaultLang)}
            </Typography>
          </GridItem>
        </GridContainer>
      </CardContent>
      <CardActions>
        <Chip size="small" label={<Trans>Structure_Tp_ShortComp</Trans>} />
        {company.companyStatusCode === "Closed" ? (
          <Tooltip title={<Trans>ThirdParty_Status_Closed</Trans>}>
            <Lock style={{ color: "red" }} />
          </Tooltip>
        ) : (
          <></>
        )}
      </CardActions>
    </Card>
  );

  if (error) {
    card = <Tooltip title={error}>{card}</Tooltip>;
  }
  return card;
};

const DialogThirdParty = ({ thirdParties, setThirdParties, addDialogState, setAddDialogState, defaultLang }) => {
  const addThirdParty = tp => {
    const saveAndClose = () => {
      setThirdParties([
        ...thirdParties,
        { discriminator: "ThirdParty", thirdPartyId: tp.identifier, thirdParty: tp, isMain: thirdParties.length === 0 }
      ]);

      setAddDialogState({ ...addDialogState, open: false, wait: false, errors: [] });
    };

    const throwErrors = errors => {
      setAddDialogState({ ...addDialogState, wait: false, errors: errors });
    };

    if (tp && thirdParties.some(t => t.thirdPartyId === tp.identifier)) {
      throwErrors([<Trans>Structure_Tp_AlreadySet</Trans>]);
      return;
    }

    if (!tp) {
      throwErrors([<Trans>Structure_Tp_NotSet</Trans>]);
      return;
    }

    saveAndClose();
  };

  return (
    <Dialog
      onClose={() => setAddDialogState({ ...addDialogState, open: false })}
      aria-labelledby="simple-dialog-title"
      open={addDialogState.open}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        <Trans>Structure_Tp_LinkTitle</Trans>
        <IconButton
          aria-label="close"
          style={{ position: "absolute", right: 0 }}
          onClick={() => setAddDialogState({ ...addDialogState, open: false })}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {addDialogState.errors.map((e, idx) => (
          <Alert severity="error" key={"error_" + idx}>
            {e}
          </Alert>
        ))}
        <DialogContentText>
          <ThirdPartySelect
            setThirdParty={addThirdParty}
            initialCriteria={{ localSearch: true, categories: ["COL"] }}
            searchModes={["Extend"]}
            clearable
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px",
    color: "white"
  },
  tpCard: {
    margin: 0,
    padding: 0
  },
  tpCardHeader: {
    padding: "4px 16px",
    marginTop: 0,
    marginBottom: 0
  },
  tpCardContent: {
    padding: "4px 16px",
    fontSize: "0.8rem",
    marginTop: 0,
    marginBottom: 0
  }
};
