import withStyles from "@material-ui/core/styles/withStyles";
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";
import cx from "classnames";
import ErrorBoundary from "components/ErrorBoundary";
import Footer from "components/Footer/Footer.jsx";
import Header from "components/Header/Header.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { HasOneOfRights, HasRight } from "services/user/UserHelper";
import { setFirstLoad } from "store/Authentication/Actions";
import { isNull } from "tools";
import ModuleDialog from "../module/ModuleDialog";
import adminRoutes from "../routes/admin";
import amcRoutes from "../routes/amc";
import dashboardRoutes from "../routes/dashboard";
import productRoutes from "../routes/product";
import profilRoutes from "../routes/Profil";
import siteRoutes from "../routes/site";
import structureRoutes from "../routes/structure";
import supervisionRoutes from "../routes/supervision";
import tpCopernicRoutes from "../routes/tpCopernic";
import tpColumbusRoutes from "../routes/tpColumbus";
import workflowRoutes from "../routes/workflow";
import InfoBanner from "components/Banner/InfoBanner";
import classNames from "classnames";

const GlobalLayout = ({ authenticationCtx, hubbleParamsCtx, changeFirstLoad, classes }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [miniFixed, setMiniFixed] = useState(true);
  var location = useLocation();

  const routes = useMemo(() => {
    return [
      ...dashboardRoutes,
      ...tpCopernicRoutes,
      ...tpColumbusRoutes,
      ...workflowRoutes,
      ...amcRoutes,
      ...siteRoutes,
      ...structureRoutes,
      ...productRoutes,
      ...profilRoutes,
      ...adminRoutes,
      ...supervisionRoutes
    ];
  }, []);

  const mainPanel = cx(classes.mainPanel, {
    [classes.mainPanelSidebarMini]: !miniFixed,
    [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
  });

  if (authenticationCtx.firstLoad) {
    changeFirstLoad(false);
    let isAdmin = HasOneOfRights(["site.admin", "structure.edit", "application.admin"]);
    let isCreator = HasRight("thirdparty_workflow.view");
    if (location.pathname === "/" && isCreator && !isAdmin) {
      return <Redirect to="/workflows" />;
    }
  }
  return (
    <div className={classes.wrapper}>
      <Sidebar routes={routes} setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} miniFixed={miniFixed} />
      <div className={mainPanel}>
        <InfoBanner />
        <Header
          sidebarMinimize={() => setMiniFixed(!miniFixed)}
          miniActive={miniFixed}
          routes={routes}
          handleDrawerToggle={() => setDrawerOpen(!drawerOpen)}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}

        <div className={classes.content}>
          <div className={classes.container}>
            <ErrorBoundary>
              <Switch>
                {routes
                  .filter(r => !isNull(r))
                  .map((prop, key) => {
                    if (prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                    if (prop.collapse)
                      return prop.views.map((prop, key) => {
                        return <Route path={prop.path} exact component={prop.component} key={key} />;
                      });
                    return <Route path={prop.path} exact component={prop.component} key={key} />;
                  })}
                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </ErrorBoundary>
          </div>
        </div>
        <Footer fluid />
      </div>
      <ModuleDialog />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authenticationCtx: state.AuthenticationReducer,
    hubbleParamsCtx: state.HubbleParamReducer.HubbleParams
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeFirstLoad: () => dispatch(setFirstLoad(false))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appStyle)(GlobalLayout));
