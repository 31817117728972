import { ApimGetPromise, ApimPost, ApimDelete, ApimPut, ApimGet } from "components/Common/ApimSender";
import { RootStore } from "store/RootStore";

export const ADD_HUBBLEPARAMS = "ADD_HUBBLEPARAMS";
const hubbleParamsEndpoint = "api/HubbleParameters";
//ADD Module
export function addHubbleParams(hubbleParams) {
  return { type: ADD_HUBBLEPARAMS, hubbleParams };
}

export const LoadHubbleParams = () => {
  return new Promise((callback, reject) => {
    ApimGetPromise("api/HubbleParameters")
      .then(c => {
        RootStore.dispatch(addHubbleParams(c));
        callback(c);
      })
      .catch(e => reject(e));
  });
};

export function AddHubbleParam(data, callbackFn, errorFn) {
  ApimPost(`${hubbleParamsEndpoint}/${data.code}`, data, c => callbackFn(c), e => errorFn(e));
}
export function DeleteHubbleParam(data) {
  ApimDelete(`${hubbleParamsEndpoint}/${data.code}`);
}
export function UpdateHubbleParam(data, callbackFn, errorFn) {
  ApimPut(`${hubbleParamsEndpoint}/${data.code}`, data, c => callbackFn(c), e => errorFn(e));
}
export function GetHubbleParamHistory(code, callbackFn, errorFn) {
  ApimGet(`${hubbleParamsEndpoint}/${code}/histories`, c => callbackFn(c), e => errorFn(e));
}