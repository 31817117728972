import * as Actions from './types';

export function OpenSite(siteId) {
    return (dispatch) => {
        return dispatch({
            type: Actions.SITE_OPEN,
            siteId
        });
    };
}

export function CloseSite(siteId) {
    return (dispatch) => {
        return dispatch({
            type: Actions.SITE_CLOSE,
            siteId
        });
    };
}