import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { isNull, isNullOrEmpty, isArrayNullOrEmpty } from 'tools';
import { CircularProgress } from "@material-ui/core";
import GridContainer from '../../../../components/Grid/GridContainer';
import GridItem from '../../../../components/Grid/GridItem';
import { Block, CheckCircle } from '@material-ui/icons';
import { green, red, orange } from "@material-ui/core/colors";

function SupervisionGlobalItem({...props}) {
    const {
        name,
        status
    } = props;

    var statusComponent = <></>;
    if (!isNullOrEmpty(status)) {
        if (status.toUpperCase() === 'OK')
            statusComponent = <CheckCircle style={{ color: green[500] }} />;
        else if (status.toUpperCase() === 'SEARCHING')
            statusComponent = <CircularProgress />;
        else if (status.toUpperCase() === 'EMPTY')
            statusComponent = <Block style={{ color: orange[500] }} />;
        else
            statusComponent = <Block style={{ color: red[500] }} />;
    }


    return (
        <GridContainer>
            <GridItem xs={11}>
                <li>
                    <span>{name}</span>
                </li>
            </GridItem>
            <GridItem xs={1}>
                {statusComponent}
            </GridItem>
        </GridContainer>
    );
}

export default SupervisionGlobalItem;