import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  CardHeader,
  IconButton,
  CardContent,
  Menu,
  MenuItem,
  Button,
  Tooltip,
  CardActions,
  Dialog,
  DialogContent,
  CircularProgress,
  Tab,
  Tabs
} from "@material-ui/core";
import { MoreVert, Flag, Lock, Add, Close } from "@material-ui/icons";
import { Trans } from "@lingui/macro";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import * as LocationActions from "module/site/store/actions";
import Muted from "components/Typography/Muted";
import { isArrayNullOrEmpty, isNullOrEmpty } from "tools";
import { Alert } from "@material-ui/lab";
import SiteSelect from "../../../../site/components/SiteSelect";
import { TabPanel } from "components/Common/Tabs";
import SiteWizard from "module/site/containers/SiteWizard";
import { SiteGet } from "module/site/actions/SiteActions";
import { HasRight } from "services/user/UserHelper";
import { extractErrorMessage } from "module/tools";

export default function PartLocations({ structure, setStructure, isEditable, defaultLang, errors }) {
  const dispatch = useDispatch();
  const [currentLocation, setCurrentLocation] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [addDialogState, setAddDialogState] = useState({ open: false, wait: false, errors: [] });

  const errorMsg = useMemo(() => {
    var error = extractErrorMessage(errors, "LOCATIONS", defaultLang);
    if (error !== "")
      return (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      );
    return <></>;
  }, [errors]);

  if (!structure) return <></>;

  var locations = structure.locations;
  var setLocations = loc => setStructure({ ...structure, locations: loc });

  const onDeleteLocation = locId => {
    let locationsAfterDelete = locations.filter(l => l.locationId !== locId);
    setLocations(locationsAfterDelete);
  };

  return (
    <>
      {errorMsg}
      <GridContainer>
        {!isArrayNullOrEmpty(locations) &&
          locations
            .filter(t => t)
            .sort((a, b) => (a.isHeadQuarter === b.isHeadQuarter ? 0 : a.isHeadQuarter ? -1 : 1))
            .map((loc, idx) => {
              var card = (
                <CardLocation
                  key={idx}
                  location={loc}
                  isEditable={isEditable}
                  onMenuOpen={e => {
                    setCurrentLocation(loc);
                    setMenuAnchor(e.currentTarget);
                  }}
                  dispatch={dispatch}
                  defaultLang={defaultLang}
                />
              );

              return (
                <GridItem xs={4} sm={4} md={4}>
                  {card}
                </GridItem>
              );
            })}
        {isEditable ? (
          <GridItem xs={5} sm={5} md={5}>
            <Button variant="contained" startIcon={<Add />} size="small" onClick={() => setAddDialogState({ ...addDialogState, open: true })}>
              <Trans>Site_Tp_Add</Trans>
            </Button>
          </GridItem>
        ) : (
          <></>
        )}
      </GridContainer>
      <Menu
        id="locMenuActions"
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={() => setMenuAnchor(null)}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "40ch"
          }
        }}
      >
        <MenuItem
          onClick={() => {
            setLocations(
              locations.map(loc => {
                return { ...loc, isHeadQuarter: loc.locationId === currentLocation.locationId };
              })
            );
            setMenuAnchor(null);
          }}
        >
          <Trans>Structure_Location_SetHeadquarter</Trans>
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteLocation(currentLocation.locationId);
            setMenuAnchor(null);
          }}
        >
          <Trans>StructureSite_Delete</Trans>
        </MenuItem>
      </Menu>
      <DialogLocation
        addDialogState={addDialogState}
        locations={locations}
        setLocations={setLocations}
        setAddDialogState={setAddDialogState}
        defaultLang={defaultLang}
      />
    </>
  );
}

const CardLocation = ({ location, isEditable, onMenuOpen, dispatch, defaultLang }) => {
  var [target, setTarget] = useState(undefined);
  const [alert, setAlert] = useState(undefined);

  useEffect(() => {
    // if (location.id ?? 0 === 0) setTarget(location);
    // else
    SiteGet(
      location.locationId,
      loc => setTarget(loc),
      e => {
        console.error(e);
        setAlert(e);
      }
    );
  }, [location]);

  var banner;
  if (alert) {
    banner = <Alert severity="error">{alert}</Alert>;
  }

  if (banner) return banner;
  if (!target)
    return (
      <div>
        <CircularProgress />
      </div>
    );

  var zipCity = <></>;
  if (!isNullOrEmpty(target.address_ZipCode) && !isNullOrEmpty(target.address_City))
    zipCity = (
      <>
        {target.address_ZipCode} {target.address_City}
        <br />
      </>
    );

  return (
    <Card
      style={{
        ...CardInlineStyle.tpCard
      }}
      variant="outlined"
    >
      <CardHeader
        avatar={
          target.locationStatusCode === "CLOSED" ? (
            <Tooltip title={<Trans>Location_Status_Closed</Trans>}>
              <Lock style={{ color: "red" }} />
            </Tooltip>
          ) : location.isHeadQuarter ? (
            <Flag style={{ color: "orange" }} />
          ) : (
            <></>
          )
        }
        action={
          isEditable ? (
            <IconButton onClick={e => onMenuOpen(e)}>
              <MoreVert />
            </IconButton>
          ) : (
            <></>
          )
        }
        title={
          <Tooltip title={target.name}>
            <Button size="small" onClick={() => dispatch(LocationActions.OpenSite(target.id))} style={{ textDecoration: "underline" }}>
              {target.name.length > 30 ? target.name.substring(0, 30) + "..." : target.name}
            </Button>
          </Tooltip>
        }
        style={{
          ...CardInlineStyle.tpCardHeader
        }}
      />
      <CardContent
        style={{
          ...CardInlineStyle.tpCardHeader
        }}
      >
        <Muted>
          {target.address_Line1}
          <br />
          {target.address_Line2}
          <br />
          {zipCity}
          <CountryAutocomplete countryCode={target.address_CountryCode} defaultLang={defaultLang} />
        </Muted>
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
};

const DialogLocation = ({ locations, setLocations, addDialogState, setAddDialogState, defaultLang }) => {
  const [tabIdx, setTabIdx] = useState(0);
  let needApprobation = HasRight("site.edit") && !HasRight("site.approve");
  const addLocation = loc => {
    const saveAndClose = () => {
      if (needApprobation) {
        setLocations([...locations, { discriminator: "Location", locationId: loc.id, location: loc }]);
      } else {
        setLocations([...locations, { discriminator: "Location", locationId: loc.id, location: loc, isHeadQuarter: locations.length === 0 }]);
      }
      setAddDialogState({ ...addDialogState, open: false, wait: false, errors: [] });
    };

    const throwErrors = errors => {
      setAddDialogState({ ...addDialogState, wait: false, errors: errors });
    };

    if (loc && locations.some(l => loc.locationId === l.locationId)) {
      throwErrors([<Trans>Site_Tp_AlreadySet</Trans>]);
      return;
    }

    if (!loc) {
      throwErrors([<Trans>Site_Tp_NotSet</Trans>]);
      return;
    }

    saveAndClose();
  };
  const buildTabs = [
    {
      title: <Trans>Site_Search</Trans>,
      component: "SiteSearch",
      status: "neutral"
    },
    {
      title: <Trans>Site_Create_New</Trans>,
      component: "SiteCreate",
      status: "neutral"
    }
  ];
  let tabStatus = buildTabs;
  let tabs = tabStatus.map(tab => {
    var tabContent = "";

    switch (tab.component) {
      case "SiteSearch":
        tabContent = <SiteSelect setLocation={addLocation} initialCriteria={{ searchMode: "Simple" }} clearable />;
        break;
      case "SiteCreate":
        tabContent = (
          <SiteWizard
            handleClose={() => setAddDialogState({ ...addDialogState, open: false })}
            defaultLang={defaultLang}
            needApprobation={needApprobation}
            showCard={true}
            showStruct={false}
            setLocation={addLocation}
          />
        );
        break;
      default:
        break;
    }

    return {
      tabContent: tabContent,
      tabName: tab.component,
      tabTitle: tab.title
    };
  });
  return (
    <>
      <Dialog
        onClose={() => setAddDialogState({ ...addDialogState, open: false })}
        aria-labelledby="simple-dialog-title"
        open={addDialogState.open}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {addDialogState.errors.map((e, idx) => (
            <Alert severity="error" key={"error_" + idx}>
              {e}
            </Alert>
          ))}
          <Tabs variant="scrollable" value={tabIdx} onChange={(e, value) => setTabIdx(value)} aria-label="Tabs location">
            {tabs.map((t, idx) => (
              <Tab key={"siteTab" + idx} label={t.tabTitle} />
            ))}
          </Tabs>
          {tabs.map((t, idx) => (
            <TabPanel value={tabIdx} index={idx} id="siteTab">
              {t.tabContent}
            </TabPanel>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px",
    color: "white"
  },
  tpCard: {
    margin: 0,
    marginBottom: 20,
    padding: 0
  },
  tpCardHeader: {
    padding: "4px 16px",
    marginTop: 0,
    marginBottom: 0
  },
  tpCardContent: {
    padding: "4px 16px",
    fontSize: "0.8rem",
    marginTop: 0,
    marginBottom: 0
  }
};
