import React from "react";
import SiteList from "../module/site/containers/SiteList";
import Search from "@material-ui/icons/Search";
//lingui
import { Trans } from "@lingui/macro";

const siteRoutes = [
  {
    path: "/sites/sites",
    name: <Trans>Site Search</Trans>,
    short: <Trans>Site Search</Trans>,
    icon: Search,
    useAsComponentSiteInMenu: true,
    component: SiteList
  }
];

export default siteRoutes;
