import { Trans } from "@lingui/macro";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import { Delete, Error } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { IdentifiedUserAutocomplete } from "components/Autocomplete/IdentifiedUserAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import DateSelector from "components/Common/DateSelector";
import Selector from "components/Common/Selector";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { addDays, compareAsc, format, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isArrayNullOrEmpty, isNull, isValidDate } from "tools";
import { USERANDRIGHT_PROFILEDELEGATION_END_ACTION as USERANDRIGHT_PROFILEDELEGATION_END_ACTION, USERANDRIGHT_PROFILEDELEGATION_GET_DETAIL as USERANDRIGHT_PROFILEDELEGATION_GET_DETAIL } from "./store/types";
import { Crupdate, Get, Search } from "./store/ProfileDelegation.Actions";
function ProfileDelegationDetail({ delegationCtx, currentUser, setDetail, endEditing, crupdate, profilesToDeleg, classes }) {

  const [hasBeenModified, setHasBeenModified] = useState(false);
  const [state, setState] = useState({ isFirstLoad: true, isOpenDelegation: true });
  const [errors, setErrors] = useState([]);

  const showDetail = delegationCtx.showDetail;
  const isPerforming = delegationCtx.isPerforming;

  const item = delegationCtx.item;
  const isNew = isNull(item) || isNull(item.id) || item.id === 0;

  const minDate = new Date();
  const maxDate = addDays(isNew ? new Date() : new Date(item.dateStart), 30);

  const canEditDelete = !isNew && (item.delegatorId === currentUser.identifier && compareAsc(new Date(item.dateEnd), minDate) >= 0 && compareAsc(new Date(item.dateEnd), maxDate) <= 0);

  useEffect(() => {
    if (state.isFirstLoad) {
      setState({ ...state, isFirstLoad: false, isOpenDelegation: (isNew || (!hasBeenModified && item.delegatorId === currentUser.identifier && compareAsc(new Date(item.dateEnd), minDate) >= 0 && compareAsc(new Date(item.dateEnd), maxDate) <= 0)) })
    }
  }, [item]);

  function changeValue(value, propertyField) {
    if (state.isOpenDelegation) {
      item[propertyField] = value;
      setDetail({
        ...item
      });
      setHasBeenModified(true);
    }
  }
  function changeDateValue(value, propertyField) {
    if (value && value != null && isValidDate(new Date(value))) {
      changeValue(value, propertyField)
    }
  }

  function changeDelegate(value) {
    changeValue(value, "delegateId");
  }

  function close() {
    setHasBeenModified(false);
    setErrors([]);
    setState({ isFirstLoad: true});
    endEditing();
  }

  let content = "";

  if (showDetail) {
    let hasErrors = !isArrayNullOrEmpty(errors);

    let label = (
      <span>
        <Trans>Profile Delegation</Trans> {item.id}
      </span>
    );

    if (isNew) {
      item.id = 0;
      item.delegatorId = currentUser.identifier;
      if (item.dateStart === undefined) item.dateStart = format(minDate, "yyyy-MM-dd");
      if (item.dateEnd === undefined) item.dateEnd = format(maxDate, "yyyy-MM-dd");

      label = (
        <span>
          <Trans>New Profile Delegation</Trans>
        </span>
      );
    }
    else {
      if (item.dateStart && item.dateStart != null) {
        item.dateStart = format(parseISO(item.dateStart), "yyyy-MM-dd");
      }

      if (!state.isOpenDelegation && item.dateEnd && item.dateEnd != null) {
        item.dateEnd = format(parseISO(item.dateEnd), "yyyy-MM-dd");
      }
    }

    let errorCard = "";
    if (hasErrors) {
      errorCard = (
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              {
                errors.map((t, idx) => {
                  return (
                    <div>
                      <div style={{ lineHeight: "0.5em" }}>
                        <Error style={{ verticalAlign: "middle", color: "red" }} />
                        <span style={{ verticalAlign: "middle", color: "red", marginRight: "5px" }}>
                          <strong>
                            {t.descriptionEn}
                          </strong>
                        </span>
                      </div>
                    </div>
                  );
                })
              }
            </CardBody>
          </Card>
        </GridItem>
      );
    }

    let validateButton = "";
    if (state.isOpenDelegation && hasBeenModified) {
      validateButton = (
        <Button
          color="info"
          onClick={() => {
            crupdate(item
              , e => {
                setErrors([]);
                if (item.dateStart > minDate) {
                  close();
                } else {
                  setState({ ...state, isFirstLoad: true });
                }
                setHasBeenModified(false);
              }
              , e => setErrors(e));
          }}
        >
          <Trans>Validate</Trans>
        </Button>
      );
    }


    var deleteButton = canEditDelete ? (
      <Button onClick={() => {
        changeValue(format(minDate, "yyyy-MM-dd"), "dateEnd");
        crupdate(item, e => { setErrors([]); close(); }, e => setErrors(e));
      }} size="small" color="danger">
        <Delete />
        <Trans>Delete</Trans>
      </Button>
    ) : "";

    if (isPerforming)
      content = (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={true}
            onClose={close}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.dialogPaper }}
            scroll="paper"
          >
            <DialogTitle id="form-dialog-title">
              <div>{label}</div>
            </DialogTitle>
            <DialogContent
              style={{
                ...DialogInlineStyleDetail.dialogContent
              }}
              dividers={true}
            >
              <center>
                <CircularProgress />
              </center>
            </DialogContent>
          </Dialog>
        </div>
      );
    else
      content = (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={true}
            onClose={close}
            aria-labelledby="form-dialog-title"
            classes={{ paper: classes.dialogPaper }}
            scroll="paper"
          >
            <DialogTitle id="form-dialog-title">
              <div>{label}</div>
            </DialogTitle>
            <DialogContent
              style={{
                ...DialogInlineStyleDetail.dialogContent
              }}
              dividers={true}
            >
              <div style={{ width: "98%" }}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card
                      style={{
                        ...CardInlineStyle.card
                      }}
                    >
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Profile Code</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <Selector
                              items={profilesToDeleg}
                              displayedProperties={{
                                keyProperty: "id",
                                valueProperty: "name"
                              }}
                              value={item.codeProfile}
                              isEditable={isNew}
                              onChange={e => changeValue(e.target.value, "codeProfile")}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Delegator login</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <TextField
                              margin="dense"
                              id="delegatorId"
                              type="text"
                              fullWidth
                              required
                              disabled={!isNew}
                              value={item.delegatorId}
                              onChange={e => changeValue(e.target.value, "delegatorId")}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Delegate login</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            {isNew &&
                              <IdentifiedUserAutocomplete login={item.delegateId} onChange={e => changeDelegate(e)} />
                            }
                            {!isNew &&
                              <TextField
                                margin="dense"
                                id="delegateId"
                                type="text"
                                fullWidth
                                disabled
                                value={item.delegateId}
                              />
                            }
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Date start</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <DateSelector
                              value={item.dateStart}
                              isEditable={isNew}
                              openDaysOnly={false}
                              onChange={e => changeDateValue(e, "dateStart")}
                              minDate={minDate}
                              maxDate={maxDate}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Date end</Trans>
                              </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <DateSelector
                              value={item.dateEnd}
                              isEditable={isNew || canEditDelete}
                              openDaysOnly={false}
                              onChange={e => changeDateValue(e, "dateEnd")}
                              minDate={minDate}
                              maxDate={maxDate}
                            />
                          </GridItem>
                          <GridItem xs={4} sm={4} md={4}>
                            <FormLabel>
                              <Trans>Comment</Trans>
                            </FormLabel>
                          </GridItem>
                          <GridItem xs={7} sm={7} md={7}>
                            <TextField
                              margin="dense"
                              id="commentaire"
                              type="text"
                              fullWidth
                              required
                              disabled={!isNew && !canEditDelete}
                              value={item.commentaire}
                              onChange={e => changeValue(e.target.value, "commentaire")}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                  {errorCard}
                </GridContainer>
              </div>
            </DialogContent>
            <DialogActions>
              {validateButton}
              {deleteButton}
              <div style={{ flex: "1 0 0" }} />
              <Button onClick={close} color="info">
                <Trans> Close </Trans>
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
  }

  return <div>{content}</div>;
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    delegationCtx: state.UR_ProfileDelegation,
    currentUser: state.AuthenticationReducer.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setDetail: item => {
      dispatch({
        type: USERANDRIGHT_PROFILEDELEGATION_GET_DETAIL,
        item: item
      });
    },
    get: id => {
      dispatch(Get(id));
    },
    endEditing: item => {
      dispatch({
        type: USERANDRIGHT_PROFILEDELEGATION_END_ACTION,
        item: null
      });
      dispatch(Search());
    },
    crupdate: (item, callbackFn, errorFn) => {
      dispatch(Crupdate(item, callbackFn, errorFn));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(ProfileDelegationDetail));