import { Trans } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/Job.Actions";
import React from "react";
import { connect } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { StructureErt, StructureSj, StructureUe } from "store/MasterValue/MasterValueTypes";
import JobSearchCriteriaComplex from "./JobSearch/JobSearchCriteriaComplex";
import JobSearchCriteriaExtended from "./JobSearch/JobSearchCriteriaExtended";
import JobSearchCriteriaSimple from "./JobSearch/JobSearchCriteriaSimple";

function JobSearchCriteria({ showSearchCriteriaOnSideBar, amc, structureUes, structureSjs, structureErts, setCriterias, setSearchMode, search }) {
  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");

  var defaultSearchMode = "Simple";
  if (!!getPref && !!getPref.Pref && !!getPref.Pref.Search) {
    defaultSearchMode = getPref.Pref.Search;
  }

  const criterias = amc.criterias["JOB"];
  const searchMode = !amc ? defaultSearchMode : amc.searchMode["JOB"];

  var allStructures = [];
  if (!!structureErts) allStructures = allStructures.concat(structureErts);
  if (!!structureSjs) allStructures = allStructures.concat(structureSjs);
  if (!!structureUes) allStructures = allStructures.concat(structureUes);

  var selectedStructure = null;
  if (!!criterias.ertCode) selectedStructure = criterias.ertCode;
  if (!!criterias.sjCode) selectedStructure = criterias.sjCode;
  if (!!criterias.ueCode) selectedStructure = criterias.ueCode;

  var searchGridSize = 4;
  if (showSearchCriteriaOnSideBar) searchGridSize = 12;

  const blockHeaderSearchClasses = ["blockHeaderSearch"];
  if (showSearchCriteriaOnSideBar) blockHeaderSearchClasses.push("Reduce");

  const simpleSearchClasses = ["simple-search"];
  const extendedSearchClasses = ["extended-search"];
  const complexSearchClasses = ["complex-search"];
  var searchTitle = "";

  var searchComponent = "";
  switch (searchMode) {
    case "Simple":
      simpleSearchClasses.push("show");
      searchComponent = <JobSearchCriteriaSimple showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar} />;
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Quick Search </Trans>;
      break;
    case "Extend":
      searchComponent = <JobSearchCriteriaExtended showSearchCriteriaOnSideBar={showSearchCriteriaOnSideBar} />;
      extendedSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Extended Search</Trans>;
      break;
    case "Complex":
      searchComponent = <JobSearchCriteriaComplex />;
      complexSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Complex Search</Trans>;
      break;
    default:
      console.warn("Unknown searchMode: " + searchMode);
      break;
  }

  var countrySimpleSearchWidth = 3;
  var simpleSearchWidth = 9;
  var divMultiSelectStyle = { width: "100%" };
  if (showSearchCriteriaOnSideBar) {
    countrySimpleSearchWidth = 12;
    simpleSearchWidth = 12;
    divMultiSelectStyle = { width: "100%", marginTop: "10px" };
  }

  function launchSearch(e) {
    e.preventDefault();
    search();
  }

  function cleanSearchCriterias(e) {
    e.preventDefault();
    var newCriterias = { pageSite: criterias.pageSite, criteriaFullText: "", criteriaMdmId: "" };
    setCriterias(newCriterias);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <SearchOutlined />
            </CardIcon>
            <div className={blockHeaderSearchClasses.join(" ")}>
              <div className="blockHeaderSearchItem">
                <ToggleButtonGroup value={searchMode} exclusive onChange={(e, mode) => setSearchMode(mode)}>
                  <ToggleButton value="Simple">
                    <ViewDay />
                  </ToggleButton>
                  <ToggleButton value="Extend">
                    <ViewHeadline />
                  </ToggleButton>
                  <ToggleButton value="Complex">
                    <ViewQuilt />
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="blockHeaderSearchItem">
                <Typography ariant="display1">{searchTitle}</Typography>
              </div>
            </div>
          </CardHeader>
          <CardBody>{searchComponent}</CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    structureUes: state.MasterValueReducer[StructureUe],
    structureSjs: state.MasterValueReducer[StructureSj],
    structureErts: state.MasterValueReducer[StructureErt]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    setSearchMode: searchMode => {
      dispatch(Actions.SetSearchMode(searchMode));
    },
    search: () => {
      dispatch(Actions.Search());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobSearchCriteria));
