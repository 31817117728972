import { Trans } from "@lingui/macro";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Alert } from "@material-ui/lab";
import workflowStyle from "assets/jss/material-dashboard-pro-react/components/workflowStyle.jsx";
import CardError from "components/Card/CardError";
import Button from "components/CustomButtons/Button";
import DialogBox from "components/DialogBox/DialogBox";
import * as Actions from "module/workflow/store/actions/Workflow.Actions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { HasRight } from "services/user/UserHelper";
import { LoadMasterValues, WorkflowStatus, WorkflowStep, WorkflowType } from "store/MasterValue/MasterValueTypes";
import { isNull } from "../../tools";
import { CloseWorkflow } from "../store/actions/Workflow.Actions";
import CreationCustomer from "./customerWorkflow/CreationCustomer";
import LocationCrupdate from "./LocationWorkflow/LocationCrupdate";
import PayCharCreation from "./payCharWorkflow/PayCharCreation";
import RequestSupplierCreation from "./purchaserWorkflow/RequestSupplierCreation";
import CreationSupplier from "./supplierWorkflow/CreationSupplier";
import InfolegaleMerge from "./ThirdPartyWorkflow/InfolegaleMerge";
import ShorteningLongName from "./ThirdPartyWorkflow/ShorteningLongName";
import StructureUe from "./StructureUeWorkflow/StructureUe";

function WorkflowDetail({ workflowTypeCode, workflowId, closeWorkflow, masterValues, currentUser, classes, defaultLang }) {
  const [state, setState] = useState({ isLoading: false, globalErrors: null, errors: [] });
  const [workflow, setWorkflow] = useState(null);

  const [dialogBox, setDialogBox] = useState(null);

  const loadWorkflow = (wkfTypeCode, wkfId) => {
    setState({ ...state, workflowBackup: null, isLoading: true, globalErrors: null, errors: [] });
    LoadMasterValues([WorkflowStatus, WorkflowStep, WorkflowType]).then(() => {
      Actions.GetWorkflow(
        wkfTypeCode,
        wkfId,
        c => {
          setState({ isLoading: false, workflowBackup: JSON.stringify(c) });
          if (c.identifier == null) c = { identifier: wkfId };
          setWorkflow(c);
        },
        e => {
          setState({ ...state, isLoading: false, errors: e });
        }
      );
    });
  };

  useEffect(() => {
    if (workflowId > 0) {
      loadWorkflow(workflowTypeCode, workflowId);
    } else if (workflowId === 0) {
      var user = currentUser;
      var wkf = {
        label: "Demande de Référencement d'un nouveau fournisseur",
        workflowStatusCode: "Started",
        workflowTypeCode: "SupplierRequest",
        creationUser: user.identifier,
        linkedObject: {
          applicantName: user.firstName + " " + user.lastName,
          applicantLogin: user.identifier,
          applicantFunction: user.function
        }
      };
      setState({ ...state, workflowBackup: JSON.stringify(wkf), isLoading: false, globalErrors: null, errors: [] });
      setWorkflow(wkf);
    } else {
      setWorkflow(null);
    }
  }, [workflowId]);

  const hasChanged = () => {
    // nettoyage des champs ajoutés pour des besoins de l'IHM
    const { isCoupaEditable, ...wkf } = workflow;

    var linkedObject = workflow.linkedObject;
    if (linkedObject) {
      if (linkedObject.coupaSupplier?.contact?.details) {
        linkedObject.coupaSupplier.contact.details = linkedObject.coupaSupplier.contact.details.map(d => {
          const { localId, ...detail } = d;
          return detail;
        });
      }

      const { linkedThirdParty, ...lnkObj } = linkedObject;
      wkf.linkedObject = lnkObj;
    }

    return JSON.stringify(wkf) !== state.workflowBackup;
  };

  const closeDetail = confirmClose => {
    if (!isNull(state.globalErrors)) {
      // erreur générale, on permet de fermer le popup
      setDialogBox(null);
      closeWorkflow();
      return;
    }

    if (hasChanged() && !confirmClose) {
      setDialogBox({ type: "yesNo", message: <Trans> ConfirmCloseWithoutSave </Trans>, yes: () => closeDetail(true) });
    } else {
      setDialogBox(null);
      closeWorkflow();
    }
  };

  const abortWorkflow = wkf => {
    if (wkf) {
      setWorkflow(wkf);
    } else wkf = workflow;
    setState({ ...state, isLoading: true, errors: [], globalErrors: null });

    Actions.AbortWorkflow(
      wkf,
      c => {
        loadWorkflow(c.workflowTypeCode, c.identifier);
      },
      e => {
        setState({ ...state, isLoading: false, globalErrors: e });
      },
      e => {
        setState({ ...state, isLoading: false, errors: e });
      }
    );
  };

  const rollbackWorkflow = wkf => {
    if (wkf) {
      setWorkflow(wkf);
    } else wkf = workflow;
    setState({ ...state, isLoading: true, errors: [], globalErrors: null });

    Actions.RollbackWorkflow(
      workflow,
      c => {
        loadWorkflow(c.workflowTypeCode, c.identifier);
      },
      e => {
        setState({ ...state, isLoading: false, globalErrors: e });
      },
      e => {
        setState({ ...state, isLoading: false, errors: e });
      }
    );
  };

  const validateWorkflow = wkf => {
    if (wkf) {
      setWorkflow(wkf);
    } else wkf = workflow;
    setState({ ...state, isLoading: true, errors: [], globalErrors: null });

    Actions.ValidateWorkflow(
      wkf,
      c => {
        if (c.workflowProcessStatus == 0) {
          loadWorkflow(c.workflow.workflowTypeCode, c.workflow.identifier);
        } else {
          setState({ ...state, isLoading: false, errors: c.errorMessages });
        }
      },
      e => {
        setState({ ...state, isLoading: false, globalErrors: e });
      }
    );
  };

  const saveDraft = () => {
    setState({ ...state, isLoading: true, errors: [], globalErrors: null });

    Actions.SaveWorkflow(
      { ...workflow, identifier: 0, creationUser: currentUser.identifier, workflowStatusCode: "Started" },
      c => loadWorkflow(c.workflowTypeCode, c.identifier),
      e => setState({ ...state, isLoading: false, globalErrors: e }),
      e => setState({ ...state, isLoading: false, errors: e })
    );
  };

  if (!workflow) return <></>;
  if (workflow.workflowTypeCode === "StructureUe")
    return (
      <StructureUe
        workflow={workflow}
        validateWorkflow={validateWorkflow}
        abortWorkflow={abortWorkflow}
        closeWorkflow={closeWorkflow}
        masterValues={masterValues}
        defaultLang={defaultLang}
      />
    );

  var user = currentUser;
  var dialogTitle = workflow.label;

  var btnDraft = "";
  if (workflow.workflowTypeCode === "SupplierRequest") {
    if (workflow.workflowStatusCode !== "Started" || !workflow.identifier || workflow.identifier === 0) {
      btnDraft = (
        <Button color="info" onClick={() => saveDraft()}>
          <Trans>SaveAsDraft</Trans>
        </Button>
      );
    } else {
      btnDraft = (
        <Button color="error" onClick={() => deleteDraft()}>
          <Trans>deleteDraft</Trans>
        </Button>
      );
    }
  } else if (workflow.workflowTypeCode == null) {
    btnDraft = (
      <Button color="error" onClick={() => deleteDraft()}>
        <Trans>deleteDraft</Trans>
      </Button>
    );
  }

  const deleteDraft = () => {
    setState({ ...state, isLoading: true, errors: [], globalErrors: null });

    Actions.DeleteDraftWorkflow(workflow, c => closeDetail(true), e => setState({ ...state, isLoading: false, globalErrors: e }));
  };

  var btnRollback = "";
  btnRollback = (
    <Button color="info" onClick={() => rollbackWorkflow()}>
      <Trans>Rollback</Trans>
    </Button>
  );

  var btnValidate = "";
  if (workflow.workflowStatusCode === "Started" || HasRight("thirdparty_workflow.edit") || HasRight("thirdparty_workflow.edit_iban")) {
    var btnActionValidate = () => validateWorkflow();
    if (
      workflow.workflowTypeCode &&
      ((workflow.workflowTypeCode === "SupplierRequest" &&
        workflow.workflowStatusCode !== "Intialization" &&
        workflow.workflowStatusCode !== "Started" &&
        workflow.workflowStatusCode !== "Aborted") ||
        workflow.workflowTypeCode === "PayCharCreation" ||
        workflow.workflowTypeCode === "LocationCrupdate")
    ) {
      btnActionValidate = e => {
        setDialogBox({
          canDismiss: false,
          type: "okCancel",
          textbox: { title: <Trans>Comment</Trans>, rows: 2 },
          ok: text => {
            validateWorkflow({ ...workflow, linkedObject: { ...workflow.linkedObject, validationComment: text } });
            setDialogBox(null);
          }
        });
      };
    }
    btnValidate = (
      <>
        <Button color="success" onClick={btnActionValidate}>
          <Trans>Validate</Trans>
        </Button>
      </>
    );
  }

  var abortPopover = <></>;
  var btnAbort = "";
  var btnActionAbort = () => abortWorkflow();
  if (
    !isNull(workflow.workflowTypeCode) &&
    (workflow.workflowTypeCode === "SupplierRequest" ||
      workflow.workflowTypeCode === "PayCharCreation" ||
      workflow.workflowTypeCode === "LocationCrupdate")
  ) {
    btnActionAbort = e => {
      setDialogBox({
        canDismiss: false,
        type: "okCancel",
        textbox: { title: <Trans>Comment</Trans>, rows: 2 },
        ok: text => {
          abortWorkflow({ ...workflow, linkedObject: { ...workflow.linkedObject, abortDescription: text } });
          setDialogBox(null);
        }
      });
    };
  }
  btnAbort = (
    <>
      <Button color="danger" onClick={btnActionAbort}>
        <Trans>WF_Abort</Trans>
      </Button>
      {abortPopover}
    </>
  );

  var workflowTypeContent = <></>;
  switch (workflow.workflowTypeCode) {
    case "CustomerCreation":
      workflowTypeContent = <CreationCustomer workflow={workflow} setWorkflow={setWorkflow} errors={state.errors} defaultLang={defaultLang} />;
      break;
    case "SupplierCreation":
      workflowTypeContent = <CreationSupplier workflow={workflow} setWorkflow={setWorkflow} errors={state.errors} defaultLang={defaultLang} />;
      break;
    case "SupplierRequest":
      workflowTypeContent = <RequestSupplierCreation workflow={workflow} setWorkflow={setWorkflow} errors={state.errors} />;
      break;
    case "LongNameShortening":
      workflowTypeContent = <ShorteningLongName workflow={workflow} setWorkflow={setWorkflow} errors={state.errors} />;
      break;
    case "InfolegaleMerge":
      workflowTypeContent = <InfolegaleMerge workflow={workflow} />;
      break;
    case "LocationCrupdate":
      workflowTypeContent = <LocationCrupdate workflow={workflow} setWorkflow={setWorkflow} errors={state.errors} />;
      break;
    case "PayCharCreation":
      workflowTypeContent = (
        <PayCharCreation workflow={workflow} setWorkflow={setWorkflow} errors={state.errors} masterValues={masterValues} defaultLang={defaultLang} />
      );
      break;
    default:
      workflowTypeContent = (
        <center>
          <Alert severity="warning">
            <Trans>Workflow_NotFound</Trans>
          </Alert>
        </center>
      );
      break;
  }

  var buttons = "";
  var codeButtons = "Close";

  if (workflow.workflowStatusCode === "Intialization") codeButtons = "Intialization";

  if (workflow.workflowTypeCode == "SupplierRequest") {
    if (workflow.workflowStatusCode === "Started") {
      codeButtons = "Intialization";
    } else if (workflow.workflowStatusCode === "Aborted" && user.identifier === workflow.creationUser) {
      codeButtons = "Intialization";
    } else if (workflow.workflowStatusCode === "Pending" && !HasRight("thirdparty_workflow.edit")) {
      codeButtons = "SupplierRequestReadOnly";
    } else if (workflow.workflowStatusCode === "Pending" && HasRight("thirdparty_workflow.edit")) {
      codeButtons = "SupplierRequestReadWrite";
    } else if (workflow.workflowStatusCode === "Aborted") {
      codeButtons = "SupplierRequestFinished";
    } else if (workflow.workflowStatusCode === "Finished") {
      codeButtons = "SupplierRequestFinished";
    }
  } else if (workflow.workflowTypeCode === "InfolegaleMerge") {
    codeButtons = "InfolegaleMerge";
  } else if (workflow.workflowTypeCode === "SupplierCreation" || workflow.workflowTypeCode == "CustomerCreation") {
    codeButtons = "ThirdPartyIris2Creation";
  } else if (workflow.workflowTypeCode === "PayCharCreation") {
    if (workflow.workflowStatusCode === "Pending" && (HasRight("thirdparty_workflow.edit_iban") || HasRight("application.admin"))) {
      codeButtons = "ValidateAbortClose";
    } else {
      codeButtons = "Close";
    }
  } else if (workflow.workflowTypeCode === "LocationCrupdate") {
    if (workflow.workflowStatusCode === "Started") {
      codeButtons = "Intialization";
    } else if (workflow.workflowStatusCode === "Aborted" && user.identifier === workflow.creationUser) {
      codeButtons = "Intialization";
    } else if (workflow.workflowStatusCode === "Pending" && !HasRight("site.approve") && !HasRight("application.admin")) {
      codeButtons = "SupplierRequestReadOnly";
    } else if (workflow.workflowStatusCode === "Pending" && (HasRight("site.approve") || HasRight("application.admin"))) {
      codeButtons = "SupplierRequestReadWrite";
    } else if (workflow.workflowStatusCode === "Aborted") {
      codeButtons = "SupplierRequestFinished";
    } else if (workflow.workflowStatusCode === "Finished") {
      codeButtons = "SupplierRequestFinished";
    }
  } else if (workflow.workflowTypeCode === "LongNameShortening") {
    if (workflow.workflowStatusCode === "Started") {
      codeButtons = "Intialization";
    } else if (workflow.workflowStatusCode === "Aborted" && user.identifier === workflow.creationUser) {
      codeButtons = "Intialization";
    } else if (workflow.workflowStatusCode === "Pending" && !HasRight("thirdparty_workflow.edit")) {
      codeButtons = "SupplierRequestReadOnly";
    } else if (workflow.workflowStatusCode === "Pending" && HasRight("thirdparty_workflow.edit")) {
      codeButtons = "SupplierRequestReadWrite";
    } else if (workflow.workflowStatusCode === "Aborted") {
      codeButtons = "SupplierRequestFinished";
    } else if (workflow.workflowStatusCode === "Finished") {
      codeButtons = "SupplierRequestFinished";
    }
  }

  switch (codeButtons) {
    case "Intialization":
    case "InfolegaleMerge":
      buttons = (
        <DialogActions>
          {btnValidate}
          <div style={{ flex: "1 0 0" }} />
          {btnDraft}
          <Button onClick={() => closeDetail(false)} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      );
      break;
    case "SupplierRequestFinished":
      buttons = (
        <DialogActions>
          <div style={{ flex: "1 0 0" }} />
          {btnDraft}
          <Button onClick={() => closeDetail(false)} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      );
      break;
    case "SupplierRequestReadOnly":
      buttons = (
        <DialogActions>
          {btnAbort}
          <div style={{ flex: "1 0 0" }} />
          {btnDraft}
          <Button onClick={() => closeDetail(false)} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      );
      break;
    case "SupplierRequestReadWrite":
      buttons = (
        <DialogActions>
          {btnValidate}
          {btnAbort}
          <div style={{ flex: "1 0 0" }} />
          {btnDraft}
          <Button onClick={() => closeDetail(false)} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      );
      break;
    case "ThirdPartyIris2Creation":
      buttons = (
        <DialogActions>
          {btnAbort}
          <div style={{ flex: "1 0 0" }} />
          {btnDraft}
          <Button onClick={() => closeDetail(false)} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      );
      break;
    case "ValidateAbortClose":
      buttons = (
        <DialogActions>
          {btnValidate}
          {btnAbort}
          <div style={{ flex: "1 0 0" }} />
          {btnDraft}
          <Button onClick={() => closeDetail(false)} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      );
      break;
    case "Close":
      buttons = (
        <DialogActions>
          <div style={{ flex: "1 0 0" }} />
          {btnDraft}
          <Button onClick={() => closeDetail(false)} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      );
      break;
    default:
      buttons = (
        <DialogActions>
          {btnRollback}
          {btnValidate}
          {btnAbort}
          <div style={{ flex: "1 0 0" }} />
          {btnDraft}
          <Button onClick={() => closeDetail(false)} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      );
      break;
  }

  var dialogContent;
  var showButton = false;
  if (state.isLoading)
    dialogContent = (
      <center>
        <CircularProgress />
      </center>
    );
  else if (state.globalErrors) dialogContent = <CardError error={state.globalErrors} />;
  else {
    dialogContent = workflowTypeContent;
    showButton = true;
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={Boolean(workflow)}
        onClose={() => closeDetail(false)}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          <div>{dialogTitle}</div>
        </DialogTitle>
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          {dialogContent}
        </DialogContent>
        {showButton && buttons}
      </Dialog>
      <DialogBox dialogBox={dialogBox} setDialogBox={setDialogBox} />
    </div>
  );
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "95%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    currentUser: state.AuthenticationReducer.user,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeWorkflow: () => dispatch(CloseWorkflow())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(workflowStyle)(WorkflowDetail));
