import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { Delete } from "@material-ui/icons";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";
import * as Actions from "module/amc/store/actions/CustomerContract.Actions";
import React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table-6";

function ContactTab({ amc, contacts, updateList }) {
  const reactTableColumns = [
    {
      accessor: "actions",
      width: 50,
      sortable: false,
      filterable: false
    },
    {
      Header: <Trans>Login</Trans>,
      accessor: "login"
    },
    {
      Header: <Trans>First Name</Trans>,
      accessor: "firstName"
    },
    {
      Header: <Trans>Last Name</Trans>,
      accessor: "lastName"
    },
    {
      Header: <Trans>Email</Trans>,
      accessor: "email"
    }
  ];

  ///-----------------------------------------------------------
  /// Convertit le résultat d'une recherche en item pour react-table
  ///-----------------------------------------------------------
  function ConvertDatas(items, getItem) {
    if (items == null || items.length === 0) return [];

    var datas = {
      tabItems: []
    };
    items.map((prop, key) => {
      datas.tabItems.push({
        ...prop.jobEmployee,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a like kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                var index = contacts
                  .map(function(e) {
                    return e.jobEmployeeLogin;
                  })
                  .indexOf(prop.jobEmployeeLogin);

                if (index > -1) {
                  contacts.splice(index, 1);
                  if (!!updateList) updateList(contacts);
                }
              }}
              color="info"
              className="like"
            >
              <Delete />
            </Button>{" "}
          </div>
        )
      });
    });

    return datas;
  }

  var items = ConvertDatas(contacts, null);

  return (
    <div style={{ width: "99%" }}>
      <ReactTable
        data={items.tabItems}
        columns={reactTableColumns}
        showPaginationBottom={true}
        className="-striped -highlight"
        style={ReactTableStyle.main}
        defaultPageSize={5}
      />
    </div>
  );
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCriterias: criterias => {
      dispatch(Actions.SetCriterias(criterias));
    },
    search: () => {
      dispatch(Actions.Search());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(ContactTab));
