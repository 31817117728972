import { Trans } from "@lingui/macro";
import { Card, CardContent } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import structureStyle from "assets/jss/mdmcolas/structure/structureStyle";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted";
import React from "react";
import { StructTag, StructUeActivite } from "store/MasterValue/MasterValueTypes";
import { extractErrorMessage } from "module/tools";
import HeaderField from "../common/HeaderField";

const Header = ({ structure, setStructure, isEditable, defaultLang, errors, isTagEditable }) => {
  const ue = structure.ue;
  const setUe = v => setStructure({ ...structure, ue: v });

  const cardLeft = (
    <>
      <Card style={{ ...CardInlineStyle.tpCard }} variant="outlined">
        <CardContent style={{ ...CardInlineStyle.tpCardHeader }}>
          <GridContainer>
            <HeaderField isEditable={false} value={structure.identifiant} label={<Trans>Structure_Identifiant</Trans>} />
            <HeaderField
              isEditable={isEditable}
              value={structure.libelle}
              onChange={e => setStructure({ ...structure, libelle: e.target.value })}
              label={<Trans>Structure_Libelle</Trans>}
              error={extractErrorMessage(errors, "STR_LIBELLE", defaultLang)}
              showErrorTooltip
            />
            <HeaderField
              isEditable={isEditable}
              value={ue.libelleCourt}
              onChange={e => setUe({ ...ue, libelleCourt: e.target.value })}
              label={<Trans>Structure_UE_LibelleCourt</Trans>}
              error={extractErrorMessage(errors, "UE_LBLCOURT", defaultLang)}
              showErrorTooltip
            />
            <HeaderField
              isEditable={isEditable}
              value={ue.codeCol}
              onChange={e => setUe({ ...ue, codeCol: e.target.value })}
              label={<Trans>Structure_UE_CodeCol</Trans>}
              error={extractErrorMessage(errors, "UE_CODECOL", defaultLang)}
              showErrorTooltip
            />
          </GridContainer>
        </CardContent>
      </Card>
    </>
  );

  const cardRight = (
    <>
      <Card style={{ ...CardInlineStyle.tpCard }} variant="outlined">
        <CardContent style={{ ...CardInlineStyle.tpCardHeader }}>
          <GridContainer>
            <HeaderField
              isEditable={isEditable}
              value={ue.nonCopernicCode}
              onChange={e => setUe({ ...ue, nonCopernicCode: e.target.value })}
              label={<Trans>NonCopernicCode</Trans>}
              error={extractErrorMessage(errors, "UE_NON_COPERNIC_CODE", defaultLang)}
              showErrorTooltip
            />
            <HeaderField
              isEditable={isEditable}
              value={ue.codeIris3}
              onChange={e => setUe({ ...ue, codeIris3: e.target.value })}
              label={<Trans>Structure_CodeJade</Trans>}
              error={extractErrorMessage(errors, "UE_IRIS3", defaultLang)}
              showErrorTooltip
            />
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_UE_ActivitePrincipale</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <MasterValueAutocomplete
                isEditable={isEditable}
                mvType={StructUeActivite}
                selectedValue={ue.activitePrincipaleCode}
                onValueChange={v => setUe({ ...ue, activitePrincipaleCode: v })}
                size="small"
                error={extractErrorMessage(errors, "UE_ACTIVITE_PRINCIPALE", defaultLang)}
              />
            </GridItem>
            <HeaderField
              isEditable={isEditable}
              value={ue.secteur}
              onChange={e => setUe({ ...ue, secteur: e.target.value })}
              label={<Trans>Structure_UE_Secteur</Trans>}
              error={extractErrorMessage(errors, "UE_SECTEUR", defaultLang)}
              showErrorTooltip
            />
            <GridItem xs={4} sm={4} md={4}>
              <Muted>
                <Trans>Structure_Tags</Trans>
              </Muted>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <MasterValueAutocomplete
                isEditable={isEditable || isTagEditable}
                mvType={StructTag}
                groupBy={i => {
                  return i.structureTagType ? GetMasterValueLabel(i.structureTagType, defaultLang) : "(vide)";
                }}
                selectedValue={structure.structureTags.map(s => {
                  return s.tagCode;
                })}
                onValueChange={value =>
                  setStructure({
                    ...structure,
                    structureTags: value.map(v => {
                      return { tagCode: v };
                    })
                  })
                }
                size="small"
                multiple
                readOnly={!(isEditable || isTagEditable)}
              />
            </GridItem>
          </GridContainer>
        </CardContent>
      </Card>
    </>
  );

  return (
    <GridContainer>
      <GridItem xs={6} sm={6} md={6}>
        {cardLeft}
      </GridItem>
      <GridItem xs={6} sm={6} md={6}>
        {cardRight}
      </GridItem>
    </GridContainer>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px",
    color: "white"
  },
  tpCard: {
    margin: "10px 0"
  },
  tpCardHeader: {
    padding: "8px 16px"
  },
  tpCardContent: {
    padding: "8px 16px",
    fontSize: "0.8rem"
  }
};

export default withStyles(structureStyle)(Header);
