import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { Warning } from "@material-ui/icons";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import { AntTab, AntTabs, TabPanel } from "components/Common/Tabs";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { connect } from "react-redux";
import JobPhaseAddtionnalForemenPart from "./jobDetails/Parts/JobPhaseAddtionnalForemenPart";
import JobPhaseCustomer from "./jobDetails/Parts/JobPhaseCustomer";
import JobPhaseDatesPart from "./jobDetails/Parts/JobPhaseDatesPart";
import JobPhaseIdentifierAndSourcePart from "./jobDetails/Parts/JobPhaseIdentifierAndSourcePart";
import JobPhaseLinksPart from "./jobDetails/Parts/JobPhaseLinksPart";

function JobPhaseDetail({ amc, isReadonly }) {
  const [tabIdx, setTabIdx] = React.useState(0);

  var warnings = amc.errors["JOB"];
  var tabGeneral = <Trans>General</Trans>;
  if (!!warnings && warnings.some(w => w.code.split("/")[1] === "GENERAL"))
    tabGeneral = (
      <span>
        <Warning style={{ color: "red" }} />
        {tabGeneral}
      </span>
    );
  var tabContacts = <Trans>Contacts</Trans>;
  if (!!warnings && warnings.some(w => w.code.split("/")[1] === "CONTACTS"))
    tabContacts = (
      <span>
        <Warning style={{ color: "red" }} />
        {tabContacts}
      </span>
    );

  return (
    <div style={{ width: "99%" }}>
      <GridContainer>
        <GridItem xs={2} sm={2} md={2}>
          <AntTabs
            orientation="vertical"
            variant="scrollable"
            value={tabIdx}
            onChange={(e, value) => setTabIdx(value)}
            aria-label="Vertical tabs job"
          >
            <AntTab label={tabGeneral} />
            <AntTab label={tabContacts} />
          </AntTabs>
        </GridItem>
        <GridItem xs={10} sm={10} md={10}>
          <TabPanel value={tabIdx} index={0}>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <JobPhaseIdentifierAndSourcePart isReadonly={isReadonly} />
                <JobPhaseLinksPart isReadonly={isReadonly} />
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <JobPhaseDatesPart isReadonly={isReadonly} />
                <JobPhaseCustomer isReadonly={isReadonly} />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}></GridItem>
            </GridContainer>
          </TabPanel>
          <TabPanel value={tabIdx} index={1}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <JobPhaseAddtionnalForemenPart isReadonly={isReadonly} />
              </GridItem>
            </GridContainer>
          </TabPanel>
        </GridItem>
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

export default connect(mapStateToProps)(withStyles(amcSearchStyle)(JobPhaseDetail));
