import React, { useEffect, useState } from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";
//
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//Icon
import StarIcon from "@material-ui/icons/Star";
import WorkIcon from "@material-ui/icons/Work";
//lingui JS
import { Trans } from "@lingui/macro";

function ProductKeyWord({ product, isEditable }) {
  var ProductKeyWord = "";
  if (product.keyWords !== null) {
    ProductKeyWord = product.keyWords.map(keyWord => {
      return (
        <div>
          <ListItem>
            <ListItemIcon>
              <bookmarkIcon />
            </ListItemIcon>
            <ListItemText inset secondary={keyWord.value} />
          </ListItem>
        </div>
      );
    });
  }
  return (
    <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader
              style={{
                ...CardInlineStyle.cardHeader
              }}
              icon
            >
              <ListItem>
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText primary={product.commercialLabel} />
                <h5>
                  {" "}
                  <Trans>Id in MDM :</Trans>
                  {product.identifier}
                </h5>
              </ListItem>
            </CardHeader>
            <CardBody>
              <GridContainer alignItems="center" justify="center">
                <GridItem xs={6} sm={6} md={6}>
                  <Card>
                    <CardHeader
                      style={{
                        ...CardInlineStyle.cardHeader
                      }}
                      icon
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <StarIcon />
                        </ListItemIcon>
                        <ListItemText primary={<Trans>Product Key Words</Trans>} />
                      </ListItem>
                    </CardHeader>
                    <CardBody>{ProductKeyWord}</CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  img: {
    width: "20px",
    height: "20px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  },
  FormLabel: {
    margin: "5px"
  },
  root: {
    width: "100%",
    marginTop: "20px"
  },
  taille: {
    width: "100%"
  }
};

export default withStyles(thirdPartySearchCriteriaStyle)(ProductKeyWord);
