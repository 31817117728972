import { Trans } from "@lingui/macro";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepButton, Stepper, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LinkMaterialUi from "@material-ui/core/Link";
import withStyles from "@material-ui/core/styles/withStyles";
import { Error } from "@material-ui/icons";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Warning from "@material-ui/icons/Warning";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import DialogBox from "components/DialogBox/DialogBox.jsx";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { TpIndustryCode } from "store/MasterValue/MasterValueTypes.jsx";
import { LoadMasterValues } from "store/MasterValue/MasterValueTypes.jsx";
import { CommonCountry, TpLegalIdentifier } from "store/MasterValue/MasterValueTypes.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardBody from "../../../components/Card/CardBody.jsx";
import Button from "../../../components/CustomButtons/Button.jsx";
import LocalStorageHelper from "../../../services/common/LocalStorageHelper.jsx";
import { isArrayNullOrEmpty, isNull } from "../../tools.jsx";
import UnknownStep1 from "../components/WizardSteps/UnknownStep1";
import UnknownStep2 from "../components/WizardSteps/UnknownStep2";
import UnknownStep3 from "../components/WizardSteps/UnknownStep3";
import { OpenThirdParty, OpenUnreferencedThirdParty } from "../store/actions/index.jsx";
import { ThirdPartyAutocomplete, ThirdPartyCrupdate, ThirdPartyValidate } from "../thirdParties/actions/ThirdPartyActions.js";

function ThirdPartyWizardUnknown({ handleClose, masterValues, classes }) {
  var defaultCountry = "FR";
  var thirdPartyDefaultLang = "F";
  var defaultLang = "EN";

  const [isMvLoaded, setIsMvLoaded] = useState("init");
  const [warningHasBeenThrown, setWarningHasBeenThrown] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [errors, setErrors] = useState([]);
  const [thirdParty, setThirdParty] = useState({
    address_CountryCode: defaultCountry !== "" ? defaultCountry : "FR",
    languageCode: thirdPartyDefaultLang
  });
  var dispatch = useDispatch();

  if (isMvLoaded === "init") {
    setIsMvLoaded("true");
    LoadMasterValues([TpLegalIdentifier, TpIndustryCode]).then(() => setIsMvLoaded(null));
  }

  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");
  if (getPref !== null) {
    defaultCountry = getPref.InfoGeneral.Country;
    defaultLang = getPref.InfoGeneral.Langue === "fr" ? "FR" : "EN";
    var country = masterValues[CommonCountry].find(c => c.codeIso2 === defaultCountry);
    thirdPartyDefaultLang = country ? country.languageCode : "F";
  }

  ///-----------------------------------------------------------
  /// Changement d'étape active
  ///-----------------------------------------------------------
  function handleStep(step) {
    this.setState({ activeStep: step });
  }

  ///-----------------------------------------------------------
  /// Changement d'étape active
  ///-----------------------------------------------------------
  function handleStep(step) {
    setActiveStep(step);
  }

  function validateThirdParty(workflowStep) {
    ThirdPartyAutocomplete(
      thirdParty,
      updatedItem => {
        setThirdParty(updatedItem);

        if (warningHasBeenThrown) {
          setWarningHasBeenThrown(false);
          setErrors([]);
          setActiveStep(activeStep + 1);
        } else {
          ThirdPartyValidate(
            updatedItem,
            workflowStep,
            errors => {
              if (!isArrayNullOrEmpty(errors)) {
                var criticalError = errors.find(t => {
                  return t.severity == "Error";
                });
                if (criticalError === null || criticalError === undefined) setWarningHasBeenThrown(true);
                setErrors(errors);
              } else {
                if (activeStep === 0) {
                  fillThirdPartyWithLegalIdentifiers(updatedItem);
                }

                setErrors([]);
                setActiveStep(activeStep + 1);
              }
            },
            error => {
              console.log(error);
            }
          );
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///-----------------------------------------------------------
  /// Sélection de la nouvelle étape
  ///-----------------------------------------------------------
  function handleNext() {
    var workflowStep = "";
    switch (activeStep) {
      case 0:
        workflowStep = "unknownStep1";
        break;
      case 1:
        workflowStep = "unknownStep2";
        break;
      case 2:
        workflowStep = "unknownStep3";
        break;
    }

    validateThirdParty(workflowStep);
  }

  function fillThirdPartyWithLegalIdentifiers(thirdParty) {
    if (isArrayNullOrEmpty(thirdParty.thirdParty_LegalIdentifiers)) {
      var legalIdentifiers = filterLegalIdentifierByCountry(thirdParty.address_CountryCode);
      var newLegalIdentifierLinks = [];
      for (var i = 0; i < legalIdentifiers.length; i++) {
        newLegalIdentifierLinks.push({ legalIdentifierCode: legalIdentifiers[i].code });
      }
      setThirdParty({ ...thirdParty, thirdParty_LegalIdentifiers: newLegalIdentifierLinks });
    } else {
      var legalIdentifiers = filterLegalIdentifierByCountry(thirdParty.address_CountryCode);
      var newLegalIdentifierLinks = [];
      for (var i = 0; i < legalIdentifiers.length; i++) {
        var currentItem = thirdParty.thirdParty_LegalIdentifiers.find(element => element.legalIdentifierCode === legalIdentifiers[i].code);
        if (!isNull(currentItem)) newLegalIdentifierLinks.push(currentItem);
        else newLegalIdentifierLinks.push({ legalIdentifierCode: legalIdentifiers[i].code });
      }
      setThirdParty({ ...thirdParty, thirdParty_LegalIdentifiers: newLegalIdentifierLinks });
    }
  }

  function filterLegalIdentifierByCountry(country) {
    var legalIdentifiers = masterValues[TpLegalIdentifier].filter(legalIdentifier => legalIdentifier.countryCode === country);
    return legalIdentifiers;
  }

  function handleSave() {
    ThirdPartyCrupdate(
      thirdParty,
      newItem => {
        handleClose();
        dispatch(OpenThirdParty(newItem.identifier));
      },
      error => {
        console.log(error);
      }
    );
  }

  function generateLinkForExistingThirdParty(thirdparties) {
    var links = thirdparties.map(item => {
      return (
        <ul>
          <Tooltip
            placement="right"
            title={
              <>
                {item.address_Line2}
                <br />
                {item.address_Line1}
                <br />
                {item.address_ZipCode}
                <br />
                {item.address_City}
                <br />
                {item.address_CountryCode}
              </>
            }
          >
            <LinkMaterialUi
              style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
              onClick={event => {
                handleClose();
                dispatch(OpenThirdParty(item.identifier));
              }}
            >
              <IconButton
                style={{
                  color: "orange"
                }}
              >
                <ArrowForward />
              </IconButton>
              <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
                {item.longName} - {item.legalIdentifier}
              </span>
            </LinkMaterialUi>
          </Tooltip>
        </ul>
      );
    });
    return links;
  }

  ///-----------------------------------------------------------
  /// Affichage du composant à afficher en fonction de l'étape active
  ///-----------------------------------------------------------
  function getStepContent() {
    switch (activeStep) {
      case 0:
        return <UnknownStep1 thirdParty={thirdParty} errors={errors} handleChange={tp => setThirdParty(tp)} defaultLang={defaultLang} />;
      case 1:
        return <UnknownStep2 thirdParty={thirdParty} errors={errors} handleChange={tp => setThirdParty(tp)} defaultLang={defaultLang} />;
      case 2:
        return <UnknownStep3 thirdParty={thirdParty} handleChange={tp => setThirdParty(tp)} />;
      default:
        return <Trans>Unknown step </Trans>;
    }
  }

  ///-----------------------------------------------------------
  /// Rendu du composant
  ///-----------------------------------------------------------

  var buttonPrev = "";
  if (activeStep > 0)
    buttonPrev = (
      <Button
        type="submit"
        color="info"
        onClick={() => {
          setErrors([]);
          setActiveStep(activeStep - 1);
        }}
      >
        <Trans>Previous</Trans>
      </Button>
    );

  var buttonNext = "";
  if (activeStep < 2)
    buttonNext = (
      <Button
        type="submit"
        color="info"
        onClick={() => {
          setErrors([]);
          handleNext();
        }}
      >
        <Trans>Next</Trans>
      </Button>
    );

  var buttonSave = "";
  if (activeStep === 2)
    buttonSave = (
      <Button type="submit" color="info" onClick={() => handleSave()}>
        <Trans>Save</Trans>
      </Button>
    );

  var steps = ["Informations générales", "Identifiants légaux", "Contact"];

  var errorCard = "";
  if (!isArrayNullOrEmpty(errors)) {
    var errorMessage = errors.map((t, idx) => {
      if (t.code === "ExistingLegalIdentifier") {
        return (
          <div>
            <div style={{ lineHeight: "0.5em" }}>
              <Error style={{ verticalAlign: "middle", color: "red" }} />
              <span style={{ verticalAlign: "middle", color: "red", marginRight: "5px" }}>
                <strong>
                  <Trans>A third party with the same legal identifier already exist</Trans>
                </strong>
              </span>
              <Trans>You can choose the existing third party or abort current workflow.</Trans>
            </div>
            <ol>
              <Tooltip
                placement="right"
                title={
                  <>
                    {t.complexLinkedObject.address_Line2}
                    <br />
                    {t.complexLinkedObject.address_Line1}
                    <br />
                    {t.complexLinkedObject.address_ZipCode}
                    <br />
                    {t.complexLinkedObject.address_City}
                    <br />
                    {t.complexLinkedObject.address_CountryCode}
                  </>
                }
              >
                <LinkMaterialUi
                  style={{ color: "black", fontWeight: "200", cursor: "pointer" }}
                  onClick={event => {
                    handleClose();
                    if (
                      t.complexLinkedObject.identifier !== null &&
                      t.complexLinkedObject.identifier !== undefined &&
                      t.complexLinkedObject.identifier > 0
                    )
                      dispatch(OpenThirdParty(t.complexLinkedObject.identifier));
                    else dispatch(OpenUnreferencedThirdParty(t.complexLinkedObject));
                  }}
                >
                  <IconButton
                    style={{
                      color: "red"
                    }}
                  >
                    <ArrowForward />
                  </IconButton>
                  <span style={{ color: "black", fontWeight: "200", cursor: "pointer" }}>
                    {t.complexLinkedObject.longName} - {t.complexLinkedObject.legalIdentifier}
                  </span>
                </LinkMaterialUi>
              </Tooltip>
            </ol>
          </div>
        );
      } else if (t.code === "ExistingLongName") {
        var links = generateLinkForExistingThirdParty(t.complexLinkedObject);
        return (
          <div>
            <div style={{ lineHeight: "0.5em" }}>
              <Warning style={{ verticalAlign: "middle", color: "orange" }} />
              <span style={{ verticalAlign: "middle", color: "orange" }}>
                <strong>
                  <Trans>At least one third party with the same long name already exists</Trans>
                </strong>
              </span>{" "}
              - {t.complexLinkedObject.longName}
            </div>
            <ol>
              <Trans>You can choose one of the existing or decide to continue :</Trans>
              {links}
            </ol>
          </div>
        );
      } else {
        return (
          <li key={idx}>
            <strong>{t.code}</strong> - {t.descriptionFr}
          </li>
        );
      }
    });
    errorCard = (
      <Card>
        <CardBody>{errorMessage}</CardBody>
      </Card>
    );
  }

  if (!!isMvLoaded) {
    return <DialogBox dialogBox={{ type: "loading" }} />;
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={true}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.dialogPaper }}
      scroll="paper"
    >
      <DialogTitle>
        <Trans>Unknown ThirdParty creation</Trans>
      </DialogTitle>
      <DialogContent>
        <div>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton onClick={() => handleStep(index)}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        {errorCard}
        {getStepContent()}
      </DialogContent>
      <DialogActions>
        {buttonPrev}
        {buttonNext}
        {buttonSave}
        <Button type="submit" color="info" onClick={() => handleClose()}>
          <Trans>Abort</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyWizardUnknown));
