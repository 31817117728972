import { Trans } from "@lingui/macro";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { AccountTree, Build, Category, DeviceHub, Domain, Equalizer, LocationOn, Notifications } from "@material-ui/icons";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { Link } from "react-router-dom";
import adminRoutes from "routes/admin";
import amcRoutes from "routes/amc";
import productRoutes from "routes/product";
import siteRoutes from "routes/site";
import structureRoutes from "routes/structure";
import supervisionRoutes from "routes/supervision";
import tpColumbusRoutes from "routes/tpColumbus";
import tpCopernicRoutes from "routes/tpCopernic";
import workflowRoutes from "routes/workflow";
import { HasRight } from "services/user/UserHelper";

const HomePage = () => {
  var modules = [
    {
      icon: LocationOn,
      title: <Trans>Sites</Trans>,
      routes: siteRoutes.filter(r => !r.role || HasRight(r.role))
    },
    {
      icon: DeviceHub,
      title: <Trans>Structures</Trans>,
      routes: structureRoutes.filter(r => !r.role || HasRight(r.role))
    }
  ];

  if (HasRight("product.view")) {
    modules = [
      ...modules,
      {
        icon: Category,
        title: <Trans>Products</Trans>,
        routes: productRoutes.filter(r => !r.role || HasRight(r.role))
      }
    ];
  }

  if (HasRight("thirdparty_customer.view") || HasRight("thirdparty_workflow.edit") || HasRight("thirdparty_supplier.view")) {
    modules = [
      ...modules,
      {
        icon: Domain,
        title: <Trans>HeaderLinks_TpCopernic</Trans>,
        routes: tpCopernicRoutes.filter(r => !r.role || HasRight(r.role))
      }
    ];
  }

  if (HasRight("tp_columbus.view")) {
    modules = [
      ...modules,
      {
        icon: Domain,
        title: <Trans>HeaderLinks_TpColumbus</Trans>,
        routes: tpColumbusRoutes.filter(r => !r.role || HasRight(r.role))
      }
    ];
  }

  if (HasRight("amc.view")) {
    modules = [
      ...modules,
      {
        icon: AccountTree,
        title: <Trans>Amc</Trans>,
        routes: amcRoutes.filter(r => !r.role || HasRight(r.role))
      }
    ];
  }

  if (HasRight("application.edit")) {
    modules = [
      ...modules,
      {
        icon: Build,
        title: <Trans>Administrator</Trans>,
        routes: adminRoutes.filter(r => !r.role || HasRight(r.role))
      },
      {
        icon: Equalizer,
        title: <Trans>Supervision</Trans>,
        routes: supervisionRoutes.filter(r => !r.role || HasRight(r.role))
      }
    ];
  }

  if (
    HasRight("thirdparty_customer.view") ||
    HasRight("thirdparty_workflow.edit") ||
    HasRight("thirdparty_supplier.view") ||
    HasRight("thirdparty_workflow.view")
  ) {
    modules = [
      ...modules,
      {
        icon: Notifications,
        title: <Trans>Notification</Trans>,
        routes: workflowRoutes.filter(r => !r.role || HasRight(r.role))
      }
    ];
  }

  return (
    <div>
      <h1>
        <Trans>Bienvenue sur le Portail référentiel</Trans>
      </h1>
      <GridContainer>
        {modules.map((m, idx) => {
          return (
            <GridItem key={"module_" + idx} xs={3}>
              <Card
                style={{
                  ...CardInlineStyle.card
                }}
              >
                <CardHeader
                  style={{
                    ...CardInlineStyle.cardHeader
                  }}
                  icon
                >
                  <h4 style={{ display: "flex" }}>
                    <m.icon />
                    <Typography>{m.title}</Typography>
                  </h4>
                </CardHeader>
                <CardBody>
                  {m.routes && (
                    <List dense>
                      {m.routes.map((r, rIdx) => {
                        return (
                          <ListItem button key={"module_" + idx + "_li_" + rIdx} component={Link} to={r.path}>
                            <ListItemIcon>
                              <r.icon />
                            </ListItemIcon>
                            <ListItemText>{r.name}</ListItemText>
                          </ListItem>
                        );
                      })}
                    </List>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    </div>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default HomePage;
