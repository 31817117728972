import React, { useState } from "react";
import { connect } from "react-redux";
import { isArrayNullOrEmpty } from "tools";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle";

import HistoryContainer from "module/history/containers/HistoryContainer";
import CardError from "components/Card/CardError";
import { CircularProgress } from "@material-ui/core";
import { GetSupplierHistory } from "../../store/actions";

const TabSupplierHistory = function({ supplierCtx }) {
  const [state, setState] = useState({ isLoading: false });

  var supplier = supplierCtx.supplier;

  if (!state.isLoading && !state.history) {
    setState({ isLoading: true, history: [] });

    GetSupplierHistory(
      supplier.thirdPartyIdentifier,
      h => setState({ isLoading: false, history: h }),
      e => setState({ isLoading: false, history: [], error: e })
    );
  }

  if (state.error) {
    return <CardError error={state.error} />;
  }

  if (state.isLoading) {
    return <CircularProgress />;
  }

  return <HistoryContainer currentItem={supplier} items={state.history} />;
};

const mapStateToProps = state => {
  return {
    supplierCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].supplierCtx : null
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(TabSupplierHistory));
