import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { RootStore } from "store/RootStore";
import { I18nProvider } from "@lingui/react";
import { i18n } from "@lingui/core";
import { GetLocale, I18nLoad } from "services/I18nHelper";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "services/authentication/MsalAuth";

const rootElement = document.getElementById("root");

const Translation = () => {
  useEffect(() => {
    I18nLoad(GetLocale()).catch(() => I18nLoad("en"));
  }, []);

  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <I18nProvider i18n={i18n}>
          <Provider store={RootStore}>
            <App />
          </Provider>
        </I18nProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

ReactDOM.render(<Translation />, rootElement);
