import * as Actions from "./types";
import { isArrayNullOrEmpty, isNullOrEmpty } from "../../../tools";
import { ApimGet, ApimPost } from "components/Common/ApimSender";

const delegationEndpoint = "api/ProfileDelegations";

export function SetCriterias(criterias) {
  return dispatch => {
    dispatch({
      type: Actions.USERANDRIGHT_PROFILEDELEGATION_SET_CRITERIAS,
      criterias: criterias
    });
  };
}

export function Search() {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.USERANDRIGHT_PROFILEDELEGATION_BEGIN_ACTION
    });
    
    const criterias = getState().UR_ProfileDelegation.criterias;
    
    ApimGet(
      delegationEndpoint + ConvertCriteriaToQueryString(criterias),
      responseJson => {
        dispatch({
          type: Actions.USERANDRIGHT_PROFILEDELEGATION_GET_ALL,
          result: responseJson
        });

        dispatch({
          type: Actions.USERANDRIGHT_PROFILEDELEGATION_END_ACTION
        });
      },
      error => {
        console.log(error);
        dispatch({
          type: Actions.USERANDRIGHT_PROFILEDELEGATION_END_ACTION
        });
      }
    );
  };
}

export function Get(id) {
  return dispatch => {
    ApimGet(
      delegationEndpoint + "/" + id,
      responseJson => {
        dispatch({
          type: Actions.USERANDRIGHT_PROFILEDELEGATION_GET_EXISTING,
          item: responseJson
        });
      },
      error => {
        dispatch({
          type: Actions.USERANDRIGHT_PROFILEDELEGATION_END_ACTION
        });
      }
    );
  };
}

export function Crupdate(item, callbackFn, errorFn) {
  return dispatch => {
    dispatch({
      type: Actions.USERANDRIGHT_PROFILEDELEGATION_BEGIN_ACTION
    });

    ApimPost(
      delegationEndpoint,
      item,
      responseJson => {
        callbackFn();
        dispatch({
          type: Actions.USERANDRIGHT_PROFILEDELEGATION_SAVE_END,
          item: responseJson
        });
      },
      error => {
        errorFn(error.response.data);
        dispatch({
          type: Actions.USERANDRIGHT_PROFILEDELEGATION_SAVE_ERROR
        });
      }
    );
  };
}

function ConvertCriteriaToQueryString(criteria) {
  let queryParams = [];

  //ProfileDelegationSearchCriteria
  if (!isNullOrEmpty(criteria.userLogin))
    queryParams.push("UserLogin=" + criteria.userLogin);
  if (!isNullOrEmpty(criteria.validAtDate))
    queryParams.push("ValidAtDate=" + criteria.validAtDate);

  //PagingAndOrderProperties
  if (!isNullOrEmpty(criteria.startPage))
    queryParams.push("StartPage=" + criteria.startPage);
  if (!isNullOrEmpty(criteria.pageSize))
    queryParams.push("PageSize=" + criteria.pageSize);
  if (!isNullOrEmpty(criteria.orderProperty))
    queryParams.push("OrderProperty=" + criteria.orderProperty);
  if (!isNullOrEmpty(criteria.isDescendingOrder))
    queryParams.push("IsDescendingOrder=" + criteria.isDescendingOrder);

  return (!isArrayNullOrEmpty(queryParams)) ? "?" + queryParams.join("&") : "";
}