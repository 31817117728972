import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

import IconButton from "@material-ui/core/IconButton";
import {
    transition,
    cardTitle,
    grayColor,
    roseColor,
    primaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor
} from "assets/jss/material-dashboard-pro-react.jsx";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

const amcSearchStyle = theme => ({
    ...customCheckboxRadioSwitch,
    IconButton: {
        color: "primary"
    },
    cardTitle: {
        ...cardTitle,
        color: "#FFFFFF"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    formCategory: {
        marginBottom: "0",
        color: "#999999",
        fontSize: "14px",
        padding: "10px 0 10px"
    },
    center: {
        textAlign: "center"
    },
    justifyContentCenter: {
        justifyContent: "center"
    },
    flexRight: {
        display: "flex",
        justifyContent: "flex-end",
        flexWrap: "wrap"
    },
    danger: {
        color: dangerColor + "!important"
    },
    detailPanel: {
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        overflow: "auto",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch"
    },
    detailPanelWithPerfectScrollbar: {
        overflow: "hidden !important"
    },
    Success: {
        backgroundColor: green[600],
    },
    Error: {
        backgroundColor: red[600],
    },
    info: {
        backgroundColor: infoColor,
        boxShadow:
            "0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12)",
        "&:hover,&:focus": {
            backgroundColor: infoColor,
            boxShadow:
                "0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2)"
        }
    }
});

export default amcSearchStyle;
