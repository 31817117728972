import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import adminStyle from "assets/jss/mdmcolas/admin/adminStyle";

import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import { setupI18n } from "@lingui/core";
import { t, Trans } from "@lingui/macro";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import {
  TpIndustryCode,
  TpPaymentPeriod,
  TpPaymentCharacteristicType,
  TpPaymentMethod,
  TpThirdPartyCategory,
  TpLegalIdentifier
} from "store/MasterValue/MasterValueTypes";

function PartThirdParties({ country, setCountry, classes }) {
  const i18n = setupI18n();
  return (
    <Grid container>
      <Grid item xs={8} sm={8} md={8}>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              <Trans> Payment Period Domestic</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={TpPaymentPeriod}
              selectedValue={country.codePayementPeriodDomestic}
              onValueChange={code => setCountry({ ...country, codePayementPeriodDomestic: code })}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              <Trans> Payment Method Domestic</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={TpPaymentMethod}
              selectedValue={country.codePayementMethodeDomestic}
              onValueChange={code => setCountry({ ...country, codePayementMethodeDomestic: code })}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              <Trans>Payment Period not Domestic</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={TpPaymentPeriod}
              selectedValue={country.codePayementPeriodNotDomestic}
              onValueChange={code => setCountry({ ...country, codePayementPeriodNotDomestic: code })}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              <Trans> Payment Method Not Domestic</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={TpPaymentMethod}
              selectedValue={country.codePayementMethodeNotDomestic}
              onValueChange={code => setCountry({ ...country, codePayementMethodeNotDomestic: code })}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              <Trans> Industry code</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={TpIndustryCode}
              selectedValue={country.codeIndustryCode}
              onValueChange={code => setCountry({ ...country, codeIndustryCode: code })}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              {" "}
              <Trans> Third Parties Category</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              multiple
              mvType={TpThirdPartyCategory}
              selectedValue={country.thirdPartyCategoriesAllowUnknown.map(v => v.thirdPartyCategoryCode)}
              onValueChange={values =>
                setCountry({
                  ...country,
                  thirdPartyCategoriesAllowUnknown: values.map(v => {
                    return { countryCodeIso2: country.codeIso2, thirdPartyCategoryCode: v };
                  })
                })
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              {" "}
              <Trans> PaymentCharacteristic Types</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              multiple
              mvType={TpPaymentCharacteristicType}
              selectedValue={country.allowedPayCharTypes.map(v => v.payCharTypeCode)}
              onValueChange={values =>
                setCountry({
                  ...country,
                  allowedPayCharTypes: values.map(v => {
                    return { countryCodeIso2: country.codeIso2, payCharTypeCode: v };
                  })
                })
              }
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              <Trans>Legal Identifier</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={TpLegalIdentifier}
              mvFilter={mv => mv.countryCode === country.codeIso2}
              selectedValue={country.thirdPartyLegalIdentifierStringFormat}
              onValueChange={v => setCountry({ ...country, thirdPartyLegalIdentifierStringFormat: v })}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              <Trans>Tax Code</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={TpLegalIdentifier}
              mvFilter={mv => mv.countryCode === country.codeIso2}
              selectedValue={country.thirdPartyTaxCodeStringFormat}
              onValueChange={v => setCountry({ ...country, thirdPartyTaxCodeStringFormat: v })}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}>
            <label className={classes.LabelSize}>
              <Trans>Additional Identifier</Trans>{" "}
            </label>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <MasterValueAutocomplete
              isEditable
              prefixCode
              mvType={TpLegalIdentifier}
              mvFilter={mv => mv.countryCode === country.codeIso2}
              selectedValue={country.thirdPartyAdditionalIdentifierStringFormat}
              onValueChange={v => setCountry({ ...country, thirdPartyAdditionalIdentifierStringFormat: v })}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} sm={4} md={4}>
        <FormControlLabel
          control={
            <Checkbox
              checked={country.canCreateUnknownSupplier}
              onClick={() => setCountry({ ...country, canCreateUnknownSupplier: !country.canCreateUnknownSupplier })}
              margin="none"
            />
          }
          label={i18n._(t` Can transform into supplier`)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={country.canCreateUnknownPointOfSale}
              onClick={() => setCountry({ ...country, canCreateUnknownPointOfSale: !country.canCreateUnknownPointOfSale })}
              margin="none"
            />
          }
          label={i18n._(t` Can transform into pointOfSale`)}
        />
        <FormControlLabel
          control={
            <Checkbox checked={country.canCheckSis} onClick={() => setCountry({ ...country, canCheckSis: !country.canCheckSis })} margin="none" />
          }
          label={i18n._(t` Can check SIS `)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={country.canCheckCompanyCompliance}
              onClick={() => setCountry({ ...country, canCheckCompanyCompliance: !country.canCheckCompanyCompliance })}
              margin="none"
            />
          }
          label={i18n._(t` Can check Company Compliance `)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={country.allowPayCharManualEdit}
              onClick={() => setCountry({ ...country, allowPayCharManualEdit: !country.allowPayCharManualEdit })}
              margin="none"
            />
          }
          label={i18n._(t` Allow PayChar manual edit `)}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={country.uppercaseLongNameFromExtRepo}
              onClick={() => setCountry({ ...country, uppercaseLongNameFromExtRepo: !country.uppercaseLongNameFromExtRepo })}
              margin="none"
            />
          }
          label={i18n._(t` UppercaseLongNameFromExtRepo `)}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(adminStyle)(PartThirdParties);
