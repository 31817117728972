import React, { useEffect, useState } from "react";
import ReactTable from "react-table-6";
import { connect, useDispatch } from "react-redux";
import * as Actions from "module/amc/store/actions/CustomerContract.Actions";

import { Checkbox, CircularProgress, FormControl, FormControlLabel, MenuItem, Select, Typography } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import CustomerContractSearchCriteria from "module/amc/components/customerContracts/CustomerContractSearchCriteria";
import CustomerContractTable from "module/amc/components/customerContracts/CustomerContractTable";

import Button from "components/CustomButtons/Button.jsx";
import { Add } from "@material-ui/icons";

//lingui
import { Trans } from "@lingui/macro";
import { isNull, isArrayNullOrEmpty, isNullOrEmpty } from "../../tools";

import "assets/scss/components/ThirdPartyResult.css";
import "assets/scss/components/ThirdPartySearchCriteria.css";
import CustomerContractPopup from "./CustomerContractPopup";
import { HasRight } from "services/user/UserHelper";

function CustomerContractList({ amc, addItem }) {
  const dispatch = useDispatch();

  var searchResult = amc.results["CUSTOMERCONTRACT"];
  var isSearching = amc.searching["CUSTOMERCONTRACT"];

  var resultComponent = "";
  if (isSearching)
    resultComponent = (
      <center>
        <CircularProgress />
      </center>
    );
  else resultComponent = <CustomerContractTable />;

  var buttonCreation = "";
  if (HasRight("amc.admin")) {
    buttonCreation = (
      <GridContainer>
        <GridItem xs={6} sm={6} md={6}>
          <Button variant="contained" onClick={addItem} size="sm" color="info">
            <Add />
            <Trans>Add Customer contract</Trans>
          </Button>
        </GridItem>
      </GridContainer>
    );
  }

  var layoutSearchResult;
  if (!isNullOrEmpty(searchResult) || isSearching) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {resultComponent}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <CustomerContractSearchCriteria showSearchCriteriaOnSideBar={true} />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = <CustomerContractSearchCriteria showSearchCriteriaOnSideBar={false} />;
  }

  return (
    <div>
      {buttonCreation}
      {layoutSearchResult}
      <CustomerContractPopup />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addItem: () => {
      dispatch(Actions.AddItem());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerContractList);
