import React, { useState } from "react";
import Select from "react-select";

import { isNullOrEmpty } from "module/tools";

import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import GpsAutocomplete from "components/Autocomplete/GpsAutocomplete";
import TextField from "@material-ui/core/TextField";

import { Trans } from "@lingui/macro";
import { isArrayNullOrEmpty } from "../../../../../tools";
import { SearchCityInseeCodes } from "services/masterdata/GeographicalMasterValuesHelper";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const ThirdPartyGeneral = ({ thirdParty, setThirdParty, errors, defaultLang }) => {
  var selectedZipCodeInit = {};
  var autcompleteZipCodeDataInit = [];
  if (!!thirdParty && !!thirdParty.address_ZipCode) {
    selectedZipCodeInit = { label: thirdParty.address_ZipCode, value: thirdParty.address_ZipCode };
    autcompleteZipCodeDataInit.push({ label: thirdParty.address_ZipCode, value: thirdParty.address_ZipCode });
  }

  var selectedCommuneInit = {};
  var autocompleteCommuneDataInit = [];
  if (!!thirdParty && !!thirdParty.address_City) {
    selectedCommuneInit = { label: thirdParty.address_City, value: thirdParty.address_City };
    autocompleteCommuneDataInit.push({ label: thirdParty.address_City, value: thirdParty.address_City });
  }

  const [autocompleteZipCodeData, setAutcompleteZipCodeData] = useState(autcompleteZipCodeDataInit);
  const [autocompleteCommuneData, setAutocompleteCommuneData] = useState(autocompleteCommuneDataInit);
  const [selectedZipCode, setSelectedZipCode] = useState(selectedZipCodeInit);
  const [selectedCommune, setSelectedCommune] = useState(selectedCommuneInit);

  function onChangeZipCode(val) {
    setSelectedZipCode(val);
    setThirdParty({ ...thirdParty, address_ZipCode: val.value });
    if (val.value.length > 2) {
      SearchCityInseeCodes({ zipCode: val.value }, items => {
        setAutocompleteCommuneData({
          autocompleteCommuneData: items.items.map(item => {
            return { label: item.commune, value: item.commune };
          })
        });
      });
    }
  }

  function onSearchZipCode(val) {
    if (val.length > 2) {
      SearchCityInseeCodes({ zipCode: val }, items => {
        var zipCodeList = items.items.map(item => {
          return item.zipCode;
        });
        var arraytTemp = Array.from(new Set(zipCodeList));
        setAutocompleteCommuneData(
          items.items.map(item => {
            return { label: item.commune, value: item.commune };
          })
        );
        setAutcompleteZipCodeData(
          items.items.map(item => {
            return { abel: item, value: item };
          })
        );
      });
    }
  }

  function onChangeCity(val) {
    setSelectedCommune(val);
    setThirdParty({ ...thirdParty, address_City: val.value });
  }

  function onSearchCity(val) {
    if (isNullOrEmpty(thirdParty.address_ZipCode)) {
      if (val.length > 2) {
        SearchCityInseeCodes({ name: val }, items => {
          var zipCodeList = items.items.map(item => {
            return item.zipCode;
          });
          var arraytTemp = Array.from(new Set(zipCodeList));
          setAutocompleteCommuneData(
            items.items.map(item => {
              return { label: item.commune, value: item.commune };
            })
          );
          setAutcompleteZipCodeData(
            items.items.map(item => {
              return { abel: item, value: item };
            })
          );
        });
      }
    }
  }

  function renderErrors() {
    var errorTabs = [];
    if (!isArrayNullOrEmpty(errors)) {
      for (var i = 0; i < errors.length; i++) {
        errorTabs[errors[i].code] = true;
      }
    }
    return errorTabs;
  }

  var item = thirdParty;
  var errorTabs = renderErrors();

  var zipCodeComponent = "";
  var cityComponent = "";

  if (!isNullOrEmpty(item.address_CountryCode) && item.address_CountryCode === "FR") {
    zipCodeComponent = (
      <Select
        options={autocompleteZipCodeData}
        value={selectedZipCode}
        onChange={onChangeZipCode}
        onInputChange={onSearchZipCode}
        clearable={true}
        searchable={true}
        error={errorTabs["AddressZipCodeMissing"]}
      />
    );
    cityComponent = (
      <Select
        options={autocompleteCommuneData}
        value={selectedCommune}
        onChange={onChangeCity}
        onInputChange={onSearchCity}
        clearable={true}
        searchable={true}
        error={errorTabs["AddressCityMissing"]}
      />
    );
  } else {
    zipCodeComponent = (
      <TextField
        margin="dense"
        id="zipCode"
        type="text"
        fullWidth
        value={item.address_ZipCode}
        onChange={e => setThirdParty({ ...thirdParty, address_ZipCode: e.target.value })}
      />
    );
    cityComponent = (
      <TextField
        margin="dense"
        id="city"
        type="text"
        fullWidth
        value={item.address_City}
        onChange={e => setThirdParty({ ...thirdParty, address_City: e.target.value })}
      />
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>General informations</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel error={errorTabs["longNameMissing"]}>
                <Trans>Long name</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <TextField
                margin="dense"
                id="longName"
                type="text"
                fullWidth
                error={errorTabs["longNameMissing"]}
                value={item.longName}
                onChange={e => setThirdParty({ ...thirdParty, longName: e.target.value })}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel error={errorTabs["customLongNameMissing"]}>
                <Trans>Colas name</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <TextField
                margin="dense"
                id="longNameColas"
                type="text"
                error={errorTabs["customLongNameMissing"]}
                fullWidth
                value={item.customLongName}
                onChange={e => setThirdParty({ ...thirdParty, customLongName: e.target.value })}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Address</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel error={errorTabs["AddressLine1Missing"]}>
                <Trans>Line 1</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <TextField
                margin="dense"
                id="line1"
                type="text"
                error={errorTabs["AddressLine1Missing"]}
                fullWidth
                value={item.address_Line1}
                onChange={e => setThirdParty({ ...thirdParty, address_Line1: e.target.value })}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel>
                <Trans>Line 2</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <TextField
                margin="dense"
                id="line2"
                type="text"
                fullWidth
                value={item.address_Line2}
                onChange={e => setThirdParty({ ...thirdParty, address_Line2: e.target.value })}
              />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel error={errorTabs["AddressZipCodeMissing"]}>
                <Trans>Zip Code</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {zipCodeComponent}
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel error={errorTabs["AddressCityMissing"]}>
                <Trans>City</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              {cityComponent}
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
              <FormLabel error={errorTabs["AddressCountryMissing"]}>
                <Trans>Country</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={8} sm={8} md={8}>
              <CountryAutocomplete
                countryCode={item.address_CountryCode}
                onChange={codeIso2 => {
                  if (item.address_CountryCode !== codeIso2 && (item.address_CountryCode === "FR" || codeIso2 === "FR"))
                    setThirdParty({ ...thirdParty, address_ZipCode: null, address_City: null, address_CountryCode: codeIso2 });
                  else setThirdParty({ ...thirdParty, address_CountryCode: codeIso2 });
                }}
                defaultLang={defaultLang}
                isEditable
                error={errorTabs["AddressCountryMissing"]}
              />
            </GridItem>

            <GridItem xs={4} sm={4} md={4}>
                <FormLabel>
                  <Trans>LatitudeLongitude</Trans>
                </FormLabel>
              </GridItem>
              <GridItem xs={8} sm={8} md={8}>
                <GpsAutocomplete
                  //latitudeError={checkError("LatitudeWrongFormat")}
                  //longitudeError={checkError("LongitudeWrongFormat")}
                  isEditable
                  defaultLang={defaultLang}
                  source={thirdParty}
                  setSource={c => {
                    setThirdParty(c);
                  }}
                />
              </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export default ThirdPartyGeneral;
