import * as ProductActions from "module/product/store/actions/types";
import * as SiteActions from "module/site/store/actions/types";
import * as StructureActions from "module/structure/store/actions/types";
import * as TpColumbusActions from "module/tpColumbus/store/actions/types";
import * as TpCopernicActions from "module/tpCopernic/store/actions/types";
import * as WorkflowActions from "module/workflow/store/actions/types";
import * as MasterValueActions from "store/MasterValue/MasterValueActions";

const initialState = {
  contextes: []
};

export default function ModuleReducer(state = initialState, action) {
  switch (action.type) {
    case SiteActions.SITE_OPEN:
      return {
        ...state,
        contextes: [
          ...state.contextes,
          {
            type: "SITE",
            id: action.siteId
          }
        ]
      };

    case StructureActions.STRUCTURE_OPEN:
      return {
        ...state,
        contextes: [
          ...state.contextes,
          {
            type: "STRUCTURE",
            identifiant: action.structureIdentifiant,
            structureType: action.structureType,
            structureToOpen: action.structureToOpen
          }
        ]
      };

    case TpColumbusActions.TP_COLUMBUS_THIRDPARTY_OPEN:
      return {
        ...state,
        contextes: [
          ...state.contextes,
          {
            type: "TP_COLUMBUS_THIRDPARTY",
            id: action.thirdPartyId
          }
        ]
      };

    case TpCopernicActions.TP_COPERNIC_COMPANY_OPEN:
      return {
        ...state,
        contextes: [
          ...state.contextes,
          {
            type: "TP_COPERNIC_COMPANY",
            id: action.companyIdentifier,
            tabIndex: action.tabIndex
          }
        ]
      };

    case TpCopernicActions.TP_COPERNIC_THIRDPARTY_OPEN:
      return {
        ...state,
        contextes: [
          ...state.contextes,
          {
            type: "TP_COPERNIC_THIRDPARTY",
            id: action.thirdPartyIdentifier
          }
        ]
      };

    case ProductActions.PRODUCT_OPEN:
      return {
        ...state,
        contextes: [
          ...state.contextes,
          {
            type: "PRODUCT",
            id: action.productId
          }
        ]
      };

    case WorkflowActions.WORKFLOW_OPEN:
      return {
        ...state,
        contextes: [
          ...state.contextes,
          {
            type: "WORKFLOW",
            id: action.workflowId,
            workflowTypeCode: action.workflowTypeCode
          }
        ]
      };

    case MasterValueActions.Add_MASTERVALUES:
      if (action.itemIdx && action.itemIdx + 1 < action.itemTotal) {
        if (state.contextes[state.contextes.length - 1].type !== "MASTERVALUE")
          return {
            ...state,
            contextes: [
              ...state.contextes,
              {
                type: "MASTERVALUE",
                itemIdx: action.itemIdx,
                itemTotal: action.itemTotal
              }
            ]
          };
        else {
          return {
            ...state,
            contextes: state.contextes.map((item, index) => {
              if (index === state.contextes.length - 1) {
                return {
                  ...item,
                  itemIdx: action.itemIdx,
                  itemTotal: action.itemTotal
                };
              }
              return item;
            })
          };
        }
      } else if (state.contextes[state.contextes.length - 1]?.type === "MASTERVALUE") {
        // remove last
        let tmp = state.contextes;
        tmp.pop();
        return {
          ...state,
          contextes: tmp
        };
      }
      return state;

    case SiteActions.SITE_CLOSE:
    case StructureActions.STRUCTURE_CLOSE:
    case TpColumbusActions.TP_COLUMBUS_THIRDPARTY_CLOSE:
    case TpCopernicActions.TP_COPERNIC_COMPANY_CLOSE:
    case TpCopernicActions.TP_COPERNIC_THIRDPARTY_CLOSE:
    case ProductActions.PRODUCT_CLOSE:
    case WorkflowActions.WORKFLOW_CLOSE:
      // remove last
      let tmp = state.contextes;
      tmp.pop();
      return {
        ...state,
        contextes: tmp
      };

    default:
      return state;
  }
}
