import { Trans } from "@lingui/macro";
import { Breadcrumbs, Divider, FormLabel, Tooltip, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import BuInfo from "assets/img/buImage/BuInfo.png";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React from "react";
import { TpCoupaActivity } from "store/MasterValue/MasterValueTypes";
import { isNullOrEmpty } from "tools";

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const GetLabel = (masterValue, lang) => {
  if (!lang) {
    lang = "en";
  }

  lang = lang.toUpperCase();
  if (!masterValue) return "";

  var value = masterValue[`label_${lang}`];
  if (isNullOrEmpty(value) && lang !== "EN") {
    return GetLabel(masterValue, "EN");
  }

  return value;
};

function RequestScope({ workflow, setRequest, errors, isReadOnly, defaultLang }) {
  const request = workflow.linkedObject;
  const error = code => errors && errors.some(e => e.code === code);

  var contentGroupPart = <></>;
  if (error("coupaContentGroup")) {
    contentGroupPart = (
      <>
        <GridItem xs={12}>
          <center>
            <span style={{ color: "red" }}>
              <Trans>No Coupa Content Group to link</Trans>
            </span>
          </center>
        </GridItem>
      </>
    );
  } else if (error("coupaContentGroupAlreadyExists")) {
    contentGroupPart = (
      <>
        <GridItem xs={12}>
          <center>
            <span style={{ color: "red" }}>
              <Trans>Supplier already linked to content group</Trans>
            </span>
          </center>
        </GridItem>
      </>
    );
  } else if (error("coupaContentGroupRequestFailed")) {
    contentGroupPart = (
      <>
        <GridItem xs={12}>
          <center>
            <span style={{ color: "red" }}>
              <Trans>Coupa validation failed, please submit a new Coupa request</Trans>
            </span>
          </center>
        </GridItem>
      </>
    );
  } else if (error("coupaValidators")) {
    contentGroupPart = (
      <>
        <GridItem xs={12}>
          <center>
            <span style={{ color: "red" }}>
              <Trans>At least one validator is required</Trans>
            </span>
          </center>
        </GridItem>
      </>
    );
  } else if (!!request.coupaContentGroup) {
    contentGroupPart = (
      <>
        <GridItem xs={4}>
          <FormLabel error={error("coupaContentGroup")}>
            <Trans>Content Group</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8}>
          <Typography>{GetLabel(request.coupaContentGroup, defaultLang)}</Typography>
        </GridItem>
      </>
    );
  }

  var contentGroupUsers = <></>;
  if (!!request.validators && request.validators.length > 0) {
    contentGroupUsers = (
      <>
        <GridItem xs={12}>
          <Divider style={{ marginTop: "15px", marginBottom: "15px" }} />
        </GridItem>
        <GridItem xs={4}>
          <FormLabel error={error("coupaValidators")}>
            <Trans>Validators</Trans>
          </FormLabel>
        </GridItem>
        <GridItem xs={8}>
          <Breadcrumbs>
            {request.validators.map(item => {
              return (
                <Typography>
                  {item.firstName} {item.lastName}
                </Typography>
              );
            })}
          </Breadcrumbs>
        </GridItem>
      </>
    );
  }
  var domaineBuLevel = (
    <Tooltip
      title={
        <>
          <img src={BuInfo} alt="bu info src" />
        </>
      }
      placement="bottom"
    >
      <span>
        <HelpIcon />
      </span>
    </Tooltip>
  );

  return (
    <>
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>Request scope</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={4}>
              <span>
                <FormLabel error={error("requestDomainCode")}>
                  <Trans>Concerned domain</Trans>
                  {domaineBuLevel}
                </FormLabel>
              </span>
            </GridItem>
            <GridItem xs={8}>
              <MasterValueAutocomplete
                isEditable={!isReadOnly}
                mvType={TpCoupaActivity}
                selectedValue={request.requestDomainCode}
                onValueChange={v => setRequest({ ...request, requestDomainCode: v })}
                formatText={i => `${i.code} - ${GetMasterValueLabel(i, defaultLang)}`}
              />
            </GridItem>
            {contentGroupPart}
            {contentGroupUsers}
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
}
export default RequestScope;
