import React from 'react';

import SiteStructures from '../SiteDetail/SiteStructures';

import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from 'assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle';

function SiteStep4Structure({ site, setSite }) {
    var cardStructure = (
        <SiteStructures site={site} setSite={setSite} isEditable={true} />
    );

    return (
        <>
            {cardStructure}
        </>);
}

export default withStyles(thirdPartySearchCriteriaStyle)(SiteStep4Structure);