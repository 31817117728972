import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as ProductActions from "./store/actions/index";

import { CircularProgress } from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import SearchCriteria from "./part/search/SearchCriteria";

import { isNullOrEmpty } from "tools";
import CardError from "components/Card/CardError";
import { ProductSearch, ProductExport } from "./actions/ProductActions";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SearchResult from "./part/search/SearchResult";
import { Trans } from "@lingui/react";

function ProductListings({ openProduct, defaultLang }) {
  const [criteria, setCriteria] = useState({});
  const [state, setState] = useState({ isLoading: false });

  const cleanSearch = () => {
    setCriteria({});
  };

  useEffect(() => {
    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    var defaultPageSize = 10;

    if (isNullOrEmpty(criteria.pageSize)) {
      if (getPref !== null) {
        defaultPageSize = getPref.Pref.ResultPageSize * 1;
      }
    }
    setCriteria({ ...criteria, pageSize: defaultPageSize });
  }, []);

  const launchSearch = criteria => {
    setCriteria(criteria);
    setState({ ...state, isLoading: true });
    ProductSearch(criteria, results => setState({ isLoading: false, searchResults: results }), error => setState({ isLoading: false, error: error }));
  };

  var searchResult = null;
  if (state.isLoading) {
    searchResult = <CircularProgress />;
  } else if (state.error) {
    searchResult = <CardError error={state.error} />;
  } else if (state.searchResults) {
    searchResult = <SearchResult results={state.searchResults} openProduct={openProduct} exportProduct={ProductExport} maxExport={10000}/>;
  }

  //   var btnCreation = userHelper.IsAuthorized("product.", "edit") && <BtnCreateProduct defaultLang={defaultLang} />;

    var layoutSearchResult;
  if (!isNullOrEmpty(searchResult)) {
    layoutSearchResult = (
      <GridContainer>
        <GridItem xs={8} sm={8} md={9}>
          {searchResult}
        </GridItem>
        <GridItem xs={4} sm={4} md={3}>
          <SearchCriteria
            criteria={criteria}
            setCriteria={setCriteria}
            search={launchSearch}
            cleanSearch={cleanSearch}
            showSearchCriteriaOnSideBar={true}
            defaultLang={defaultLang}
          />
        </GridItem>
      </GridContainer>
    );
  } else {
    layoutSearchResult = (
      <SearchCriteria
        criteria={criteria}
        setCriteria={setCriteria}
        search={launchSearch}
        cleanSearch={cleanSearch}
        showSearchCriteriaOnSideBar={false}
        defaultLang={defaultLang}
      />
    );
  }

    return (
    <div>
      {/*btnCreation*/}
      {layoutSearchResult}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openProduct: product => {
      dispatch(ProductActions.OpenProduct(product.identifier));
      //dispatch(HistoryActions.addHistoryStructure(structure));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductListings);
