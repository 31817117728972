import React, { useState } from 'react';
import { IconButton, CircularProgress, OutlinedInput, InputAdornment } from '@material-ui/core';
import { ArrowForward, CheckOutlined, ErrorOutlined } from '@material-ui/icons';
import { Trans } from "@lingui/macro";
import { AuthenticateInWebo } from 'services/user/UserHelper';

export default function AuthenticationInWebo({ userIdentifier, successCallback }) {
    const [status, setStatus] = useState({ code: 'READY' });
    const [pinCode, setPinCode] = useState('');

    function CheckInWebo() {
        setStatus({ code: 'LOADING' });
        AuthenticateInWebo(userIdentifier, pinCode, (result) => {
            if (!result || result.status === "TechnicalError") {
                setStatus({ code: 'TECH_ERROR', message: result.message });
            }
            else if (result.status === 'Ok') {
                setStatus({ code: 'SUCCESS' });
                localStorage.setItem("AuthJwt", JSON.stringify({ token: result.token, expire: result.tokenExpire }));
                successCallback();
            }
            else {
                setStatus({ code: 'ERROR' });
            }
        }, (error) => {
            setStatus({ code: 'TECH_ERROR', message: error });
        });
    }

    var statusIcon = "";

    switch (status.code) {
        case 'READY':
        case 'ERROR':
        case 'TECH_ERROR':
            statusIcon = (<ArrowForward />);
            break;
        case 'LOADING':
            statusIcon = (<CircularProgress />);
            break;
        case 'SUCCESS':
            statusIcon = (<CheckOutlined />);
            break;
    }

    return (
        <form onSubmit={(e) => { e.preventDefault(); CheckInWebo(); }}>
            <div>
                <Trans> Authentication InWebo instructions </Trans>
            </div>
            <div>
                <OutlinedInput
                    disabled={status.code === 'LOADING'}
                    value={pinCode}
                    inputProps={{
                        maxLength: 8,
                        style: {
                            fontSize: '2em',
                            letterSpacing: '0.4em'
                        }
                    }}
                    onChange={e => setPinCode(e.target.value)}
                    fullWidth
                    autoFocus
                    error={status.code === 'ERROR' || status.code === 'TECH_ERROR'}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                disabled={status.code === 'LOADING'}
                                edge="end"
                                onClick={CheckInWebo}
                            >
                                {statusIcon}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <div>{status.code === 'ERROR' ? (<span style={{ color: 'red' }}><Trans> Authentication InWebo InvalidPinCod </Trans></span>) : ""}</div>
                <div>{status.message ? (<span style={{ color: 'red' }}>{status.message}</span>) : ""}</div>
            </div>
        </form>
    );
}