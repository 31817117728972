import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import PagesHeader from "components/Header/PagesHeader.jsx";
import Footer from "components/Footer/Footer.jsx";

import technicalStyle from "assets/jss/material-dashboard-pro-react/layouts/technicalStyle.jsx";

import bgImage from "assets/images/visuel_colas.jpg";

const TechnicalLayout = ({ children, classes }) => {
  document.body.style.overflow = "unset";
  return (
    <div>
      <PagesHeader />
      <div className={classes.wrapper}>
        <div className={classes.fullPage} style={{ backgroundImage: "url(" + bgImage + ")" }}>
          {children}
          <Footer white />
        </div>
      </div>
    </div>
  );
};

export default withStyles(technicalStyle)(TechnicalLayout);
