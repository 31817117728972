import { Trans } from "@lingui/macro";
import { IconButton } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Add } from "@material-ui/icons";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/Job.Actions";
import React from "react";
import { connect } from "react-redux";
import JobEmployeeHelper from "services/amc/JobEmployeeHelper";
import GenericServerAutocomplete from "components/Autocomplete/GenericServerAutocomplete";
import ContactTab from "../../../common/ContactTab";

function JobPhaseAddtionnalForemenPart({ amc, itemChanged, isReadonly }) {
  const [newContact, setNewContact] = React.useState(null);

  var jobEmployeeHelper = new JobEmployeeHelper();
  var item = !!amc.currentItem["JOB"] ? amc.currentItem["JOB"] : {};
  var dateInputProps = {};
  if (isReadonly)
    dateInputProps = {
      readOnly: { isReadonly },
      disabled: { isReadonly }
    };

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <GridContainer>
          <GridItem xs={7} sm={7} md={7}>
            <h4>
              <Trans>Additional foremen</Trans>
            </h4>
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <GenericServerAutocomplete
              selectedValue={newContact}
              searchPartialUri={"api/JobEmployees/Autocomplete/"}
              onValueChange={e => setNewContact(e)}
            />
          </GridItem>
          <GridItem xs={1} sm={1} md={1}>
            <IconButton
              onClick={() => {
                if (newContact != null) {
                  var tempContact = item.foremen;
                  try {
                    (async () => {
                      var temp = await jobEmployeeHelper.GetByLogin(newContact);
                      tempContact.push({ jobEmployeeLogin: newContact, jobEmployee: temp });
                      itemChanged({ ...item, foremen: tempContact });
                      setNewContact(null);
                    })();
                  } catch (e) {}
                }
              }}
            >
              <Add />
            </IconButton>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <ContactTab contacts={item.foremen} />
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    masterValues: state.MasterValueReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: (job, item) => {
      dispatch(Actions.ItemChanged(job, item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(JobPhaseAddtionnalForemenPart));
