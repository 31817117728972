import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

import AltaresPart from "module/supervision/components/globalHealth/AltaresPart";
import CoupaPart from "module/supervision/components/globalHealth/CoupaPart";
import ExportPart from "module/supervision/components/globalHealth/ExportPart";
import InfolegalePart from "module/supervision/components/globalHealth/InfolegalePart";
import InvoicePart from "module/supervision/components/globalHealth/InvoicePart";
import NotificationPart from "module/supervision/components/globalHealth/NotificationPart";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

function GlobalHealth() {
  return (
    <GridContainer>
      <GridItem xs={6}>
        <GridContainer>
          <GridItem xs={12}>
            <NotificationPart />
          </GridItem>
          <GridItem xs={12}>
            <InfolegalePart />
          </GridItem>
          <GridItem xs={12}>
            <AltaresPart />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem xs={6}>
        <GridContainer>
          <GridItem xs={12}>
            <CoupaPart />
          </GridItem>
          <GridItem xs={12}>
            <ExportPart />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}

export default GlobalHealth;
