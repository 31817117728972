import { Trans } from "@lingui/macro";
import { FormLabel } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import amcSearchStyle from "assets/jss/mdmcolas/amc/amcSearchStyle.jsx";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import OldStructureAutocompleteWithDefaultValue from "components/Autocomplete/OldStructureAutocompleteWithDefaultValue";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FieldText from "components/Common/FieldText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import * as Actions from "module/amc/store/actions/Project.Actions";
import { displayWarningFromErrorMessages, getErrorStateFromErrorMessages } from "module/tools";
import React from "react";
import { connect } from "react-redux";
import { StructureUe } from "store/MasterValue/MasterValueTypes";
import { JobBu } from "store/MasterValue/MasterValueTypes";

function ProjectIdentifierAndSourcePart({ amc, structureUes, itemChanged, isReadonly }) {
  var item = !!amc.currentItem["PROJECT"] ? amc.currentItem["PROJECT"] : {};
  var warnings = amc.errors["PROJECT"];

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Identifiers and sources</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Label</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText isEditable={!isReadonly} id="label" value={item.label} onChange={e => itemChanged({ ...item, label: e.target.value })} />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Id Erp</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText isEditable={!isReadonly} id="idErp" value={item.idErp} onChange={e => itemChanged({ ...item, idErp: e.target.value })} />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Local Identifier</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={!isReadonly}
              id="localIdentifier"
              value={item.sourceApplicationId}
              onChange={e => itemChanged({ ...item, sourceApplicationId: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Local Application</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <FieldText
              isEditable={false}
              id="applicationSource"
              value={item.sourceApplication}
              onChange={e => itemChanged({ ...item, sourceApplication: e.target.value })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>Ue</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <OldStructureAutocompleteWithDefaultValue
              selectedValue={item.ueIdentifier}
              readOnly={isReadonly}
              structures={structureUes}
              onValueChange={e => itemChanged({ ...item, ueIdentifier: e })}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            {displayWarningFromErrorMessages(warnings, "PROJECT/BUCODE")}
            <FormLabel error={getErrorStateFromErrorMessages(warnings, "PROJECT/BUCODE")}>
              <Trans>Bu</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <MasterValueAutocomplete
              mvType={JobBu}
              selectedValue={item.buCode}
              isEditable={!isReadonly}
              prefixCode={true}
              error={getErrorStateFromErrorMessages(warnings, "PROJECT/BUCODE")}
              onValueChange={e => itemChanged({ ...item, buCode: e })}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

const mapStateToProps = state => {
  return {
    amc: state.AMC_Reducer,
    masterValues: state.MasterValueReducer,
    structureUes: state.MasterValueReducer[StructureUe],
    defaultLang: state.AuthenticationReducer.user.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    itemChanged: item => {
      dispatch(Actions.ItemChanged(item));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(amcSearchStyle)(ProjectIdentifierAndSourcePart));
