import React from 'react';
import axios from 'axios';
import * as Actions from './types';
import { Trans } from '@lingui/macro';
import { RootStore } from 'store/RootStore';

export function ItemChanged(job, item) {


    if (!job.jobPhases)
        job.jobPhases = [];

    var index = -1;
    if (!!item.identifier && typeof (item.identifier) != "undefined")
        index = job.jobPhases.map(function (e) { return e.identifier; }).indexOf(item.identifier);
    if (index < 0 && !!item.localId && typeof (item.localId) != "undefined")
        index = job.jobPhases.map(function (e) { return e.localId; }).indexOf(item.localId);

    if (index > -1) {
        job.jobPhases[index] = item;
    }
    else
        job.jobPhases.push(item);

    return (dispatch, getState) => {
        dispatch({
            type: Actions.AMC_ITEM_CHANGED,
            module: 'JOBPHASE',
            item: item
        });
        dispatch({
            type: Actions.AMC_ITEM_CHANGED,
            module: 'JOB',
            item: job
        });
    };
}


export function DeletePhase(job) {
    return (dispatch, getState) => {
        dispatch({
            type: Actions.AMC_ITEM_CHANGED,
            module: 'JOB',
            item: job
        });
    };
}

export function SelectItem(item) {
    return (dispatch, getState) => {
        dispatch({
            type: Actions.AMC_GET_END,
            module: 'JOBPHASE',
            item: item
        });
    };
}