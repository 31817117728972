import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { SearchOutlined } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import React from "react";
import { connect } from "react-redux";
import * as Actions from "../../store/actions/MigCode.Actions";

function MigCodeSearchCriteria({ migCodeCtx, searchMigCode, setCriterias, classes }) {
  var criterias = migCodeCtx.criterias;

  var searchGridSize = 4;

  var launchSearch = e => {
    e.preventDefault();
    setCriterias({
      ...criterias,
      startPage: 0,
      pageSize: 10,
      orderProperty: "identifier",
      isDescendingOrder: false
    });
    searchMigCode();
  };

  var cleanSearchCriteria = e => {
    e.preventDefault();

    setCriterias({
      migCode: "",
      legalIdentifier: "",
      startPage: 0,
      pageSize: 10,
      orderProperty: "identifier",
      isDescendingOrder: false
    });
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <SearchOutlined />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <div>
              <form onSubmit={launchSearch} autoComplete="off">
                <GridContainer>
                  <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                    <CustomInput
                      labelText={<Trans>Mig code</Trans>}
                      id="criteriaMigCode"
                      onChange={e => setCriterias({ ...criterias, migCode: e.target.value })}
                      value={criterias.migCode}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                    <CustomInput
                      labelText={<Trans>Legal identifier or Duns</Trans>}
                      id="criteriaLEgalIdentifier"
                      onChange={e => setCriterias({ ...criterias, legalIdentifier: e.target.value })}
                      value={criterias.legalIdentifier}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                    <CustomInput
                      labelText={<Trans>Label</Trans>}
                      id="criteriaLabel"
                      onChange={e => setCriterias({ ...criterias, label: e.target.value })}
                      value={criterias.label}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <div className={classes.flexRight}>
                  <Button onClick={cleanSearchCriteria}>
                    <Trans> Clean </Trans>
                  </Button>
                  <Button type="submit" color="info" onClick={launchSearch}>
                    <Trans>Search</Trans>
                  </Button>
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = state => {
  return {
    migCodeCtx: state.TP_MigCode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchMigCode: () => {
      dispatch(Actions.SearchMigCode());
    },
    setCriterias: criteria => {
      dispatch(Actions.SetCriterias(criteria));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(MigCodeSearchCriteria));
