import React, { useEffect, useState } from "react";
import ReactTable from "react-table-6";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../store/actions";

import Button from "../../../components/CustomButtons/Button";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardHeader from "../../../components/Card/CardHeader";

import Delete from "@material-ui/icons/Delete";

import DunsMonitoringSearchCriteria from "module/tpCopernic/components/DunsMonitoring/DunsMonitoringSearchCriteria";

import { I18n } from "@lingui/react";
import { Trans } from "@lingui/macro";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";

function DunsMonitoring({ dunsMonitoringCtx, removeDuns, classes }) {
  const dispatch = useDispatch();

  const [criterias, setCriterias] = useState(dunsMonitoringCtx.criterias);
  var result = dunsMonitoringCtx.result;

  const reactTableColumns = [
    {
      filterable: false,
      sorting: false,
      accessor: "actions",
      width: 50
    },
    {
      Header: <Trans>Duns</Trans>,
      accessor: "duns"
    },
    {
      Header: <Trans>Registration name</Trans>,
      accessor: "registrationName"
    },
    {
      Header: <Trans>Status</Trans>,
      accessor: "status"
    },
    {
      Header: <Trans>Creation date</Trans>,
      accessor: "creationDate"
    },
    {
      Header: <Trans>Last check date</Trans>,
      accessor: "lastCheckDate"
    },
    {
      Header: <Trans>Last update date</Trans>,
      accessor: "lastUpdateDate"
    },
    {
      Header: <Trans>request date</Trans>,
      accessor: "requestDate"
    }
  ];

  var reactTable = "";

  if (!!result) {
    var items = ConvertDatas(result.items, removeDuns, dunsMonitoringCtx.criterias);

    reactTable = (
      <Card>
        <CardBody>
          <ReactTable
            manual
            data={items}
            page={result.pageIndex}
            pages={result.nbOfPages}
            columns={reactTableColumns}
            sorted={[{ id: dunsMonitoringCtx.criterias.orderProperty, desc: dunsMonitoringCtx.criterias.isDescendingOrder }]}
            onPageChange={(state, instance) => {
              var tempCriteria = dunsMonitoringCtx.criterias;
              tempCriteria.startPage = state;
              setCriterias({ ...dunsMonitoringCtx.criterias, startPage: state });
              dispatch(Actions.SearchDunsMonitoring(tempCriteria));
            }}
            onPageSizeChange={(state, instance) => {
              var tempCriteria = dunsMonitoringCtx.criterias;
              tempCriteria.pageSize = state;
              setCriterias({ ...dunsMonitoringCtx.criterias, pageSize: state });
              dispatch(Actions.SearchDunsMonitoring(tempCriteria));
            }}
            onSortedChange={(newSorted, column, shiftKey) => {
              var tempCriteria = dunsMonitoringCtx.criterias;
              tempCriteria.orderProperty = newSorted[0].id;
              tempCriteria.isDescendingOrder = newSorted[0].desc;
              setCriterias({ ...dunsMonitoringCtx.criterias, orderProperty: newSorted[0].id, isDescendingOrder: newSorted[0].desc });
              dispatch(Actions.SearchDunsMonitoring(tempCriteria));
            }}
            defaultPageSize={result.pageSize}
            showPaginationBottom={true}
            className="-striped -highlight"
            style={ReactTableStyle.main}
          />
        </CardBody>
      </Card>
    );
  }

  return (
    <div>
      <DunsMonitoringSearchCriteria />

      {reactTable}
    </div>
  );
}

///-----------------------------------------------------------
/// Convertit le résultat d'une recherche en item pour react-table
///-----------------------------------------------------------
function ConvertDatas(items, removeDuns, searchCriteria) {
  if (!items || items.length === 0) return [];

  var result = [];
  items.map((prop, key) => {
    var removeButton = "";
    if (prop.status !== "ToRemove" && prop.status !== "Unmanaged") {
      removeButton = (
        // we've added some custom button actions
        <div className="actions-right">
          {/* use this button to add a like kind of action */}
          <Button
            justIcon
            round
            simple
            onClick={() => {
              prop.status = "ToRemove";
              removeDuns(prop, searchCriteria);
            }}
            color="info"
            className="like"
          >
            <Delete />
          </Button>
        </div>
      );
    }
    result.push({
      actions: <div>{removeButton}</div>,
      ...prop
    });
  });

  return result;
}

const ReactTableStyle = {
  main: {
    height: "100%",
    overflow: "hidden"
  }
};

const mapDispatchToProps = dispatch => {
  return {
    removeDuns: item => {
      dispatch(Actions.RemoveDunsFromMonitoring(item));
    }
  };
};

const mapStateToProps = state => {
  return {
    dunsMonitoringCtx: state.TP_DunsMonitoring
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(DunsMonitoring));
