import React from "react";
import FieldText from "components/Common/FieldText";
import GridItem from "components/Grid/GridItem";
import Muted from "components/Typography/Muted";

const HeaderField = ({ label, value, onChange, isEditable, error, showErrorTooltip, fieldType, warning }) => {
  let type = fieldType ? fieldType : "text";

  let textField = (
    <FieldText
      isEditable={isEditable}
      value={value}
      onChange={onChange}
      size="small"
      fullWidth
      margin="none"
      inputProps={{ style: { fontSize: "14px" } }}
      style={{ fontSize: "14px" }}
      error={error}
      showErrorTooltip={showErrorTooltip}
      type={type}
      warning={warning}
    />
  );

  return (
    <>
      <GridItem xs={4} sm={4} md={4}>
        <Muted>{label}</Muted>
      </GridItem>
      <GridItem xs={8} sm={8} md={8}>
        {textField}
      </GridItem>
    </>
  );
};
export default HeaderField;
