import axios from "axios";
import { ApimGet, ApimMultipleRequest } from "components/Common/ApimSender";
import * as Actions from "./types";

export function ThirdPartyContactAdd(contact) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_THIRDPARTY_CONTACT_ADD,
      contact: contact
    });
  };
}

export function ThirdPartyContactGet(thirdPartyId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/ThirdParties/" + thirdPartyId + "/contacts",
      contacts => {
        dispatch({
          type: Actions.TP_COPERNIC_THIRDPARTY_CONTACT_GET,
          contacts: contacts
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_THIRDPARTY_ERROR,
          error
        });
      }
    );
  };
}

export function ThirdPartyContactRemove(contactId, isNewContact) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_THIRDPARTY_CONTACT_REM,
      contactId,
      isNewContact
    });
  };
}

export function ThirdPartyContactUpdate(contact) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_THIRDPARTY_CONTACT_UPD,
      contact
    });
  };
}

export function ThirdPartyContactSave(thirdPartyId, contacts) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_THIRDPARTY_CONTACT_SAVE_BEGIN
    });

    const url = "api/TpCopernic/ThirdParties/" + thirdPartyId + "/contacts";
    var urlDataMethod = [];

    contacts.map(contact => {
      if (contact.action === "INSERT") {
        contact.contact.id = 0;
        contact.contact.details = contact.contact.details.map(detail => {
          return { ...detail, id: 0 };
        });
        urlDataMethod.push({ url, data: contact.contact, method: "POST" });
      }
      if (contact.action === "UPDATE") {
        urlDataMethod.push({ url, data: contact.contact, method: "POST" });
      }
      if (contact.action === "DELETE") {
        urlDataMethod.push({ url: url + "/" + contact.contact.id, method: "DELETE" });
      }
    });

    ApimMultipleRequest(
      urlDataMethod,
      () =>
        ApimGet(
          url,
          contacts => {
            dispatch({
              type: Actions.TP_COPERNIC_THIRDPARTY_CONTACT_SAVE_END,
              contacts: contacts
            });
          },
          error => {
            console.error(error);
            dispatch({
              type: Actions.TP_COPERNIC_THIRDPARTY_SAVE_ERROR,
              error
            });
          }
        ),
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_THIRDPARTY_SAVE_ERROR,
          error
        });
      }
    );
  };
}
