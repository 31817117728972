import { Trans } from "@lingui/macro";
import withStyles from "@material-ui/core/styles/withStyles";
import { SearchOutlined } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import * as Actions from "../../store/actions";

function DunsMonitoringSearchCriteria({ dunsMonitoringCtx, searchDunsMonitoring, classes }) {
  const dispatch = useDispatch();

  const [criterias, setCriterias] = useState(dunsMonitoringCtx.criterias);
  var [dunsMonitoringStatusDefaultValue, setDunsMonitoringStatusDefaultValue] = useState([]);

  var searchGridSize = 4;

  var launchSearch = e => {
    e.preventDefault();
    searchDunsMonitoring(criterias);
  };

  var dunsMonitoringStatusOptions = [
    { value: "Created", label: <Trans>Created</Trans> },
    { value: "Error", label: <Trans>Error</Trans> },
    { value: "Managed", label: <Trans>Managed</Trans> },
    { value: "Pending", label: <Trans>Pending</Trans> },
    { value: "ToUpdate", label: <Trans>To Update</Trans> },
    { value: "Unmanaged", label: <Trans>Unmanaged</Trans> }
  ];

  var dunsMonitoringStatusChange = e => {
    var newcriterias = criterias;
    var tabs = [];
    newcriterias.status = "";
    if (e !== null && e !== undefined) {
      e.map(item => {
        tabs.push(item);
        newcriterias.status += item.value + "|";
      });
    }
    setDunsMonitoringStatusDefaultValue(tabs);
    setCriterias({ ...newcriterias });
  };

  var cleanSearchCriteria = e => {
    e.preventDefault();

    setDunsMonitoringStatusDefaultValue([]);
    setCriterias({
      duns: "",
      status: ""
    });
  };

  var divMultiSelectStyle = { width: "100%" };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <SearchOutlined />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <div>
              <form onSubmit={launchSearch} autoComplete="off">
                <GridContainer>
                  <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                    <CustomInput
                      labelText={<Trans>Duns</Trans>}
                      id="criteriaDuns"
                      onChange={e => setCriterias({ ...criterias, duns: e.target.value })}
                      value={criterias.duns}
                      formControlProps={{
                        fullWidth: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                    <div style={divMultiSelectStyle}>
                      <Select
                        isMulti
                        closeMenuOnSelect={false}
                        options={dunsMonitoringStatusOptions}
                        placeholder={<Trans>Status</Trans>}
                        menuPlacement="auto"
                        menuPosition="fixed"
                        onChange={dunsMonitoringStatusChange}
                        value={dunsMonitoringStatusDefaultValue}
                      ></Select>
                    </div>
                  </GridItem>
                </GridContainer>
                <div className={classes.flexRight}>
                  <Button onClick={cleanSearchCriteria}>
                    <Trans> Clean </Trans>
                  </Button>
                  <Button type="submit" color="info" onClick={launchSearch}>
                    <Trans>Search</Trans>
                  </Button>
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

const mapStateToProps = state => {
  return {
    dunsMonitoringCtx: state.TP_DunsMonitoring
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchDunsMonitoring: criteria => {
      dispatch(Actions.SearchDunsMonitoring(criteria));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(DunsMonitoringSearchCriteria));
