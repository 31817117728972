import { PublicClientApplication } from "@azure/msal-browser";
import Axios from "axios";
import { apimLoginRequest, graphConfig, graphLoginRequest, msalConfig } from "services/authentication/MsalAuth";
import { isNull } from "tools";

export function ApimGet(relativeUrl, callbackFn, errorFn, callback400Fn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  var OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
  var ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

  msalInstance
    .acquireTokenSilent({
      ...apimLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(apiTokenResponse => {
      let token = apiTokenResponse.accessToken;
      let url = ApiMdmColasEndPoint + relativeUrl;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };
      let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
      if (authJwt) {
        headers = { ...headers, AuthJwt: authJwt.token };
      }

      return Axios(url, { headers })
        .then(r => {
          if (r.status === 200) {
            return r.data;
          } else if (r.status === 204) {
            return [];
          }
        })
        .then(json => {
          if (callbackFn) {
            callbackFn(json);
          }
        })
        .catch(error => {
          if (error && error.response && error.response.status === 400 && callback400Fn) {
            callback400Fn(error);
            return;
          }

          if (errorFn) {
            errorFn(error);
          }
        });
    })
    .catch(error => {
      if (errorFn) {
        errorFn(error);
      }
    });
}

export async function ApimGetAsync(relativeUrl) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  var OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
  var ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

  return msalInstance
    .acquireTokenSilent({
      ...apimLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(apiTokenResponse => {
      let token = apiTokenResponse.accessToken;
      let url = ApiMdmColasEndPoint + relativeUrl;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };
      let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
      if (authJwt) {
        headers = { ...headers, AuthJwt: authJwt.token };
      }

      Axios(url, { headers })
        .then(r => {
          if (r.status === 200) {
            return r.data;
          } else if (r.status === 204) {
            return [];
          }
        })
        .then(json => {
          return json;
        });
    });
}

export const ApimGetPromise = relativeUrl => {
  var ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;
  return GetPromise(ApiMdmColasEndPoint + relativeUrl);
};

export const GetPromise = absoluteUrl =>
  new Promise((callbackFn, errorFn) => {
    const msalInstance = new PublicClientApplication(msalConfig);

    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    var OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;

    msalInstance
      .acquireTokenSilent({
        ...apimLoginRequest,
        account: msalInstance.getActiveAccount()
      })
      .then(apiTokenResponse => {
        let token = apiTokenResponse.accessToken;
        let url = absoluteUrl;
        let headers = {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + token
        };
        let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
        if (authJwt) {
          headers = { ...headers, AuthJwt: authJwt.token };
        }

        return Axios(url, { headers })
          .then(r => {
            if (r.status === 200) {
              return r.data;
            } else if (r.status === 204) {
              return [];
            }
          })
          .then(json => {
            if (callbackFn) {
              callbackFn(json);
            }
          })
          .catch(error => {
            if (errorFn) {
              errorFn(error);
            }
          });
      })
      .catch(error => {
        if (errorFn) {
          errorFn(error);
        }
      });
  });

export const ApimPostPromise = (relativeUrl, data) =>
  new Promise((callbackFn, errorFn) => {
    ApimPost(relativeUrl, data, callbackFn, errorFn);
  });

export function ApimPost(relativeUrl, data, callbackFn, errorFn, callback400Fn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  var OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
  var ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

  msalInstance
    .acquireTokenSilent({
      ...apimLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(apiTokenResponse => {
      let token = apiTokenResponse.accessToken;
      let url = ApiMdmColasEndPoint + relativeUrl;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };
      let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
      if (authJwt) {
        headers = { ...headers, AuthJwt: authJwt.token };
      }

      return Axios.post(url, data, { headers })
        .then(r => {
          if (r.status === 200 || r.status === 201) {
            return r.data;
          } else if (r.status === 204) {
            return [];
          }
        })
        .then(json => {
          if (callbackFn) {
            callbackFn(json);
          }
        })
        .catch(error => {
          if (error && error.response && error.response.status === 400 && callback400Fn) {
            callback400Fn(error);
            return;
          }

          if (errorFn) {
            errorFn(error);
          }
        });
    })
    .catch(error => {
      if (errorFn) {
        errorFn(error);
      }
    });
}

export function ApimMultipleGet(urlNames, callbackGlobalFn, errorGlobalFn, callbackSingleFn, errorSingleFn) {
  ApimMultipleRequest(
    urlNames.map(r => {
      return { ...r, method: "GET" };
    }),
    callbackGlobalFn,
    errorGlobalFn,
    callbackSingleFn,
    errorSingleFn
  );
}

export function ApimMultiplePost(urlNamesData, callbackGlobalFn, errorGlobalFn, callbackSingleFn, errorSingleFn) {
  ApimMultipleRequest(
    urlNamesData.map(r => {
      return { ...r, method: "POST" };
    }),
    callbackGlobalFn,
    errorGlobalFn,
    callbackSingleFn,
    errorSingleFn
  );
}

export function ApimMultipleRequest(urlNamesDataMethods, callbackGlobalFn, errorGlobalFn, callbackSingleFn, errorSingleFn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  let OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
  let ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

  return msalInstance
    .acquireTokenSilent({
      ...apimLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(apiTokenResponse => {
      let token = apiTokenResponse.accessToken;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };
      let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
      if (authJwt) {
        headers = { ...headers, AuthJwt: authJwt.token };
      }

      var promises = [];
      urlNamesDataMethods
        .filter(a => !isNull(a))
        .forEach(urlNamesDataMethod => {
          if (urlNamesDataMethod.method === "POST") {
            promises.push(
              Axios.post(ApiMdmColasEndPoint + urlNamesDataMethod.url, urlNamesDataMethod.data, { headers })
                .then(r => {
                  if (r.status === 200) {
                    return { name: urlNamesDataMethod.name, data: r.data };
                  } else if (r.status === 204) {
                    return { name: urlNamesDataMethod.name, data: [] };
                  }
                })
                .then(r => {
                  if (callbackSingleFn) {
                    callbackSingleFn(r);
                  }
                  return r;
                })
                .catch(error => {
                  if (errorSingleFn) {
                    errorSingleFn(error);
                  }
                })
            );
          } else if (urlNamesDataMethod.method === "GET") {
            promises.push(
              Axios.get(ApiMdmColasEndPoint + urlNamesDataMethod.url, { headers })
                .then(r => {
                  if (r.status === 200) {
                    return { name: urlNamesDataMethod.name, data: r.data };
                  } else if (r.status === 204) {
                    return { name: urlNamesDataMethod.name, data: [] };
                  }
                })
                .then(r => {
                  if (callbackSingleFn) {
                    callbackSingleFn(r);
                  }
                  return r;
                })
                .catch(error => {
                  if (errorSingleFn) {
                    errorSingleFn(error);
                  }
                })
            );
          } else if (urlNamesDataMethod.method === "DELETE") {
            promises.push(
              Axios.delete(ApiMdmColasEndPoint + urlNamesDataMethod.url, { headers })
                .then(r => {
                  if (r.status === 200) {
                    return [];
                  }
                })
                .then(json => {
                  if (callbackSingleFn) {
                    callbackSingleFn(json);
                  }
                })
                .catch(error => {
                  if (errorSingleFn) {
                    errorSingleFn(error);
                  }
                })
            );
          }
        });

      Axios.all(promises)
        .then(res => {
          if (callbackGlobalFn) {
            callbackGlobalFn(res);
          }
        })
        .catch(error => {
          if (errorGlobalFn) {
            errorGlobalFn(error);
          }
        });
    })
    .catch(error => {
      if (errorGlobalFn) {
        errorGlobalFn(error);
      }
    });
}

export function ApimPut(relativeUrl, data, callbackFn, errorFn, callback400Fn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  var OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
  var ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

  msalInstance
    .acquireTokenSilent({
      ...apimLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(apiTokenResponse => {
      let token = apiTokenResponse.accessToken;
      let url = ApiMdmColasEndPoint + relativeUrl;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };
      let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
      if (authJwt) {
        headers = { ...headers, AuthJwt: authJwt.token };
      }

      return Axios.put(url, data, { headers })
        .then(r => {
          if (r.status === 200 || r.status === 201) {
            return r.data;
          } else if (r.status === 204) {
            return [];
          }
        })
        .then(json => {
          if (callbackFn) {
            callbackFn(json);
          }
        })
        .catch(error => {
          if (error && error.response && error.response.status === 400 && callback400Fn) {
            callback400Fn(error);
            return;
          }

          if (errorFn) {
            errorFn(error);
          }
        });
    })
    .catch(error => {
      if (errorFn) {
        errorFn(error);
      }
    });
}

export function ApimDelete(relativeUrl, callbackFn, errorFn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  var OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
  var ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

  msalInstance
    .acquireTokenSilent({
      ...apimLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(apiTokenResponse => {
      let token = apiTokenResponse.accessToken;
      let url = ApiMdmColasEndPoint + relativeUrl;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };
      let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
      if (authJwt) {
        headers = { ...headers, AuthJwt: authJwt.token };
      }
      Axios.delete(url, { headers })
        .then(r => {
          if (r.status === 200) {
            return [];
          }
        })
        .then(json => {
          if (callbackFn) {
            callbackFn(json);
          }
        })
        .catch(error => {
          if (errorFn) {
            errorFn(error);
          }
        });
    })
    .catch(error => {
      if (errorFn) {
        errorFn(error);
      }
    });
}

export const ApimDeletePromise = relativeUrl =>
  new Promise((callbackFn, errorFn) => {
    ApimDelete(relativeUrl, callbackFn, errorFn);
  });

export function ApimUploadFile(relativeUrl, file, callbackFn, errorFn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  var OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
  var ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

  msalInstance
    .acquireTokenSilent({
      ...apimLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(apiTokenResponse => {
      let token = apiTokenResponse.accessToken;
      let url = ApiMdmColasEndPoint + relativeUrl;
      let headers = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };
      let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
      if (authJwt) {
        headers = { ...headers, AuthJwt: authJwt.token };
      }
      var formdata = new FormData();
      formdata.append("file", file, file.name);

      return Axios.post(url, formdata, { headers })
        .then(r => {
          if (r.status === 200 || r.status === 201) {
            return r.data;
          } else if (r.status === 204) {
            return [];
          }
        })
        .then(json => {
          if (callbackFn) {
            callbackFn(json);
          }
        })
        .catch(error => {
          if (errorFn) {
            errorFn(error);
          }
        });
    })
    .catch(error => {
      if (errorFn) {
        errorFn(error);
      }
    });

  //   fetch(url, requestOptions)
  //     .then(r => {
  //       if (r.status === 200) {
  //         return r.json();
  //       } else if (r.status === 204) {
  //         return [];
  //       }

  //       throw new Error(r.message);
  //     })
  //     .catch(error => {
  //       if (errorFn) {
  //         errorFn(error);
  //       }
  //     })
  //     .then(responseJson => {
  //       if (!!callbackFn) callbackFn(responseJson);
  //     });
  // })
  // .catch(error => {
  //   if (errorFn) {
  //     errorFn(error);
  //   }
  // });
}

export function ApimGetDownloadFile(relativeUrl, fileName, callbackFn, errorFn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  var OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
  var ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

  msalInstance
    .acquireTokenSilent({
      ...apimLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(apiTokenResponse => {
      let token = apiTokenResponse.accessToken;
      let url = ApiMdmColasEndPoint + relativeUrl;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };
      let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
      if (authJwt) {
        headers = { ...headers, AuthJwt: authJwt.token };
      }
      // via Axios, le fichier t�l�charg� a un pb d'encoding, on utilise fetch � la place
      fetch(url, { headers, method: "GET" })
        .then(response => {
          if (response.status === 200) return response.blob();
          else if (response.status === 204) {
            return [];
          }
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          if (!!callbackFn) {
            callbackFn();
          }
        })
        .catch(error => {
          if (errorFn) {
            errorFn(error.message);
          }
        });
    })
    .catch(error => {
      if (errorFn) {
        errorFn(error);
      }
    });
}

export function ApimPostDownloadFile(relativeUrl, data, fileName, callbackFn, errorFn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  var OcpApimSubscriptionKey = process.env.REACT_APP_APIM_KEY;
  var ApiMdmColasEndPoint = process.env.REACT_APP_APIM_URL;

  msalInstance
    .acquireTokenSilent({
      ...apimLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(apiTokenResponse => {
      let token = apiTokenResponse.accessToken;
      let url = ApiMdmColasEndPoint + relativeUrl;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Ocp-Apim-Subscription-Key": OcpApimSubscriptionKey,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };
      let authJwt = JSON.parse(localStorage.getItem("AuthJwt"));
      if (authJwt) {
        headers = { ...headers, AuthJwt: authJwt.token };
      }
      // via Axios, le fichier t�l�charg� a un pb d'encoding, on utilise fetch � la place
      fetch(url, { headers, body: JSON.stringify(data), method: "POST" })
        .then(response => {
          if (response.status === 200) return response.blob();
          else if (response.status === 204) {
            return [];
          }
        })
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          if (!!callbackFn) {
            callbackFn();
          }
        })
        .catch(error => {
          if (errorFn) {
            errorFn(error.message);
          }
        });
    })
    .catch(error => {
      if (errorFn) {
        errorFn(error);
      }
    });
}

export function GraphGet(relativeUrl, callbackFn, errorFn, callback400Fn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance
    .acquireTokenSilent({
      ...graphLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(graphTokenResponse => {
      let token = graphTokenResponse.accessToken;
      let url = graphConfig.graphMeEndpoint + relativeUrl;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };

      Axios(url, { headers })
        .then(r => {
          if (r.status === 200) {
            return r.data;
          } else if (r.status === 204) {
            return [];
          }
        })
        .then(json => {
          if (callbackFn) {
            callbackFn(json);
          }
        })
        .catch(error => {
          if (error && error.response && error.response.status === 400 && callback400Fn) {
            callback400Fn(error);
            return;
          }

          if (errorFn) {
            errorFn(error);
          }
        });
    });
}

export function GraphGetBase64(relativeUrl, callbackFn, errorFn, callback400Fn) {
  const msalInstance = new PublicClientApplication(msalConfig);

  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  msalInstance
    .acquireTokenSilent({
      ...graphLoginRequest,
      account: msalInstance.getActiveAccount()
    })
    .then(graphTokenResponse => {
      let token = graphTokenResponse.accessToken;
      let url = graphConfig.graphMeEndpoint + relativeUrl;
      let headers = {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token
      };

      const options = {
        method: "GET",
        headers: headers
      };

      fetch(url, options)
        .then(response => response.blob())
        .then(
          blob =>
            new Promise(callback => {
              let reader = new FileReader();
              reader.onload = function() {
                callback(this.result);
              };
              reader.readAsDataURL(blob);
            })
        )
        .then(json => {
          if (callbackFn) {
            callbackFn(json);
          }
        })
        .catch(error => {
          if (error && error.response && error.response.status === 400 && callback400Fn) {
            callback400Fn(error);
            return;
          }

          if (errorFn) {
            errorFn(error);
          }
        });
    });
}
