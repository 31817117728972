import React from "react";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { AppBar, Hidden, Toolbar } from "@material-ui/core";

// material-ui icons
import { Menu, MoreVert, ViewList } from "@material-ui/icons";

// core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import { useLocation } from "react-router-dom";
import { isNull } from "tools";

const HeaderBrand = ({ routes }) => {
  let location = useLocation();
  var name;
  routes
    .filter(r => !isNull(r))
    .map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if (prop.path === location.pathname) {
        name = prop.name;
      }
      return null;
    });
  if (name) {
    return name;
  } else {
    return "Default Brand Name";
  }
};

const Header = ({ sidebarMinimize, classes, miniActive, rtlActive, empty, handleDrawerToggle, routes }) => {
  const cssSidebarMinimize = cx(classes.sidebarMinimize, {
    [classes.sidebarMinimizeRTL]: rtlActive
  });
  return (
    <AppBar className={classes.appBar} style={{top:"auto"}}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          <div className={cssSidebarMinimize}>
            {!miniActive ? (
              <Button justIcon round color="white" onClick={sidebarMinimize}>
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button justIcon round color="white" onClick={sidebarMinimize}>
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title} color="transparent">
            <HeaderBrand routes={routes} />
          </Button>
        </div>
        <Hidden smDown implementation="css">
          {!empty && <HeaderLinks rtlActive={rtlActive} />}
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button className={classes.appResponsive} color="transparent" justIcon aria-label="open drawer" onClick={handleDrawerToggle}>
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(headerStyle)(Header);
