import { RootStore } from "store/RootStore";
import { isArrayNullOrEmpty, isNull, isNullOrEmpty } from "../../module/tools";
import { ApimGet, ApimPost } from "components/Common/ApimSender";

export function HasRight(right) {
  if (isNullOrEmpty(right)) {
    return false;
  }

  var rights = RootStore.getState().AuthenticationReducer?.user?.rights;
  if (!rights) return false;

  return rights.some(r => r === right);
}

export function HasAllRights(arrayRights) {
  if (isArrayNullOrEmpty(arrayRights)) {
    return false;
  }

  var rights = RootStore.getState().AuthenticationReducer?.user?.rights;
  if (!rights) return false;

  return arrayRights.every(a => rights.some(r => r === a));
}

export function HasOneOfRights(arrayRights) {
  if (isArrayNullOrEmpty(arrayRights)) {
    return false;
  }

  var rights = RootStore.getState().AuthenticationReducer?.user?.rights;
  if (!rights) return false;

  return arrayRights.some(a => rights.some(r => r === a));
}

export function HasRightOnDomain(domain) {
  if (isNullOrEmpty(domain)) {
    return false;
  }

  var rights = RootStore.getState().AuthenticationReducer?.user?.rights;
  if (!rights) return false;

  return rights.some(r => r.split(".")[0] === domain);
}

export function GetIdentifiedUser(login, callbackFn, errorFn) {
  return ApimGet("api/IdentifiedUsers/byLogin/" + login, callbackFn, errorFn);
}

export function SearchIdentifiedUser(keyword, callbackFn, errorFn) {
  ApimGet("api/IdentifiedUsers/search/" + keyword, callbackFn, errorFn);
}

export function GetCurrentUser(callbackFn, errorFn) {
  ApimGet("api/Users", callbackFn, errorFn);
}

export function GetUserFromDirectory(login, callbackFn, errorFn) {
  ApimGet("api/UsersDirectory/" + login, callbackFn, errorFn);
}

export function GetUsersByProfileTerritories(profileCodes, countryCode, ertId, callbackFn, errorFn) {
  ApimPost("api/Users/ByProfileTerritories", { profileCodes, countryCode, ertId }, callbackFn, errorFn);
}

export function AuthenticateInWebo(login, pinCode, callbackFn, errorFn) {
  ApimPost("api/Users/authInWebo", { pinCode: pinCode }, callbackFn, errorFn);
}

export function ChangeUserLanguage(language, callbackFn, errorFn) {
  ApimPost("api/Users/change", { language: language }, callbackFn, errorFn);
}
